import { ServiceController } from '@services/ServiceController'
import { EnumHaasOrderType, orderTypeToString } from '../../enums/EnumHaasOrderType'
import { EnumOrderDirection, orderDirectionToString } from '../../enums/EnumOrderDirection'
import { CloudTradeMarket } from '../../public/CloudTradeMarket'
import { UserAccount } from './UserAccount'

export class UserOrder {
  private services: ServiceController

  unix: number

  orderId: string
  stopOrderId: string

  accountId: string
  get account(): UserAccount {
    return this.services.accountService.getAccount(this.accountId) as UserAccount
  }

  marketTag: string
  get market(): CloudTradeMarket {
    return this.services.initData.getTradeMarket(this.marketTag) as CloudTradeMarket
  }

  direction: EnumOrderDirection
  directionAsString: string

  type: EnumHaasOrderType
  get typeAsString(): string {
    return orderTypeToString(this.type)
  }

  orderPrice: number
  triggerPrice: number

  orderAmount: number
  tradeAmount: number

  get amountLabel(): string {
    return this.market?.amountLabel
  }

  status: string

  notes: string

  constructor(services: ServiceController, accountId: string, jsonData: any) {
    this.services = services

    this.unix = jsonData['U']

    this.orderId = jsonData['OID']
    this.stopOrderId = jsonData['SID']

    this.accountId = accountId
    this.marketTag = jsonData['M']

    this.direction = jsonData['D']
    this.directionAsString = orderDirectionToString(this.direction)

    this.type = jsonData['T']

    this.orderPrice = jsonData['OP']
    this.triggerPrice = jsonData['SP']

    this.orderAmount = jsonData['OA']
    this.tradeAmount = jsonData['TA']

    this.status = jsonData['S']

    this.notes = jsonData['N']
  }
}
