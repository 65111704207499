export class HaasBlogImage {
  parentId: number
  imageId: number
  imageBlob: string

  constructor(jsonData: any) {
    this.parentId = jsonData['PID']
    this.imageId = jsonData['IID']
    this.imageBlob = jsonData['D']
  }
}
