import { CloudInitData } from '@dataObjects/public/CloudInitData'
import { CloudTradeMarket } from '@dataObjects/public/CloudTradeMarket'
import { HaasBlogTopic } from '@dataObjects/public/HaasBlogTopic'
import { MarketPriceInformation, MarketTechnicalInformation } from '@dataObjects/public/MarketTechnicalInformation'
import { PeriodType } from '@pages/Home/Components/HomeTimelinePeriod'
import { MarketingPromotionType, parseMarketingPromotionType } from '@services/interface/objects/MarketingPromotionType'
import { UserActivityCache } from '@services/interface/objects/UserActivityCache'
import { parseUserPollDetailsType, UserPollDetailsType } from '@services/interface/objects/UserPollDetailsType'
import {
  parseUserStatisticReportType,
  UserStatisticReportType,
} from '@services/interface/objects/UserStatisticReportType'
import { _ } from '@utils/lodash'
import { PaginatedResponse } from '@utils/types/PaginatedResponse'
import { EnumHaasChartPricePlotStyle } from '@vendor/hxCharts/enums/EnumHaasChartPricePlotStyle'
import { CancellationToken } from '../api/rest/ApiRequest'
import { ServiceController, ServiceControllerConstructorType } from '../ServiceController'
import { InterfaceApi } from './InterfaceApi'
import {HaasChatMessage} from "@dataObjects/public/HaasChatMessage";
import HaasBotTemplate from "@dataObjects/public/HaasBotTemplate";
import {Persona} from "@application/Header/Components/Chat/ChatWidget";
import {KeyedEventHandler} from "@utils/eventHandlers/KeyedEventHandler";
import {EventHandler} from "@utils/eventHandlers/EventHandler";

export type TickerBarItem = {
  marketTag: string
  market: CloudTradeMarket
  interval: number
  style: EnumHaasChartPricePlotStyle
  index: number
}

export class InterfaceService {
  private api: InterfaceApi
  private controller: ServiceController

  private tickerBarItems?: TickerBarItem[]

  private dailyReportCache : UserActivityCache;
  private weeklyReportCache : UserActivityCache;
  private monthlyReportCache : UserActivityCache;

  onChatBotResponse = new EventHandler<{ chatId : string,  messageId : string, response : string}>();

  constructor(props: ServiceControllerConstructorType) {
    this.controller = props.controller
    this.api = new InterfaceApi(props.authenticator, this.controller)

    this.dailyReportCache = new UserActivityCache(this, 'day')
    this.weeklyReportCache = new UserActivityCache(this, 'week')
    this.monthlyReportCache = new UserActivityCache(this, 'month')
  }

  async getInitData(): Promise<CloudInitData> {
    const response = await this.api.getInitData()
    this.controller.initData = new CloudInitData(response)
    return this.controller.initData
  }

  async getMarketPriceInformation(market: string, token: CancellationToken): Promise<MarketPriceInformation> {
    const response = await this.api.getMarketPriceInformation(market, token)
    return new MarketPriceInformation(response)
  }

  async getMarketTechnicalInformation(market: string, token: CancellationToken): Promise<MarketTechnicalInformation> {
    const response = await this.api.getMarketTechnicalInformation(market, token)
    return new MarketTechnicalInformation(response)
  }

  async getTermsAndConditions(): Promise<HaasBlogTopic> {
    const response = await this.api.getTermsAndConditions()
    return new HaasBlogTopic(response)
  }

  async getSubscriptionAgreement(): Promise<HaasBlogTopic> {
    const response = await this.api.getSubscriptionAgreement()
    return new HaasBlogTopic(response)
  }

  async getWebshopSalesAgreement(): Promise<HaasBlogTopic> {
    const response = await this.api.getWebshopSalesAgreement()
    return new HaasBlogTopic(response)
  }

  async getChangeLog(): Promise<PaginatedResponse<HaasBlogTopic>> {
    const response = await this.api.getChangeLog()
    return {
      items: response['I'].map((c: any) => new HaasBlogTopic(c)),
      nextPageId: response['NP'],
    }
  }

  async getTickers(): Promise<TickerBarItem[]> {
    const { initData } = this.controller

    if (!this.tickerBarItems) {
      const response = await this.api.getTickers()
      this.tickerBarItems = response
        .map((c: any) => {
          const market = initData.getTradeMarket(c['M'])
          if (!market) return null
          return {
            market,
            marketTag: c['M'],
            interval: c['I'],
            style: c['S'],
            index: c['IX'],
          }
        })
        .filter((c: TickerBarItem) => !!c)
    }

    const tickers = [...(this.tickerBarItems || [])]
    return _.orderBy(tickers, (c) => c.index)
  }

  async addTicker(marketTag: string, index: number): Promise<boolean> {
    const { initData } = this.controller
    if (!this.tickerBarItems) return false

    if (this.tickerBarItems.some((c) => c.marketTag === marketTag)) return true

    const response = await this.api.addTicker(marketTag, index)
    this.tickerBarItems.push({
      market: initData.getTradeMarket(response['M']) as CloudTradeMarket,
      marketTag: response['M'],
      interval: response['I'],
      style: response['S'],
      index: response['IX'],
    })

    return true
  }

  async editTicker(marketTag: string, interval: number, style: EnumHaasChartPricePlotStyle, index: number): Promise<boolean> {
    if (!this.tickerBarItems) return false

    const item = this.tickerBarItems.find((c) => c.marketTag === marketTag)
    if (!item) return true

    await this.api.editTicker(marketTag, interval, style, index)

    this.tickerBarItems = [...this.tickerBarItems.filter((c) => c.marketTag !== marketTag), { market: item.market, marketTag, interval, style, index }]

    return true
  }

  async editTickerSorting(markets: string[]): Promise<TickerBarItem[] | undefined> {
    const response = await this.api.editTickerSorting(markets)
    if (!response) return

    _.each(markets, (market, index) => {
      const ticker = this.tickerBarItems?.find((c) => c.marketTag === market)
      if (!ticker) return

      ticker.index = Number(index)
    })

    return this.getTickers()
  }

  async deleteTicker(market: string): Promise<boolean> {
    if (!this.tickerBarItems) return false

    await this.api.deleteTicker(market)
    this.tickerBarItems = this.tickerBarItems.filter((c) => c.marketTag !== market)
    return true
  }

  async getUserStatistics(period : PeriodType, token? : CancellationToken): Promise<UserStatisticReportType> {
    switch (period) {
      case 'day':
        return this.dailyReportCache.getValue();
      case 'week':
        return this.weeklyReportCache.getValue();
      case 'month':
      default:
        return this.monthlyReportCache.getValue();
    }
  }


  async getDailyUserStatistics(token? : CancellationToken): Promise<UserStatisticReportType> {
    const response = await this.api.getDailyUserStatistics(token)
    return parseUserStatisticReportType(response)
  }

  async getWeeklyUserStatistics(token? : CancellationToken): Promise<UserStatisticReportType> {
    const response = await this.api.getWeeklyUserStatistics(token)
    return parseUserStatisticReportType(response)
  }

  async getMonthlyUserStatistics(token? : CancellationToken): Promise<UserStatisticReportType> {
    const response = await this.api.getMonthlyUserStatistics(token)
    return parseUserStatisticReportType(response)
  }

  async getPoll(pollId: string, token: CancellationToken): Promise<UserPollDetailsType> {
    const response = await this.api.getPoll(pollId, token)
    return parseUserPollDetailsType(response)
  }

  async voteOnPoll(pollId: string, vote: string): Promise<UserPollDetailsType> {
    const response = await this.api.voteOnPoll(pollId, vote)
    return parseUserPollDetailsType(response)
  }

  async getPromotions(token: CancellationToken): Promise<MarketingPromotionType[]> {
    const response = await this.api.getPromotions(token)
    return response.map((c: any) => parseMarketingPromotionType(c))
  }

  async askQuestion(bot : Persona, chatId: string, messageId : string, message: string, token: CancellationToken): Promise<boolean> {
    const response = await this.api.askQuestion(bot, chatId, messageId, message, token)
    return !!response;
  }

  async askJulia(chatId: string, message: string, token: CancellationToken): Promise<HaasChatMessage> {
    const response = await this.api.askJulia(chatId, message, token)
    return new HaasChatMessage(response);
  }
  async getJuliaHistory(chatId: string, token: CancellationToken): Promise<HaasChatMessage[]> {
    const response = await this.api.getJuliaHistory(chatId, token)
    return response.map((c : any) => new HaasChatMessage(c));
  }

  async askThomas(chatId: string, message: string, token: CancellationToken): Promise<HaasChatMessage> {
    const response = await this.api.askThomas(chatId, message, token)
    return new HaasChatMessage(response);
  }
  async getThomasHistory(chatId: string, token: CancellationToken): Promise<HaasChatMessage[]> {
    const response = await this.api.getThomasHistory(chatId, token)
    return response.map((c : any) => new HaasChatMessage(c));
  }

  async askSimone(chatId: string, message: string, token: CancellationToken): Promise<HaasChatMessage> {
    const response = await this.api.askSimone(chatId, message, token)
    return new HaasChatMessage(response);
  }

  async getSimoneHistory(chatId: string, token: CancellationToken): Promise<HaasChatMessage[]> {
    const response = await this.api.getSimoneHistory(chatId, token)
    return response.map((c : any) => new HaasChatMessage(c));
  }


  async askDavid(chatId: string, message: string, token: CancellationToken): Promise<HaasChatMessage> {
    const response = await this.api.askDavid(chatId, message, token)
    return new HaasChatMessage(response);
  }

  async getDavidHistory(chatId: string, token: CancellationToken): Promise<HaasChatMessage[]> {
    const response = await this.api.getDavidHistory(chatId, token)
    return response.map((c : any) => new HaasChatMessage(c));
  }
}
