import { UserBalanceValueContainer } from '@dataObjects/private/account/UserBalanceContainer'
import { UserNotification } from '@dataObjects/private/notifications/UserNotification'
import SocketClient, { SocketClientResponseType } from '@services/api/socket/SocketClient'
import UserSocketBalanceChannel from '@services/api/socket/user/channels/UserSocketBalanceChannel'
import UserSocketConvertChannel from '@services/api/socket/user/channels/UserSocketConvertChannel'
import { ServiceController } from '@services/ServiceController'

export class WebSocketSubscription {
  constructor(private onUnsubscribe: () => void) {}

  dispose() {
    this.onUnsubscribe()
  }
}

class UserSocketClient extends SocketClient {
  private channels: Record<string, UserSocketBalanceChannel | UserSocketConvertChannel> = {}

  constructor(controller: ServiceController, isSocketConnected: (isSocketConnected: boolean) => void) {
    super(controller, isSocketConnected)

    this.subscribeToPushMessages = true
  }

  processMessage(message: SocketClientResponseType) {
    const { subscriptionId, data } = message

    // Can we handle it as a push message?
    if (this.handlePushMessages(message)) return

    // Is it a specific subscription?
    if (this.channels[subscriptionId]) {
      this.channels[subscriptionId].onMessage(data)
      return
    }
  }

  private handlePushMessages(message: SocketClientResponseType): boolean {
    const { subscriptionId, data } = message
    const { backtestService, botService, scriptService, labsService, notificationService, orderService, positionService, interfaceService } = this.controller

    switch (subscriptionId) {
      case 'chatBotResponse':
        const chatId = data['C'];
        const messageId  = data['MID'];
        const response = data['R'];

        interfaceService.onChatBotResponse.fire({chatId, messageId, response })
        notificationService.onNotification(new UserNotification(data))
        return true

      case 'browserNotifications':
        notificationService.onNotification(new UserNotification(data))
        return true

      case 'pushNotifications':
        notificationService.onPushNotification(new UserNotification(data))
        return true

      case 'balanceChange':
        return true

      case 'positionOpened':
        positionService.onPositionOpened(data)
        return true
      case 'positionUpdate':
        positionService.onPositionUpdate(data)
        return true
      case 'positionClose':
        positionService.onPositionClosed(data)
        return true

      case 'orderOpened':
        orderService.onOrderOpened(data)
        return true
      case 'orderUpdate':
        orderService.onOrderUpdate(data)
        return true
      case 'orderClose':
        orderService.onOrderClosed(data)
        return true

      case 'tradeUpdate':
        orderService.onTradeUpdate(data)
        return true

      case 'botUpdated':
        return true

      case 'haasBotUpdate':
        botService.onBotUpdate(data).then()
        return true
      case 'runtimeUpdate':
        botService.onRuntimeUpdate(data).then()
        return true

      case 'backtestUpdate':
        backtestService.onBacktestUpdate(data)
        return true

      case 'luaCommandUpdate':
        scriptService.onLuaCommandUpdate(data)
        return true
      case 'visualCommandUpdate':
        scriptService.onVisualCommandUpdate(data)
        return true

      case 'labsExecutionUpdate':
        labsService.onExecutionUpdate(data)
        return true
      case 'labBacktestUpdateMessage':
        labsService.onBacktestUpdate(data)
        return true
    }

    return false
  }

  subscribeToBalance(accountId: string, currency: string, callback: (data: UserBalanceValueContainer) => void): WebSocketSubscription {
    const channel = new UserSocketBalanceChannel(this, accountId, currency)
    channel.subscribe(callback)

    this.channels[channel.subscriptionId] = channel
    return new WebSocketSubscription(() => this.unsubscribe(channel.subscriptionId))
  }

  subscribeToConvert(priceSource: string, fromCurrency: string, toCurrency: string, callback: (conversionPrice: number) => void): WebSocketSubscription {
    const channel = new UserSocketConvertChannel(this, priceSource, fromCurrency, toCurrency)
    channel.subscribe(callback)

    this.channels[channel.subscriptionId] = channel
    return new WebSocketSubscription(() => this.unsubscribe(channel.subscriptionId))
  }

  unsubscribe(subscriptionId: string) {
    super.unsubscribe(subscriptionId)
    delete this.channels[subscriptionId]
  }
}

export default UserSocketClient
