import { EnumLicenseTypes } from '../enums/EnumLicenseTypes'

export class UserLicense {
  name: string
  validUntil: number
  rights: EnumLicenseTypes

  maxBots: number
  maxDashboards: number
  maxBacktestMonths: number
  maxSimulatedAccounts: number
  maxRealAccounts: number
  maxLabsMonths: number
  maxOpenOrders: number

  hireSignalsEnabled: boolean
  hireStrategiesEnabled: boolean
  haasLabsEnabled: boolean
  resellSignalsEnabled: boolean
  marketDetailsEnabled: boolean
  localAPIEnabled: boolean
  scriptedExchangesEnabled: boolean
  machineLearningEnabled: boolean


  constructor(jsonData: any) {
    this.name = jsonData['LicenseName']
    this.validUntil = jsonData['ValidUntill']
    this.rights = jsonData['Rights']

    this.maxBots = jsonData['MaxBots']
    this.maxDashboards = jsonData['MaxDashboards']
    this.maxBacktestMonths = jsonData['MaxBacktestMonths']
    this.maxSimulatedAccounts = jsonData['MaxSimulatedAccounts']
    this.maxRealAccounts = jsonData['MaxRealAccounts']
    this.maxLabsMonths = jsonData['MaxLabsMonths']
    this.maxOpenOrders = jsonData['MaxOpenOrders']

    this.hireSignalsEnabled = jsonData['HireSignalsEnabled']
    this.hireStrategiesEnabled = jsonData['HireStrategiesEnabled']
    this.haasLabsEnabled = jsonData['HaasLabsEnabled']
    this.resellSignalsEnabled = jsonData['ResellSignalsEnabled']
    this.marketDetailsEnabled = jsonData['MarketDetailsEnabled']
    this.localAPIEnabled = jsonData['LocalAPIEnabled']
    this.scriptedExchangesEnabled = jsonData['ScriptedExchangesEnabled']
    this.machineLearningEnabled = jsonData['MachinelearningEnabled']
  }
}
