export class UserBalance {
  walletTag: string
  currency: string

  available: number
  locked: number
  total: number

  constructor(jsonData: any) {
    this.currency = jsonData['C']
    this.walletTag = jsonData['WT']

    this.available = jsonData['A']
    this.total = jsonData['T']

    this.locked = Math.max(0, this.total - this.available);
  }
}

export class UserBalanceValue extends UserBalance {
  rate: number
  value: number

  constructor(jsonData: any) {
    super(jsonData)
    this.rate = jsonData['R']
    this.value = jsonData['V']
  }
}
