import { CryptoTransaction } from './CryptoTransaction'

export class UserExternalWallet {
  id: string
  name: string
  address: string
  coin: string
  finalBalance: number
  btcBalance: number
  usdBalance: number
  lastUpdate: number
  transactions: CryptoTransaction[]

  constructor(jsonData: any) {
    this.id = jsonData['Id']
    this.name = jsonData['Name']
    this.address = jsonData['Address']
    this.coin = jsonData['Coin']
    this.finalBalance = jsonData['FinalBalance']
    this.btcBalance = jsonData['BtcBalance']
    this.usdBalance = jsonData['UsdBalance']
    this.lastUpdate = jsonData['LastUpdate']
    this.transactions = jsonData['HistoricalData'].map((c: any) => new CryptoTransaction(c))
  }
}
