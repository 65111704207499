export enum EnumLicenseTypes {
  Trial = 0, // All in demo mode
  Version3User = 1,
  EnterpriseDeveloper = 2,
  EnterpriseBeginner = 3,
  EnterpriseSimple = 4,
  EnterpriseAdvanced = 5,
  SponsoredByExchange = 9,
  LitePlus = 10,
  Standard = 13,
  Professional = 14,
  WhiteLabel = 17,
  Partner = 50,
  Administrator = 51, // Team used
  Developer = 52, // Developers used
}
