import { DefaultTheme } from 'styled-components'

// Color related methods
export function getNumberColor(number: number, theme: DefaultTheme) {
  if (number < 0) return theme.RED
  if (number > 0) return theme.GREEN
  return ''
}

export function createGradiant(startColor: string, endColor: string) {
  return `linear-gradient(15deg, ${startColor} 0%, ${endColor} 100%)`
}
