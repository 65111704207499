import { EnumHistoricalState } from '@dataObjects/enums/EnumHistoricalState'
import {CloudInitData} from "@dataObjects/public/CloudInitData";
import {CloudTradeMarket} from "@dataObjects/public/CloudTradeMarket";

export class HistoryMarketSettings {
  marketTag: string
  maxMonths: number
  status: EnumHistoricalState
  market?: CloudTradeMarket;

  constructor(jsonData: any, initData : CloudInitData) {
    this.marketTag = jsonData['Market']
    this.market = initData.getTradeMarket(this.marketTag);
    this.maxMonths = jsonData['MaxMonths']
    this.status = jsonData['Status']
  }
}
