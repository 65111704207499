export class UserDevice {
  id: string
  deviceid: string
  ipadres: string
  platformid: string

  constructor(jsonData: any) {
    this.id = jsonData['Id']
    this.deviceid = jsonData['DeviceId']
    this.ipadres = jsonData['IpAddress']
    this.platformid = jsonData['PlatformId']
  }
}
