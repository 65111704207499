export enum EnumServiceType {
  AccountService,
  BotExecutionService,
  BotService,
  ExternalWalletService,
  HaasExchangeService,
  InterfaceService,
  InterfaceSocketService,
  MachineLearningService,
  MonitorService,
  NotificationService,
  NewsfeedsService,
  PortfolioService,
  PriceDataService,
  PriceHistoryService,
  PriceSocketService,
  PriceTrackingService,
  ScriptExecutionService,
  StrategiesService,
  TradingService,
  UserManagementService,
  WebService,
  WebShopService,
  WebSocketService,

  LocalService,
  MonsterService,
  ChatBotService,
  
  LabsService,
  LabsExecutionService,
  ReportingService,
}
