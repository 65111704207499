import { EnumHaasScriptInputFieldType } from '@dataObjects/enums/EnumHaasScriptInputFieldType'

export type UserLabParameterConfig = {
  key: string
  type: EnumHaasScriptInputFieldType
  options: any[]
  isEnabled: boolean
  isSpecific: boolean
}

export function parseUserLabParameterConfig(jsonData: any): UserLabParameterConfig {
  return {
    key: jsonData['K'],
    type: jsonData['T'],
    options: jsonData['O'],
    isEnabled: jsonData['I'],
    isSpecific: jsonData['IS'],
  }
}

export function prepareUserLabParameterConfig(parameter: UserLabParameterConfig) {
  return {
    K: parameter.key,
    O: parameter.options,
    I: parameter.isEnabled,
    IS: parameter.isSpecific,
  }
}
