class DateHelper {
  static getLastMonday(date: Date) {
    const day = date.getUTCDay()
    const prevMonday = new Date()

    if (date.getUTCDay() === 0) {
      prevMonday.setUTCDate(date.getUTCDate() - 7)
    } else {
      prevMonday.setUTCDate(date.getUTCDate() - (day - 1))
    }

    return prevMonday
  }

  static addDays(timestamp: Date, numberOfDays: number) {
    timestamp = new Date(timestamp.valueOf())
    timestamp.setUTCDate(timestamp.getUTCDate() + numberOfDays)
    return timestamp
  }

  static addMonths(timestamp: Date, numberOfMonths: number) {
    timestamp = new Date(timestamp)
    timestamp.setUTCMonth(timestamp.getUTCMonth() + numberOfMonths)
    return timestamp
  }

  static addYears(timestamp: Date, numberOfYears: number) {
    timestamp = new Date(timestamp.valueOf())
    timestamp.setUTCFullYear(timestamp.getUTCFullYear() + numberOfYears)
    return timestamp
  }

  static startOfTheMonth(date: Date) {
    const unix = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1, 0, 0, 0, 0)
    return new Date(unix)
  }

  static startOfTheYear(date: Date) {
    const unix = Date.UTC(date.getUTCFullYear(), 0, 1, 0, 0, 0, 0)
    return new Date(unix)
  }
}

export default DateHelper
