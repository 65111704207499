import { _ } from '@utils/lodash'
import { EnumHaasChartAxisSide } from '@vendor/hxCharts/enums/EnumHaasChartAxisSide'
import ColorHelper from '@vendor/hxCharts/helpers/ColorHelper'
import HxChartBuilder from '@vendor/hxCharts/HxChartBuilder'
import { HaasChartContainer } from '@vendor/hxCharts/types/HaasChartContainer'

export class CloudReport {
  dailyReports: CloudDailyReport[]
  lastReport: CloudDailyReport

  charts: CloudReportCharts

  constructor(jsonData: any[]) {
    this.dailyReports = jsonData.map((c) => new CloudDailyReport(c)).sort((a, b) => a.unix - b.unix)

    this.lastReport = this.dailyReports[this.dailyReports.length - 1] ?? new CloudDailyReport({})

    this.charts = new CloudReportCharts(this)
  }
}

class CloudReportCharts {
  users!: HaasChartContainer
  accounts!: HaasChartContainer
  bots!: HaasChartContainer
  luaScripts!: HaasChartContainer
  visualScripts!: HaasChartContainer
  backtests!: HaasChartContainer
  dashboards!: HaasChartContainer
  sharedScripts!: HaasChartContainer

  constructor(report: CloudReport) {
    this.getUserChart(report)
    this.getAccountChart(report)
    this.getBotChart(report)
    this.getLuaScriptsChart(report)
    this.getVisualScriptsChart(report)
    this.getBacktestChart(report)
    this.getVisualScriptsChart(report)
    this.getDashboardChart(report)
    this.getSharedScriptChart(report)
  }

  private getUserChart(data: CloudReport) {
    const dpUsersNew: Record<number, number> = {}
    const dpUsersActive: Record<number, number> = {}
    const dpTotal: Record<number, number> = {}

    _.each(data.dailyReports, (dailyReport) => {
      dpUsersNew[dailyReport.unix] = dailyReport.usersNew
      dpUsersActive[dailyReport.unix] = dailyReport.usersActive
      dpTotal[dailyReport.unix] = dailyReport.usersTotal
    })

    const builder = new HxChartBuilder(1440)
      .addPlot(0, 'Total & logged in users', 0.6)
      .addLine(0, 'Total', dpTotal, 1440, ColorHelper.SkyBlue, EnumHaasChartAxisSide.Right, true)
      .addPlot(1, 'Logged in', 0.2)
      .addBars(1, 'Logged in', dpUsersActive, 1440, ColorHelper.Orange, EnumHaasChartAxisSide.Right, true, true)
      .addPlot(2, 'New', 0.2)
      .addBars(2, 'New', dpUsersNew, 1440, ColorHelper.DarkGray, EnumHaasChartAxisSide.Right, true, true)

    this.users = builder.chart
  }

  private getAccountChart(data: CloudReport) {
    const dpLiveNew: Record<number, number> = {}
    const dpLiveTotal: Record<number, number> = {}

    const dpSimulatedNew: Record<number, number> = {}
    const dpSimulatedTotal: Record<number, number> = {}

    _.each(data.dailyReports, (dailyReport) => {
      dpLiveNew[dailyReport.unix] = dailyReport.liveAccountsNew
      dpLiveTotal[dailyReport.unix] = dailyReport.liveAccountsCurrent

      dpSimulatedNew[dailyReport.unix] = dailyReport.simulatedAccountsNew
      dpSimulatedTotal[dailyReport.unix] = dailyReport.simulatedAccountsCurrent
    })

    const builder = new HxChartBuilder(1440)
      .addPlot(0, 'Current live & simulated accounts', 0.6)
      .addLine(0, 'Live', dpLiveTotal, 1440, ColorHelper.SkyBlue, EnumHaasChartAxisSide.Right, true)
      .addLine(0, 'Sim', dpSimulatedTotal, 1440, ColorHelper.Orange, EnumHaasChartAxisSide.Right, true)
      .addPlot(1, 'New (Live)', 0.2)
      .addBars(1, 'New (Live)', dpLiveNew, 1440, ColorHelper.DarkGray, EnumHaasChartAxisSide.Right, true, true)
      .addPlot(2, 'New (Sim)', 0.2)
      .addBars(2, 'New (Sim)', dpSimulatedNew, 1440, ColorHelper.DarkGray, EnumHaasChartAxisSide.Right, true, true)

    this.accounts = builder.chart
  }

  private getBotChart(data: CloudReport) {
    const dpNew: Record<number, number> = {}
    const dpActive: Record<number, number> = {}
    const dpTotal: Record<number, number> = {}

    _.each(data.dailyReports, (dailyReport) => {
      dpNew[dailyReport.unix] = dailyReport.botsNew
      dpActive[dailyReport.unix] = dailyReport.botsActive
      dpTotal[dailyReport.unix] = dailyReport.botsCurrent
    })

    const builder = new HxChartBuilder(1440)
      .addPlot(0, 'Total & Activated', 0.8)
      .addLine(0, 'Total', dpTotal, 1440, ColorHelper.SkyBlue, EnumHaasChartAxisSide.Right, true)
      .addLine(0, 'Activated', dpActive, 1440, ColorHelper.Orange, EnumHaasChartAxisSide.Left, true)
      .addPlot(1, 'New', 0.2)
      .addBars(1, 'New', dpNew, 1440, ColorHelper.DarkGray, EnumHaasChartAxisSide.Right, true, true)

    this.bots = builder.chart
  }

  private getLuaScriptsChart(data: CloudReport) {
    if (!data.dailyReports.length)
      return new HxChartBuilder(1440).chart;

    const { luaScriptsCurrent, luaCommandsCurrent, luaScriptsNew } = data.dailyReports[0]

    let totalScripts = luaScriptsCurrent + luaCommandsCurrent - luaScriptsNew

    const dpNew: Record<number, number> = {}
    const dpScripts: Record<number, number> = {}
    const dpCommands: Record<number, number> = {}
    const dbTotalScripts: Record<number, number> = {}

    _.each(data.dailyReports, (dailyReport) => {
      dpNew[dailyReport.unix] = dailyReport.luaScriptsNew
      dpScripts[dailyReport.unix] = dailyReport.luaScriptsCurrent
      dpCommands[dailyReport.unix] = dailyReport.luaCommandsCurrent

      totalScripts += dailyReport.luaScriptsNew
      dbTotalScripts[dailyReport.unix] = totalScripts
    })

    const builder = new HxChartBuilder(1440)
      .addPlot(0, 'Total scripts & commands', 0.8)
      .addLine(0, 'Total', dbTotalScripts, 1440, ColorHelper.SkyBlue, EnumHaasChartAxisSide.Right, true)
      .addLine(0, 'Scripts', dpScripts, 1440, ColorHelper.Orange, EnumHaasChartAxisSide.Left, true)
      .addLine(0, 'Commands', dpCommands, 1440, ColorHelper.Purple, EnumHaasChartAxisSide.Left, true)
      .addPlot(1, 'New scripts', 0.2)
      .addBars(1, 'New scripts', dpNew, 1440, ColorHelper.DarkGray, EnumHaasChartAxisSide.Right, false, true)

    this.luaScripts = builder.chart
  }

  private getVisualScriptsChart(data: CloudReport) {
    if (!data.dailyReports.length)
      return new HxChartBuilder(1440).chart;

    const { visualScriptsCurrent, visualCommandsCurrent, visualScriptsNew } = data.dailyReports[0]

    let totalScripts = visualScriptsCurrent + visualCommandsCurrent - visualScriptsNew

    const dpNew: Record<number, number> = {}
    const dpScripts: Record<number, number> = {}
    const dpCommands: Record<number, number> = {}
    const dbTotalScripts: Record<number, number> = {}

    _.each(data.dailyReports, (dailyReport) => {
      dpNew[dailyReport.unix] = dailyReport.visualScriptsNew
      dpScripts[dailyReport.unix] = dailyReport.visualScriptsCurrent
      dpCommands[dailyReport.unix] = dailyReport.visualCommandsCurrent

      totalScripts += dailyReport.visualScriptsNew
      dbTotalScripts[dailyReport.unix] = totalScripts
    })

    const builder = new HxChartBuilder(1440)
      .addPlot(0, 'Total scripts & commands', 0.8)
      .addLine(0, 'Total', dbTotalScripts, 1440, ColorHelper.SkyBlue, EnumHaasChartAxisSide.Right, true)
      .addLine(0, 'Scripts', dpScripts, 1440, ColorHelper.Orange, EnumHaasChartAxisSide.Left, true)
      .addLine(0, 'Commands', dpCommands, 1440, ColorHelper.Purple, EnumHaasChartAxisSide.Left, true)
      .addPlot(1, 'New scripts', 0.2)
      .addBars(1, 'New scripts', dpNew, 1440, ColorHelper.DarkGray, EnumHaasChartAxisSide.Right, false, true)

    this.visualScripts = builder.chart
  }

  private getBacktestChart(data: CloudReport) {
    const dbLabs: Record<number, number> = {}
    const dbLabsBacktests: Record<number, number> = {}
    const dbEditorBacktests: Record<number, number> = {}
    const dbTotalBacktests: Record<number, number> = {}

    _.each(data.dailyReports, (dailyReport) => {
      dbLabs[dailyReport.unix] = dailyReport.labsExecutedNew
      dbLabsBacktests[dailyReport.unix] = dailyReport.labsBacktestsExecutedNew
      dbEditorBacktests[dailyReport.unix] = dailyReport.editorQuicktestNew + dailyReport.editorBacktestNew
      dbTotalBacktests[dailyReport.unix] = dailyReport.backtestTotal
    })

    const builder = new HxChartBuilder(1440)
      .addPlot(0, 'Total backtests executed', 0.4)
      .addLine(0, 'Total', dbTotalBacktests, 1440, ColorHelper.SkyBlue, EnumHaasChartAxisSide.Right, true)
      .addPlot(1, 'Labs', 0.2)
      .addBars(1, 'Labs', dbLabs, 1440, ColorHelper.Olive, EnumHaasChartAxisSide.Right, false, true)
      .addPlot(2, 'Labs backtests', 0.2)
      .addBars(2, 'Labs backtests', dbLabsBacktests, 1440, ColorHelper.DarkGreen, EnumHaasChartAxisSide.Right, false, true)
      .addPlot(3, 'Manual backtests', 0.2)
      .addBars(3, 'Manual backtests', dbEditorBacktests, 1440, ColorHelper.DarkGray, EnumHaasChartAxisSide.Right, false, true)

    this.backtests = builder.chart
  }

  private getDashboardChart(data: CloudReport) {
    const dbDashboardNew: Record<number, number> = {}
    const dbDashboardCurrent: Record<number, number> = {}

    const dbDashboardWidgetNew: Record<number, number> = {}
    const dbDashboardWidgetCurrent: Record<number, number> = {}

    _.each(data.dailyReports, (dailyReport) => {
      const { unix } = dailyReport

      dbDashboardNew[unix] = dailyReport.dashboardsNew
      dbDashboardCurrent[unix] = dailyReport.dashboardsCurrent

      dbDashboardWidgetNew[unix] = dailyReport.dashboardWidgetsNew
      dbDashboardWidgetCurrent[unix] = dailyReport.dashboardWidgetsCurrent
    })

    const builder = new HxChartBuilder(1440)
      .addPlot(0, 'Total dashboards & widgets', 0.6)
      .addLine(0, 'Dashboards', dbDashboardCurrent, 1440, ColorHelper.SkyBlue, EnumHaasChartAxisSide.Right, true)
      .addLine(0, 'Widgets', dbDashboardWidgetCurrent, 1440, ColorHelper.Orange, EnumHaasChartAxisSide.Right, true)
      .addPlot(1, 'New dashboards', 0.2)
      .addBars(1, 'New dashboards', dbDashboardNew, 1440, ColorHelper.SkyBlue, EnumHaasChartAxisSide.Right, false, true)
      .addPlot(2, 'New widgets', 0.2)
      .addBars(2, 'New widgets', dbDashboardWidgetNew, 1440, ColorHelper.Orange, EnumHaasChartAxisSide.Right, false, true)

    this.dashboards = builder.chart
  }

  private getSharedScriptChart(data: CloudReport) {
    const dpSharedNew: Record<number, number> = {}
    const dpSharedTotal: Record<number, number> = {}

    const dpViewedNew: Record<number, number> = {}
    const dpViewedTotal: Record<number, number> = {}

    let sharedTotal = 0
    let viewedTotal = 0

    _.each(data.dailyReports, (dailyReport) => {
      sharedTotal += dailyReport.haasScriptsShared
      viewedTotal += dailyReport.haasScriptsViewed

      dpSharedNew[dailyReport.unix] = dailyReport.haasScriptsShared
      dpSharedTotal[dailyReport.unix] = sharedTotal

      dpViewedNew[dailyReport.unix] = dailyReport.haasScriptsViewed
      dpViewedTotal[dailyReport.unix] = viewedTotal
    })

    const builder = new HxChartBuilder(1440)
      .addPlot(0, 'Shared & viewed scripts', 0.6)
      .addLine(0, 'Shared', dpSharedTotal, 1440, ColorHelper.SkyBlue, EnumHaasChartAxisSide.Right, true)
      .addLine(0, 'Views', dpViewedTotal, 1440, ColorHelper.Orange, EnumHaasChartAxisSide.Right, true)
      .addPlot(1, 'New shared', 0.2)
      .addBars(1, 'New shared', dpSharedNew, 1440, ColorHelper.DarkGray, EnumHaasChartAxisSide.Right, true, true)
      .addPlot(2, 'New views', 0.2)
      .addBars(2, 'New views', dpViewedNew, 1440, ColorHelper.DarkGray, EnumHaasChartAxisSide.Right, true, true)

    this.sharedScripts = builder.chart
  }
}

class CloudDailyReport {
  year: number
  month: number
  day: number
  unix: number

  usersNew: number
  usersActive: number
  usersCurrent: number
  usersLoggedIn: string[]
  usersTotal: number

  liveAccountsNew: number
  liveAccountsCurrent: number
  liveAccountsTotal: number

  simulatedAccountsNew: number
  simulatedAccountsCurrent: number
  simulatedAccountsTotal: number

  botsNew: number
  botsActive: number
  botsCurrent: number
  botsTotal: number

  luaScriptsNew: number
  luaScriptsCurrent: number
  luaCommandsCurrent: number
  luaScriptsTotal: number

  visualScriptsNew: number
  visualScriptsCurrent: number
  visualCommandsCurrent: number
  visualScriptsTotal: number

  backtestNew: number
  backtestTotal: number

  editorQuicktestNew: number
  editorQuicktestTotal: number

  editorBacktestNew: number
  editorBacktestTotal: number

  labsExecutedNew: number
  labsExecutedTotal: number

  labsBacktestsExecutedNew: number
  labsBacktestsExecutedTotal: number

  haasScriptsShared: number
  haasScriptsViewed: number

  dashboardsNew: number
  dashboardsCurrent: number
  dashboardsTotal: number

  dashboardWidgetsNew: number
  dashboardWidgetsCurrent: number
  dashboardWidgetsTotal: number

  constructor(jsonData: any) {
    this.year = jsonData['Y']
    this.month = jsonData['M']
    this.day = jsonData['D']
    this.unix = Date.UTC(this.year, this.month - 1, this.day + 1, 0, 0, 0, 0) / 1000

    this.usersNew = jsonData['UN']
    this.usersActive = jsonData['UA']
    this.usersCurrent = jsonData['UC']
    this.usersLoggedIn = jsonData['ULI']
    this.usersTotal = jsonData['UT']

    this.liveAccountsNew = jsonData['LAN']
    this.liveAccountsCurrent = jsonData['LAC']
    this.liveAccountsTotal = jsonData['LAT']

    this.simulatedAccountsNew = jsonData['SAN']
    this.simulatedAccountsCurrent = jsonData['SAC']
    this.simulatedAccountsTotal = jsonData['SAT']

    this.botsNew = jsonData['BN']
    this.botsActive = jsonData['BA']
    this.botsCurrent = jsonData['BC']
    this.botsTotal = jsonData['BT']

    this.luaScriptsNew = jsonData['LSN']
    this.luaScriptsCurrent = jsonData['LSC']
    this.luaCommandsCurrent = jsonData['LCC']
    this.luaScriptsTotal = jsonData['LST']

    this.visualScriptsNew = jsonData['VSN']
    this.visualScriptsCurrent = jsonData['VSC']
    this.visualCommandsCurrent = jsonData['VCC']
    this.visualScriptsTotal = jsonData['VST']

    this.editorQuicktestNew = jsonData['EQN']
    this.editorQuicktestTotal = jsonData['EQT']

    this.editorBacktestNew = jsonData['EBN']
    this.editorBacktestTotal = jsonData['EBT']

    this.labsExecutedNew = jsonData['LEN']
    this.labsExecutedTotal = jsonData['LET']

    this.labsBacktestsExecutedNew = jsonData['LBEN']
    this.labsBacktestsExecutedTotal = jsonData['LBET']

    this.haasScriptsViewed = jsonData['HSV']
    this.haasScriptsShared = jsonData['HSS']

    this.backtestNew = this.labsBacktestsExecutedNew + this.editorBacktestNew + this.editorQuicktestNew
    this.backtestTotal = this.labsBacktestsExecutedTotal + this.editorBacktestTotal + this.editorQuicktestTotal

    this.dashboardsNew = jsonData['DN']
    this.dashboardsCurrent = jsonData['DC']
    this.dashboardsTotal = jsonData['DT']

    this.dashboardWidgetsNew = jsonData['DWN']
    this.dashboardWidgetsCurrent = jsonData['DWC']
    this.dashboardWidgetsTotal = jsonData['DWT']
  }
}