import { EnumServiceOperationStatus } from '../../enums/EnumServiceOperationStatus'
import { EnumServiceType } from '../../enums/EnumServiceType'

type ServiceMySQLReport = {
  name: string
  activeConnections: number
  badConnections: number
  maxConnections: number
}
type ServerLoad = {
  min1Average : number;
  min5Average : number;
  min15Average : number;
}
export class ServiceHealthReport {
  startTimestamp = 0
  updateTimestamp = 0

  systemCtlName = ''

  machineId = ''
  serviceId = ''
  serviceName = ''
  serviceVersion = ''
  serviceType: EnumServiceType
  serviceStatus: EnumServiceOperationStatus

  serviceStatusText: string

  runs64Bits = false
  systemCores = 0
  memoryUsage = 0
  cpuUsage = 0
  serverCpuUsages = 0
  serverLoad : ServerLoad

  openTasks = 0
  completedTasks = 0
  cachedTasks = 0

  mySql: ServiceMySQLReport[]

  cacheMemoryItems = 0

  constructor(jsonData: any) {
    this.startTimestamp = jsonData['StartTimestamp']
    this.updateTimestamp = jsonData['UpdateTimestamp']

    this.machineId = jsonData['MachineId']
    this.serviceId = jsonData['ServiceId']
    this.serviceName = jsonData['ServiceName']
    this.serviceVersion = jsonData['ServiceVersion']
    this.serviceType = jsonData['ServiceType']
    this.serviceStatus = jsonData['ServiceStatus']

    this.systemCores = jsonData['SystemCores']
    this.runs64Bits = jsonData['Runs64Bits']
    this.memoryUsage = jsonData['MemoryUsage']
    this.cpuUsage = jsonData['CpuUsage']
    this.serverCpuUsages = jsonData['ServerTotalCpuUsages']
    this.serverLoad = {
      min1Average : jsonData['ServerLoad']['Min1Average'],
      min5Average : jsonData['ServerLoad']['Min5Average'],
      min15Average : jsonData['ServerLoad']['Min15Average'],
    }

    this.openTasks = jsonData['OpenTasks']
    this.completedTasks = jsonData['CompletedTasks']
    this.cachedTasks = jsonData['CachedTasks']

    this.mySql = (jsonData['MySqlReports'] ?? []).map(
      (c: any): ServiceMySQLReport => ({
        name: c['N'],
        activeConnections: c['AC'],
        badConnections: c['BC'],
        maxConnections: c['MC'],
      })
    )

    this.cacheMemoryItems = jsonData['CacheMemoryItems']

    switch (this.serviceStatus) {
      case EnumServiceOperationStatus.Initializing:
        this.serviceStatusText = 'Initializing'
        break
      case EnumServiceOperationStatus.Initialized:
        this.serviceStatusText = 'Initialized'
        break
      case EnumServiceOperationStatus.Operational:
        this.serviceStatusText = 'Operational'
        break
      case EnumServiceOperationStatus.Stopping:
        this.serviceStatusText = 'Stopping'
        break
      case EnumServiceOperationStatus.Stopped:
        this.serviceStatusText = 'Stopped'
        break
    }

    if (this.serviceName.indexOf('PriceTrackingService') >= 0) this.systemCtlName = `hc-price-tracking-${this.serviceName.replace('PriceTrackingService_', '')}`
    else if (this.serviceName.indexOf('PriceSocketService') >= 0) this.systemCtlName = `hc-price-socket-${this.serviceName.replace('PriceSocketService_', '')}`
    else if (this.serviceName.indexOf('TradingService') >= 0) this.systemCtlName = `hc-price-trading-${this.serviceName.replace('TradingService_', '')}`
    else if (this.serviceName.indexOf('HaasExchangeService') >= 0) this.systemCtlName = `hc-price-simulation-${this.serviceName.replace('HaasExchangeService_', '')}`
    else if (this.serviceName.indexOf('WebService') >= 0) this.systemCtlName = 'hc-web'
    else this.systemCtlName = `hc-price-${this.serviceName}`
  }
}
