import { EnumAccountMarketMarginMode } from '@dataObjects/enums/EnumAccountMarketMarginMode'
import { EnumAccountPositionMode } from '@dataObjects/enums/EnumAccountPositionMode'
import { ApiRequest, CancellationToken, EnumApiMethod } from '../api/rest/ApiRequest'
import { RestApiBase } from '../api/rest/RestApiBase'

export class AccountApi extends RestApiBase {
  private baseUrl = 'AccountAPI.php'

  getAccounts(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ACCOUNTS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  renameAccount(guid: string, name: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'RENAME_ACCOUNT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        accountid: guid,
        name: name,
      },
    })
  }

  editAccountKeys(accountId: string, version : number, publicKey: string, privateKey: string, extraKey: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_ACCOUNT_KEYS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        accountId,
        version,
        publicKey,
        privateKey,
        extraKey
     },
    }) }
  changeAccountVisability(accountId: string, publicVisible: boolean): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_ACCOUNT_VISABILTY',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        accountId,
        publicVisible,
      },
    })
  }

  deleteAccount(guid: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_ACCOUNT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        accountId: guid,
      },
    })
  }

  addAccount(name: string, driverCode: string, driverType: number, version : number, publicKey: string, privateKey: string, extraKey: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ADD_ACCOUNT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        name,
        driverCode,
        driverType,
        version,
        publicKey: publicKey,
        privateKey: privateKey,
        extraKey: extraKey,
      },
    })
  }

  testAccount(driverCode: string, driverType: number, version : number, publicKey: string, privateKey: string, extraKey: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'TEST_ACCOUNT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        driverCode,
        driverType,
        version,
        publicKey: publicKey,
        privateKey: privateKey,
        extraKey: extraKey,
      },
    })
  }

  checkAccount(accountId : string, exchangeCode : string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CHECK_ACCOUNT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        accountId,
        exchangeCode,
      },
    })
  }

  addSimulatedAccount(name: string, driverCode: string, driverType: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ADD_SIMULATED_ACCOUNT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        name: name,
        driverCode: driverCode,
        driverType: driverType,
      },
    })
  }

  getWallet(accountGuid: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_WALLET',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: {
        accountId: accountGuid,
      },
    })
  }
  getAllWallets(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ALL_WALLETS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: {},
    })
  }
  getBalance(accountGuid: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BALANCE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: {
        accountId: accountGuid,
      },
    })
  }
  getAllBalances(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ALL_BALANCES',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: {},
    })
  }

  getOpenOrders(accountGuid: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ORDERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        accountId: accountGuid,
      },
    })
  }
  getAllOpenOrders(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ALL_ORDERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getPositions(accountGuid: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_POSITIONS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: {
        accountId: accountGuid,
      },
    })
  }
  getAllPositions(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ALL_POSITIONS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getTradeHistory(accountIds: string[], marketSearchTags: string[], nextPageId: number, pageLength: number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_TRADES',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: {
        accountIds: accountIds.join(','),
        marketSearchTags: marketSearchTags.join(','),
        nextPageId,
        pageLength,
      },
    })
  }

  getPositionMode(accountId: string, market: string): ApiRequest<EnumAccountPositionMode> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_POSITION_MODE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      timeout: 30 * 1000,
      arguments: { accountId, market },
    })
  }

  getMarginSettings(accountId: string, market: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_MARGIN_SETTINGS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      timeout: 30 * 1000,
      arguments: {
        accountId,
        market,
      },
    })
  }

  adjustMarginSettings(accountId: string, market: string, positionMode: EnumAccountPositionMode, marginMode: EnumAccountMarketMarginMode, leverage: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ADJUST_MARGIN_SETTINGS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      timeout: 30 * 1000,
      arguments: {
        accountId,
        market,
        positionMode,
        marginMode,
        leverage,
      },
    })
  }

  depositFunds(accountId: string, walletId: string, currency: string, amount: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DEPOSIT_FUNDS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        accountId,
        currency,
        walletId,
        amount,
      },
    })
  }

  withdrawalFunds(accountId: string, walletId: string, currency: string, amount: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'WITHDRAWAL_FUNDS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        accountId,
        currency,
        walletId,
        amount,
      },
    })
  }

  startOAuth(name: string, driverCode: string, driverType: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'START_AUTH',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { name, driverCode, driverType },
    })
  }

  isAuthCompleted(accountId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'IS_AUTH_COMPLETED',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { accountId },
    })
  }

  completeTradeStationAuth(responseState: string, responseCode : string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'COMPLETE_TRADESTATION_AUTH',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { responseState, responseCode },
    })
  }
}
