export type HaasScriptFolderType = {
    id: number
    name: string
    parentId: number
    isFolder: boolean
    createdUnix: number
    updatedUnix: number

    parent?: HaasScriptFolderType | undefined
    children: HaasScriptFolderType[]
}

export const parseHaasScriptFolderType = (jsonData: any): HaasScriptFolderType => ({
    id: jsonData['FID'],
    name: jsonData['FN'],
    parentId: jsonData['PID'],
    isFolder: true,
    createdUnix: jsonData['CU'],
    updatedUnix: jsonData['UU'],

    parent: undefined,
    children: [],
})
