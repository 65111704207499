export class CloudNewsFeedHeadline {
  id: string
  title: string
  url: string
  unix: number
  timestamp: Date
  sourceId: string
  source: string
  media: string

  constructor(jsonData: any) {
    this.id = jsonData['Id']
    this.title = jsonData['Title']
    this.url = jsonData['Url']
    this.unix = jsonData['Timestamp']
    this.timestamp = new Date(jsonData['Timestamp'] * 1000)
    this.sourceId = jsonData['SourceId']
    this.source = jsonData['Source']
    this.media = jsonData['Media']
  }
}
