import { _ } from '@utils/lodash'

export class UserPortfolioHistoryItem {
  private currentValue = 0
  timestamp!: number
  amount!: number

  value!: number
  valueInBTC!: number
  valueInUSD!: number

  get change() {
    if (this.currentValue === 0 || this.value === 0) return 0

    return ((this.currentValue - this.value) / this.value) * 100
  }

  constructor(currentValue: number) {
    this.currentValue = currentValue
  }

  static fromItems(items: UserPortfolioHistoryItem[], currentValue: number) {
    const item = new UserPortfolioHistoryItem(currentValue)

    item.timestamp = _.min(items, (c) => c.timestamp)

    item.amount = _.sum(items, (c) => c.amount)

    item.value = _.sum(items, (c) => c.value)
    item.valueInBTC = _.sum(items, (c) => c.valueInBTC)
    item.valueInUSD = _.sum(items, (c) => c.valueInUSD)

    return item
  }

  static fromJson(jsonData: any, currentValue: number) {
    const item = new UserPortfolioHistoryItem(currentValue)

    item.timestamp = jsonData['T']

    item.amount = jsonData['A']

    item.value = jsonData['V']
    item.valueInBTC = jsonData['VB']
    item.valueInUSD = jsonData['VU']

    return item
  }
}
