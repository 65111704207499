export enum EnumHaasCommandCategory {
  ArrayHelper = 0,
  PositionPrices = 1,
  Charting = 2,
  CustomCommands = 3,
  CustomCommandsHelpers = 4,
  EnumerationsTradingLR = 5,
  EnumerationsColor = 6,
  EnumerationsCharting = 7,
  EnumerationsMovingAverages = 8,
  EnumerationsPosition = 9,
  EnumerationsParameterType = 10,
  EnumerationsSignal = 11,
  EnumerationsTrading = 12,
  EnumerationsOrderType = 13,
  EasySafeties = 14,
  EasyInsurances = 15,
  EasyIndicators = 16,
  Equations = 17,
  FlowControl = 40,
  InputFields = 18,
  InputSettings = 19,
  ManagedTradingSignals = 20,
  Mathematical = 21,
  MemoryHelpers = 22,
  Miscellaneous = 23,
  PositionInformation = 24,
  OrderHandling = 25,
  OrderInformation = 26,
  PriceData = 27,
  PriceMarketInformation = 28,
  ProfitInformation = 29,
  Settings = 30,
  SignalHelpers = 31,
  StringHelpers = 32,
  TechnicalAnalysis = 33,
  TechnicalAnalysisHelpers = 34,
  TimeInformation = 35,
  TradeBot = 36,
  TradeMarketInformation = 37,
  UnmanagedTradingSignals = 38,
  Wallet = 39,
  EnumerationsCandlePattern = 41,
  EnumerationsSignalType = 42,
  EnumerationsDataType = 43,
}
