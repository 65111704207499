export enum Components {
  'PortfolioHeader',
  'Header',
  'Footer',
  'CustomLayoutHit',
  'HaasSectionHeader',
  'CustomLayoutHitHover',
  'BotListButtons',
  'AddWrap',
  'DashboardContent',
  'TickerBarChart',
  'FancyMarketsMenu',
  'HxChartsPlotToolbar',
  'HaasDateTimePickerModelOverlay',
  'HaasLoadingBar',
  'MenuOverlay',
  'Menu',
  'BodyEnd',
  'Notifications',
}

export default (component: Components): number => component + 3
