import { HaasBlogCategory } from '@dataObjects/public/HaasBlogCategory'
import { HaasBlogComment } from '@dataObjects/public/HaasBlogComment'
import { HaasBlogTopic } from '@dataObjects/public/HaasBlogTopic'
import { CancellationToken } from '@services/api/rest/ApiRequest'
import { BlogApi } from '@services/blog/BlogApi'
import { PaginatedResponse } from '@utils/types/PaginatedResponse'
import { ServiceController, ServiceControllerConstructorType } from '../ServiceController'

export class BlogService {
  private api: BlogApi
  private controller: ServiceController

  private blogCategories : HaasBlogCategory[] = [];

  constructor(props: ServiceControllerConstructorType) {
    this.controller = props.controller
    this.api = new BlogApi(props.authenticator, this.controller)
  }

  async getAllCategories(): Promise<HaasBlogCategory[]> {
    if (this.blogCategories.length)
      return this.blogCategories;

    const response = await this.api.getAllCategories()
    this.blogCategories = response.map((c: any) => new HaasBlogCategory(c))

    return this.blogCategories;
  }

  async getCategory(topicId: number): Promise<HaasBlogCategory> {
    const response = await this.api.getCategory(topicId)
    return new HaasBlogCategory(response)
  }

  async addCategory(title: string): Promise<boolean> {
    return this.api.addCategory(title)
  }

  async editCategory(categoryId: number, title: string): Promise<boolean> {
    return this.api.editCategory(categoryId, title)
  }

  async deleteCategory(categoryId: number): Promise<boolean> {
    return this.api.deleteCategory(categoryId)
  }


  async getLatestTopics(pageLength: number, nextPageId: number, token: CancellationToken): Promise<PaginatedResponse<HaasBlogTopic>> {
    const response = await this.api.getLatestTopics(pageLength, nextPageId, token)
    return {
      items: response['I'].map((c: any) => new HaasBlogTopic(c)),
      nextPageId: response['NP'],
    }
  }

  async getLatestCommentedTopics(pageLength: number, nextPageId: number, token: CancellationToken): Promise<PaginatedResponse<HaasBlogTopic>> {
    const response = await this.api.getLatestCommentedTopics(pageLength, nextPageId, token)
    return {
      items: response['I'].map((c: any) => new HaasBlogTopic(c)),
      nextPageId: response['NP'],
    }
  }


  async getTopicsBySearch(searchKey: string, pageLength: number, nextPageId: number, token : CancellationToken): Promise<PaginatedResponse<HaasBlogTopic>> {
    const response = await this.api.getTopicsBySearch(searchKey, pageLength, nextPageId, token)
    return {
      items: response['I'].map((c: any) => new HaasBlogTopic(c)),
      nextPageId: response['NP'],
    }
  }

  async getTopicsByUserId(searchUserId: string, pageLength: number, nextPageId: number, token : CancellationToken): Promise<PaginatedResponse<HaasBlogTopic>> {
    const response = await this.api.getTopicsByUserId(searchUserId, pageLength, nextPageId, token)
    return {
      items: response['I'].map((c: any) => new HaasBlogTopic(c)),
      nextPageId: response['NP'],
    }
  }

  async getTopicsByCategoryId(categoryId: number, pageLength: number, nextPageId: number, token : CancellationToken): Promise<PaginatedResponse<HaasBlogTopic>> {
    const response = await this.api.getTopicsByCategoryId(categoryId, pageLength, nextPageId, token)
    return {
      items: response['I'].map((c: any) => new HaasBlogTopic(c)),
      nextPageId: response['NP'],
    }
  }



  async getTopic(topicId: number, pageLength: number, nextPageId: number, token : CancellationToken): Promise<HaasBlogTopic> {
    const response = await this.api.getTopic(topicId, pageLength, nextPageId, token)
    return new HaasBlogTopic(response)
  }

  async addTopic(categoryId: number, title: string, message: string): Promise<HaasBlogTopic> {
    const response = await this.api.addTopic(categoryId, title, message)
    return new HaasBlogTopic(response)
  }

  async editTopic(topicId: number, title: string, message: string): Promise<boolean> {
    return this.api.editTopic(topicId, title, message)
  }

  async deleteTopic(topicId: number): Promise<boolean> {
    return this.api.deleteTopic(topicId)
  }


  async getComments(topicId: number, pageLength: number, nextPageId: number, token : CancellationToken): Promise<PaginatedResponse<HaasBlogComment>> {
    const response = await this.api.getComments(topicId, pageLength, nextPageId, token)
    return {
      items : response['I'].map((c : any) => new HaasBlogComment(c)),
      nextPageId : response['NP']
    }
  }

  async addComment(topicId: number, message: string): Promise<HaasBlogComment> {
    const response = await this.api.addComment(topicId, message)
    return new HaasBlogComment(response)
  }

  async editComment(commentId: number, message: string): Promise<boolean> {
    return this.api.editComment(commentId, message)
  }

  async deleteComment(commentId: number): Promise<boolean> {
    return this.api.deleteComment(commentId)
  }


  async addImage(commentId: number, imageBlob: string): Promise<boolean> {
    return this.api.addImage(commentId, imageBlob)
  }

  async deleteImage(imageId: number): Promise<boolean> {
    return this.api.deleteImage(imageId)
  }


  async upVoteTopic(topicId: number): Promise<boolean> {
    return this.api.upVoteTopic(topicId)
  }
}
