import {ApiAuthenticator} from '@services/api/ApiAuthenticator'
import {ServiceController} from '@services/ServiceController'
import {_} from '@utils/lodash'
import {EnumLicenseTypes} from "@dataObjects/enums/EnumLicenseTypes";

export type SocketClientResponseType = {
  subscriptionId: string
  channel: string
  data: any
}

export type SocketClientRequestType = {
  type: string
  subscriptionId: string
  channel: string
  args?: any
}

class SocketClient {
  protected controller: ServiceController
  private authenticator: ApiAuthenticator
  private isSocketConnected: (isSocketConnected: boolean) => void

  private subscriptionQueue: SocketClientRequestType[] = []
  protected socket!: WebSocket
  private isAlive = false
  private isLoggedIn = false
  private keepAlive = false
  private keepLoggedIn = false
  private reconnectCounter = 0
  protected subscribeToPushMessages = false

  websocketUrl: string

  constructor(controller: ServiceController, isSocketConnected: (isSocketConnected: boolean) => void) {

    this.websocketUrl = String(process.env.REACT_APP_WS_URL);
    // this.websocketUrl = "ws://127.0.0.1:8092/";
    // this.websocketUrl = 'ws://127.0.0.1:12356/'
    // this.websocketUrl = "wss://production.hcdn.ws.haasapi.com";
    // this.websocketUrl = "wss://ws-ui.haasstage.com";

    if (process.env.REACT_APP_ENVIRONMENT === 'APP') {
      this.websocketUrl = String(window.settings?.REACT_APP_WS_URL);
    }

    this.controller = controller
    this.authenticator = controller.authenticator
    this.isSocketConnected = isSocketConnected

    this.start = this.start.bind(this)
    this.onOpen = this.onOpen.bind(this)
    this.onMessage = this.onMessage.bind(this)
    this.onError = this.onError.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  start() {
    if (this.socket) {
      this.socket.onopen = null
      this.socket.onmessage = null
      this.socket.onerror = null
      this.socket.onclose = null
      this.socket.close()
    }

    this.keepAlive = true

    this.socket = new WebSocket(this.websocketUrl)
    this.socket.onopen = this.onOpen
    this.socket.onmessage = this.onMessage
    this.socket.onerror = this.onError
    this.socket.onclose = this.onClose
  }

  stop() {
    this.keepAlive = false
    this.socket.close()
  }

  login() {
    if (this.controller.authenticator.isWhiteLabel())
      return;

    this.keepLoggedIn = true
    if (this.isAlive && !this.isLoggedIn) this.sendLogin()
  }

  private onOpen(ev: Event) {
    this.isLoggedIn = false
    this.isSocketConnected(true)
  }

  private onClose(ev: CloseEvent) {
    this.isAlive = false
    this.isLoggedIn = false
    this.isSocketConnected(false)

    // Stop here if we dont want to keep the connection alive
    if (!this.keepAlive) return

    // Increase counter and start connection again
    this.reconnectCounter++
    setTimeout(this.start, 5000)
  }

  private onError(ev: Event) {
    debugger // Keep
  }

  private onMessage(ev: MessageEvent) {
    try {
      const message: SocketClientResponseType = JSON.parse(ev.data)

      switch (message.subscriptionId) {
        case 'welcome':
          this.isAlive = true
          if (this.keepLoggedIn) this.sendLogin()
          break

        case 'login':
          this.onLoggedIn(message.data as boolean)
          break

        case 'ping':
          this.sendMessage({
            subscriptionId: 'pong',
            type: 'pong',
            channel: 'pong',
            args: {},
          })

          break

        default:
          this.processMessage(message)
          break
      }
    } catch (e) {
      console.log(e, 'Error receiving websocket message', ev)
    }
  }

  private sendLogin() {
    if (this.isLoggedIn) return
    if (!this.isAlive) return
    const { userId, interfaceKey } = this.controller.authenticator.session

    this.subscribe('login', 'login', {
      userId,
      interfaceKey,
      pushMessages: this.subscribeToPushMessages,
    })
  }

  private onLoggedIn(isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.isLoggedIn = false
      return
    }

    this.isLoggedIn = true

    // Clear queue
    _.each(this.subscriptionQueue, this.sendMessage)
    this.subscriptionQueue = []

    // If we are reconnecting, raise event
    if (this.reconnectCounter > 0) {
      this.reconnectCounter = 0
      this.onReconnect()
    }
  }

  sendMessage(message: SocketClientRequestType) {
    // Test
    this.socket?.send(JSON.stringify(message))
  }

  processMessage(message: SocketClientResponseType) {
    // Is overwritten in the derived class
  }

  subscribe(subscriptionId: string, channel: string, args: any) {
    const message = {
      type: 'subscribe',
      subscriptionId: subscriptionId,
      channel: channel,
      args: args,
    }

    if (!this.isAlive) return this.subscriptionQueue.push(message)

    this.sendMessage(message)
  }

  unsubscribe(subscriptionId: string) {
    if (!this.isAlive) return

    const message = {
      type: 'unsubscribe',
      subscriptionId: subscriptionId,
      channel: '',
      args: {},
    }

    this.sendMessage(message)
  }

  onReconnect() {
    // Is overwritten in the derived class
  }
}

export default SocketClient
