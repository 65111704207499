import { EnumHaasScriptOrderType } from '@dataObjects/scripting/EnumHaasScriptOrderType'
import { _ } from '@utils/lodash'
import { EnumHaasScriptInputFieldType } from '../enums/EnumHaasScriptInputFieldType'
import { EnumHaasScriptMarketType } from '../enums/EnumHaasScriptMarketType'

export class HaasScriptInputField {
  type!: EnumHaasScriptInputFieldType
  subType!: EnumHaasScriptMarketType
  group!: string

  key!: string
  name!: string
  tooltip!: string

  value!: string | number
  defaultValue!: string | number

  minValue!: number
  maxValue!: number

  options!: Record<string | number, number | string | string[]>

  constructor(jsonData?: any) {
    if (!jsonData) return

    this.type = jsonData['T']
    this.subType = jsonData['ST']

    this.group = jsonData['G']

    this.key = jsonData['K']
    this.name = jsonData['N']
    this.tooltip = jsonData['TT']

    this.value = HaasScriptInputField.parseValue(this.type, jsonData['V'])
    this.defaultValue = HaasScriptInputField.parseValue(this.type, jsonData['D'])

    this.minValue = jsonData['MIN']
    if (isNaN(this.minValue)) this.minValue = 0

    this.maxValue = jsonData['MAX']
    if (isNaN(this.maxValue)) this.maxValue = 0

    this.options = jsonData['O']
  }

  static parseValue(type: EnumHaasScriptInputFieldType, value: any) {
    switch (type) {
      case EnumHaasScriptInputFieldType.Number:
        const numberValue = Number(value)
        if (!isNaN(numberValue)) return numberValue

        return 0
      case EnumHaasScriptInputFieldType.Checkbox:
        switch (value) {
          case true:
          case 'true':
          case 'True':
            return true

          default:
            return false
        }
      case EnumHaasScriptInputFieldType.Interval: {
        const numberValue = Number(value)
        if (!isNaN(numberValue)) return numberValue

        return 1
      }
      case EnumHaasScriptInputFieldType.Dropdown:
      case EnumHaasScriptInputFieldType.PriceSource:
      case EnumHaasScriptInputFieldType.PriceSourceMarket:
      case EnumHaasScriptInputFieldType.Account:
      case EnumHaasScriptInputFieldType.AccountMarket:
      case EnumHaasScriptInputFieldType.Market:
      case EnumHaasScriptInputFieldType.Text:
      case EnumHaasScriptInputFieldType.Header:
      case EnumHaasScriptInputFieldType.Table:
      case EnumHaasScriptInputFieldType.Button:
      default:
        return value
    }
  }

  static createHeader(text: string): HaasScriptInputField {
    const input = new HaasScriptInputField()
    input.type = EnumHaasScriptInputFieldType.Header
    input.group = text
    input.key = _.createGuid()
    input.name = text
    input.tooltip = ''
    return input
  }

  static create(group: string, key: string, name: string, type: EnumHaasScriptInputFieldType, defaultValue: string | number, tooltip?: string) {
    const input = new HaasScriptInputField()
    input.type = type
    input.group = group
    input.key = key
    input.name = name
    input.tooltip = tooltip ?? ''
    input.value = defaultValue
    input.defaultValue = defaultValue
    return input
  }

  static createOrderType(group: string, key: string, name: string, defaultValue: EnumHaasScriptOrderType) {
    const input = new HaasScriptInputField()
    input.type = EnumHaasScriptInputFieldType.Dropdown
    input.group = group
    input.key = key
    input.name = name
    input.value = defaultValue
    input.defaultValue = defaultValue

    input.options = {
      [EnumHaasScriptOrderType.Limit]: 'Limit Order',
      [EnumHaasScriptOrderType.Market]: 'Market order',
      [EnumHaasScriptOrderType.NoTimeout]: 'No timeout order',
      [EnumHaasScriptOrderType.MakerOrCancel]: 'Maker order',
    }
    return input
  }
}
