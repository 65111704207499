export type UserLabConfig = {
  maxPopulation: number
  maxGeneration: number
  maxElites: number
  mixRate: number
  adjustRate: number
}

export function parseUserLabConfig(jsonData: any): UserLabConfig {
  return {
    maxPopulation: jsonData['MP'],
    maxGeneration: jsonData['MG'],
    maxElites: jsonData['ME'],
    mixRate: jsonData['MR'],
    adjustRate: jsonData['AR'],
  }
}

export function prepareUserLabConfig(config: UserLabConfig) {
  return {
    MP: config.maxPopulation,
    MG: config.maxGeneration,
    ME: config.maxElites,
    MR: config.mixRate,
    AR: config.adjustRate,
  }
}
