/* eslint-disable */
const CryptoJS = require('crypto-js')
const SecureStorage = require('secure-web-storage')

const SECRET_KEY = 'my secret key'

interface ISecureStorage {
  getItem<T>(key: string): T | undefined
  setItem<T>(key: string, data: T): void
  removeItem(key: string): void
}

const internalSecureStorage = new SecureStorage(localStorage, {
  hash: function hash(key: string) {
    key = CryptoJS.SHA256(key, SECRET_KEY)

    return key.toString()
  },
  encrypt: function encrypt(data: any) {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY)

    data = data.toString()

    return data
  },
  decrypt: function decrypt(data: any) {
    data = CryptoJS.AES.decrypt(data, SECRET_KEY)

    data = data.toString(CryptoJS.enc.Utf8)

    return data
  },
})

export const secureStorage: ISecureStorage = {
  getItem<T>(key: string): T | undefined {
    return internalSecureStorage.getItem(key)
  },
  setItem<T>(key: string, data: T): void {
    try {
      internalSecureStorage.setItem(key, data)
    } catch (e) {
      return undefined
    }
  },
  removeItem(key: string): void {
    return internalSecureStorage.removeItem(key)
  },
}
