import { HaasScriptSettingsForm } from '@components/HaasScriptSettings/HaasScriptSettingsForm'
import { EnumHaasRuntimeType } from '@dataObjects/enums/EnumHaasRuntimeType'
import { HaasBot, parseHaasBot } from '@dataObjects/private/HaasBot'
import { HaasBotAsync } from '@dataObjects/private/HaasBotAsync'
import RuntimeCustomReport from '@dataObjects/runtime/reports/RuntimeCustomReport'
import { RuntimeReport } from '@dataObjects/runtime/reports/RuntimeReport'
import { parseRuntime, Runtime } from '@dataObjects/runtime/Runtime'
import { RuntimeAsync } from '@dataObjects/runtime/RuntimeAsync'
import { RuntimeAsyncUpdate } from '@dataObjects/runtime/RuntimeAsyncUpdate'
import { RuntimeOrderWithBot } from '@dataObjects/runtime/RuntimeOrder'
import { RuntimePosition } from '@dataObjects/runtime/RuntimePosition'
import { EventHandler } from '@utils/eventHandlers/EventHandler'
import { KeyedEventHandler } from '@utils/eventHandlers/KeyedEventHandler'
import { _ } from '@utils/lodash'
import { PaginatedResponse } from '@utils/types/PaginatedResponse'
import { EnumHaasChartPricePlotStyle } from '@vendor/hxCharts/enums/EnumHaasChartPricePlotStyle'
import { HaasChartContainer } from '@vendor/hxCharts/types/HaasChartContainer'
import { ApiRequest, CancellationToken } from '../api/rest/ApiRequest'
import { ServiceController, ServiceControllerConstructorType } from '../ServiceController'
import { BotApi } from './BotApi'
import HaasBotTemplate from "@dataObjects/public/HaasBotTemplate";
import {parseWhiteLabelReport, WhiteLabelReportType} from "@services/admin/AdministratorService";

export class BotService {
  private api: BotApi
  private controller: ServiceController

  private botCache: Record<string, HaasBotAsync> = {}
  private runtimeCache: Record<string, RuntimeAsync> = {}

  botUpdates = new KeyedEventHandler<HaasBot>()
  botListUpdate = new EventHandler<HaasBot[]>()
  botFavoritesUpdate = new EventHandler<HaasBot[]>()

  runtimeUpdates = new KeyedEventHandler<Runtime>()
  runtimePartialUpdates = new KeyedEventHandler<RuntimeAsyncUpdate>()

  public constructor(props: ServiceControllerConstructorType) {
    this.controller = props.controller
    this.api = new BotApi(props.authenticator, this.controller)
  }

  async fetchBots(): Promise<void> {
    if (this.controller.authenticator.isWhiteLabel())
      return;

    const response = await this.api.getBots()
    this.botCache = _.map(response, (container, value) => {
      const bot = parseHaasBot(this.controller, value)
      container[bot.botId] = new HaasBotAsync(bot, this.controller, this.api, bot.botId, this.botUpdates)
    })

    this.botListUpdate.fire(this.getBots())
  }

  async fetchBot(botId: string): Promise<HaasBot> {
    if (!this.botCache[botId]) this.botCache[botId] = new HaasBotAsync(undefined, this.controller, this.api, botId, this.botUpdates)

    return (await this.botCache[botId].get()) as HaasBot
  }

  getBot(botId: string | undefined): HaasBot | undefined {
    if (!botId) return undefined

    if (!this.botCache[botId]) return undefined

    return this.botCache[botId].getSync()
  }

  getBots(): HaasBot[] {
    const bots = _.select(this.botCache, (item) => item.getSync()).filter((c) => !!c)

    return _.orderBy(bots as HaasBot[], (value) => value.botName)
  }

  async onBotUpdate(jsonData: any) {
    try {
      const { BID: botId } = jsonData

      const bot = this.botCache[botId]
      if (!bot) return

      this.botCache[botId].update(jsonData)
    } catch (e) {
      console.log(e)
    }
  }

  async addBot(account: string, market: string, leverage: number, name: string, scriptId: string, type: EnumHaasRuntimeType): Promise<HaasBot> {
    const interval = this.controller.settingsService.defaultInterval.get() ?? 1
    const chartStyle = this.controller.settingsService.defaultChartStyle.get() ?? EnumHaasChartPricePlotStyle.CandleStickHLC

    const response = await this.api.addBot(account, market, leverage, name, scriptId, type, interval, chartStyle)
    const bot = parseHaasBot(this.controller, response)

    this.botCache[bot.botId] = new HaasBotAsync(bot, this.controller, this.api, bot.botId, this.botUpdates)
    this.botListUpdate.fire(this.getBots())
    return bot
  }

  async addBotFromBacktest(backtestId: string, name: string, accountId: string, market: string, leverage: number): Promise<HaasBot> {
    const response = await this.api.addBotFromBacktest(backtestId, name, accountId, market, leverage)
    const bot = parseHaasBot(this.controller, response)

    this.botCache[bot.botId] = new HaasBotAsync(bot, this.controller, this.api, bot.botId, this.botUpdates)
    this.botListUpdate.fire(this.getBots())
    return bot
  }

  async addBotFromLabs(labId: string, backtestId: string, name: string, accountId: string, market: string, leverage: number): Promise<HaasBot> {
    const response = await this.api.addBotFromLabs(labId, backtestId, name, accountId, market, leverage)
    const bot = parseHaasBot(this.controller, response)

    this.botCache[bot.botId] = new HaasBotAsync(bot, this.controller, this.api, bot.botId, this.botUpdates)
    this.botListUpdate.fire(this.getBots())
    return bot
  }

  async activateBot(cleanReports: boolean, ...botIds: string[]): Promise<boolean> {
    const response = await this.api.activateBot(botIds.join(','), cleanReports)
    if (!response) return false

    _.each(botIds, (botId) => {
      if (!this.botCache[botId]) return
      ;(this.botCache[botId] as HaasBotAsync).updatePartial({ isActivated: true })
      if (!this.runtimeCache[botId]) return
      ;(this.runtimeCache[botId] as RuntimeAsync).updatePartial({
        activated: true,
        activatedSince: Math.floor(Date.now() / 1000),
      })
    })
    return true
  }

  async pauseBot(...botIds: string[]): Promise<boolean> {
    const response = await this.api.pauseBot(botIds.join(','))
    if (!response) return false

    _.each(botIds, (botId) => {
      if (!this.botCache[botId]) return
      ;(this.botCache[botId] as HaasBotAsync).updatePartial({ isPaused: true })
      if (!this.runtimeCache[botId]) return
      ;(this.runtimeCache[botId] as RuntimeAsync).updatePartial({
        paused: true,
      })
    })
    return true
  }

  async resumeBot(...botIds: string[]): Promise<boolean> {
    const response = await this.api.resumeBot(botIds.join(','))
    if (!response) return false

    _.each(botIds, (botId) => {
      if (!this.botCache[botId]) return
      ;(this.botCache[botId] as HaasBotAsync).updatePartial({ isPaused: false })
      if (!this.runtimeCache[botId]) return
      ;(this.runtimeCache[botId] as RuntimeAsync).updatePartial({
        paused: false,
      })
    })
    return true
  }

  async deactivateBot(cancelOrders: boolean, ...botIds: string[]): Promise<boolean> {
    const response = await this.api.deactivateBot(botIds.join(','), cancelOrders)
    if (!response) return false

    _.each(botIds, (botId) => {
      ;(this.botCache[botId] as HaasBotAsync).updatePartial({ isActivated: false })
      if (!this.runtimeCache[botId]) return
      ;(this.runtimeCache[botId] as RuntimeAsync).updatePartial({
        activated: false,
        deactivatedSince: Math.floor(Date.now() / 1000),
      })
    })

    return true
  }

  async deactivateAllBots(accountId: string, market: string): Promise<boolean> {
    return this.api.deactivateAllBots(accountId, market)
  }

  async editBot(botId: string, scriptId: string, settings: HaasScriptSettingsForm): Promise<boolean> {
    const response = await this.api.editBot(botId, scriptId, settings)
    const bot = parseHaasBot(this.controller, response['H'])
    this.botCache[botId]?.updatePartial(bot)

    if (response['R']) {
      const runtime = parseRuntime(this.controller, response['R'])
      this.runtimeCache[botId]?.updatePartial(runtime)
    }

    return true
  }

  async editScript(botId: string, scriptId: string, type: EnumHaasRuntimeType): Promise<boolean> {
    const response = await this.api.editScript(botId, scriptId, type)
    const bot = parseHaasBot(this.controller, response['H'])
    const runtime = parseRuntime(this.controller, response['R'])

    this.botCache[botId]?.updatePartial(bot)
    this.runtimeCache[botId]?.updatePartial(runtime)
    return true
  }

  async editNotes(botId: string, notes: string): Promise<boolean> {
    const response = await this.api.editNotes(botId, notes)
    if (!response) return false

    this.botCache[botId].updatePartial({
      notes: notes,
      notesTimestamp: Date.now() / 1000,
    })

    return true
  }

  async executeButton(botId: string, buttonId: string): Promise<boolean> {
    return this.api.executeButton(botId, buttonId)
  }

  async renameBot(botId: string, name: string): Promise<boolean> {
    await this.api.renameBot(botId, name)

    this.botCache[botId]?.updatePartial({ botName: name })
    this.runtimeCache[botId]?.updatePartial({ botName: name })

    return true
  }

  async favoriteBot(botId: string, isFavorite: boolean): Promise<boolean> {
    const response = await this.api.favoriteBot(botId, isFavorite)
    if (!response) return false

    this.botCache[botId] = _.copyClass(this.botCache[botId])
    this.botCache[botId].updatePartial({ isFavorite })

    const favoriteBots = _.filter(this.botCache, (item) => item.getSync()?.isFavorite === true)
    this.botFavoritesUpdate.fire(favoriteBots)

    return true
  }

  async resetBot(botId: string, resetReports: boolean, resetPositions: boolean, resetLogs: boolean, resetChart: boolean): Promise<HaasBot> {
    const response = await this.api.resetBot(botId, resetReports, resetPositions, resetLogs, resetChart)
    const bot = parseHaasBot(this.controller, response['H'])
    const runtime = parseRuntime(this.controller, response['R'])

    this.botCache[botId]?.updatePartial(bot)
    this.runtimeCache[botId]?.updatePartial(runtime)

    return bot
  }

  async cloneBot(botId: string, name: string): Promise<HaasBot> {
    const response = await this.api.cloneBot(botId, name)
    const bot = parseHaasBot(this.controller, response)

    this.botCache[bot.botId] = new HaasBotAsync(bot, this.controller, this.api, bot.botId, this.botUpdates)
    return bot
  }

  async deleteBot(...botIds: string[]): Promise<boolean> {
    await this.api.deleteBot(botIds.join(','))

    _.each(botIds, (id) => {
      delete this.botCache[id]

      if (this.runtimeCache[id]) {
        this.runtimeCache[id].dispose()
        delete this.runtimeCache[id]
      }
    })

    this.botListUpdate.fire(this.getBots())
    return true
  }

  async onRuntimeUpdate(jsonData: any) {
    try {
      const data = new RuntimeAsyncUpdate(this.controller, jsonData)
      const runtime = this.runtimeCache[data.botId]
      if (!runtime) return

      runtime.update(data)
      this.runtimePartialUpdates.fire(data.botId, data)
    } catch (e) {
      console.log(e)
    }
  }

  async getRuntime(botId: string, token: CancellationToken): Promise<Runtime> {
    if (!this.runtimeCache[botId]) this.runtimeCache[botId] = new RuntimeAsync(this.controller, this.api, botId, this.runtimeUpdates)

    return (await this.runtimeCache[botId].get()) as Runtime
  }

  getRuntimeObject(botId: string): RuntimeAsync {
    if (!this.runtimeCache[botId]) this.runtimeCache[botId] = new RuntimeAsync(this.controller, this.api, botId, this.runtimeUpdates)

    return this.runtimeCache[botId]
  }

  async getRuntimeReport(botId: string, token: CancellationToken): Promise<RuntimeReport[]> {
    const response = await this.api.getRuntimeReport(botId, token)
    return _.select(response, (value) => new RuntimeReport(this.controller, value))
  }

  async getRuntimeCustomReport(botId: string, token: CancellationToken): Promise<RuntimeCustomReport[]> {
    const response = await this.api.getRuntimeCustomReport(botId, token)
    return _.select(response, (items, title) => new RuntimeCustomReport(title, items))
  }

  async getRuntimeClosedPositions(botId: string, nextPageId: number, pageLength: number, token: CancellationToken): Promise<PaginatedResponse<RuntimePosition>> {
    const response = await this.api.getRuntimeClosedPositions(botId, nextPageId, pageLength, token)
    return {
      items: response['I'].map((c: any) => new RuntimePosition(this.controller, c, botId)),
      nextPageId: response['NP'],
    }
  }

  async getRuntimeLogbook(botId: string, nextPageId: number, pageLength: number, token: CancellationToken): Promise<{ logs: string[]; nextPageId: number }> {
    const response = await this.api.getRuntimeLogbook(botId, nextPageId, pageLength, token)

    nextPageId = response['NP']
    const logs = response['I'] as string[]

    return { logs, nextPageId }
  }

  getBotChart(
    botId: string,
    interval: number,
    style: EnumHaasChartPricePlotStyle,
    showVolume: boolean,
    saveSettings: boolean,
    token: CancellationToken
  ): ApiRequest<HaasChartContainer> {
    const bot = this.getBot(botId)
    if (bot) {
      bot.chartStyle = style
      bot.chartInterval = interval
      bot.showVolume = showVolume
    }

    return this.api.getBotChart(botId, interval, style, showVolume, saveSettings, token)
  }

  getProfitChart(botId: string, interval: number, saveSettings: boolean, token: CancellationToken): ApiRequest<any> {
    return this.api.getProfitChart(botId, interval, saveSettings, token)
  }

  async getOpenOrders(token: CancellationToken): Promise<RuntimeOrderWithBot[]> {
    const response = await this.api.getOpenOrders(token)
    return _.selectMany(response, (c: any[], botId: any) => c.map((x) => new RuntimeOrderWithBot(this.controller, x, true, undefined, String(botId))))
  }

  async getOpenPositions(token: CancellationToken): Promise<RuntimePosition[]> {
    const response = await this.api.getOpenPositions(token)
    return _.selectMany(response, (c: any[], botId: any) => c.map((x) => new RuntimePosition(this.controller, x, String(botId))))
  }

  async getBotOrdersCsv(botId: string, token: CancellationToken): Promise<string> {
    return this.api.getBotOrdersCsv(botId, token)
  }

  async getBotPositionsCsv(botId: string, token: CancellationToken): Promise<string> {
    return this.api.getBotPositionsCsv(botId, token)
  }

  async cancelOrder(botId: string, orderId: string): Promise<boolean> {
    await this.api.cancelOrder(botId, orderId)
    return true
  }

  async cancelAllOrders(botId: string): Promise<boolean> {
    await this.api.cancelAllOrders(botId)
    return true
  }

  async getBotTemplates(): Promise<HaasBotTemplate[]> {
    const response = await this.api.getBotTemplates();
    let templates : HaasBotTemplate[] = response.map((c : any) => new HaasBotTemplate(c, this.controller.initData));
    templates = _.orderDescBy(templates, c => c.returnOnInvestment);

    for (let i = 0; i < templates.length; i++) {
      templates[i].rank = i + 1;
    }

    return templates;
  }

  async getBotTemplate(botId: string): Promise<HaasBotTemplate> {
    const response = await this.api.getBotTemplate(botId);
    return new HaasBotTemplate(botId, this.controller.initData);
  }

  async cloneBotTemplate(botId: string, botName : string, accountId : string, market : string): Promise<string> {
    const response = await this.api.cloneBotTemplate(botId, botName, accountId, market);
    const bot = parseHaasBot(this.controller, response)

    this.botCache[bot.botId] = new HaasBotAsync(bot, this.controller, this.api, bot.botId, this.botUpdates)
    this.botListUpdate.fire(this.getBots())

    return bot.botId;
  }

  async getReport(): Promise<WhiteLabelReportType[]> {
    const response = await this.api.getReport();
    return response.map(parseWhiteLabelReport);
  }
}
