import { HaasPieData } from '@components/HaasPieChart/HaasPieChart'
import { _ } from '@utils/lodash'
import { UserPortfolioBalance } from './UserPortfolioBalance'
import { UserPortfolioHistoryItem } from './UserPortfolioHistoryItem'

export class UserCurrencyPortfolio {
  timestamp: number
  currency: string

  avgPrice = 0
  amount = 0

  value = 0
  valueInBTC = 0
  valueInUSD = 0

  currencyBalances: UserPortfolioBalance[]

  rollingDaily: UserPortfolioHistoryItem
  rollingWeekly: UserPortfolioHistoryItem
  rollingMonthly: UserPortfolioHistoryItem

  share = 0

  constructor(timestamp: number, currency: string, items: UserPortfolioBalance[], portfolioValue: number) {
    this.timestamp = timestamp
    this.currency = currency

    _.each(items, (item) => {
      this.amount += item.amount

      this.value += item.value
      this.valueInBTC += item.valueInBTC
      this.valueInUSD += item.valueInUSD
    })

    this.avgPrice = 0
    if (this.amount > 0) this.avgPrice = this.value / this.amount

    this.currencyBalances = items

    this.rollingDaily = UserPortfolioHistoryItem.fromItems(
      items.map((c) => c.daily),
      this.value
    )
    this.rollingWeekly = UserPortfolioHistoryItem.fromItems(
      items.map((c) => c.weekly),
      this.value
    )
    this.rollingMonthly = UserPortfolioHistoryItem.fromItems(
      items.map((c) => c.monthly),
      this.value
    )

    if (portfolioValue !== 0) this.share = (this.value / portfolioValue) * 100
  }

  toPieData(): HaasPieData {
    return {
      name: this.currency,
      value: this.value,
    }
  }
}
