import { EnumAccountPositionMode } from '@dataObjects/enums/EnumAccountPositionMode'
import { EnumAccountMarketMarginMode } from '../../enums/EnumAccountMarketMarginMode'

export class UserAccountMarketSettings {
  market: string
  positionMode: EnumAccountPositionMode
  marginMode: EnumAccountMarketMarginMode
  longLeverage: number
  shortLeverage: number

  constructor(market: string, jsonData: any) {
    this.market = market
    this.positionMode = jsonData['PM']
    this.marginMode = jsonData['SMM']
    this.longLeverage = jsonData['LL']
    this.shortLeverage = jsonData['SL']
  }
}
