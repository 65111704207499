import { CloudInitData } from '@dataObjects/public/CloudInitData'
import { _ } from '@utils/lodash'
import { BotExecutionReport } from './BotExecutionReport'

export class BotExecutionServiceReport {
  machineId: string
  serviceId: string
  serviceName: string

  executionReports: BotExecutionReport[]
  botMarkets: Record<string, number> = {}

  constructor(jsonData: any, initData: CloudInitData) {
    this.machineId = jsonData['MID']
    this.serviceId = jsonData['SID']
    this.serviceName = jsonData['SN']

    this.executionReports = jsonData['ER'].map((c: any) => new BotExecutionReport(c, initData, this.machineId, this.serviceId, this.serviceName))
    _.each(this.executionReports, (item) => {
      if (!this.botMarkets[item.market?.tag || 'N/A']) this.botMarkets[item.market?.tag || 'N/A'] = 0

      this.botMarkets[item.market?.tag || 'N/A']++
    })
  }
}
