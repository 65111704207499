import { CloudInitData } from '@dataObjects/public/CloudInitData'
import { BlogService } from '@services/blog/BlogService'
import PublicService from '@services/public/PublicService'
import { ScriptBlogService } from '@services/scriptblog/ScriptBlogService'
import { AccountService } from './account/AccountService'
import { AdministratorService } from './admin/AdministratorService'
import { ApiAuthenticator } from './api/ApiAuthenticator'
import MarketSocketClient from './api/socket/market/MarketSocketClient'
import UserSocketClient from './api/socket/user/UserSocketClient'
import { BacktestService } from './backtest/BacktestService'
import { BalanceService } from './balance/BalanceService'
import { BotService } from './bots/BotService'
import { ChartingService } from './charting/ChartingService'
import { DashboardService } from './dashboard/DashboardService'
import { ExternalWalletService } from './externalwallet/ExternalWalletService'
import { InterfaceService } from './interface/InterfaceService'
import { LabsService } from './labs/LabsService'
import { LoginService } from './login/LoginService'
import { NewsFeedsService } from './newsfeeds/NewsFeedsService'
import { NotificationService } from './notifications/NotificationService'
import { OrderService } from './orders/OrderService'
import { PortfolioService } from './portfolio/PortfolioService'
import { PositionsService } from './positions/PositionsService'
import { PriceService } from './price/PriceService'
import { ScriptService } from './script/ScriptService'
import { SettingsService } from './settings/SettingsService'
import { SetupService } from './setup/SetupService'
import { SignalService } from './signal/SignalService'
import { TradingService } from './trading/TradingService'
import { WebshopService } from './webshop/WebshopService'

export type ServiceControllerConstructorType = {
  controller: ServiceController
  authenticator: ApiAuthenticator
  publicSocket: MarketSocketClient
  userSocket: UserSocketClient
}

type Props = {
  isSocketConnected: (isSocketConnected: boolean) => void
  logout: () => void
}

export class ServiceController {
  authenticator: ApiAuthenticator

  publicSocket: MarketSocketClient
  userSocket: UserSocketClient

  initData!: CloudInitData

  administratorService: AdministratorService
  accountService: AccountService
  balanceService: BalanceService
  blogService: BlogService
  botService: BotService
  backtestService: BacktestService
  chartingService: ChartingService
  dashboardService: DashboardService
  externalWalletsService: ExternalWalletService
  interfaceService: InterfaceService
  labsService: LabsService
  loginService: LoginService
  newsFeedService: NewsFeedsService
  notificationService: NotificationService
  orderService: OrderService
  portfolioService: PortfolioService
  publicService: PublicService
  positionService: PositionsService
  priceService: PriceService
  scriptService: ScriptService
  scriptBlogService: ScriptBlogService
  settingsService: SettingsService
  signalService: SignalService
  tradingService: TradingService
  webshopService: WebshopService
  setupService: SetupService

  logout: () => void

  constructor(props: Props) {
    this.logout = props.logout
    this.authenticator = new ApiAuthenticator()

    this.publicSocket = new MarketSocketClient(this, props.isSocketConnected)
    this.publicSocket.start()

    this.userSocket = new UserSocketClient(this, props.isSocketConnected)
    this.userSocket.start()

    const serviceOptions: ServiceControllerConstructorType = {
      controller: this,
      authenticator: this.authenticator,
      publicSocket: this.publicSocket,
      userSocket: this.userSocket,
    }

    this.administratorService = new AdministratorService(serviceOptions)
    this.accountService = new AccountService(serviceOptions)
    this.balanceService = new BalanceService(serviceOptions)
    this.blogService = new BlogService(serviceOptions)
    this.botService = new BotService(serviceOptions)
    this.backtestService = new BacktestService(serviceOptions)
    this.chartingService = new ChartingService(serviceOptions)
    this.dashboardService = new DashboardService(serviceOptions)
    this.externalWalletsService = new ExternalWalletService(serviceOptions)
    this.interfaceService = new InterfaceService(serviceOptions)
    this.loginService = new LoginService(serviceOptions)
    this.labsService = new LabsService(serviceOptions)
    this.newsFeedService = new NewsFeedsService(serviceOptions)
    this.notificationService = new NotificationService(serviceOptions)
    this.orderService = new OrderService(serviceOptions)
    this.publicService = new PublicService()
    this.portfolioService = new PortfolioService(serviceOptions)
    this.positionService = new PositionsService(serviceOptions)
    this.priceService = new PriceService(serviceOptions)
    this.scriptService = new ScriptService(serviceOptions)
    this.scriptBlogService = new ScriptBlogService(serviceOptions)
    this.settingsService = new SettingsService(serviceOptions)
    this.signalService = new SignalService(serviceOptions)
    this.tradingService = new TradingService(serviceOptions)
    this.webshopService = new WebshopService(serviceOptions)
    this.setupService = new SetupService(serviceOptions)
  }
}
