import { UserPosition } from '@dataObjects/private/account/UserPosition'
import { UserPositionContainer } from '@dataObjects/private/account/UserPositionContainer'
import { EventHandler } from '@utils/eventHandlers/EventHandler'
import { KeyedEventHandler } from '@utils/eventHandlers/KeyedEventHandler'
import { _ } from '@utils/lodash'
import { AccountApi } from '../account/AccountApi'
import { CancellationToken } from '../api/rest/ApiRequest'
import { ServiceController, ServiceControllerConstructorType } from '../ServiceController'

export class PositionsService {
  private api: AccountApi
  private controller: ServiceController

  private positionCache: UserPosition[] = []
  positionUpdate = new KeyedEventHandler<UserPosition>()
  positionUpdates = new EventHandler<UserPosition[]>()

  constructor(props: ServiceControllerConstructorType) {
    this.controller = props.controller
    this.api = new AccountApi(props.authenticator, this.controller)
  }

  onPositionOpened(jsonData: any) {
    const position = new UserPosition(this.controller, jsonData['AID'], jsonData)

    this.positionCache = [...this.positionCache.filter((c) => c.positionId !== position.positionId), position]

    if (this.positionUpdates.hasSubscribers()) this.positionUpdates.fire([...this.positionCache])
  }

  onPositionUpdate(jsonData: any) {
    const position = new UserPosition(this.controller, jsonData['AID'], jsonData)
    const { positionId } = position

    const oldPosition = this.positionCache.find((c) => c.positionId === positionId)

    this.positionCache = [...this.positionCache.filter((c) => c.positionId !== positionId), position]

    if (this.positionUpdates.hasSubscribers()) this.positionUpdates.fire([...this.positionCache])

    if (oldPosition && this.positionUpdate.hasSubscribers(positionId)) this.positionUpdate.fire(positionId, position)
  }

  onPositionClosed(positionId: string) {
    this.positionCache = this.positionCache.filter((c) => c.positionId !== positionId)
    this.positionUpdates.fire([...this.positionCache])
  }

  onAccountDeleted(accountId: string) {
    this.positionCache = this.positionCache.filter((c) => c.accountId !== accountId)

    if (this.positionUpdates.hasSubscribers()) this.positionUpdates.fire([...this.positionCache])
  }

  async fetchOpenPositions() {
    if (this.controller.authenticator.isWhiteLabel())
      return;

    const response = await this.api.getAllPositions()

    const positionContainers = response.map((c: any) => new UserPositionContainer(this.controller, c)) as UserPositionContainer[]
    this.positionCache = _.selectMany(positionContainers, (value) => value.positions)
  }

  async getOpenPositions(accountGuid: string, token: CancellationToken): Promise<UserPositionContainer> {
    const response = await this.api.getPositions(accountGuid, token)
    const container = new UserPositionContainer(this.controller, response)
    this.positionCache = [...this.positionCache.filter((c) => c.accountId !== accountGuid), ...container.positions]

    return container
  }

  getAllPositions(accountId?: string): UserPosition[] {
    if (!accountId) return [...this.positionCache]

    return [...this.positionCache.filter((c) => c.accountId === accountId)]
  }
}
