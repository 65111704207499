import { HaasScriptInputField } from '@dataObjects/scripting/HaasScriptInputField'

export class HaasScriptPerformanceRecord {
  id: number
  scriptId: string
  market: string
  interval: number
  leverage: number
  startTime: number
  endTime: number
  amount: number
  roi: number
  hodlRoi: number
  maxDrawDown: number
  sharpeRatio: number
  sortinoRatio: number
  winPercentage: number
  profitFactor: number
  cpcIndex: number
  tailRatio: number
  commonSenseRatio: number
  profitMarginRatio: number
  ordersProgressed: number
  inputFields: HaasScriptInputField[]
  timespan: number
  ordersPerDay: number
  roiPerDay: number

  constructor(jsonData: any) {
    this.id = jsonData['ID']
    this.scriptId = jsonData['SID']
    this.market = jsonData['M']
    this.interval = jsonData['I']
    this.leverage = jsonData['L']
    this.startTime = jsonData['ST']
    this.endTime = jsonData['ET']
    this.amount = jsonData['A']
    this.roi = jsonData['ROI']
    this.hodlRoi = jsonData['HODL']
    this.maxDrawDown = jsonData['MDD']
    this.sharpeRatio = jsonData['SHR']
    this.sortinoRatio = jsonData['SOR']
    this.winPercentage = jsonData['WP']
    this.profitFactor = jsonData['PF']
    this.cpcIndex = jsonData['CPC']
    this.tailRatio = jsonData['TR']
    this.commonSenseRatio = jsonData['CSR']
    this.profitMarginRatio = jsonData['PMR']
    this.ordersProgressed = jsonData['O']
    this.inputFields = jsonData['S'] // Parse as array
    this.timespan = jsonData['TS']
    this.ordersPerDay = jsonData['OPD']
    this.roiPerDay = jsonData['ROIPD']
  }
}
