export class NumberStatistics {
  min: number
  max: number
  avg: number

  constructor(jsonData: any) {
    this.min = jsonData['Min']
    this.max = jsonData['Max']
    this.avg = jsonData['Avg']
  }
}
