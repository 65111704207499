import { NotificationManager } from '@application/Notifications/NotificationManager'
import { cloneHaasScriptSettings, HaasScriptSettingsForm } from '@components/HaasScriptSettings/HaasScriptSettingsForm'
import { EnumAccountMarketMarginMode } from '@dataObjects/enums/EnumAccountMarketMarginMode'
import { EnumAccountPositionMode } from '@dataObjects/enums/EnumAccountPositionMode'
import { EnumHaasRuntimeType } from '@dataObjects/enums/EnumHaasRuntimeType'
import { Runtime } from '@dataObjects/runtime/Runtime'
import { EnumHaasScriptOrderType } from '@dataObjects/scripting/EnumHaasScriptOrderType'
import { HaasBlock } from '@dataObjects/scripting/HaasBlock'
import { HaasScriptCompiledResult } from '@dataObjects/scripting/HaasScriptCompiledResult'
import { HaasScriptItem } from '@dataObjects/scripting/HaasScriptItem'
import TimerAsync from '@elements/HaasAsyncComponent/components/TimerAsync'
import { CancellationToken } from '@services/api/rest/ApiRequest'
import { BacktestService } from '@services/backtest/BacktestService'
import { ScriptService } from '@services/script/ScriptService'
import { ServiceController } from '@services/ServiceController'
import { SettingsService } from '@services/settings/SettingsService'
import ExceptionHandler from '@utils/ExceptionHandler'
import { _ } from '@utils/lodash'
import { EnumHaasChartPricePlotStyle } from '@vendor/hxCharts/enums/EnumHaasChartPricePlotStyle'
import {HaasChartContainer, mergeHaasChartContainer} from '@vendor/hxCharts/types/HaasChartContainer'
import WebLuaEditor, { LuaEditorUserState } from '../Editors/LuaEditor/WebLuaEditor'
import WebVisualEditor, { VisualEditorUserState } from '../Editors/VisualEditor/WebVisualEditor'
import { WebEditorRefsType } from '../WebEditor.refs'
import { WebEditorBacktestProgressUpdate } from '../WebEditor.types'

export enum EnumWebEditorTabState {
  Error,
  RequestingSource,
  RequestingCommands,
  Idle,

  ExecutingDebugTest,
  ExecutingQuickTest,
  ExecutingBackTest,
}

export enum EnumWebEditorTabExecutionState {
  Idle = -2,
  Requesting = -1,
  LoadingScript = 0,
  LoadingPriceHistory = 1,
  Executing = 2,
  Completed = 3,
  Failed = 4,
  FetchingResult = 5,
}

export type WebEditorTabState = {
  state: EnumWebEditorTabState
  isLoading: boolean
  isSaving: boolean

  isExecuting: boolean
  executionState: WebEditorTabExecutionState

  backtestId: string

  scriptId: string
  scriptType: EnumHaasRuntimeType
  sourceCode?: string
  hasChanges: boolean

  settings: HaasScriptSettingsForm

  logs: string[]
  chart?: HaasChartContainer
  chartPartition : number;
  runtime?: Runtime
  compileResult?: HaasScriptCompiledResult
  debugMatrix?: Record<string, string>
}

export type WebEditorTabExecutionState = {
  messageId: number

  serviceId: string
  backtestId: string
  isBacktest: boolean

  state: EnumWebEditorTabExecutionState
  message: string
  progress: number
}

type WebEditorTabProps = {
  services: ServiceController
  scriptId: string
  onUpdate: (state: WebEditorTabState) => void
  onSourceCodeChange: (scriptId: string) => void
  settings?: HaasScriptSettingsForm
}

class WebEditorTab {
  private settingsService: SettingsService
  private scriptService: ScriptService
  private backtestService: BacktestService
  private onUpdate: (state: WebEditorTabState) => void
  private onSourceCodeChange: (scriptId: string) => void
  private token = new CancellationToken()

  scriptId: string
  scriptType: EnumHaasRuntimeType

  sourceCode?: string | HaasBlock[]
  originalSourceCode?: string

  tabState: EnumWebEditorTabState
  isSaving = false

  settings: HaasScriptSettingsForm
  executionState: WebEditorTabExecutionState

  logs: string[] = []
  chart?: HaasChartContainer
  chartPartition = 0;
  isLoadingChartPartition = false;

  runtime?: Runtime
  debugMatrix?: Record<string, string>
  compileResult?: HaasScriptCompiledResult

  editorUserState?: LuaEditorUserState | VisualEditorUserState

  private pollTimer = new TimerAsync(() => this.fetchExecutionUpdate(), 30, 5)
  private pollCts = new CancellationToken()
  private editorRefs?: WebEditorRefsType<WebLuaEditor | WebVisualEditor>

  constructor(props: WebEditorTabProps) {
    const { scriptId, services, settings, onUpdate, onSourceCodeChange } = props
    const { backtestService, scriptService, settingsService } = services
    const { type } = scriptService.getScriptDetails(scriptId) as HaasScriptItem

    this.onUpdate = onUpdate
    this.onSourceCodeChange = onSourceCodeChange

    this.scriptId = scriptId
    this.scriptType = type
    this.sourceCode = undefined
    this.originalSourceCode = undefined

    this.tabState = EnumWebEditorTabState.RequestingSource
    this.isSaving = false

    this.scriptService = scriptService
    this.backtestService = backtestService
    this.settingsService = settingsService

    const account = backtestService.accounts[0]
    const market = account?.markets[0]
    const leverage = market?.minLeverage ?? 1

    this.settings = {
      botId: '',
      botName: '',

      accountId: settings?.accountId ?? account?.accountId,
      marketTag: settings?.marketTag ?? market?.tag,
      leverage: settings?.leverage ?? leverage,
      marginMode: settings?.marginMode ?? EnumAccountMarketMarginMode.CrossMargin,
      positionMode: settings?.positionMode ?? EnumAccountPositionMode.Hedge,

      chartStyle: settings?.chartStyle ?? EnumHaasChartPricePlotStyle.CandleStickHLC,
      interval: settings?.interval ?? 1,

      orderTemplate: settings?.orderTemplate ?? EnumHaasScriptOrderType.Limit,
      tradeAmount: settings?.tradeAmount ?? 1,

      scriptParameters: {},
    }

    this.executionState = {
      messageId: -1,
      serviceId: '',
      backtestId: '',
      isBacktest: false,
      state: EnumWebEditorTabExecutionState.Idle,
      progress: 0,
      message: '',
    }

    this.fetchExecutionUpdate = this.fetchExecutionUpdate.bind(this)
    this.fetchBacktestResult = this.fetchBacktestResult.bind(this)
    this.onExecutionUpdate = this.onExecutionUpdate.bind(this)
    this.processExecutionResults = this.processExecutionResults.bind(this)

    this.loadSourceCode()
  }

  setRefs(editorRefs: WebEditorRefsType<WebLuaEditor | WebVisualEditor>) {
    this.editorRefs = editorRefs
  }

  private async loadSourceCode() {
    this.tabState = EnumWebEditorTabState.RequestingSource
    this.publishUpdate()

    const response = await this.scriptService.getScriptRecord(this.scriptId)
    if (!response) {
      this.tabState = EnumWebEditorTabState.Error
      this.publishUpdate()
      return
    }

    this.logs = response.compileResult.executionLog
    this.sourceCode = response.sourceCode
    this.originalSourceCode = response.sourceCode
    this.compileResult = response.compileResult
    this.settings.scriptParameters = _.map(this.compileResult.inputFields, (container, item, key) => (container[key] = item.value))

    await this.loadCommands()
  }

  private async loadCommands() {
    this.tabState = EnumWebEditorTabState.RequestingCommands
    this.publishUpdate()

    await this.settingsService.favoriteCommands.fetch()

    switch (this.scriptType) {
      case EnumHaasRuntimeType.Lua:
        await this.scriptService.fetchCommands()
        break
      case EnumHaasRuntimeType.Visual:
        await this.scriptService.fetchBlocks()
        break
      case EnumHaasRuntimeType.Element:
        await this.scriptService.fetchElements()
        break
    }

    this.tabState = EnumWebEditorTabState.Idle
    this.publishUpdate()

    await this.loadExecutingBacktest()
  }

  private async loadExecutingBacktest() {
    try {
      await this.fetchExecutingBacktestIds()
    } catch {
      this.tabState = EnumWebEditorTabState.Idle
    } finally {
      this.publishUpdate()
    }
  }

  private async fetchExecutingBacktestIds() {
    const prevBacktestId = this.executionState.backtestId
    const executionIds = await this.backtestService.isScriptExecuting(this.scriptId, this.token)
    if (!executionIds?.backtestId || executionIds.state >= EnumWebEditorTabExecutionState.Completed) {
      this.tabState = EnumWebEditorTabState.Idle
      this.executionState.state = EnumWebEditorTabExecutionState.Idle
      // this.executionState.backtestId = "";
      this.executionState.serviceId = ''
      return
    }

    this.logs = []
    this.chart = undefined
    this.runtime = undefined
    this.debugMatrix = undefined

    this.tabState = EnumWebEditorTabState.ExecutingBackTest
    this.executionState.state = EnumWebEditorTabExecutionState.Executing
    this.executionState.backtestId = executionIds.backtestId
    this.executionState.serviceId = executionIds.serviceId
    this.executionState.messageId = -1
    this.executionState.progress = 0

    await this.fetchExecutionUpdate(true)

    this.backtestService.backtestUpdates.changeSubscription(prevBacktestId, this.executionState.backtestId, this.onExecutionUpdate)
    this.pollTimer.start()
  }

  async saveScript(sourceCode: string, settings: HaasScriptSettingsForm) {
    this.isSaving = true
    this.logs = []
    this.publishUpdate()

    try {
      const response = await this.scriptService.editScriptSourceCode(this.scriptId, sourceCode, settings)
      if (!response) return false

      this.sourceCode = sourceCode
      this.originalSourceCode = sourceCode
      this.logs = response.executionLog
      this.compileResult = response

      _.each(settings.scriptParameters ?? {}, (item, key) => {
        const inputField = this.compileResult?.inputFields[String(key)]
        if (!inputField) return

        inputField.value = item
      })

      return true
    } finally {
      this.isSaving = false
      this.publishUpdate()
    }
  }

  async doDebugTest() {
    const script = this.scriptService.getScriptDetails(this.scriptId)
    if (!script) return

    try {
      // Clear runtime, chart & log & publish state update
      this.prepareExecution(EnumWebEditorTabState.ExecutingDebugTest)

      // Execute quick test
      const response = await this.backtestService.executeDebugTest(script, cloneHaasScriptSettings(this.settings), this.token)
      if (!response) {
        // Log error
        NotificationManager.error('Debug failed')
        return
      }

      switch (script.type) {
        case EnumHaasRuntimeType.Lua:
          this.logs = response as string[]
          break
        case EnumHaasRuntimeType.Visual:
          this.debugMatrix = response as Record<string, string>
          this.addLog('Debug test finished')
          break
        case EnumHaasRuntimeType.Element:
          break
      }
    } finally {
      this.tabState = EnumWebEditorTabState.Idle
      this.publishUpdate()
    }
  }

  async doQuickTest() {
    const script = this.scriptService.getScriptDetails(this.scriptId)
    if (!script) return

    // Clear runtime, chart & log & publish state update
    this.prepareExecution(EnumWebEditorTabState.ExecutingQuickTest)

    // Execute quick test
    try {
      this.executionState.serviceId = await this.backtestService.executeQuicktest(this.executionState.backtestId, script, cloneHaasScriptSettings(this.settings), this.token)
      if (!this.executionState.serviceId) {
        // Log error
        NotificationManager.error('Quicktest failed')
        this.resetAfterExecution()
        return
      }

      this.pollTimer.start()
    } catch (e) {
      ExceptionHandler.LogException(e)
      this.resetAfterExecution()
    }
  }

  async doBackTest(startUnix: number, endUnix: number) {
    const script = this.scriptService.getScriptDetails(this.scriptId)
    if (!script) return

    // Clear runtime, chart & log & publish state update
    this.prepareExecution(EnumWebEditorTabState.ExecutingBackTest)

    // Execute back test
    try {
      this.executionState.serviceId = await this.backtestService.executeBackTest(
          this.executionState.backtestId,
          script,
          cloneHaasScriptSettings(this.settings),
          startUnix,
          endUnix,
          this.token
      )
      if (!this.executionState.serviceId) {
        // Log error
        NotificationManager.error('Backtest failed')
        this.resetAfterExecution()
        return
      }

      this.pollTimer.start()
    } catch (e) {
      ExceptionHandler.LogException(e)
      this.resetAfterExecution()
    }
  }

  cancelExecution() {
    switch (this.tabState) {
      case EnumWebEditorTabState.Error:
      case EnumWebEditorTabState.RequestingSource:
      case EnumWebEditorTabState.RequestingCommands:
      case EnumWebEditorTabState.Idle:
        return

      case EnumWebEditorTabState.ExecutingDebugTest:
      case EnumWebEditorTabState.ExecutingQuickTest:
      case EnumWebEditorTabState.ExecutingBackTest:
        break
    }

    const { serviceId, backtestId } = this.executionState

    this.tabState = EnumWebEditorTabState.Idle
    this.resetExecutionState()
    this.backtestService.backtestUpdates.unsubscribe(backtestId, this.onExecutionUpdate)

    this.token.cancel()
    this.token = new CancellationToken()

    this.backtestService.cancelBacktest(serviceId, backtestId)

    this.publishUpdate()
  }

  private prepareExecution(type: EnumWebEditorTabState) {
    const oldBacktestId = this.executionState.backtestId

    this.tabState = type

    this.resetExecutionState()

    switch (type) {
      case EnumWebEditorTabState.Error:
      case EnumWebEditorTabState.RequestingSource:
      case EnumWebEditorTabState.RequestingCommands:
      case EnumWebEditorTabState.Idle:
        break

      case EnumWebEditorTabState.ExecutingDebugTest:
        this.logs = []
        this.debugMatrix = undefined
        this.addLog('Debug test started')
        NotificationManager.info('Debug test started')
        break
      case EnumWebEditorTabState.ExecutingQuickTest:
        this.logs = []
        // this.chart = undefined;
        this.runtime = undefined
        this.debugMatrix = undefined
        NotificationManager.info('Quick test started')
        break
      case EnumWebEditorTabState.ExecutingBackTest:
        this.logs = []
        // this.chart = undefined;
        this.runtime = undefined
        this.debugMatrix = undefined
        NotificationManager.info('Backtest started')
        break
    }

    this.backtestService.backtestUpdates.changeSubscription(oldBacktestId, this.executionState.backtestId, this.onExecutionUpdate)
    this.publishUpdate()
  }

  private async fetchExecutionUpdate(forceUpdate?: boolean) {
    const { serviceId, backtestId, isBacktest, state, progress } = this.executionState

    if (state !== EnumWebEditorTabExecutionState.Executing) return
    if (!forceUpdate && progress < 5) return

    const response = await this.backtestService.getExecutionUpdate(serviceId, backtestId, isBacktest, this.pollCts)
    this.onExecutionUpdate(response)
  }

  private onExecutionUpdate(data: WebEditorBacktestProgressUpdate) {
    const { backtestId } = this.executionState

    switch (data.state) {
      case EnumWebEditorTabExecutionState.LoadingScript:
      case EnumWebEditorTabExecutionState.LoadingPriceHistory:
      case EnumWebEditorTabExecutionState.Executing:
        if (data.messageId > this.executionState.messageId) {
          this.executionState.messageId = data.messageId
          this.executionState.state = data.state
          this.executionState.progress = data.progress
          this.executionState.message = data.message

          this.publishUpdate()
        }
        break

      case EnumWebEditorTabExecutionState.Completed:
      case EnumWebEditorTabExecutionState.Failed:
        this.executionState.state = data.state
        this.executionState.message = data.message
        this.executionState.progress = 100

        this.pollTimer.stop()
        this.fetchBacktestResult(backtestId)
        break
    }
  }

  async fetchBacktestResult(backtestId: string) {
    this.logs = []
    this.chart = undefined
    this.executionState.backtestId = backtestId
    this.executionState.progress = 100
    this.executionState.message = ''

    if (this.executionState.state === EnumWebEditorTabExecutionState.Failed) {
      this.tabState = EnumWebEditorTabState.Idle
      this.publishUpdate()
      return
    }

    this.executionState.state = EnumWebEditorTabExecutionState.FetchingResult
    this.publishUpdate()

    try {
      const response = await this.backtestService.getBacktestRuntime(backtestId, this.pollCts)
      response.executionLog = await this.backtestService.getBacktestLogs(backtestId, this.pollCts)

      this.chartPartition = 0;
      response.chart = await this.backtestService.getBacktestChartPartition(backtestId, 1, this.chartPartition, this.pollCts)

      this.processExecutionResults(response)
    } finally {
      this.executionState.state = EnumWebEditorTabExecutionState.Idle
      this.tabState = EnumWebEditorTabState.Idle
      this.publishUpdate()
    }
  }

  private processExecutionResults(runtime: Runtime) {
    const { backtestId } = this.executionState

    // Set runtime, chart & logs
    this.runtime = runtime
    this.chart = runtime.chart
    this.logs = runtime.executionLog

    this.editorRefs?.backtestRemoteRef?.current?.onBacktestFinished(backtestId)

    switch (this.tabState) {
      case EnumWebEditorTabState.Error:
      case EnumWebEditorTabState.RequestingSource:
      case EnumWebEditorTabState.RequestingCommands:
      case EnumWebEditorTabState.Idle:
      case EnumWebEditorTabState.ExecutingDebugTest:
        break

      case EnumWebEditorTabState.ExecutingQuickTest:
        NotificationManager.success('Quick test finished')
        break
      case EnumWebEditorTabState.ExecutingBackTest:
        NotificationManager.success('Backtest finished')
        break
    }
  }

  private resetExecutionState() {
    this.executionState = {
      messageId: -1,
      serviceId: '',
      backtestId: _.createGuid(),
      isBacktest: this.tabState !== EnumWebEditorTabState.ExecutingQuickTest,
      state: EnumWebEditorTabExecutionState.Requesting,
      progress: 0,
      message: '',
    }
  }

  private resetAfterExecution() {
    this.tabState = EnumWebEditorTabState.Idle
    this.resetExecutionState()
    this.publishUpdate()
  }

  getState(): WebEditorTabState {
    let isLoading = false
    let isExecuting = false

    switch (this.tabState) {
      case EnumWebEditorTabState.Error:
      case EnumWebEditorTabState.Idle:
        break
      case EnumWebEditorTabState.RequestingSource:
      case EnumWebEditorTabState.RequestingCommands:
        isLoading = true
        break
      case EnumWebEditorTabState.ExecutingDebugTest:
      case EnumWebEditorTabState.ExecutingQuickTest:
      case EnumWebEditorTabState.ExecutingBackTest:
        isExecuting = true
        break
    }

    const sourceCode = typeof this.sourceCode === 'string' ? this.sourceCode : JSON.stringify(this.sourceCode)

    return {
      state: this.tabState,
      isLoading,
      isSaving: this.isSaving,
      isExecuting,
      executionState: {
        ...this.executionState,
      },

      backtestId: this.executionState.backtestId,
      scriptId: this.scriptId,
      scriptType: this.scriptType,
      sourceCode: sourceCode,
      hasChanges: sourceCode !== this.originalSourceCode,

      settings: this.settings,

      logs: this.logs,
      chart: this.chart,
      chartPartition : this.chartPartition,
      runtime: this.runtime,
      compileResult: this.compileResult,
      debugMatrix: this.debugMatrix,
    }
  }

  updateSettings(settings: HaasScriptSettingsForm) {
    this.settings = settings

    _.each(settings.scriptParameters ?? {}, (item, key) => {
      const inputField = this.compileResult?.inputFields[String(key)]
      if (!inputField) return

      inputField.value = item
    })

    this.publishUpdate()
  }

  updateSourceCode(sourceCode: string | HaasBlock[], publishUpdate = false) {
    this.sourceCode = sourceCode
    this.onSourceCodeChange(this.scriptId)

    if (publishUpdate) this.publishUpdate()
  }

  async getNextChartPartition() {
    if (!this.chart || this.chartPartition == -1 || this.isLoadingChartPartition)
      return;

    this.isLoadingChartPartition = true;

    try {
      let partition = this.chartPartition + 1;
      let response = await this.backtestService.getBacktestChartPartition(this.executionState.backtestId, 1, partition, this.pollCts);

      if (response.IsLastPartition)
        partition = -1;

      const mainChart = {...this.chart};
      mergeHaasChartContainer(mainChart, response);
      response = mainChart;

      this.chartPartition = partition;
      this.chart = response;

      this.publishUpdate();
    } finally {
      this.isLoadingChartPartition = false;
    }

  }

  async onTabOpened() {
    if (this.tabState !== EnumWebEditorTabState.Idle) return

    await this.fetchExecutingBacktestIds()
    this.publishUpdate()
  }

  private publishUpdate() {
    this.onUpdate(this.getState())
  }

  private addLog(message: string) {
    this.logs.unshift(`${Math.floor(Date.now() / 1000)} ||| ${message}`)
  }
}

export default WebEditorTab
