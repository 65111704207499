import { _ } from '@utils/lodash'

export class CloudPublicTrade {
  guid: string
  unix: number
  timestamp: Date

  price: number
  amount: number
  isBuyOrder: boolean

  constructor(jsonData: any) {
    this.guid = _.createGuid()
    this.unix = jsonData['T']
    this.timestamp = new Date(jsonData['T'] * 1000)

    this.price = jsonData['P']
    this.amount = jsonData['A']
    this.isBuyOrder = jsonData['B'] === 1
  }
}
