import { AppContextType } from '@context/Application/AppContext'
import { ServiceController } from '@services/ServiceController'
import { EnumHaasChartPricePlotStyle } from '@vendor/hxCharts/enums/EnumHaasChartPricePlotStyle'
import { CloudMarket } from '../public/CloudMarket'
import { CloudTradeMarket } from '../public/CloudTradeMarket'
import { UserAccount } from './account/UserAccount'

export type HaasBot = {
  botId: string
  botName: string

  scriptId: string

  account?: UserAccount
  accountId: string

  marketTag: string
  market: CloudTradeMarket | undefined

  isActivated: boolean
  isPaused: boolean
  isFavorite: boolean

  realizedProfit: number
  unrealizedProfit: number
  roi: number

  accountError: boolean
  scriptError: boolean
  tradeAmountError: boolean

  chartInterval: number
  chartStyle: EnumHaasChartPricePlotStyle
  showVolume: boolean

  updateCounter: number

  notes: string
  scriptNote: string
  notesTimestamp: number

  equals: (bot: HaasBot) => boolean
}

export const parseHaasBotStatus = (bot: HaasBot, context: AppContextType): [string, string] => {
  const { accountError, scriptError, tradeAmountError } = bot
  const { active, idle } = context.language.botsPage
  const { isActivated, isPaused } = bot
  const { TEXT_INACTIVE_COLOR, RED, GREEN, YELLOW } = context.theme

  if (!isActivated) return [idle, TEXT_INACTIVE_COLOR]

  if (accountError) return ['Account error', RED]
  if (scriptError) return ['Scripting error', RED]
  if (tradeAmountError) return ['Insufficient funds', RED]

  if (isPaused) return ['Paused', YELLOW]

  return [active, GREEN]
}

export const parseHaasBot = (services: ServiceController, jsonData: any): HaasBot => {
  const { initData, accountService } = services

  return {
    botId: jsonData['ID'],
    botName: jsonData['BN'],
    updateCounter: jsonData['UC'],

    scriptId: jsonData['SI'],

    accountId: jsonData['AI'],
    account: accountService.getAccount(jsonData['AI']),

    marketTag: jsonData['PM'],
    market: initData.getTradeMarket(new CloudMarket(jsonData['PM'], services.initData)),

    isActivated: jsonData['IA'],
    isPaused: jsonData['IP'],
    isFavorite: jsonData['IF'],

    realizedProfit: jsonData['RP'],
    unrealizedProfit: jsonData['UP'],
    roi: jsonData['ROI'],

    accountError: jsonData['AE'],
    scriptError: jsonData['SE'],
    tradeAmountError: jsonData['TAE'],

    chartInterval: jsonData['CI'],
    chartStyle: jsonData['CS'],
    showVolume: jsonData['CV'],

    notes: jsonData['NO'],
    scriptNote: jsonData['SN'],
    notesTimestamp: jsonData['NT'],

    equals: (bot: HaasBot) => jsonData['ID'] === bot?.botId,
  }
}
