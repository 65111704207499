import { HaasScriptCompiledResult, parseCompileResult } from './HaasScriptCompiledResult'
import { HaasScriptItem } from './HaasScriptItem'

export class HaasScriptRecord extends HaasScriptItem {
  sourceCode: string
  compileResult: HaasScriptCompiledResult

  constructor(jsonData: any) {
    super(jsonData)

    this.createdUnix = jsonData['CU']
    this.updatedUnix = jsonData['UU']
    this.sourceCode = jsonData['SC']

    this.compileResult = parseCompileResult(jsonData['CR'] || {})
  }
}
