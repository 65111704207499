import { UserBalanceValueContainer } from '@dataObjects/private/account/UserBalanceContainer'
import UserSocketClient from '@services/api/socket/user/UserSocketClient'
import { _ } from '@utils/lodash'

class UserSocketBalanceChannel {
  private socket: UserSocketClient
  private accountId: string
  private currency: string

  private callback?: (data: UserBalanceValueContainer) => void

  subscriptionId = _.createGuid()

  constructor(socket: UserSocketClient, accountId: string, currency: string) {
    this.socket = socket
    this.accountId = accountId
    this.currency = currency
  }

  subscribe(callback: (data: UserBalanceValueContainer) => void) {
    this.callback = callback

    this.socket.subscribe(this.subscriptionId, 'balance', {
      accountId: this.accountId,
      currency: this.currency,
    })
  }

  onMessage(data: any) {
    if (!this.callback) return

    const container = new UserBalanceValueContainer(data)
    this.callback(container)
  }
}

export default UserSocketBalanceChannel
