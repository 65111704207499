import { HaasBlogComment } from '@dataObjects/public/HaasBlogComment'
import { HaasBlogTopic } from '@dataObjects/public/HaasBlogTopic'

export class HaasBlogReport {
  reportId: number
  postId: number

  userId: number
  username : string;

  avatarColor: string
  avatarCharacter = "";

  topic? : HaasBlogTopic;
  comment? : HaasBlogComment;

  reportedAt : number;
  reason : string;

  constructor(jsonData : any) {
    this.reportId = jsonData['RID']
    this.postId = jsonData['PID']

    this.userId = jsonData["UID"];
    this.username = jsonData['U'];

    this.avatarColor = HaasBlogComment.stringToColour(this.username)
    if (this.username && this.username.length)
      this.avatarCharacter = this.username[0].toUpperCase();

    this.topic = jsonData['T'] ? new HaasBlogTopic(jsonData['T']) : undefined;
    this.comment = jsonData['C'] ? new HaasBlogComment(jsonData['C']) : undefined;

    this.reportedAt = jsonData['RA']
    this.reason = jsonData['R']
  }
}
