import { EnumHaasChartStatus } from '../enums/EnumHaasChartStatus'
import { _ } from '../helpers/lodash'
import { HaasChartColorSchemeType } from './HaasChartColorSchemeType'
import { HaasChartPlotType } from './HaasChartPlotType'
import {HaasChartDataLineType} from "@vendor/hxCharts/types/HaasChartDataLineType";
import {HaasChartPricePlot} from "@vendor/hxCharts/types/HaasChartPricePlot";

export type HaasChartContainer = {
  Guid: string
  Status: EnumHaasChartStatus

  Charts: Record<number, HaasChartPlotType>
  Colors: HaasChartColorSchemeType
  Interval: number

  IsLastPartition : boolean;
}

export const getEmptyHaasChartContainer = (interval: number): HaasChartContainer => {
  return {
    Guid: _.createGuid(),
    Status: EnumHaasChartStatus.IsPerfect,

    Charts: {
      // 0 : getEmptyHaasChartPlotType(plotId, 0, "", 1)
    },
    Colors: {
      Font: 'Tahoma',
      Axis: '#000000',
      Grid: '#303030',
      Text: '#BBBBBB',
      Background: 'rgb(37, 37, 37)',
      PriceGhostLine: '#FFFF00',
      VolumeGhostLine: '#FFFF00',
    },
    Interval: interval,
    IsLastPartition : true
  }
}

export const mergeHaasChartContainer = (main : HaasChartContainer, partition : HaasChartContainer) => {
  _.each(main.Charts, (plot, plotIndex) => {
    const plotPartition = partition.Charts[Number(plotIndex)];
    if (!plotPartition) return;

    mergeHaasChartPlot(plot, plotPartition);
  });
}

export const mergeHaasChartPlot = (main : HaasChartPlotType, partition : HaasChartPlotType) => {

  // Merge price plot
  mergeHaasChartPlotPricePlot(main.PricePlot, partition.PricePlot);

  // Merge data lines
  _.each(main.DataLines, (dataLine, dataLineIndex) => {
    const dataLinesPartition = partition.DataLines[String(dataLineIndex)];
    if (!dataLinesPartition) return;

    mergeHaasChartPlotDataLine(dataLine, dataLinesPartition);
  });

  // Merge shapes
  _.each(partition.Shapes, (shape, unix) => {
    main.Shapes[Number(unix)] = shape;
  })

  // Merge annotations
  main.Annotations = [ ...partition.Annotations, ...main.Annotations]

  // Merge trade annotations
  main.TradeAnnotations = [...partition.TradeAnnotations, ...main.TradeAnnotations]
}

export const mergeHaasChartPlotDataLine = (main : HaasChartDataLineType, partition : HaasChartDataLineType) => {
  _.each(partition.DataPoints, (item, unix) => {
    main.DataPoints[Number(unix)] = item;
  });

  _.each(partition.DataSets, (set, index) => {
    const mainSet = main.DataSets[Number(index)];
    if (!mainSet) return;

    _.each(set, (item, unix) => {
      mainSet[Number(unix)] = item;
    });

  });
}

export const mergeHaasChartPlotPricePlot = (main : HaasChartPricePlot, partition : HaasChartPricePlot) => {
  _.each(partition.Candles, (candle, unix) => {
    main.Candles[Number(unix)] = candle;
  })
}
