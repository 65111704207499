import Timer from './Timer'

class TimerAsync {
  private callback: () => Promise<any>
  private timer: Timer
  private isStopped = true

  constructor(callback: () => Promise<any>, intervalInSeconds: number, timeoutInSeconds = 0) {
    this.callback = callback
    this.onCallback = this.onCallback.bind(this)

    this.timer = new Timer(this.onCallback, intervalInSeconds, timeoutInSeconds)
  }

  private onCallback() {
    this.timer.stop()

    this.callback().finally(() => {
      if (this.isStopped) return

      this.timer.start()
    })
  }

  start() {
    this.isStopped = false
    this.timer.start()
  }

  stop() {
    this.isStopped = true
    this.timer.stop()
  }
}

export default TimerAsync
