export class LogRecord {
  timestamp: number
  service: string
  severity: string
  message: string
  counter: number

  constructor(jsonData: any) {
    this.timestamp = jsonData['Timestamp']
    this.service = jsonData['Service']
    this.severity = jsonData['Severcity']
    this.message = jsonData['Message']
    this.counter = jsonData['Counter']
  }
}
