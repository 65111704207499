export type UserNotificationProfileForm = {
  general: boolean
  security: boolean

  simulatedAccountMessages: boolean

  orderPlaced: boolean
  orderFailed: boolean
  orderCancelled: boolean
  orderCompleted: boolean

  botClosedOrder: boolean
  botClosedPosition: boolean
}

export class UserNotificationProfile implements UserNotificationProfileForm {
  general: boolean
  security: boolean

  simulatedAccountMessages: boolean

  orderPlaced: boolean
  orderFailed: boolean
  orderCancelled: boolean
  orderCompleted: boolean

  botClosedOrder: boolean
  botClosedPosition: boolean

  constructor(jsonData: any) {
    this.general = jsonData['G']
    this.security = jsonData['S']

    this.simulatedAccountMessages = jsonData['SM']

    this.orderPlaced = jsonData['OP']
    this.orderFailed = jsonData['OF']
    this.orderCancelled = jsonData['OC']
    this.orderCompleted = jsonData['OCD']

    this.botClosedOrder = jsonData['BOC']
    this.botClosedPosition = jsonData['BOP']
  }

  static ToApiObject(profile: UserNotificationProfile) {
    return {
      G: profile.general,
      S: profile.security,

      SM: profile.simulatedAccountMessages,

      OP: profile.orderPlaced,
      OF: profile.orderFailed,
      OC: profile.orderCancelled,
      OCD: profile.orderCompleted,

      BOC: profile.botClosedOrder,
      BOP: profile.botClosedPosition,
    }
  }
}
