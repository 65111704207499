import { ServiceController } from '@services/ServiceController'
import { _ } from '@utils/lodash'
import { UserAccount } from '../private/account/UserAccount'
import { CloudTradeMarket } from '../public/CloudTradeMarket'
import { EnumHaasPositionDirection } from '../scripting/EnumHaasPositionDirection'
import { leverageToString, positionDirectionToString } from '../utils'
import { RuntimeOrder } from './RuntimeOrder'

export class RuntimePosition {
  positionGuid = ''
  positionId = ''
  botId = ''

  openTime = 0
  closeTime = 0

  direction: EnumHaasPositionDirection = EnumHaasPositionDirection.Long
  directionAsString = ''

  account?: UserAccount
  accountId = ''

  market?: CloudTradeMarket
  marketTag = ''

  leverage = 0
  leverageAsString = ''

  averageEnterPrice = 0
  averageExitPrice = 0

  currentPrice = 0
  priceDecimals = 0

  size = 0
  available = 0
  inOrder = 0
  total = 0

  amountLabel = ''
  amountDecimals = 0

  feeCosts = 0

  realizedProfits = 0
  unrealizedProfits = 0
  profitLabel = ''

  roi = 0

  isClosed = true

  enterOrders: RuntimeOrder[] = []
  exitOrders: RuntimeOrder[] = []

  constructor(services?: ServiceController, jsonData?: any, botId?: string) {
    if (!services || !jsonData) return

    this.positionGuid = jsonData['pg']
    this.positionId = jsonData['g']
    this.botId = botId || ''

    this.openTime = jsonData['ot']
    this.closeTime = jsonData['ct']

    this.direction = jsonData['d']
    this.directionAsString = positionDirectionToString(this.direction)

    this.accountId = jsonData['ac']
    this.account = services.accountService.getAccount(this.accountId)
    if (!this.account) this.account = services.backtestService.getBacktestAccount(this.accountId)

    this.marketTag = jsonData['ma']
    this.market = services.initData.getTradeMarket(this.marketTag)

    this.leverage = jsonData['le']
    this.leverageAsString = leverageToString(this.account, this.leverage)

    this.currentPrice = jsonData['cp']
    this.priceDecimals = this.market?.priceDecimals ?? 8

    this.available = jsonData['av']
    this.inOrder = jsonData['io']
    this.total = jsonData['t']

    this.amountLabel = this.market?.amountLabel ?? ''
    this.amountDecimals = this.market?.amountDecimals ?? 8

    this.feeCosts = jsonData['fe']

    this.realizedProfits = jsonData['rp']
    this.unrealizedProfits = jsonData['up']
    this.profitLabel = jsonData['pl']

    this.roi = jsonData['roi']

    this.isClosed = jsonData['ic']

    this.enterOrders = jsonData['eno'].map((c: any) => new RuntimeOrder(services, c, false, this))
    this.exitOrders = jsonData['exo'].map((c: any) => new RuntimeOrder(services, c, false, this))

    this.size = _.sum(this.enterOrders, (c) => c.amountFilled)

    this.averageEnterPrice = jsonData['ap']
    this.averageExitPrice = RuntimePosition.calculateAveragePrice(this.exitOrders)
  }

  private static calculateAveragePrice(orders: RuntimeOrder[], amount?: number, skipAmount = 0) {
    if (amount === undefined) amount = _.sum(orders, (c) => c.amountFilled)

    if (amount === 0) return 0

    let totalCost = 0
    let totalAmount = 0

    let remainingAmount = Number(amount)

    for (let i = 0; i < orders.length; i++) {
      const { amountFilled, tradePrice } = orders[i]
      let orderAmount = amountFilled

      if (skipAmount > 0) {
        if (skipAmount >= amountFilled) {
          skipAmount -= amountFilled
          continue
        }

        if (skipAmount < amountFilled) {
          orderAmount -= skipAmount
          skipAmount = 0
        }
      }

      if (orderAmount < remainingAmount) {
        // The order is smaller then the left over amount
        remainingAmount -= orderAmount
      } else {
        // The order is bigger then the left over amount
        orderAmount = remainingAmount
        remainingAmount = 0
      }

      totalCost += tradePrice * orderAmount
      totalAmount += orderAmount

      if (remainingAmount <= 0) break
    }

    if (totalAmount === 0) return 0

    return totalCost / totalAmount
  }
}
