export class CloudExchangeSocial {
  icon: any
  link: string
  type: string

  constructor(icon: any, link: string, type: string) {
    this.icon = icon
    this.link = link
    this.type = type
  }
}
