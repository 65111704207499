import {EnumAccountMarketMarginMode} from '@dataObjects/enums/EnumAccountMarketMarginMode'
import {EnumAccountPositionMode} from '@dataObjects/enums/EnumAccountPositionMode'
import {Runtime} from '@dataObjects/runtime/Runtime'
import {EnumHaasScriptOrderType} from '@dataObjects/scripting/EnumHaasScriptOrderType'
import {_} from '@utils/lodash'
import {EnumHaasChartPricePlotStyle} from '@vendor/hxCharts/enums/EnumHaasChartPricePlotStyle'

export type HaasScriptSettingsForm = {
  botId: string
  botName: string

  accountId?: string
  marketTag?: string
  leverage: number
  positionMode: EnumAccountPositionMode
  marginMode: EnumAccountMarketMarginMode

  interval: number
  chartStyle: EnumHaasChartPricePlotStyle

  tradeAmount: number

  orderTemplate: EnumHaasScriptOrderType

  scriptParameters: Record<string, any>
}

export const emptyHaasScriptForm: HaasScriptSettingsForm = {
  botId: '',
  botName: '',

  accountId: undefined,
  marketTag: undefined,
  leverage: 0,

  positionMode: EnumAccountPositionMode.OneWay,
  marginMode: EnumAccountMarketMarginMode.CrossMargin,

  interval: 1,
  chartStyle: EnumHaasChartPricePlotStyle.CandleStickHLC,

  tradeAmount: 0,

  orderTemplate: EnumHaasScriptOrderType.Limit,

  scriptParameters: {},
}

export const runtimeToHaasScriptForm = (runtime?: Runtime): HaasScriptSettingsForm => {
  if (!runtime) return emptyHaasScriptForm

  return {
    accountId: runtime.accountId,
    marketTag: runtime.market?.tag,

    positionMode : runtime.positionMode,
    marginMode : runtime.marginMode,
    leverage: runtime.leverage,

    interval: runtime.defaultInterval,
    chartStyle: runtime.defaultChartType,

    tradeAmount: runtime.tradeAmount,
    orderTemplate: runtime.orderTemplate,
    scriptParameters: _.map(runtime.inputFields, (container, item, key) => {
      container[key] = item.value
    }),

  } as HaasScriptSettingsForm
}

export const cloneHaasScriptSettings = (settings: HaasScriptSettingsForm, botId = '', botName = ''): HaasScriptSettingsForm => {
  return {
    botId,
    botName,
    accountId: settings.accountId || '',
    marketTag: settings.marketTag || '',
    leverage: settings.leverage,
    positionMode: settings.positionMode,
    marginMode: settings.marginMode,

    interval: settings.interval,
    chartStyle: settings.chartStyle,

    tradeAmount: settings.tradeAmount,

    orderTemplate: settings.orderTemplate,

    scriptParameters: {
      ...(settings.scriptParameters || {}),
    },
  }
}
