import { ServiceController } from '@services/ServiceController'
import { UserPosition } from './UserPosition'

export class UserPositionContainer {
  accountId: string
  positions: UserPosition[]

  constructor(services: ServiceController, jsonData: any) {
    this.accountId = jsonData['AID']
    this.positions = jsonData['I'].map((value: any) => new UserPosition(services, this.accountId, value))
  }
}
