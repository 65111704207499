import { createGlobalStyle } from 'styled-components'

import zindex, { Components } from '../utils/zindex'

export default createGlobalStyle`
  $height: 22px;

  .hxCharts {
    font-family: Tahoma, monospace;
    font-size: 11px;

    .hxPlot-toolbar {
      position: absolute;
      display: flex;
      flex: 0 0 auto;
      flex-direction: row;
      z-index: ${zindex(Components.HxChartsPlotToolbar)};
      align-items: flex-start;
      pointer-events: none;

      .hxPlot-toolbar-titles,
      .hxPlot-toolbar-values {
        display: flex;
        flex: 1;
        align-content: center;
        justify-content: center;
      }

      .hxPlot-toolbar-titles {
        flex-direction: column;
        align-items: flex-start;

        .hxPlot-toolbar-titles-row {
          display: flex;
          flex: 0 0 auto;
          flex-direction: row;
          align-content: center;
          align-items: center;
          height: $height;
          padding-left: 1rem;
          padding-right: 5px;
          pointer-events: auto;

          .hxPlot-toolbar-titles-row-title {
            display: flex;
            height: $height;
            flex-direction: row;
            align-items: center;
            align-content: center;
          }

          .hxPlot-toolbar-titles-row-icons {
            display: none;
            //display: flex;
            flex-direction: row;
            align-items: center;
            height: $height;

            .hxPlot-toolbar-titles-row-icons-icon {
              display: flex;
              flex: 0 0 auto;
              flex-direction: column;
              justify-content: center;
              align-content: center;
              align-items: center;
              height: 15px;
              width: 15px;
              background: #252525;
              margin-left: 5px;
              //margin-right: 5px;
              cursor: pointer;

              svg * {
                fill: #a2a2a2;
              }

              &:hover svg * {
                fill: #dedede;
              }
            }
          }

          &:hover .hxPlot-toolbar-titles-row-icons {
            display: flex;
          }
        }

        .hxPlot-toolbar-titles-row-trades {
          justify-content: flex-start;
          align-items: flex-start;
        }

        .hxPlot-toolbar-titles-row-configurable:hover {
          //padding-left: 0.5rem;
          background: #252525;
          border-radius: 0 5px 5px 0;
          border-top: solid 1px #7c7c7c;
          border-right: solid 1px #7c7c7c;
          border-bottom: solid 1px #7c7c7c;

          .hxPlot-toolbar-titles-row-title {
            cursor: pointer;
            color: #dedede;
          }
        }
      }

      .hxPlot-toolbar-values {
        flex-direction: column;
        pointer-events: none;

        .hxPlot-toolbar-values-row {
          display: flex;
          flex: 1;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-end;
          align-content: center;
          height: 15px;
          padding-right: 1rem;
          margin-bottom: 0.5rem;

          div {
            display: flex;
            flex-wrap: nowrap;
            font-size: 11px;
          }

          div:not(:first-child) {
            margin-left: 5px;
          }
        }
      }
    }
  }
`
