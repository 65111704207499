import UnixHelper from '@vendor/hxCharts/helpers/UnixHelper'

class Timer {
  private startUnix: number
  private lastExecutionUnix: number
  private animationId?: number
  private timeoutInSeconds: number
  private intervalInSeconds: number
  private callback: () => void
  private isStarted = true

  constructor(callback: () => void, intervalInSeconds: number, timeoutInSeconds = 0) {
    this.startUnix = 0
    this.lastExecutionUnix = 0
    this.callback = callback
    this.timeoutInSeconds = timeoutInSeconds
    this.intervalInSeconds = intervalInSeconds

    this.checkTime = this.checkTime.bind(this)
  }

  private checkTime() {
    try {
      const unix = UnixHelper.now()
      if (this.timeoutInSeconds > 0 && this.startUnix + this.timeoutInSeconds > unix) return

      if (this.lastExecutionUnix + this.intervalInSeconds > unix) return

      if (unix % this.intervalInSeconds !== 0) return

      this.lastExecutionUnix = unix
      this.callback()
    } finally {
      if (this.isStarted) {
        this.animationId = requestAnimationFrame(this.checkTime)
      }
    }
  }

  start(resetExecutionUnix = true) {
    this.stop()

    if (!resetExecutionUnix) {
      this.startUnix = UnixHelper.now()
      this.lastExecutionUnix = 0
    }
    this.isStarted = true
    this.animationId = requestAnimationFrame(this.checkTime)
  }

  stop() {
    this.isStarted = false
    if (!this.animationId) return

    cancelAnimationFrame(this.animationId)
    this.animationId = undefined
  }
}

export default Timer
