import { EnumHaasChartAxisSide } from '../enums/EnumHaasChartAxisSide'
import { EnumHaasChartAxisType } from '../enums/EnumHaasChartAxisType'
import { EnumHaasChartPricePlotStyle } from '../enums/EnumHaasChartPricePlotStyle'
import { HaasChartAnnotationsType } from './HaasChartAnnotationsType'
import { HaasChartDataLineType } from './HaasChartDataLineType'
import { HaasChartPlotAxisType } from './HaasChartPlotAxisType'
import { HaasChartPricePlot } from './HaasChartPricePlot'
import { HaasChartShapesType } from './HaasChartShapesType'
import { HaasChartTradeMarketType } from './HaasChartTradeMarketType'

export type HaasChartPlotType = {
  PlotId: string
  PlotIndex: number

  Title: string
  Height: number

  IsSignalPlot: boolean

  RightAxis: HaasChartPlotAxisType
  LeftAxis: HaasChartPlotAxisType
  PricePlot: HaasChartPricePlot
  DataLines: Record<string, HaasChartDataLineType>
  Shapes: Record<number, HaasChartShapesType>
  Annotations: HaasChartAnnotationsType[]
  TradeAnnotations: HaasChartTradeMarketType[]
}

export const getEmptyHaasChartPlotType = (plotId: string, plotIndex: number, title: string, height = 0.2): HaasChartPlotType => {
  return {
    PlotId: plotId,
    IsSignalPlot: false,
    DataLines: {},
    Annotations: [],
    TradeAnnotations: [],
    Shapes: [],
    PricePlot: {
      Colors: {
        Up: '#00FF00',
        UpFill: false,
        Down: '#FF0000',
        DownFill: true,
        Marked: '#FFFF00',
        MarkedFill: true,
      },
      Style: EnumHaasChartPricePlotStyle.CandleStick,
      Side: EnumHaasChartAxisSide.Right,
      Candles: [],
      Interval: 1,
      Market: '',
    },
    Height: height,
    PlotIndex: plotIndex,
    Title: title,
    RightAxis: {
      IsVisible: true,
      Type: EnumHaasChartAxisType.Linear,
      Side: EnumHaasChartAxisSide.Right,
      AxisStart: null,
      AxisEnd: null,
      Labels: [],
    },
    LeftAxis: {
      IsVisible: false,
      Type: EnumHaasChartAxisType.Linear,
      Side: EnumHaasChartAxisSide.Left,
      AxisStart: null,
      AxisEnd: null,
      Labels: [],
    },
  }
}
