import {RuntimePosition} from "@dataObjects/runtime/RuntimePosition";
import {RuntimeOrder} from "@dataObjects/runtime/RuntimeOrder";
import {CloudInitData} from "@dataObjects/public/CloudInitData";
import {CloudTradeMarket} from "@dataObjects/public/CloudTradeMarket";

class HaasBotTemplate {

    rank : number;
    botId : string;
    botName : string;
    scriptName : string;
    scriptVersion : number;
    market : CloudTradeMarket|undefined;
    marketTag : string;
    activatedSince : number;
    timeRunning : string;
    returnOnInvestment : number;
    dailyAverageROI : number;
    dailyHighROI : number;
    dailyLowROI : number;
    last24HROI : number;
    cloned : number;
    notes : string;
    finishedOrders : RuntimeOrder;
    finishedPositions : RuntimePosition;

    constructor(jsonData : any, initDate : CloudInitData) {
        this.rank = 1;
        this.botId = jsonData['ID'];
        this.botName = jsonData['BN'];
        this.scriptName = jsonData['SN'];
        this.scriptVersion = jsonData['SV'];
        this.marketTag = jsonData['PM'];
        this.market = initDate.getTradeMarket(jsonData['PM']);
        this.activatedSince = jsonData['AS'];
        this.timeRunning = jsonData['TR'];
        this.returnOnInvestment = jsonData['ROI'];
        this.dailyAverageROI = jsonData['AVG_ROI'];
        this.dailyHighROI = jsonData['HIGH_ROW'];
        this.dailyLowROI = jsonData['LOW_ROI'];
        this.last24HROI = jsonData['ROI24'];
        this.finishedOrders = jsonData['FO'];
        this.finishedPositions = jsonData['FP'];
        this.cloned = jsonData['C'];
        this.notes = jsonData['N'];
    }
}

export default HaasBotTemplate
