export class HaasScriptBackup {
  id: number
  scriptId: string
  version: number
  created: number
  sourceCode: string

  constructor(jsonData: any) {
    this.id = jsonData['Id']
    this.scriptId = jsonData['ScriptId']
    this.version = jsonData['Version']
    this.created = jsonData['Created']
    this.sourceCode = jsonData['SourceCode']
  }
}
