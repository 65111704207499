export enum EnumHaasChartPricePlotStyle {
  CandleStick = 300,
  CandleStickHLC = 301,
  HeikinAshi = 302,
  OHLC = 303,
  HLC = 304,
  CloseLine = 305,
  Line = 306,
  Mountain = 307,
  Spread = 308,
}
