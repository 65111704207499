import { secureStorage } from './SecureStorage'

export class LocalStorageItem<T> {
  private key: string

  constructor(key: string) {
    this.key = key
  }

  getItem(defaultValue: T): T {
    return secureStorage.getItem<T>(this.key) ?? defaultValue
  }

  setItem(data: T): void {
    secureStorage.setItem<T>(this.key, data)
  }
}
