export type UserPositionActivityReport = {
  positionId: string
  accountId: string
  market: string
  profitInBTC: number
  profitInUSD: number
  returnOnInvestment: number
  getProfit: (currency: string) => number
}

export const parseUserPositionActivityReport = (jsonData: any): UserPositionActivityReport => {
  return {
    positionId: jsonData['PositionId'],
    accountId: jsonData['AccountId'],
    market: jsonData['Market'],
    profitInBTC: jsonData['ProfitInBTC'],
    profitInUSD: jsonData['ProfitInUSD'],
    returnOnInvestment: jsonData['ReturnOnInvestment'],
    getProfit: (currency: string) => {
      switch (currency) {
        case 'BTC':
          return jsonData['ProfitInBTC']
        case 'USD':
          return jsonData['ProfitInUSD']
      }
    },
  }
}
