import { CloudNewsFeedHeadline } from '@dataObjects/public/CloudNewsFeedHeadline'
import { CloudNewsFeedSource } from '@dataObjects/public/CloudNewsFeedSource'
import { CloudVideoStreamSource } from '@dataObjects/public/CloudVideoStreamSource'
import { _ } from '@utils/lodash'
import { Query } from '@utils/QueryPromise'
import { ServiceController, ServiceControllerConstructorType } from '../ServiceController'
import { NewsFeedsApi } from './NewsFeedsApi'

export class NewsFeedsService {
  private api: NewsFeedsApi
  private controller: ServiceController

  constructor(props: ServiceControllerConstructorType) {
    this.controller = props.controller
    this.api = new NewsFeedsApi(props.authenticator, this.controller)
  }

  async getMarketNews(market: string): Promise<CloudNewsFeedHeadline[]> {
    const [base, quote] = market.split('/')

    const response = await this.api.getMarketNews(`${base}_${quote}_`)
    return response.map((c: any) => new CloudNewsFeedHeadline(c))
  }

  async searchNews(newsSources: string[], searchKeys: string[]): Promise<CloudNewsFeedHeadline[]> {
    const response = await this.api.searchNews(newsSources, searchKeys)
    return response.map((c: any) => new CloudNewsFeedHeadline(c))
  }

  async getNewsPage(): Promise<Record<string, CloudNewsFeedHeadline[]>> {
    const response = await this.api.getNewsPage()
    return _.map(response, (container, value: any[], key) => {
      container[key] = value.map((c: any) => new CloudNewsFeedHeadline(c))
    })
  }

  async getHeadlinesOnName(feedId: string): Promise<CloudNewsFeedHeadline[]> {
    const response = await this.api.getHeadlinesOnName(feedId)
    return response.map((c: any) => new CloudNewsFeedHeadline(c))
  }

  async getHeadlinesOnGroup(feedId: string): Promise<CloudNewsFeedHeadline[]> {
    const response = await this.api.getHeadlinesOnGroup(feedId)
    return response.map((c: any) => new CloudNewsFeedHeadline(c))
  }

  async getHeadlinesOnKey(group: string, key: string): Promise<CloudNewsFeedHeadline[]> {
    const response = await this.api.getHeadlinesOnKey(group, key)
    return response.map((c: any) => new CloudNewsFeedHeadline(c))
  }

  async getNewsFeedSources(): Promise<CloudNewsFeedSource[]> {
    const response = await this.api.getNewsFeedSources()
    return response.map((c: any) => new CloudNewsFeedSource(c))
  }

  getNewsFeedGroups(): Query<string[]> {
    return this.api.getNewsFeedGroups()
  }

  async getVideoFeedSources(): Promise<CloudVideoStreamSource[]> {
    const response = await this.api.getVideoFeedSources()
    return response.map((c: any) => new CloudVideoStreamSource(c))
  }
}
