import { EnumHaasRuntimeType } from '@dataObjects/enums/EnumHaasRuntimeType'
import { CloudInitData } from '@dataObjects/public/CloudInitData'
import { CloudTradeMarket } from '@dataObjects/public/CloudTradeMarket'

export class ScriptExecutionReport {
  machineId: string
  serviceId: string
  serviceName: string

  userId: string
  backtestId: string
  scriptId: string
  scriptName: string
  scriptType: EnumHaasRuntimeType

  executionTimestamp: number
  executionProgress: number

  market?: CloudTradeMarket
  marketTag: string

  startUnix: number
  endUnix: number

  constructor(jsonData: any, initData: CloudInitData, machineId: string, serviceId: string, serviceName: string) {
    this.machineId = machineId
    this.serviceId = serviceId
    this.serviceName = serviceName

    this.userId = jsonData['UID']
    this.backtestId = jsonData['BID']
    this.scriptId = jsonData['SID']
    this.scriptName = jsonData['SN']
    this.scriptType = EnumHaasRuntimeType.Visual

    this.market = initData.getTradeMarket(jsonData['M'])
    this.marketTag = jsonData['M']

    this.startUnix = jsonData['SU']
    this.endUnix = jsonData['EU']
    this.executionTimestamp = jsonData['ET']
    this.executionProgress = jsonData['EP']
  }
}
