export class HaasBlogCategory {
  categoryId: number
  category: string
  topics : number;
  comments : number;
  updatedAt : number;
  isLocked : boolean;

  constructor(jsonData: any) {
    this.categoryId = jsonData['CID']
    this.category = jsonData['C']

    this.topics = jsonData["T"];
    this.comments = jsonData["CO"];

    this.updatedAt = jsonData["UA"];
    this.isLocked = jsonData["IL"];
  }
}
