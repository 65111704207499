import { HaasScriptSettingsForm } from '@components/HaasScriptSettings/HaasScriptSettingsForm'
import { ServiceController } from '@services/ServiceController'
import { EnumHaasLabsExecutionStatus } from './EnumHaasLabsExecutionStatus'

export type UserLabResultSummary = {
  noOrders: number
  noTrades: number
  noPositions: number
  feeCosts: Record<string, number>
  realizedProfits: Record<string, number>
  roi: number[]
  customReport: Record<string, Record<string, string>>
}

export type UserLabResult = {
  labId: string
  backtestId: string
  noGeneration: number
  noPopulation: number
  status: EnumHaasLabsExecutionStatus
  parameters: Record<string, any>
  settings: HaasScriptSettingsForm
  summary: UserLabResultSummary
}

export function parseUserLabResult(services: ServiceController, jsonData: any): UserLabResult {
  return {
    labId: jsonData['LID'],
    backtestId: jsonData['BID'],
    status: jsonData['ST'],
    parameters: jsonData['P'],
    settings: jsonData['SE'],
    noGeneration: jsonData['NG'],
    noPopulation: jsonData['NP'],
    summary: {
      noOrders: jsonData['S']['O'],
      noTrades: jsonData['S']['T'],
      noPositions: jsonData['S']['P'],
      feeCosts: jsonData['S']['FC'],
      realizedProfits: jsonData['S']['RP'],
      roi: jsonData['S']['ROI'],
      customReport: jsonData['S']['CR'] ?? {},
    },
  }
}
