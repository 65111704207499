import { CloudMarket } from '@dataObjects/public/CloudMarket'
import { Query } from '@utils/QueryPromise'
import { EnumHaasChartPricePlotStyle } from '@vendor/hxCharts/enums/EnumHaasChartPricePlotStyle'
import { ApiRequest, CancellationToken, EnumApiMethod } from '../api/rest/ApiRequest'
import { RestApiBase } from '../api/rest/RestApiBase'

export class PriceApi extends RestApiBase {
  private baseUrl = 'PriceAPI.php'

  getPrice(market: CloudMarket, token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'PRICE',
      endPoint: this.baseUrl,
      token: token,
      arguments: {
        market: market.tag,
      },
    })
  }

  getPriceHistory(market: CloudMarket, interval: number, token?: CancellationToken): Query<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'LASTTICKS',
      endPoint: this.baseUrl,
      token: token,
      arguments: {
        market: market.tag,
        interval: interval,
      },
    })
  }

  getChart(market: CloudMarket, interval: number, style: EnumHaasChartPricePlotStyle, token?: CancellationToken): Query<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'GET_CHART',
      endPoint: this.baseUrl,
      token: token,
      arguments: {
        market: market.tag,
        interval: interval,
        style: style,
      },
    })
  }

  getOrderbook(market: CloudMarket, token?: CancellationToken): Query<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'ORDERBOOK',
      endPoint: this.baseUrl,
      token: token,
      arguments: {
        market: market.tag,
      },
    })
  }

  getTrades(market: CloudMarket, token?: CancellationToken): Query<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'LASTTRADES',
      endPoint: this.baseUrl,
      token: token,
      arguments: {
        market: market.tag,
      },
    })
  }

  getUniqueCoins(token?: CancellationToken): Query<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'COINLIST',
      token: token,
      endPoint: this.baseUrl,
    })
  }

  getUniqueMarkets(token?: CancellationToken): Query<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'UNIQUE_MARKETLIST',
      token: token,
      endPoint: this.baseUrl,
    })
  }

  getPriceCompareChart(market1: string, market2: string, market3: string, market4: string, market5: string, interval: number, token?: CancellationToken): Query<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'COMPARE_CLOSEPRICES_CHART',
      endPoint: this.baseUrl,
      token: token,
      arguments: {
        market1,
        market2,
        market3,
        market4,
        market5,
        interval,
      },
    })
  }

  getVolumeCompareChart(market1: string, market2: string, market3: string, market4: string, market5: string, interval: number, token?: CancellationToken): Query<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'COMPARE_VOLUMES_CHART',
      endPoint: this.baseUrl,
      token: token,
      arguments: {
        market1,
        market2,
        market3,
        market4,
        market5,
        interval,
      },
    })
  }

  getOrderbookCompareChart(market1: string, market2: string, market3: string, market4: string, market5: string, interval: number, token?: CancellationToken): Query<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'COMPARE_ORDERBOOK_CHART',
      endPoint: this.baseUrl,
      token: token,
      arguments: {
        market1,
        market2,
        market3,
        market4,
        market5,
        interval,
      },
    })
  }

  getHistoryDebugChart(market: CloudMarket, token?: CancellationToken): Query<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'GET_HISTORY_COMPARE_CHART',
      endPoint: this.baseUrl,
      token: token,
      arguments: {
        market: market.tag,
      },
    })
  }

  getVolumeDebugChart(market: CloudMarket, token?: CancellationToken): Query<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'GET_VOLUME_COMPARE_CHART',
      endPoint: this.baseUrl,
      token: token,
      arguments: {
        market: market.tag,
      },
    })
  }

  getSpreadDebugChart(market: CloudMarket, token?: CancellationToken): Query<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'GET_SPREAD_COMPARE_CHART',
      endPoint: this.baseUrl,
      token: token,
      arguments: {
        market: market.tag,
      },
    })
  }
}
