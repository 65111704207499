import { ServiceController } from '@services/ServiceController'
import { EnumOrderDirection, orderDirectionToString } from '../enums/EnumOrderDirection'
import { EnumHaasOrderCancelReason } from '../scripting/EnumHaasOrderCancelReason'
import { EnumHaasScriptOrderType } from '../scripting/EnumHaasScriptOrderType'
import { leverageToString, orderStatusToString } from '../utils'
import { EnumRuntimeOrderStatus } from './EnumRuntimeOrderStatus'
import { RuntimeOrder } from './RuntimeOrder'

export class RuntimeFailedOrder {
  private services: ServiceController

  orderId: string

  openTime: number
  closeTime: number

  accountId: string
  marketTag: string
  leverage: number

  direction: EnumOrderDirection
  price: number
  amount: number

  note: string

  cancelReason: EnumHaasOrderCancelReason

  constructor(services: ServiceController, jsonData: any) {
    this.services = services

    this.orderId = jsonData['id']
    this.openTime = jsonData['ot']
    this.closeTime = jsonData['ct']
    this.direction = jsonData['d']
    this.price = jsonData['p']
    this.amount = jsonData['a']
    this.note = jsonData['n']

    const [accountId, marketTag, leverageString] = String(jsonData['ac']).split('/')

    this.accountId = accountId
    this.marketTag = marketTag
    this.leverage = Number(leverageString) || 0

    this.cancelReason = jsonData['cr']
  }

  toRuntimeOrder(): RuntimeOrder {
    const { initData, accountService } = this.services
    const account = accountService.getAccount(this.accountId)
    const market = initData.getTradeMarket(this.marketTag)

    const status = EnumRuntimeOrderStatus.Cancelled

    const order = new RuntimeOrder(this.services)
    order.orderId = this.orderId
    order.positionId = ''

    order.status = status
    order.statusAsString = orderStatusToString(status, this.cancelReason)
    order.cancelReason = this.cancelReason

    order.type = EnumHaasScriptOrderType.Limit

    order.openTime = this.openTime
    order.closeTime = this.closeTime

    order.account = account
    order.accountId = this.accountId
    order.accountName = account?.name || 'Unknown'

    order.market = market
    order.marketTag = this.marketTag

    order.leverage = this.leverage
    order.leverageAsString = leverageToString(account, this.leverage)

    order.direction = this.direction
    order.directionAsString = orderDirectionToString(this.direction)

    order.orderPrice = this.price
    order.tradePrice = this.price
    order.priceDecimals = market?.priceDecimals || 0

    order.amount = this.amount
    order.amountFilled = 0
    order.amountDecimals = market?.amountDecimals || 0
    order.amountLabel = market?.amountLabel || ''

    order.feeCosts = 0
    order.feeCurrency = ''

    order.profits = 0
    order.profitLabel = ''
    order.roi = 0

    order.note = this.note

    return order
  }
}
