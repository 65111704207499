import { ServiceController } from '@services/ServiceController'
import { UserOrder } from './UserOrder'

export class UserOrderContainer {
  accountId: string
  orders: UserOrder[]

  constructor(services: ServiceController, jsonData: any) {
    this.accountId = jsonData['AID']
    this.orders = jsonData['I'].map((value: any) => new UserOrder(services, this.accountId, value))
  }
}
