import { NotificationManager } from '@application/Notifications/NotificationManager'
import { EnumUserNotificationChannel } from '@dataObjects/private/notifications/EnumUserNotificationChannel'
import { EnumUserNotificationsCategory } from '@dataObjects/private/notifications/EnumUserNotificationsCategory'
import { EnumUserNotificationsSeverity } from '@dataObjects/private/notifications/EnumUserNotificationsSeverity'
import { UserDiscordRecord } from '@dataObjects/private/notifications/UserDiscordRecord'
import { UserNotification } from '@dataObjects/private/notifications/UserNotification'
import {
  UserNotificationProfile,
  UserNotificationProfileForm,
} from '@dataObjects/private/notifications/UserNotificationProfile'
import { UserTelegramRecord } from '@dataObjects/private/notifications/UserTelegramRecord'
import { EventHandler } from '@utils/eventHandlers/EventHandler'
import { TSounds } from '../../sounds'
import { CancellationToken } from '../api/rest/ApiRequest'
import { LoginApi } from '../login/LoginApi'
import { ServiceController, ServiceControllerConstructorType } from '../ServiceController'
import { UserSetting } from '../settings/SettingsService'
import { NotificationApi } from './NotificationApi'

export class NotificationService {
  private api: NotificationApi
  private settingsApi: LoginApi

  private controller: ServiceController
  private notifications: UserNotification[] = []

  notificationUpdates = new EventHandler<UserNotification>()
  notificationCountUpdate = new EventHandler<number>()

  infoSound: UserSetting<TSounds>
  successSound: UserSetting<TSounds>
  warningSound: UserSetting<TSounds>
  errorSound: UserSetting<TSounds>

  constructor(props: ServiceControllerConstructorType) {
    this.controller = props.controller

    this.api = new NotificationApi(props.authenticator, props.controller)
    this.settingsApi = new LoginApi(props.authenticator, props.controller)

    this.infoSound = new UserSetting<TSounds>(this.settingsApi, 'SOUND_INFO', 'info')
    this.successSound = new UserSetting<TSounds>(this.settingsApi, 'SOUND_SUCCESS', 'success')
    this.warningSound = new UserSetting<TSounds>(this.settingsApi, 'SOUND_WARNING', 'warning')
    this.errorSound = new UserSetting<TSounds>(this.settingsApi, 'SOUND_ERROR', 'error')

    NotificationManager.initialize(this)
  }

  async fetchLastNotifications() {
    const response = await this.api.getLastNotifications(50)
    this.notifications = response.map((c: any) => new UserNotification(c))
    this.notifications.reverse()

    this.notificationCountUpdate.fire(this.notifications.length)
  }

  getCurrentNotifications() {
    return [...this.notifications]
  }

  marketAsSeen(notificationId: string) {
    this.notifications = this.notifications.filter((c) => c.notificationId !== notificationId)

    this.notificationCountUpdate.fire(this.notifications.length)
  }

  marketAllAsSeen() {
    this.notifications = []
    this.notificationCountUpdate.fire(this.notifications.length)
  }

  onNotification(notification: UserNotification) {
    this.notifications.push(notification)
    if (this.notifications.length > 101) this.notifications.shift()

    this.notificationUpdates.fire(notification)
    this.notificationCountUpdate.fire(this.notifications.length)

    if (notification.category < EnumUserNotificationsCategory.Trading) {
      switch (notification.severity) {
        case EnumUserNotificationsSeverity.Info:
          NotificationManager.info(notification.message)
          break
        case EnumUserNotificationsSeverity.Success:
          NotificationManager.success(notification.message)
          break
        case EnumUserNotificationsSeverity.Warning:
          NotificationManager.warning(notification.message)
          break
        case EnumUserNotificationsSeverity.Error:
          NotificationManager.error(notification.message)
          break
      }
    }
  }

  onPushNotification(notification: UserNotification) {
    if (Notification.permission === 'denied') return
    const { title, message } = notification

    Notification.requestPermission().then(() => {
      const img = '/assets/haasonline/tradeserver-cloud-192x192.png'
      new Notification(title, { body: message, icon: img })
    })
  }

  async getNotificationPage(category: EnumUserNotificationsCategory, nextPageId: number, pageLength: number, token: CancellationToken) {
    const response = await this.api.getNotificationPage(category, nextPageId, pageLength, token)

    nextPageId = response['NP']
    const items = response['I'].map((c: any) => new UserNotification(c))

    return { items, nextPageId }
  }

  async downloadNotifications(): Promise<UserNotification[]> {
    const response = await this.api.downloadNotifications()
    return response.map((c: any) => new UserNotification(c))
  }

  async getNotificationProfile(type: EnumUserNotificationChannel): Promise<UserNotificationProfileForm> {
    const response = await this.api.getNotificationProfile(type)
    return new UserNotificationProfile(response)
  }

  async setNotificationProfile(type: EnumUserNotificationChannel, profile: UserNotificationProfile): Promise<boolean> {
    return this.api.setNotificationProfile(type, profile)
  }

  async removeBot(channel: EnumUserNotificationChannel): Promise<boolean> {
    return this.api.removeNotificationBot(channel)
  }

  async getTelegramBot(token: CancellationToken) {
    const response = await this.api.getTelegramBot(token)
    return new UserTelegramRecord(response)
  }

  async setupTelegramBot(token: string, profile: UserNotificationProfile): Promise<boolean> {
    return this.api.setupTelegramBot(token, profile)
  }

  async getDiscordBot(token: CancellationToken) {
    const response = await this.api.getDiscordBot(token)
    return new UserDiscordRecord(response)
  }

  async setupDiscordBot(serverId: string, token: string, profile: UserNotificationProfile): Promise<boolean> {
    return this.api.setupDiscordBot(serverId, token, profile)
  }

  async getSlackBot(token: CancellationToken) {
    const response = await this.api.getSlackBot(token)
    return new UserTelegramRecord(response)
  }

  async setupSlackBot(applicationId: string, profile: UserNotificationProfile): Promise<boolean> {
    return this.api.setupSlackBot(applicationId, profile)
  }
}
