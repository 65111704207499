import { _ } from '@utils/lodash'
import { EnumHaasCommandType } from '../enums/EnumHaasCommandType'
import { HaasBlockConnectionData } from './HaasBlockConnectionData'
import { HaasCommand } from './HaasCommand'

export class HaasBlock {
  X!: number
  Y!: number
  W!: number
  H!: number
  Guid!: string
  Type!: EnumHaasCommandType
  CommandName!: string
  Connector!: HaasBlockConnectionData
  Inputs!: HaasBlockConnectionData[]
  Output!: HaasBlockConnectionData
  SubOutputs!: HaasBlockConnectionData[]
  Command!: any
  IsReversed!: boolean
  IsMirrored!: boolean
  ShowUnusedParameters!: boolean
  NeedsConnector!: boolean
  Resizable!: boolean
  Disabled!: boolean

  command: HaasCommand

  constructor(jsonData: any) {
    _.each(jsonData, (value, key) => {
      // @ts-ignore
      this[key] = value
    })

    this.command = new HaasCommand(this.Command)
  }
}
