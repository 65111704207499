export class CloudTick {
  unix: number
  timestamp: Date

  open: number
  close: number
  high: number
  low: number
  volume: number

  buyPrice: number
  sellPrice: number

  constructor(jsonData: any) {
    this.unix = jsonData['T']
    this.timestamp = new Date(jsonData['T'] * 1000)

    this.open = jsonData['O']
    this.close = jsonData['C']
    this.high = jsonData['H']
    this.low = jsonData['L']
    this.volume = jsonData['V']

    this.buyPrice = jsonData['B']
    this.sellPrice = jsonData['S']
  }
}

export class CloudSocketTick {
  close: number
  high: number
  low: number
  volume: number

  constructor(jsonData: any) {
    this.close = jsonData['C']
    this.high = jsonData['H']
    this.low = jsonData['L']
    this.volume = jsonData['V']
  }
}
