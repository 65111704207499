import { CloudOrderbook } from '@dataObjects/public/CloudOrderbook'
import { CloudPublicTrade } from '@dataObjects/public/CloudPublicTrade'
import { CloudSocketTick, CloudTick } from '@dataObjects/public/CloudTick'
import MarketSocketChannelCollection from '@services/api/socket/market/MarketSocketChannelCollection'
import { ServiceController } from '@services/ServiceController'
import SocketClient, { SocketClientResponseType } from '../SocketClient'
import {
  MarketSocketLastTickChannel,
  MarketSocketMinuteTickChannel,
  MarketSocketOrderbookChannel,
  MarketSocketPriceChannel,
  MarketSocketTradesChannel,
  MarketSocketTradesSummaryFastChannel,
  MarketSocketTradesSummarySlowChannel,
} from './MarketSocketChannels'

class MarketSocketClient extends SocketClient {
  priceChannel: MarketSocketChannelCollection<number>
  lastTickChannel: MarketSocketChannelCollection<CloudTick>
  minuteTickChannel: MarketSocketChannelCollection<CloudTick>
  orderbookChannel: MarketSocketChannelCollection<CloudOrderbook>
  tradeChannel: MarketSocketChannelCollection<CloudPublicTrade[]>
  tradeSummarySlowChannel: MarketSocketChannelCollection<CloudSocketTick>
  tradeSummaryFastChannel: MarketSocketChannelCollection<CloudSocketTick>

  constructor(controller: ServiceController, isSocketConnected: (isSocketConnected: boolean) => void) {
    super(controller, isSocketConnected)

    this.priceChannel = new MarketSocketChannelCollection(this, (market) => new MarketSocketPriceChannel(this, market))
    this.lastTickChannel = new MarketSocketChannelCollection(this, (market) => new MarketSocketLastTickChannel(this, market))
    this.minuteTickChannel = new MarketSocketChannelCollection(this, (market) => new MarketSocketMinuteTickChannel(this, market))

    this.orderbookChannel = new MarketSocketChannelCollection(this, (market) => new MarketSocketOrderbookChannel(this, market))
    this.tradeChannel = new MarketSocketChannelCollection(this, (market) => new MarketSocketTradesChannel(this, market))

    this.tradeSummarySlowChannel = new MarketSocketChannelCollection(this, (market) => new MarketSocketTradesSummarySlowChannel(this, market))
    this.tradeSummaryFastChannel = new MarketSocketChannelCollection(this, (market) => new MarketSocketTradesSummaryFastChannel(this, market))
  }

  processMessage(message: SocketClientResponseType) {
    const { channel, subscriptionId, data } = message
    switch (channel) {
      case 'price':
        return this.priceChannel.onMessage(subscriptionId, data)
      case 'lastTick':
        return this.lastTickChannel.onMessage(subscriptionId, data)
      case 'minuteTick':
        return this.minuteTickChannel.onMessage(subscriptionId, data)
      case 'orderbook':
        return this.orderbookChannel.onMessage(subscriptionId, data)
      case 'trades':
        return this.tradeChannel.onMessage(subscriptionId, data)
      case 'tradesSummarySlow':
        return this.tradeSummarySlowChannel.onMessage(subscriptionId, data)
      case 'tradesSummaryFast':
        return this.tradeSummaryFastChannel.onMessage(subscriptionId, data)
      default:
        console.warn(`Unsupported channel for MarketSocketClient: ${channel}`)
        debugger // Keep
        break
    }
  }

  onReconnect() {
    this.priceChannel.onReconnect()
    this.lastTickChannel.onReconnect()
    this.minuteTickChannel.onReconnect()
    this.orderbookChannel.onReconnect()
    this.tradeChannel.onReconnect()
    this.tradeSummarySlowChannel.onReconnect()
    this.tradeSummaryFastChannel.onReconnect()
  }
}

export default MarketSocketClient
