import zindex, { Components } from '@utils/zindex'
import React from 'react'
import ReactDOM from 'react-dom'

type Props = {
  pointerEvents?: boolean
  parentSelector?: string
  zindex?: number
}

class BodyEnd extends React.PureComponent<Props> {
  private el: HTMLDivElement

  constructor(props: Props) {
    super(props)
    this.el = document.createElement('div')
    this.el.classList.add('qx-AykKH')
    if (props.pointerEvents === false) this.el.classList.add('qx-AykKQ')
  }

  componentDidMount(): void {
    let { parentSelector } = this.props
    if (!parentSelector) parentSelector = 'body'

    this.el.style.zIndex = `${this.props.zindex || zindex(Components.BodyEnd)}`
    document.querySelector(parentSelector)?.appendChild(this.el)
  }

  componentWillUnmount(): void {
    let { parentSelector } = this.props
    if (!parentSelector) parentSelector = 'body'

    document.querySelector(parentSelector)?.removeChild(this.el)
  }

  componentDidUpdate(): void {
    this.el.style.zIndex = `${this.props.zindex || zindex(Components.BodyEnd)}`
  }

  render(): React.ReactPortal {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}

export default BodyEnd
