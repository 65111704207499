import { HaasScriptSettingsForm } from '@components/HaasScriptSettings/HaasScriptSettingsForm'
import { EnumHaasLabAlgorithm } from '@dataObjects/enums/EnumHaasLabAlgorithm'
import { prepareUserLabConfig, UserLabConfig } from '@dataObjects/labs/UserLabConfig'
import { parseUserLabDetails, UserLabDetails } from '@dataObjects/labs/UserLabDetails'
import { parseUserLabExecutionUpdate, UserLabExecutionUpdate } from '@dataObjects/labs/UserLabExecutionUpdate'
import { prepareUserLabParameterConfig, UserLabParameterConfig } from '@dataObjects/labs/UserLabParameterConfig'
import { parseUserLabResult, UserLabResult } from '@dataObjects/labs/UserLabResult'
import {
  parseUserLabsBacktestProgressUpdate,
  UserLabsBacktestProgressUpdate,
} from '@dataObjects/labs/UserLabsBacktestProgressUpdate'
import { parseUserLabRecord, UserLabRecord } from '@dataObjects/labs/UserLabsRecords'
import { parseRuntime, Runtime } from '@dataObjects/runtime/Runtime'
import { KeyedEventHandler } from '@utils/eventHandlers/KeyedEventHandler'
import { Query } from '@utils/QueryPromise'
import { PaginatedResponse } from '@utils/types/PaginatedResponse'
import { HaasChartContainer } from '@vendor/hxCharts/types/HaasChartContainer'
import { CancellationToken } from '../api/rest/ApiRequest'
import { ServiceController, ServiceControllerConstructorType } from '../ServiceController'
import { LabsApi } from './LabsApi'

export class LabsService {
  private api: LabsApi
  private controller: ServiceController

  executionUpdates = new KeyedEventHandler<UserLabExecutionUpdate>()
  backtestUpdates = new KeyedEventHandler<UserLabsBacktestProgressUpdate>()

  constructor(props: ServiceControllerConstructorType) {
    this.controller = props.controller
    this.api = new LabsApi(props.authenticator, this.controller)
  }

  onExecutionUpdate(jsonData: any) {
    const data = parseUserLabExecutionUpdate(jsonData)
    this.executionUpdates.fire(data.labId, data)
  }

  onBacktestUpdate(jsonData: any) {
    const data = parseUserLabsBacktestProgressUpdate(jsonData)
    this.backtestUpdates.fire(data.labId, data)
  }

  async getLabs(token: CancellationToken): Promise<UserLabRecord[]> {
    const response = await this.api.getLabs(token)
    return response.map(parseUserLabRecord)
  }

  async getLabDetails(labId: string, token: CancellationToken): Promise<UserLabDetails> {
    const response = await this.api.getLabDetails(labId, token)
    return parseUserLabDetails(response)
  }

  async createLab(name: string, scriptId: string, accountId: string, market: string): Promise<UserLabRecord> {
    const response = await this.api.createLab(name, scriptId, accountId, market)
    return parseUserLabRecord(response)
  }

  async cloneLab(labId: string): Promise<UserLabRecord> {
    const response = await this.api.cloneLab(labId)
    return parseUserLabRecord(response)
  }

  async discardCancelReason(labId: string): Promise<UserLabRecord> {
    return this.api.discardCancelReason(labId)
  }

  async deleteLab(labId: string): Promise<boolean> {
    const response = await this.api.deleteLab(labId)
    return response
  }

  async changeLabScript(labId: string, scriptId: string): Promise<UserLabDetails> {
    const response = await this.api.changeLabScript(labId, scriptId)
    return parseUserLabDetails(response)
  }

  async updateLabDetails(
    labId: string,
    name: string,
    type: EnumHaasLabAlgorithm,
    config: UserLabConfig,
    settings: HaasScriptSettingsForm,
    parameters: UserLabParameterConfig[]
  ): Promise<UserLabDetails> {
    const response = await this.api.updateLabDetails(labId, name, type, prepareUserLabConfig(config), settings, parameters.map(prepareUserLabParameterConfig))
    return parseUserLabDetails(response)
  }

  async startLabExecution(labId: string, startUnix: number, endUnix: number, sendEmail: boolean): Promise<UserLabDetails> {
    const response = await this.api.startLabExecution(labId, startUnix, endUnix, sendEmail)
    return parseUserLabDetails(response)
  }

  async getLabExecutionUpdate(labId: string, token: CancellationToken): Promise<UserLabExecutionUpdate> {
    const response = await this.api.getLabExecutionUpdate(labId, token)
    return parseUserLabExecutionUpdate(response)
  }

  async cancelLabExecution(labId: string): Promise<boolean> {
    return this.api.cancelLabExecution(labId)
  }

  async getBacktestResult(labId: string, backtestId: string): Promise<UserLabResult> {
    const response = await this.api.getBacktestResult(labId, backtestId)
    return parseUserLabResult(this.controller, response)
  }

  async getBacktestRuntime(labId: string, backtestId: string, token: CancellationToken): Promise<Runtime> {
    const response = await this.api.getBacktestRuntime(labId, backtestId, token)
    return parseRuntime(this.controller, response, true)
  }

  getBacktestChart(labId: string, backtestId: string, token: CancellationToken): Query<HaasChartContainer> {
    return this.api.getBacktestChart(labId, backtestId, token)
  }

  getBacktestLog(labId: string, backtestId: string, token: CancellationToken): Query<string[]> {
    return this.api.getBacktestLog(labId, backtestId, token)
  }

  async getBacktestResultPage(labId: string, nextPageId: number, pageLength: number): Promise<PaginatedResponse<UserLabResult>> {
    const response = await this.api.getBacktestResultPage(labId, nextPageId, pageLength)
    const result = {
      items: response['I'].map((c: any) => parseUserLabResult(this.controller, c)),
      nextPageId: response['NP'],
    }

    return result
  }

  async getFileCsv(backtestId: string, name : string): Promise<string> {
    return this.api.getFileCsv(backtestId, name)
  }

  async getFileJson(backtestId: string, name : string): Promise<string> {
    return this.api.getFileJson(backtestId, name)
  }
}
