import Icons from '@assets/icons'
import { ServiceController } from '@services/ServiceController'
import { DefaultTheme } from 'styled-components'

import { EnumOrderDirection, orderDirectionToString } from '../enums/EnumOrderDirection'
import { UserAccount } from '../private/account/UserAccount'
import { CloudMarket } from '../public/CloudMarket'
import { CloudTradeMarket } from '../public/CloudTradeMarket'
import { EnumHaasOrderCancelReason } from '../scripting/EnumHaasOrderCancelReason'
import { EnumHaasScriptOrderType } from '../scripting/EnumHaasScriptOrderType'
import { leverageToString, orderStatusToString } from '../utils'
import { EnumRuntimeOrderStatus } from './EnumRuntimeOrderStatus'
import { RuntimePosition } from './RuntimePosition'

export class RuntimeOrder {
  positionId!: string
  orderId!: string
  executingId?: string
  botId = ''

  openTime!: number
  closeTime!: number

  account?: UserAccount
  accountId!: string
  accountName!: string

  market?: CloudTradeMarket
  marketTag!: string

  leverage!: number
  leverageAsString!: string

  direction!: EnumOrderDirection
  directionAsString!: string

  type!: EnumHaasScriptOrderType

  orderPrice!: number
  triggerPrice!: number
  tradePrice!: number

  priceDecimals!: number

  amount!: number
  amountFilled!: number
  amountDecimals!: number
  amountLabel!: string

  feeCosts!: number
  feeCurrency!: string

  profits!: number
  profitLabel!: string

  roi!: number
  note!: string

  status!: EnumRuntimeOrderStatus
  statusAsString!: string
  cancelReason!: EnumHaasOrderCancelReason

  timeout!: number

  constructor(services: ServiceController, jsonData?: any, isOpen?: boolean, position?: RuntimePosition) {
    if (!jsonData) return

    this.orderId = jsonData['id']
    this.executingId = jsonData['eid']
    this.positionId = position ? position.positionId : jsonData['pid']

    this.type = jsonData['t']

    this.openTime = jsonData['ot']
    this.closeTime = jsonData['ct']

    this.direction = jsonData['d']
    this.directionAsString = orderDirectionToString(this.direction)

    this.accountId = position ? position.accountId : jsonData['ac']
    this.account = services.accountService.getAccount(this.accountId)
    if (!this.account) this.account = services.backtestService.getBacktestAccount(this.accountId)

    this.accountName = this.account?.name || ''

    this.marketTag = position?.market?.tag || jsonData['ma']
    this.market = services.initData.getTradeMarket(this.marketTag)

    this.leverage = position ? position.leverage : jsonData['le']
    this.leverageAsString = leverageToString(this.account, this.leverage)

    this.orderPrice = jsonData['p']
    this.tradePrice = jsonData['ep']
    this.triggerPrice = jsonData['tp']

    this.priceDecimals = position ? position.priceDecimals : jsonData['pd']

    this.amount = jsonData['a']
    this.amountFilled = jsonData['af']
    this.amountLabel = position ? position.amountLabel : this.market?.amountLabel ?? ''
    this.amountDecimals = position ? position.amountDecimals : this.market?.amountDecimals ?? 8

    this.feeCosts = jsonData['fe']
    this.feeCurrency = jsonData['fc']

    this.profits = jsonData['pr']
    this.profitLabel = position ? position.profitLabel : this.market?.profitLabel ?? ''

    this.roi = jsonData['r']
    this.note = jsonData['n']

    this.timeout = jsonData['to']
    this.cancelReason = jsonData['cr']

    switch (this.type) {
      case EnumHaasScriptOrderType.NoTimeout:
        break
      case EnumHaasScriptOrderType.Default:
      case EnumHaasScriptOrderType.Limit:
      case EnumHaasScriptOrderType.Market:
      case EnumHaasScriptOrderType.MakerOrCancel:
        if (this.timeout === 0) this.timeout = 600
        break
    }

    if (isOpen) this.status = EnumRuntimeOrderStatus.Open
    else if (this.amount === this.amountFilled) this.status = EnumRuntimeOrderStatus.Filled
    else if (this.cancelReason === EnumHaasOrderCancelReason.Timeout) this.status = EnumRuntimeOrderStatus.Cancelled
    else this.status = EnumRuntimeOrderStatus.Failed

    this.statusAsString = orderStatusToString(this.status, this.cancelReason)
  }

  getStatusIcon() {
    if (this.status === EnumRuntimeOrderStatus.Open) return Icons.Signal()

    if (this.status === EnumRuntimeOrderStatus.Filled) return Icons.Check()

    if (this.cancelReason === EnumHaasOrderCancelReason.Timeout) return Icons.Clock()

    return Icons.Cancel()
  }

  getStatusIconColor(theme: DefaultTheme) {
    if (this.status === EnumRuntimeOrderStatus.Open) return theme.COLOR_PRIMARY_ACTIVE

    if (this.status === EnumRuntimeOrderStatus.Filled) return theme.GREEN

    return theme.RED
  }

  toFinishOrderString() {
    const note = this.note ? `(${this.note})` : ''

    const market = CloudMarket.FromTag(this.marketTag)
    return `${this.directionAsString} order finished. ${this.amountFilled} ${this.amountLabel} @ ${this.tradePrice} ${market.secondary} ${note}`
  }
}

export class RuntimeOrderWithBot extends RuntimeOrder {
  botId: string

  constructor(services: ServiceController, jsonData: any, isOpen: boolean, position?: RuntimePosition, botId?: string) {
    super(services, jsonData, isOpen, position)

    this.botId = botId || jsonData['BID']
  }
}
