import { EnumHaasChartPricePlotStyle } from '@vendor/hxCharts/enums/EnumHaasChartPricePlotStyle'
import { ApiRequest, CancellationToken, EnumApiMethod } from '../api/rest/ApiRequest'
import { RestApiBase } from '../api/rest/RestApiBase'
import {Persona} from "@application/Header/Components/Chat/ChatWidget";

export class InterfaceApi extends RestApiBase {
  private baseUrl = 'InterfaceAPI.php'
  private tickerBarUrl = 'PriceTickerBarAPI.php'

  getInitData(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'INIT_DATA',
      endPoint: this.baseUrl,
    })
  }

  getTermsAndConditions(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'WEBSITE_TERMS_AND_CONDITIONS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
    })
  }

  getSubscriptionAgreement(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'WEBSITE_SUBSCRIPTION_AGREEMENT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
    })
  }

  getWebshopSalesAgreement(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'WEBSHOP_SALES_AGREEMENT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
    })
  }

  getChangeLog(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CHANGELOG',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getMarketPriceInformation(market: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'MARKET_PRICE_INFO',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      timeout: 60 * 5 * 1000,
      arguments: {
        market,
      },
    })
  }

  getMarketTechnicalInformation(market: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'MARKET_TA_INFO',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      timeout: 60 * 5 * 1000,
      arguments: {
        market,
      },
    })
  }

  getTickers(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_TICKERS',
      endPoint: this.tickerBarUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  addTicker(market: string, index: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ADD_TICKER',
      endPoint: this.tickerBarUrl,
      authenticator: this.authenticator,
      arguments: { market, index },
    })
  }

  editTicker(market: string, interval: number, style: EnumHaasChartPricePlotStyle, index: number): ApiRequest<boolean> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_TICKER',
      endPoint: this.tickerBarUrl,
      authenticator: this.authenticator,
      arguments: { market, interval, style, index },
    })
  }

  editTickerSorting(markets: string[]): ApiRequest<boolean> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_TICKER_SORTING',
      endPoint: this.tickerBarUrl,
      authenticator: this.authenticator,
      arguments: { markets: JSON.stringify(markets) },
    })
  }

  deleteTicker(market: string): ApiRequest<boolean> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_TICKER',
      endPoint: this.tickerBarUrl,
      authenticator: this.authenticator,
      arguments: { market },
    })
  }

  getDailyUserStatistics(token? : CancellationToken): ApiRequest<boolean> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_DAILY_STATISTICS',
      endPoint: 'UserAPI.php',
      token,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getWeeklyUserStatistics(token? : CancellationToken): ApiRequest<boolean> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_WEEKLY_STATISTICS',
      endPoint: 'UserAPI.php',
      token,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getMonthlyUserStatistics(token? : CancellationToken): ApiRequest<boolean> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_MONTHLY_STATISTICS',
      endPoint: 'UserAPI.php',
      token,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getPoll(pollId: string, token: CancellationToken): ApiRequest<boolean> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_POLL',
      endPoint: 'UserAPI.php',
      token,
      authenticator: this.authenticator,
      arguments: { pollId },
    })
  }

  voteOnPoll(pollId: string, vote: string): ApiRequest<boolean> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'VOTE_POLL',
      endPoint: 'UserAPI.php',
      authenticator: this.authenticator,
      arguments: {
        pollId,
        vote
      },
    })
  }

  getPromotions(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_PROMOTIONS',
      endPoint: 'UserAPI.php',
      token,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  askQuestion(bot : Persona, chatId: string, messageId: string, message: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ASK_QUESTION',
      endPoint: 'InterfaceAPI.php',
      token,
      authenticator: this.authenticator,
      timeout: 1000 * 60 * 10,
      arguments: {
        bot,
        chatId,
        messageId,
        message
      },
    })
  }

  askJulia(chatId: string, message: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ASK_JULIA',
      endPoint: 'InterfaceAPI.php',
      token,
      authenticator: this.authenticator,
      timeout: 1000 * 60 * 10,
      arguments: {
        chatId,
        message
      },
    })
  }

  getJuliaHistory(chatId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_CHAT_JULIA',
      endPoint: 'InterfaceAPI.php',
      token,
      authenticator: this.authenticator,
      arguments: {
        chatId
      },
    })
  }

  askThomas(chatId: string, message: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ASK_THOMAS',
      endPoint: 'InterfaceAPI.php',
      token,
      authenticator: this.authenticator,
      timeout: 1000 * 60 * 10,
      arguments: {
        chatId,
        message
      },
    })
  }

  getThomasHistory(chatId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_CHAT_THOMAS',
      endPoint: 'InterfaceAPI.php',
      token,
      authenticator: this.authenticator,
      arguments: {
        chatId
      },
    })
  }


  askSimone(chatId: string, message: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ASK_SIMONE',
      endPoint: 'InterfaceAPI.php',
      token,
      authenticator: this.authenticator,
      timeout: 1000 * 60 * 10,
      arguments: {
        chatId,
        message
      },
    })
  }

  getSimoneHistory(chatId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_CHAT_SIMONE',
      endPoint: 'InterfaceAPI.php',
      token,
      authenticator: this.authenticator,
      arguments: {
        chatId
      },
    })
  }

  askDavid(chatId: string, message: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ASK_DAVID',
      endPoint: 'InterfaceAPI.php',
      token,
      authenticator: this.authenticator,
      arguments: {
        chatId,
        message
      },
    })
  }

  getDavidHistory(chatId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_CHAT_DAVID',
      endPoint: 'InterfaceAPI.php',
      token,
      authenticator: this.authenticator,
      arguments: {
        chatId
      },
    })
  }

}
