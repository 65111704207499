import DateHelper from './DateHelper'

export type UnixTimestamp = number

class UnixHelper {
  private static WeeklyInSeconds = 7 * 24 * 60 * 60
  private static BiWeeklyInSeconds = 14 * 24 * 60 * 60
  private static MonthlyInSeconds = 28 * 24 * 60 * 60

  static timezone? = ''
  static hours12? = false

  static timeFormatter = new Intl.DateTimeFormat(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    // second : "2-digit",
    hour12: false,
  })

  static dateFormatter = new Intl.DateTimeFormat(undefined, {
    year: '2-digit',
    month: 'short',
    day: '2-digit',
  })

  static monthFormatter = new Intl.DateTimeFormat(undefined, {
    year: '2-digit',
    month: 'short',
  })

  static dateTimeFormatter = new Intl.DateTimeFormat(undefined, {
    year: '2-digit',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    // second : "2-digit",
    hour12: false,
  })

  static setDateTimeSettings(timeZone: string, hour12: boolean) {
    const timeOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: hour12 ?? false,
    }

    const dateOptions = {
      year: '2-digit',
      month: 'short',
      day: '2-digit',
      hour12: hour12 ?? false,
    }

    const monthOptions = {
      year: '2-digit',
      month: 'short',
      day: undefined,
    }

    const dateTimeOptions = {
      year: '2-digit',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: hour12 ?? false,
    }

    if (timeZone) (timeOptions as any).timeZone = timeZone
    if (timeZone) (dateOptions as any).timeZone = timeZone
    if (timeZone) (monthOptions as any).timeZone = timeZone
    if (timeZone) (dateTimeOptions as any).timeZone = timeZone

    this.timeFormatter = new Intl.DateTimeFormat(undefined, timeOptions)
    this.dateFormatter = new Intl.DateTimeFormat(undefined, dateOptions)
    this.monthFormatter = new Intl.DateTimeFormat(undefined, monthOptions)
    this.dateTimeFormatter = new Intl.DateTimeFormat(undefined, dateTimeOptions)
  }

  static now() {
    return Math.floor(Date.now() / 1000)
  }

  static round(unix?: number, interval?: number, offsetInSeconds = 0) {
    if (!unix) unix = this.now()
    if (!interval) interval = 1
    interval = interval * 60

    if (interval === this.WeeklyInSeconds) return unix - (((unix - 946857600) - offsetInSeconds) % this.WeeklyInSeconds)

    if (interval === this.BiWeeklyInSeconds) return unix - (((unix - 946857600) - offsetInSeconds) % this.BiWeeklyInSeconds)

    if ((unix % interval) * 60 === 0) return unix

    unix = unix - ((unix - offsetInSeconds) % interval)
    return unix
  }

  static roundUp(unix?: number, interval?: number) {
    if (!unix) unix = this.now()
    if (!interval) interval = 1

    if ((unix % interval) * 60 === 0) return unix

    unix = this.round(unix, interval)
    return unix + interval * 60
  }

  static fromDate(date: Date) {
    return Math.floor(date.getTime() / 1000)
  }

  static toTimeString(unix: UnixTimestamp) {
    if (!unix) return ' - '

    let timeString = this.timeFormatter.format(new Date(unix * 1000))
    if (timeString.startsWith("24:"))
      timeString = `00:${timeString.substring(3)}`;

    return timeString
  }

  static toDateString(unix: UnixTimestamp) {
    if (!unix) return ' - '

    return this.dateFormatter.format(new Date(unix * 1000))
  }

  static toMonthString(unix: UnixTimestamp) {
    if (!unix) return ' - '

    return this.monthFormatter.format(new Date(unix * 1000))
  }

  static toDateTimeString(unix: UnixTimestamp) {
    if (!unix) return ' - '

    const dtString = this.dateTimeFormatter.format(new Date(unix * 1000))
    return dtString.replace(" 24:", " 00:")
  }

  static getLastMonday(unix?: UnixTimestamp) {
    if (!unix) unix = this.now()

    unix -= (unix - 946857600) % this.WeeklyInSeconds
    return unix
  }

  static addTicks(unix: UnixTimestamp, interval: number, ticks: number) {
    if (interval < 40320) return unix + ticks * interval * 60

    let date = new Date(unix * 1000)
    date = DateHelper.addMonths(date, ticks)
    return this.fromDate(date)
  }

  private static startOfTheMonth(unix: number) {
    let date = new Date(unix * 1000)
    date = DateHelper.startOfTheMonth(date)
    return this.fromDate(date)
  }
}

export default UnixHelper
