import AppContextController from '@context/Application/AppContext'
import BodyEnd from '@form/BodyEnd'
import HxChartsStyle from '@styling/HxChartsStyle'
import HxEditorStyle from '@styling/HxEditorStyle'
import NotificationsStyle from '@styling/NotificationsStyle'
import Unix from '@utils/Unix'
import zindex, { Components } from '@utils/zindex'
import { createBrowserHistory } from 'history'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { SpriteContextProvider } from 'react-lazy-svg'
import { NotificationContainer } from 'react-notifications'
import 'react-notifications/lib/notifications.css'

import * as serviceWorker from './serviceWorker'

const App = React.lazy(() => import('./application/App'))

function importAll(r: __WebpackModuleApi.RequireContext) {
  return r.keys().map(r)
}

importAll(require.context('./', false, /\.(png|jpe?g|svg)$/))

const loadSVG = async (url: string) => {
  return await (await fetch(url)).text()
}

/* Workaround to prevent SC to load fonts with every router change */
const style = document.createElement('style')
style.textContent = `
  @font-face {
    font-family: Lato;
    font-display: fallback;
    src: url('/assets/fonts/Lato-regular.ttf');
  }
  @font-face {
    font-family: Lato-bold;
    font-display: fallback;
    src: url('/assets/fonts/Lato-bold.ttf');
  }
  @font-face {
    font-family: Tahoma;
    font-display: fallback;
    src: url('/assets/fonts/Tahoma-regular.ttf');
  }
`
document.head.appendChild(style)

ReactDOM.render(
  <>
    <NotificationsStyle />
    <HxEditorStyle />
    <HxChartsStyle />
    <AppContextController>
      <SpriteContextProvider loadSVG={loadSVG}>
        <Suspense fallback={null}>
          <App history={createBrowserHistory()} />
        </Suspense>
      </SpriteContextProvider>
    </AppContextController>
    <BodyEnd pointerEvents={false} zindex={zindex(Components.Notifications)}>
      <NotificationContainer />
    </BodyEnd>
  </>,
  document.getElementById('root')
)

const startUnix = Unix.now();

serviceWorker.register({
  updateViaCache : 'none',
  onSuccess : registration => { },
  onUpdate : registration => {
    console.log("SW Updated")
    if (startUnix + 60 * 3 > Unix.now()){
        caches.keys().then(function(cacheNames) {
            Promise.all(cacheNames.map(function(cacheName) {
                return caches.delete(cacheName);
            }))
                .then(value => {
                    console.log("Force refresh")
                    window.location.reload();
                });
        });

    } else {
      console.log("Refresh too late")
      alert("An update has been detected. Please refresh for the best experience")
    }
  },
})