import {ExchangeReportConfig} from '@admin/pages/ExchangeReports/ExchangeReportPage'
import {UserNotification} from '@dataObjects/private/notifications/UserNotification'
import {HaasShopOrder} from '@dataObjects/private/webshop/HaasShopOrder'
import {HaasScriptItem} from '@dataObjects/scripting/HaasScriptItem'
import {CloudReport} from '@services/admin/dataObjects/CloudReport'
import {parseUserPollDetailsType, UserPollDetailsType} from '@services/interface/objects/UserPollDetailsType'
import {CancellationToken} from '../api/rest/ApiRequest'
import {ServiceController, ServiceControllerConstructorType} from '../ServiceController'
import {AdministratorApi} from './AdministratorApi'
import {ServiceHealthReportCache} from './cache/ServiceHealthReportCache'
import {ServiceListCache} from './cache/ServiceListCache'
import {LicenseActivation} from './dataObjects/LicenseActivation'
import {LicenseRegistrations} from './dataObjects/LicenseRegistrations'
import {OperationsCounterStatistics} from './dataObjects/OperationsCounterStatistics'
import {AccountServiceReport} from './dataObjects/serviceReports/AccountServiceReport'
import {BotExecutionServiceReport} from './dataObjects/serviceReports/BotExecutionServiceReport'
import {HaasExchangeServiceReport} from './dataObjects/serviceReports/HaasExchangeServiceReport'
import {InterfaceServiceReport} from './dataObjects/serviceReports/InterfaceServiceReport'
import {ScriptExecutionServiceReport} from './dataObjects/serviceReports/ScriptExecutionServiceReport'
import {ServiceHealthReport} from './dataObjects/serviceReports/ServiceHealthReport'
import {WebSocketServiceReport} from './dataObjects/serviceReports/WebSocketServiceReport'
import {UserDevice} from './dataObjects/UserDevice'
import {UserProfile} from './dataObjects/UserProfile'
import {EnumServiceType} from './enums/EnumServiceType'
import {WooOrder} from "@dataObjects/private/webshop/WooOrder";
import {WooProduct} from "@dataObjects/private/webshop/WooProduct";
import {WooUserProfile} from "@dataObjects/private/webshop/WooUserProfile";
import {ExchangeReportChartsDataType} from "@admin/pages/ExchangeReports/Components/ExchangeReportCharts";
import {ExchangeReportDTO} from "@services/admin/dataObjects/ExchangeReportDTO";
import {EnumServiceOperationStatus} from "@services/admin/enums/EnumServiceOperationStatus";
import _ from "@utils/lodash";
import numberHelper from "@vendor/hxCharts/helpers/NumberHelper";
import {PaginatedResponse} from "@utils/types/PaginatedResponse";
import {HaasScriptBacktest} from "@dataObjects/scripting/HaasScriptBacktest";
import {Query} from "@utils/QueryPromise";

export class AdministratorService {
  private api: AdministratorApi
  private controller: ServiceController
  private services: ServiceListCache
  private serviceReports: ServiceHealthReportCache

  constructor(props: ServiceControllerConstructorType) {
    this.controller = props.controller
    this.api = new AdministratorApi(props.authenticator, this.controller)

    this.services = new ServiceListCache(this.api)
    this.serviceReports = new ServiceHealthReportCache(this.api)
  }

  getServices(token: CancellationToken): Promise<Record<string, string>> {
    return this.services.getValue()
  }

  async getAllServiceReport(token: CancellationToken): Promise<ServiceHealthReport[]> {
    const response = await this.serviceReports.getValue();

    // const filteredResponse = response.filter(c => c.serviceStatus === EnumServiceOperationStatus.Operational);
    // const sortedResponse = _.orderBy(filteredResponse, item => item.machineId);
    // const groupedOnServer = _.groupBy(sortedResponse, item => item.machineId);

    // const uniqueServices = _.distinctBy(filteredResponse, c => c.serviceType);
    // _.each(uniqueServices, c => console.log(EnumServiceType[c.serviceType]))

    // const logLines : string[] = [];
    //
    // _.each(groupedOnServer, (items, group) => {
    //   logLines.push(String(group));
    //
    //   _.each(items, item => {
    //     let service = `hc-price-${item.serviceName}`;
    //     if (item.serviceType === EnumServiceType.WebService)
    //       service = `hc-web`;
    //     if (item.serviceType === EnumServiceType.HaasExchangeService)
    //       service = `hc-price-simulation-${item.serviceName.split("_")[1]}`;
    //     if (item.serviceType === EnumServiceType.PriceTrackingService)
    //       service = `hc-price-tracking-${item.serviceName.split("_")[1]}`;
    //     if (item.serviceType === EnumServiceType.PriceSocketService)
    //       service = `hc-price-socket-${item.serviceName.split("_")[1]}`;
    //     if (item.serviceType === EnumServiceType.TradingService)
    //       service = `hc-price-trading-${item.serviceName.split("_")[1]}`;
    //
    //     logLines.push(`systemctl start ${service}`)
    //   });
    //
    //   logLines.push("");
    // })
    //
    // console.log(_.join(logLines, "\n", c => c));

    return response;
  }

  async getAdminStatistics(token: CancellationToken): Promise<OperationsCounterStatistics> {
    const response = await this.api.getAdminStatistics(token)
    return new OperationsCounterStatistics(response)
  }

  async getAccountServiceReport(serviceId: string, token: CancellationToken): Promise<AccountServiceReport> {
    const response = await this.api.getServiceAdminReport(serviceId, EnumServiceType.AccountService, token)
    return new AccountServiceReport(response)
  }

  async getBotExecutionServiceReport(serviceId: string, token: CancellationToken): Promise<BotExecutionServiceReport> {
    const response = await this.api.getServiceAdminReport(serviceId, EnumServiceType.BotExecutionService, token)
    return new BotExecutionServiceReport(response, this.controller.initData)
  }

  async getHaasExecutionServiceReport(serviceId: string, token: CancellationToken): Promise<HaasExchangeServiceReport> {
    const response = await this.api.getServiceAdminReport(serviceId, EnumServiceType.HaasExchangeService, token)
    return new HaasExchangeServiceReport(response)
  }

  async getScriptExecutionServiceReport(serviceId: string, token: CancellationToken): Promise<ScriptExecutionServiceReport> {
    const response = await this.api.getServiceAdminReport(serviceId, EnumServiceType.ScriptExecutionService, token)
    return new ScriptExecutionServiceReport(response, this.controller.initData)
  }

  async getWebSocketServiceReport(serviceId: string, token: CancellationToken): Promise<WebSocketServiceReport> {
    const response = await this.api.getServiceAdminReport(serviceId, EnumServiceType.WebSocketService, token)
    return new WebSocketServiceReport(response)
  }

  async getInterfaceServiceReport(serviceId: string, token: CancellationToken): Promise<InterfaceServiceReport> {
    const response = await this.api.getServiceAdminReport(serviceId, EnumServiceType.InterfaceService, token)
    return new InterfaceServiceReport(response)
  }

  // Services
  async doStartService(serverName: string, serviceName: string): Promise<boolean> {
    await this.api.doStartService(serverName, serviceName)
    return true
  }

  async doRestartService(serverName: string, serviceName: string): Promise<boolean> {
    await this.api.doRestartService(serverName, serviceName)
    return true
  }

  async doStopService(serverName: string, serviceName: string): Promise<boolean> {
    await this.api.doStopService(serverName, serviceName)
    return true
  }

  async doRequestStatusService(serverName: string, serviceName: string): Promise<boolean> {
    await this.api.doRequestStatusService(serverName, serviceName)
    return true
  }

  async doDeleteServiceReport(machineId: string, serviceId: string): Promise<boolean> {
    await this.api.doDeleteService(machineId, serviceId)
    return true
  }

  // Licenses
  async getBlockedLicenses(token: CancellationToken): Promise<LicenseActivation[]> {
    const response = await this.api.getBlockedLicenses(token)
    return response.map((c: any) => new LicenseActivation(c))
  }

  async doLicenseReset(licenseKey: string): Promise<boolean> {
    await this.api.doLicenseReset(licenseKey)
    return true
  }

  async getValidLicenses(token: CancellationToken): Promise<LicenseRegistrations[]> {
    const response = await this.api.getValidLicenses(token)
    return response.map((c: any) => new LicenseRegistrations(c))
  }

  async getUserLicenses(userId: string, token: CancellationToken): Promise<LicenseRegistrations[]> {
    const response = await this.api.getUserLicenses(userId, token)
    return response.map((c: any) => new LicenseRegistrations(c))
  }

  async getLicenseDetails(licenseKey: string): Promise<LicenseActivation> {
    const response = await this.api.getLicenseDetails(licenseKey)
    return new LicenseActivation(response)
  }

  // User list
  async getAllUsers(token: CancellationToken): Promise<UserProfile[]> {
    const response = await this.api.getAllUsers(token)
    return response.map((c: any) => new UserProfile(c))
  }

  async getBannedUsers(token: CancellationToken): Promise<UserProfile[]> {
    const response = await this.api.getBannedUsers(token)
    return response.map((c: any) => new UserProfile(c))
  }

  async getUnconfirmedUsers(token: CancellationToken): Promise<UserProfile[]> {
    const response = await this.api.getUnconfirmedUsers(token)
    return response.map((c: any) => new UserProfile(c))
  }

  async getAdministratorUsers(token: CancellationToken): Promise<UserProfile[]> {
    const response = await this.api.getAdministratorUsers(token)
    return response.map((c: any) => new UserProfile(c))
  }

  async getPartners(token: CancellationToken): Promise<UserProfile[]> {
    const response = await this.api.getPartners(token)
    return response.map((c: any) => new UserProfile(c))
  }

  async getWhiteLabelPartnersProfiles(token: CancellationToken): Promise<UserProfile[]> {
    const response = await this.api.getWhiteLabelPartnersProfiles(token)
    return response.map((c: any) => new UserProfile(c))
  }

  async getDevelopers(token: CancellationToken): Promise<UserProfile[]> {
    const response = await this.api.getDevelopers(token)
    return response.map((c: any) => new UserProfile(c))
  }


  // User actions
  async reactivateUser(userId: string): Promise<boolean> {
    await this.api.reactivateUser(userId)
    return true
  }

  async resetUserPassword(userId: string): Promise<boolean> {
    await this.api.resetUserPassword(userId)
    return true
  }

  async fullLoginResetUser(userId: string): Promise<boolean> {
    await this.api.fullLoginResetUser(userId)
    return true
  }

  async lockUser(userId: string): Promise<boolean> {
    await this.api.lockUser(userId)
    return true
  }

  async unbanUser(userId: string): Promise<boolean> {
    await this.api.unbanUser(userId)
    return true
  }

  async banUser(userId: string): Promise<boolean> {
    await this.api.banUser(userId)
    return true
  }

  async makeNormalUser(userId: string): Promise<boolean> {
    await this.api.makeNormalUser(userId)
    return true
  }

  async makePartnerUser(userId: string): Promise<boolean> {
    await this.api.makePartnerUser(userId)
    return true
  }


  async makeWhiteLabelPartnerUser(userid: string, partnerName : string, exchanges : string[]): Promise<boolean> {
    await this.api.makeWhiteLabelPartnerUser(userid, partnerName, exchanges)
    return true
  }

  async makeAdminUser(userId: string): Promise<boolean> {
    await this.api.makeAdminUser(userId)
    return true
  }

  async deleteUser(userId: string): Promise<boolean> {
    await this.api.deleteUser(userId)
    return true
  }


  // User details
  async getUserNotifications(targetUserId: string): Promise<UserNotification[]> {
    const response = await this.api.getUserNotifications(targetUserId)
    return response['I'].map((c: any) => new UserNotification(c))
  }

  async getAllowedDevices(targetUser: string, token: CancellationToken): Promise<UserDevice[]> {
    const response = await this.api.getAllowedDevices(targetUser, token)
    return response.map((c: any) => new UserDevice(c))
  }

  async doClearAllowedDevices(targetUser: string, token: CancellationToken): Promise<boolean> {
    await this.api.doClearAllowedDevices(targetUser, token)
    return true
  }

  // NewsFeeds
  async doDeleteNewsFeed(feedId: string) {
    await this.api.doDeleteNewsFeed(feedId)
  }

  async doUpdateNewsFeed(feedId: string) {
    await this.api.doUpdateNewsFeed(feedId)
  }

  async addNewsfeed(name: string, grouping: string, type: number, url: string, isFeatured : boolean): Promise<boolean> {
    await this.api.addNewsfeed(name, grouping, type, url, isFeatured)
    return true
  }

  async editNewsFeed(feedId: string, name: string, grouping: string, type: number, url: string, isFeatured : boolean): Promise<boolean> {
    await this.api.editNewsFeed(feedId, name, grouping, type, url, isFeatured)
    return true
  }

  // VideoStreams
  async doDeleteVideostream(feedId: string) {
    await this.api.doDeleteVideostream(feedId)
  }

  async newVideoStream(title: string, url: string): Promise<boolean> {
    await this.api.doNewVideoStream(title, url)
    return true
  }

  async editVideoStream(feedId: string, title: string, url: string): Promise<boolean> {
    await this.api.doEditVideoStreams(feedId, title, url)
    return true
  }

  // Strategies
  async getUserScripts(targetUserId: string, token: CancellationToken): Promise<HaasScriptItem[]> {
    const response = await this.api.getUserScripts(targetUserId, token)
    return response.map((c: any) => new HaasScriptItem(c))
  }

  async doDeleteScript(targetUserId: string, scriptId: string, typeStrategy: number, isCommand: boolean, token: CancellationToken): Promise<boolean> {
    await this.api.doDeleteScript(targetUserId, scriptId, typeStrategy, isCommand, token)
    return true
  }

  // Price history
  async buildPriceHistory(market: string): Promise<boolean> {
    await this.api.buildPriceHistory(market, new CancellationToken())
    return true
  }

  // Fix recent, fix old
  // Wipe cache

  // Webshop
  async getWooProfile(targetuserId: string): Promise<WooUserProfile> {
    return new WooUserProfile(await this.api.getWooProfile(targetuserId))
  }

  async getAllStoreOrders(token: CancellationToken): Promise<WooOrder[]> {
    const response = await this.api.getAllShopOrders(token)
    return response.map((c: any) => new WooOrder(c))
  }

  // To be removed
  async getUserStoreOrders(userid: string, token: CancellationToken): Promise<HaasShopOrder[]> {
    const response = await this.api.getUserShopOrders(userid, token)
    return response.map((c: any) => new HaasShopOrder(c))
  }

  async getAllProducts(token: CancellationToken): Promise<WooProduct[]> {
    const response = await this.api.getAllProducts(token)
    return response.map((c: any) => new WooProduct(c))
  }

  async getAllOldShopOrders(token: CancellationToken): Promise<WooOrder[]> {
    const response = await this.api.getAllOldShopOrders(token)
    return response.map((c: any) => new WooOrder(c))
  }

  // Reports
  async getCloudReports(config: ExchangeReportConfig, token: CancellationToken): Promise<CloudReport> {
    const response = await this.api.getCloudReport(config, token)
    return new CloudReport(response)
  }

  async getExchangeReports(config: ExchangeReportConfig, token: CancellationToken): Promise<ExchangeReportDTO> {
    const response = await this.api.getExchangeReports(config, token)
    return new ExchangeReportDTO(response, this.controller.initData);
  }

  async getExchangeReportCharts(config: ExchangeReportConfig, token: CancellationToken): Promise<ExchangeReportChartsDataType> {
    const response = await this.api.getExchangeReportCharts(config, token)
    return response as ExchangeReportChartsDataType
  }

  // Polls
  async configurePoll(pollId: string, title: string, isOpen: boolean, options: string[], resetVotes: boolean): Promise<UserPollDetailsType> {
    const response = await this.api.configurePoll(pollId, title, isOpen, options, resetVotes)
    return parseUserPollDetailsType(response)
  }

  // Promotions
  async newPromotion(text: string, link: string, imageDataUrl: string, popupImageDataUrl: string, startDate : number, endDate : number): Promise<boolean> {
    return this.api.newPromotion(text, link, imageDataUrl, popupImageDataUrl, startDate, endDate)
  }

  async editPromotion(promotionId: number, text: string, link: string, imageDataUrl: string, popupImageDataUrl: string, startDate : number, endDate : number): Promise<boolean> {
    return this.api.editPromotion(promotionId, text, link, imageDataUrl, popupImageDataUrl, startDate, endDate)
  }

  async deletePromotion(promotionId: number): Promise<boolean> {
    return this.api.deletePromotion(promotionId)
  }


  async getWhiteLabelPartners(): Promise<Record<string, string>> {
    return await this.api.getWhiteLabelPartners();
  }

  async getWhiteLabelReport(partnerIds : string[]): Promise<WhiteLabelReportType[]> {
    const response = await this.api.getWhiteLabelReport(partnerIds);
    return response.map(parseWhiteLabelReport);
  }

  async getWhiteLabelPartnerProfile(targetUserId : string): Promise<string[]> {
    const response = await this.api.getWhiteLabelPartnerProfile(targetUserId);

    return response.exchanges as string[];
  }

  async editWhiteLabelPartnerExchanges(partnerId : string, exchanges : string[]): Promise<boolean> {
    return this.api.editWhiteLabelPartnerExchanges(partnerId, exchanges);
  }


  async getRuntimeLogbook(botId: string, nextPageId: number, pageLength: number, token: CancellationToken): Promise<{ logs: string[]; nextPageId: number }> {
    const response = await this.api.getRuntimeLogbook(botId, nextPageId, pageLength, token)

    nextPageId = response['NP']
    const logs = response['I'] as string[]

    return { logs, nextPageId }
  }

}

export const parseWhiteLabelReport = (jsonData : any) : WhiteLabelReportType => {
  try {
    return {
      year : jsonData['Y'],
      month : jsonData['M'],
      cost : jsonData['C'],
      bots : jsonData['B'],
      users : jsonData['U'],
      marketDetails : jsonData['MD'].map((c : any) : WhiteLabelMarketReport => ({
        market : c['M'],
        bots : c['B'],
        users : c['U']
      })),
      scriptDetails : jsonData['SD'].map((c : any) : WhiteLabelScriptReport => ({
        type : c['T'],
        markets : c['M'],
        bots : c['B'],
        users : c['U']
      })),
      userDetails : jsonData['UD'].map((c : any) : WhiteLabelUserReport => ({
        userId : c['U'],
        bots : c['B'],
        markets : c['M']
      })),
    }
  } catch (e) {
    throw e;
  }
}

export type WhiteLabelReportType = {
  year : number;
  month : number;
  cost : number,
  bots : number,
  users : number,

  marketDetails : WhiteLabelMarketReport[],
  userDetails : WhiteLabelUserReport[],
  scriptDetails : WhiteLabelScriptReport[],
}

type WhiteLabelMarketReport = {
  market : string,
  bots : number,
  users : number;
}

type WhiteLabelUserReport = {
  userId : string,
  bots : number,
  markets : number;
}

type WhiteLabelScriptReport = {
  type : WhiteLabelScriptType,
  users : number,
  bots : number;
  markets : number;
}

export enum WhiteLabelScriptType {
  SpotGrid,
  FuturesGrid,
  Rebalance,
  DollarCostAveraging
}


export const parseWhiteLabelBot = (jsonData : any) : WhiteLabelBotType => {
  return {
    userId : jsonData['UserId'],
    botId : jsonData['BotId'],
    type : jsonData['Type'],
    symbol : jsonData['Symbol'],
    startUnix : jsonData['StartUnix'],
  }
}


export type WhiteLabelBotType = {
  userId : string;
  botId : string;
  type : WhiteLabelScriptType,
  symbol : string,
  startUnix : number,
}
