export class Exception {
  timestamp: number
  source: string
  message: string
  notes: string
  stacktrace: string
  counter: number

  constructor(jsonData: any) {
    this.timestamp = jsonData['Timestamp']
    this.source = jsonData['Source']
    this.notes = jsonData['Notes']
    this.message = jsonData['Message']
    this.stacktrace = jsonData['StackTrace']
    this.counter = jsonData['Counter']
  }
}
