import { UserAccount } from '@dataObjects/private/account/UserAccount'
import { CloudMarket } from '@dataObjects/public/CloudMarket'
import { ServiceController } from '@services/ServiceController'
import { _ } from '@utils/lodash'

export type UserTradeVolumeValuesType = {
  inBTC: number
  inUSD: number
}

export class UserTradeVolume {
  account?: UserAccount
  market?: CloudMarket

  totalAmount: number
  volumeTotal: UserTradeVolumeValuesType
  volumeLast24Hours: UserTradeVolumeValuesType
  volumeLast7Days: UserTradeVolumeValuesType
  volumeLast1Month: UserTradeVolumeValuesType

  constructor(controller: ServiceController, key: string, jsonData: any, isAccount: boolean) {
    if (isAccount) {
      this.account = controller.accountService.getAccount(key)
    } else {
      this.market = new CloudMarket(key, controller.initData)
    }

    this.totalAmount = isAccount ? 0 : jsonData['TA']
    this.volumeTotal = { inBTC: jsonData['TV']['B'], inUSD: jsonData['TV']['U'] }
    this.volumeLast24Hours = { inBTC: jsonData['VLD']['B'], inUSD: jsonData['VLD']['U'] }
    this.volumeLast7Days = { inBTC: jsonData['VLW']['B'], inUSD: jsonData['VLW']['U'] }
    this.volumeLast1Month = { inBTC: jsonData['VLM']['B'], inUSD: jsonData['VLM']['U'] }
  }
}

class UserTradeVolumePortfolio {
  volumeLast24Hours: UserTradeVolumeValuesType
  volumeLast7Days: UserTradeVolumeValuesType
  volumeLast1Month: UserTradeVolumeValuesType
  volumeTotal: UserTradeVolumeValuesType

  volumePerAccount: UserTradeVolume[]
  volumePerMarket: UserTradeVolume[]

  constructor(controller: ServiceController, jsonData: any) {
    this.volumePerAccount = _.select(jsonData['VPA'], (item, key) => new UserTradeVolume(controller, String(key), item, true))
    this.volumePerMarket = _.select(jsonData['VPM'], (item, key) => new UserTradeVolume(controller, String(key), item, false))

    this.volumeTotal = { inBTC: _.sum(this.volumePerAccount, (c) => c.volumeTotal.inBTC), inUSD: _.sum(this.volumePerAccount, (c) => c.volumeTotal.inUSD) }
    this.volumeLast24Hours = { inBTC: _.sum(this.volumePerAccount, (c) => c.volumeLast24Hours.inBTC), inUSD: _.sum(this.volumePerAccount, (c) => c.volumeLast24Hours.inUSD) }
    this.volumeLast7Days = { inBTC: _.sum(this.volumePerAccount, (c) => c.volumeLast7Days.inBTC), inUSD: _.sum(this.volumePerAccount, (c) => c.volumeLast7Days.inUSD) }
    this.volumeLast1Month = { inBTC: _.sum(this.volumePerAccount, (c) => c.volumeLast1Month.inBTC), inUSD: _.sum(this.volumePerAccount, (c) => c.volumeLast1Month.inUSD) }
  }
}

export default UserTradeVolumePortfolio
