import { ApiRequest, CancellationToken, EnumApiMethod } from '../api/rest/ApiRequest'
import { RestApiBase } from '../api/rest/RestApiBase'

export class SignalApi extends RestApiBase {
  private baseUrl = 'SignalAPI.php'

  getSignals(token: CancellationToken): ApiRequest<any[]> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'MY_SIGNALS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }

  addSignal(name: string, description: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'NEW_SIGNAL',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        name: name,
        description: description,
      },
    })
  }

  editSignal(id: string, name: string, description: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_SIGNAL',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        id: id,
        name: name,
        description: description,
      },
    })
  }

  deleteSignal(id: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'REMOVE_SIGNAL',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        id: id,
      },
    })
  }

  emailSignal(id: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EMAIL_SIGNALDETAILS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        id: id,
      },
    })
  }

  getSignal(id: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_SIGNAL',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        id: id,
      },
    })
  }

  publishSignal(id: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'PUBLISH_SIGNAL',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        id: id,
      },
    })
  }

  unpublishSignal(id: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'UNPUBLISH_SIGNAL',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        id: id,
      },
    })
  }
}
