import _ from "@utils/lodash";
import {CloudInitData} from "@dataObjects/public/CloudInitData";

export class ExchangeReportDTO {

    uniqueUsers : number;
    uniqueUsersTrading : number;
    uniqueAccounts : number;
    uniqueAccountsTrading : number;
    uniqueExchanges : number;
    uniqueMarkets : number;
    activeBots : number;
    executedBacktests : number;
    executedOrders : number;
    executedTrades : number;
    tradeVolumeInBTC : number;
    tradeVolumeInUSD : number;
    feeCostsInBTC : number;
    feeCostsInUSD : number;
    feeRebatesInBTC : number;
    feeRebatesInUSD : number;

    exchanges : ExchangeReportDetailsDTO[];
    markets : ExchangeReportMarketsDTO[];
    users : ExchangeReportUsersDTO[];
    licenses : ExchangeReportLicenseDTO[];

    constructor(jsonData : any, initData : CloudInitData) {
        this.uniqueUsers = jsonData['UU'];
        this.uniqueUsersTrading = jsonData['UUT'];

        this.uniqueAccounts = jsonData['UA'];
        this.uniqueAccountsTrading = jsonData['UAT'];

        this.uniqueExchanges = jsonData['UE'];
        this.uniqueMarkets = jsonData['UM'];

        this.activeBots = jsonData['AB'];

        this.executedBacktests = jsonData['EB'];

        this.executedOrders = jsonData['EO'];
        this.executedTrades = jsonData['ET'];

        this.tradeVolumeInBTC = jsonData['TVB'];
        this.tradeVolumeInUSD = jsonData['TVU'];

        this.feeCostsInBTC = jsonData['FCB'];
        this.feeCostsInUSD = jsonData['FCU'];

        this.feeRebatesInBTC = jsonData['FRB'];
        this.feeRebatesInUSD = jsonData['FRU'];

        this.exchanges = _.select(jsonData['E'], c => new ExchangeReportDetailsDTO(c, initData));
        this.markets = _.select(jsonData['M'], c => new ExchangeReportMarketsDTO(c));
        this.users = _.select(jsonData['U'], c => new ExchangeReportUsersDTO(c));
        this.licenses = _.select(jsonData['L'], c => new ExchangeReportLicenseDTO(c, initData));
    }

}

class ExchangeReportDetailsDTO {

    reportTag : string;

    exchangeCode : string;
    exchangeName : string;

    isEnterprise : boolean;
    isSimulated : boolean;

    isLite : boolean;
    isStandard : boolean;
    isPro : boolean;

    users : number;
    accounts : number;
    bots : number;
    markets : number;
    backtests : number;
    executedOrders : number;
    executedTrades : number;
    tradeVolumeInBTC : number;
    tradeVolumeInUSD : number;
    feeCostsInBTC : number;
    feeCostsInUSD : number;
    feeRebatesInBTC : number;
    feeRebatesInUSD : number;

    constructor(jsonData : any, initData : CloudInitData) {
        this.reportTag = jsonData['EC'];

        this.exchangeCode = jsonData['EC'].split("-")[0];
        this.exchangeName = initData.priceSources.find(c => c.exchangeCode === this.exchangeCode)?.exchangeName ?? this.exchangeCode;

        this.isEnterprise = jsonData['EC'].includes("-ENTERPRISE");
        this.isSimulated = jsonData['EC'].includes("-SIMULATED");

        this.isLite = false;
        this.isStandard = false;
        this.isPro = false;

        this.users = jsonData['U'];
        this.accounts = jsonData['A'];
        this.bots = jsonData['B'];
        this.markets = jsonData['M'];
        this.backtests = jsonData['BT'];
        this.executedOrders = jsonData['EO'];
        this.executedTrades = jsonData['ET'];
        this.tradeVolumeInBTC = jsonData['TVB'];
        this.tradeVolumeInUSD = jsonData['TVU'];
        this.feeCostsInBTC = jsonData['FCB'];
        this.feeCostsInUSD = jsonData['FCU'];
        this.feeRebatesInBTC = jsonData['FRB'];
        this.feeRebatesInUSD = jsonData['FRU'];
    }
}

class ExchangeReportMarketsDTO {

    exchangeCode : string;
    market : string;
    amountLabel : string;
    executedOrders : number;
    orderAmount : number;
    executedTrades : number;
    tradeAmount : number;
    tradeVolumeInBTC : number;
    tradeVolumeInUSD : number;
    feeCostsInBTC : number;
    feeCostsInUSD : number;
    feeRebatesInBTC : number;
    feeRebatesInUSD : number;

    constructor(jsonData : any) {
        this.exchangeCode = jsonData['EC'];
        this.market = jsonData['M'];
        this.amountLabel = jsonData['AL'];
        this.executedOrders = jsonData['EO'];
        this.orderAmount = jsonData['OA'];
        this.executedTrades = jsonData['ET'];
        this.tradeAmount = jsonData['TA'];
        this.tradeVolumeInBTC = jsonData['TVB'];
        this.tradeVolumeInUSD = jsonData['TVU'];
        this.feeCostsInBTC = jsonData['FCB'];
        this.feeCostsInUSD = jsonData['FCU'];
        this.feeRebatesInBTC = jsonData['FRB'];
        this.feeRebatesInUSD = jsonData['FRU'];
    }

}

class ExchangeReportUsersDTO {

    userId : string;
    exchanges : number;
    markets : number;
    executedOrders : number;
    executedTrades : number;
    tradeVolumeInBTC : number;
    tradeVolumeInUSD : number;
    feeCostsInBTC : number;
    feeCostsInUSD : number;
    feeRebatesInBTC : number;
    feeRebatesInUSD : number;

    constructor(jsonData : any) {
        this.userId = jsonData['U'];
        this.exchanges = jsonData['E'];
        this.markets = jsonData['M'];
        this.executedOrders = jsonData['EO'];
        this.executedTrades = jsonData['ET'];
        this.tradeVolumeInBTC = jsonData['TVB'];
        this.tradeVolumeInUSD = jsonData['TVU'];
        this.feeCostsInBTC = jsonData['FCB'];
        this.feeCostsInUSD = jsonData['FCU'];
        this.feeRebatesInBTC = jsonData['FRB'];
        this.feeRebatesInUSD = jsonData['FRU'];
    }

}

class ExchangeReportLicenseDTO {

    exchangeName : string;
    exchangeCode : string;
    licenseTag : string;

    isEnterprise : boolean;
    isSimulated : boolean;

    users : number;
    accounts : number;
    bots : number;
    markets : number;
    backtests : number;
    executedOrders : number;
    executedTrades : number;
    tradeVolumeInBTC : number;
    tradeVolumeInUSD : number;
    feeCostsInBTC : number;
    feeCostsInUSD : number;
    feeRebatesInBTC : number;
    feeRebatesInUSD : number;

    constructor(jsonData : any, initData : CloudInitData) {
        this.exchangeCode = jsonData['EC'].split("-")[0];
        this.exchangeName = initData.priceSources.find(c => c.exchangeCode === this.exchangeCode)?.exchangeName ?? this.exchangeCode;

        this.isEnterprise = jsonData['EC'].includes("-ENTERPRISE");
        this.isSimulated = jsonData['EC'].includes("-SIMULATED");

        this.licenseTag = jsonData['LT'];

        this.users = jsonData['U'];
        this.accounts = jsonData['A'];
        this.bots = jsonData['B'];
        this.markets = jsonData['M'];
        this.backtests = jsonData['BT'];
        this.executedOrders = jsonData['EO'];
        this.executedTrades = jsonData['ET'];
        this.tradeVolumeInBTC = jsonData['TVB'];
        this.tradeVolumeInUSD = jsonData['TVU'];
        this.feeCostsInBTC = jsonData['FCB'];
        this.feeCostsInUSD = jsonData['FCU'];
        this.feeRebatesInBTC = jsonData['FRB'];
        this.feeRebatesInUSD = jsonData['FRU'];
    }
}
