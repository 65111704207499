import { Exception } from '@services/admin/dataObjects/Exception'

export enum EnumHaasOrderType {
  Limit,
  Market,
  StopLimit,
  StopMarket,
  TakeProfitLimit,
  TakeProfitMarket,
  TrailingStopLimit,
  TrailingStopMarket,
}

export const isDefaultOrder = (orderType: EnumHaasOrderType) => {
  switch (orderType) {
    case EnumHaasOrderType.Limit:
    case EnumHaasOrderType.Market:
      return true

    case EnumHaasOrderType.StopLimit:
    case EnumHaasOrderType.StopMarket:
    case EnumHaasOrderType.TakeProfitLimit:
    case EnumHaasOrderType.TakeProfitMarket:
    case EnumHaasOrderType.TrailingStopLimit:
    case EnumHaasOrderType.TrailingStopMarket:
      return false

    default:
      throw new Exception('')
  }
}

export const isMarketOrder = (orderType: EnumHaasOrderType) => {
  switch (orderType) {
    case EnumHaasOrderType.Limit:
    case EnumHaasOrderType.StopLimit:
    case EnumHaasOrderType.TakeProfitLimit:
    case EnumHaasOrderType.TrailingStopLimit:
      return false
    case EnumHaasOrderType.Market:
    case EnumHaasOrderType.StopMarket:
    case EnumHaasOrderType.TakeProfitMarket:
    case EnumHaasOrderType.TrailingStopMarket:
      return true

    default:
      throw new Exception('')
  }
}

export const orderTypeToString = (orderType: EnumHaasOrderType) => {
  switch (orderType) {
    case EnumHaasOrderType.Limit:
      return 'Limit'
    case EnumHaasOrderType.Market:
      return 'Market'

    case EnumHaasOrderType.StopLimit:
      return 'Stop-loss limit'
    case EnumHaasOrderType.StopMarket:
      return 'Stop-loss market'

    case EnumHaasOrderType.TakeProfitLimit:
      return 'Take-profit limit'
    case EnumHaasOrderType.TakeProfitMarket:
      return 'Take-profit market'

    case EnumHaasOrderType.TrailingStopLimit:
      return 'Trailing-stop limit'
    case EnumHaasOrderType.TrailingStopMarket:
      return 'Trailing-stop market'

    default:
      throw new Exception('')
  }
}
