import { _ } from './lodash'

export type ResolveType<T> = (value?: T) => void
export type RejectType = (reason?: any) => void
type FinallyType<T> = (state?: QueryResultState, value?: T | undefined | null, reason?: any) => void

type ResolvedType<T, TResult1> = (value: T) => TResult1 | PromiseLike<TResult1>
type RejectedType = (reason: any) => void

type QueryPromiseConstructor<T> = {
  (resolve: ResolveType<T>, reject: RejectType): any
}

type QueryResultState = 'running' | 'resolved' | 'rejected' | 'cancelled'

export class Query<T> {
  readonly [Symbol.toStringTag]: string

  guid = _.createGuid()

  private promise!: Promise<T>
  private reject!: (reason?: any) => void

  private resolveValue: T | null | undefined
  private rejectReason: string | null | undefined

  private state: QueryResultState = 'running'
  private isCancelled = false
  private onFinalize?: (value: any) => T

  constructor(props?: QueryPromiseConstructor<T>) {
    if (props) this.start(props)
  }

  protected start(props: QueryPromiseConstructor<T>) {
    this.promise = new Promise<T>((resolve, reject) => {
      this.reject = reject

      props(
        (value) => {
          if (this.isCancelled) return

          this.state = 'resolved'
          if (this.onFinalize) value = this.onFinalize(value)

          this.resolveValue = value
          resolve(value)
        },
        (reason) => {
          if (this.isCancelled) return

          this.state = 'rejected'
          this.rejectReason = reason
          reject(reason)
        }
      )
    })
  }

  then<T2 extends any>(resolved?: ResolvedType<T, T2>, rejected?: RejectedType): Query<T> {
    this.promise?.then(resolved, rejected)
    return this
  }

  catch(onRejected?: (reason: string) => void): Query<T> {
    this.promise?.catch(onRejected)
    return this
  }

  cancel(reason?: string) {
    this.isCancelled = true
    this.state = 'cancelled'
    this.reject(reason)
  }

  finally(onFinally: FinallyType<T>): Query<T> {
    this.promise.finally(() => {
      onFinally(this.state, this.resolveValue, this.rejectReason)
    })
    return this
  }

  finalize(onFinalize: (value: any) => T): Query<T> {
    this.onFinalize = onFinalize
    return this
  }

  resolved(resolved: (value: T | null | undefined) => void): Query<T> {
    return this.finally((state, value) => {
      if (state === 'resolved') resolved(value)
    })
  }

  rejected(rejected: (reason: string) => void): Query<T> {
    return this.finally((state, value, reason) => {
      if (state === 'rejected') rejected(reason)
    })
  }

  cancelled(cancelled: (reason: string) => void): Query<T> {
    return this.finally((state, value, reason) => {
      if (state === 'cancelled') cancelled(reason)
    })
  }

  dispose() {
    if (this.state === 'running') this.cancel()
  }
}
