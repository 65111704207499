const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

type Props = RegistrationOptions & {
  onSuccess?: (registration: ServiceWorkerRegistration) => void
  onUpdate?: (registration: ServiceWorkerRegistration) => void
}

const CHECK_INTERVAL_TIME = 1000 * 60 * 3 // 3 min

export function register(props : Props): void {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

      if (isLocalhost) {
        // This ors is running on localhost. Let's check if a service worker still exist not.
        checkLocalServiceWorker(swUrl, props)
      } else {
        // Is not localhost. Just register service worker
        registerServiceWorker(swUrl, props)
      }
    })
  }
}

export function unregister(): void {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister()
    })
  }
}


async function registerServiceWorker(swUrl: string, props : Props) {
  try {
    // Register the service worker
    const registration = await navigator.serviceWorker.register(swUrl, props);

    // Attach the update event
    registration.onupdatefound = () => onUpdateFound(registration, props);

    navigator.serviceWorker.addEventListener("activate", event => {
      caches.keys().then(function(cacheNames) {
        return Promise.all(
            cacheNames.filter(function(cacheName) {
              // Return true if you want to remove this cache,
              // but remember that caches are shared across
              // the whole origin
            }).map(function(cacheName) {
              return caches.delete(cacheName);
            })
        );
      })
    })


    // Do update
    await checkForUpdates(registration);

    // Start the interval for updates
    setInterval(() => checkForUpdates(registration), CHECK_INTERVAL_TIME)

  } catch (e) {
    console.error('Error during service worker registration:', e)
  }
}

async function checkForUpdates(registration : ServiceWorkerRegistration) {

  try {
    console.debug("Checking for updates...")
    await registration.update();

  } catch (e) {
    console.error(e);
  }
}

function onUpdateFound(registration : ServiceWorkerRegistration, props : Props) {

  const worker = registration.installing
  if (worker == null)
    return

  worker.onstatechange = () => {
    const { state } = worker;

    console.debug(`Service worker state changes to: ${state}`)

    switch (state) {
      case 'activated':
        break;
      case 'activating':
        break;
      case 'installing':
        break;
      case 'parsed':
        break;
      case 'redundant':
        break;

      case 'installed':
        if (navigator.serviceWorker.controller) {



          // Update detected and cache updated. Will serve new files after refresh
          console.log('Update detected. Please refresh for the best user experience.')

          // Execute callback
          props?.onUpdate && props.onUpdate(registration);

        } else {
          // Cache is updated and served
          console.log('Content is cached for offline use.')

          // Execute callback
          props?.onSuccess && props.onSuccess(registration);
        }
        break;
    }

  }
}


async function checkLocalServiceWorker(swUrl: string, props : Props) {

  try {
    // Check if the service worker can be found. If it can't reload the page.
    const response = await fetch(swUrl, { headers: { 'Service-Worker': 'script' } });

    // Ensure service worker exists, and that we really are getting a JS file.
    const contentType = response.headers.get('content-type')
    if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {

      // No service worker found. Probably a different app. Reload the page.
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister().then(() => {
          window.location.reload()
        })
      })

    } else {
      // Service worker found. Proceed as normal.
      registerServiceWorker(swUrl, props)
    }
  } catch (error) {
    console.log('No internet connection found. App is running in offline mode.')
  }
}
