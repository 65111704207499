import { HaasScriptSettingsForm } from '@components/HaasScriptSettings/HaasScriptSettingsForm'
import { EnumHaasLabAlgorithm } from '@dataObjects/enums/EnumHaasLabAlgorithm'
import { EnumHaasChartPricePlotStyle } from '@vendor/hxCharts/enums/EnumHaasChartPricePlotStyle'
import { ApiRequest, CancellationToken, EnumApiMethod } from '../api/rest/ApiRequest'
import { RestApiBase } from '../api/rest/RestApiBase'

export class LabsApi extends RestApiBase {
  private baseUrl = 'LabsAPI.php'

  getLabs(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_LABS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }

  getLabDetails(labId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_LAB_DETAILS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: { labId },
    })
  }

  createLab(name: string, scriptId: string, accountId: string, market: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CREATE_LAB',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        name,
        scriptId,
        accountId,
        market,
        interval: 1,
        style: EnumHaasChartPricePlotStyle.CandleStick,
      },
    })
  }

  cloneLab(labId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CLONE_LAB',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { labId },
    })
  }

  discardCancelReason(labId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DISCARD_CANCEL_REASON',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { labId },
    })
  }

  deleteLab(labId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_LAB',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { labId },
    })
  }

  changeLabScript(labId: string, scriptId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CHANGE_LAB_SCRIPT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        scriptId,
        labId,
      },
    })
  }

  updateLabDetails(labId: string, name: string, type: EnumHaasLabAlgorithm, config: any, settings: HaasScriptSettingsForm, parameters: any[]): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'UPDATE_LAB_DETAILS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        labId,
        name,
        type,
        config: JSON.stringify(config),
        settings: JSON.stringify(settings),
        parameters: JSON.stringify(parameters),
      },
    })
  }

  startLabExecution(labId: string, startUnix: number, endUnix: number, sendEmail: boolean): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'START_LAB_EXECUTION',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        labId,
        startUnix,
        endUnix,
        sendEmail,
      },
    })
  }

  cancelLabExecution(labId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CANCEL_LAB_EXECUTION',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { labId },
    })
  }

  getLabExecutionUpdate(labId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_LAB_EXECUTION_UPDATE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: { labId },
    })
  }

  getBacktestResult(labId: string, backtestId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BACKTEST_RESULT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        labId,
        backtestId,
      },
    })
  }

  getBacktestResultPage(labId: string, nextPageId: number, pageLength: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BACKTEST_RESULT_PAGE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        labId,
        nextPageId,
        pageLength,
      },
    })
  }

  getBacktestRuntime(labId: string, backtestId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BACKTEST_RUNTIME',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        labId,
        backtestId,
      },
    })
  }

  getBacktestChart(labId: string, backtestId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BACKTEST_CHART',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        labId,
        backtestId,
      },
    })
  }

  getBacktestLog(labId: string, backtestId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BACKTEST_LOG',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        labId,
        backtestId,
      },
    })
  }

  getFileCsv(backtestId: string, name : string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_FILE_CSV',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { backtestId, name },
    })
  }

  getFileJson(backtestId: string, name : string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_FILE_JSON',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { backtestId, name },
    })
  }
}
