import { CacheObject } from '@dataObjects/CacheObject'
import { PeriodType } from '@pages/Home/Components/HomeTimelinePeriod'
import { InterfaceService } from '@services/interface/InterfaceService'
import { UserStatisticReportType } from '@services/interface/objects/UserStatisticReportType'

export class UserActivityCache extends CacheObject<UserStatisticReportType> {
  private api: InterfaceService
  private period: PeriodType

  constructor(api: InterfaceService, period : PeriodType) {
    super(5 * 1000)
    this.api = api
    this.period = period
  }

  protected async loadValue() : Promise<UserStatisticReportType> {
    switch (this.period) {
      case 'day':
        return this.api.getDailyUserStatistics(this.token)
      case 'week':
        return this.api.getWeeklyUserStatistics(this.token)
      case 'month':
      default:
        return this.api.getMonthlyUserStatistics(this.token)
    }
  }
}