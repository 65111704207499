import { ExchangeReportConfig } from '@admin/pages/ExchangeReports/ExchangeReportPage'
import { ApiRequest, CancellationToken, EnumApiMethod } from '../api/rest/ApiRequest'
import { RestApiBase } from '../api/rest/RestApiBase'
import { EnumServiceType } from './enums/EnumServiceType'

export class AdministratorApi extends RestApiBase {
  private baseUrl = 'AdministratorAPI.php'

  getServices(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_SERVICES',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }

  getAllServiceReport(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ALL_SERVICE_REPORT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }

  getServiceAdminReport(serviceId: string, serviceType: EnumServiceType, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_SERVICE_ADMIN_REPORT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: { serviceId, serviceType },
    })
  }

  getAdminStatistics(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ADMIN_STATISTICS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }

  // SERVICES ===============================================================================================
  doStartService(serverName: string, serviceName: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'START_SERVICE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        server: serverName,
        service: serviceName,
      },
    })
  }

  doRestartService(serverName: string, serviceName: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'RESTART_SERVICE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        server: serverName,
        service: serviceName,
      },
    })
  }

  doStopService(serverName: string, serviceName: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'STOP_SERVICE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        server: serverName,
        service: serviceName,
      },
    })
  }

  doDeleteService(machineId: string, serviceId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_SERVICE_REPORT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        machineId,
        serviceId,
      },
    })
  }

  doRequestStatusService(serverName: string, serviceName: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'STATUS_SERVICE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        server: serverName,
        service: serviceName,
      },
    })
  }

  // LICENSES ===============================================================================================
  getBlockedLicenses(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BLOCKED_LICENSES',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }

  doLicenseReset(licenseKey: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'RESET_LICENSE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        license: licenseKey,
      },
    })
  }

  getValidLicenses(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_VALID_LICENSES',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }

  getLicenseDetails(licenseKey: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_LICENCE_USAGE_DETAILS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        license: licenseKey,
      },
    })
  }

  // USERS ===============================================================================================
  getUnconfirmedUsers(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_UNCONFIRMED_USERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }

  getBannedUsers(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BANNED_USERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }

  getAdministratorUsers(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ADMINISTRATORS_USERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }

  getPartners(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_PARTNERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }

  getWhiteLabelPartnersProfiles(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_WL_PARTNERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }

  getDevelopers(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_DEVELOPERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }

  getAllUsers(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ALL_USERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }

  getUserNotifications(targetUserId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_USER_NOTIFICATIONS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        targetUserId: targetUserId,
      },
    })
  }

  lockUser(userid: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'LOCK_USER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        targetUserId: userid,
      },
    })
  }

  banUser(userid: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'BAN_USER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        targetUserId: userid,
      },
    })
  }

  unbanUser(userid: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'UNBAN_USER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        targetUserId: userid,
      },
    })
  }

  resetUserPassword(userid: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'PASSWORD_RESET',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        targetUserId: userid,
      },
    })
  }

  reactivateUser(userid: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'REACTIVATE_USER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        targetUserId: userid,
      },
    })
  }

  fullLoginResetUser(userid: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'RESETLOGIN_USER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        targetUserId: userid,
      },
    })
  }

  deleteUser(userid: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_USER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        targetUserId: userid,
      },
    })
  }

  makeAdminUser(userid: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'MAKE_ADMINISTRATOR',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        targetUserId: userid,
      },
    })
  }

  makePartnerUser(userid: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'MAKE_PARTNER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        targetUserId: userid,
      },
    })
  }

  makeWhiteLabelPartnerUser(userid: string, partnerName : string, exchanges : string[]): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'MAKE_WL_PARTNER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        targetUserId: userid,
        partnerName,
        exchanges : exchanges.join(",")
      },
    })
  }

  makeNormalUser(userid: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'MAKE_NORMALUSER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        targetUserId: userid,
      },
    })
  }

  // USER DETAILS ===============================================================================================
  getAllowedDevices(targetUser: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ALLOWED_DEVICES',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        targetUserId: targetUser,
      },
    })
  }

  doClearAllowedDevices(userid: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CLEAR_ALLOWED_DEVICES',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        targetUserId: userid,
      },
    })
  }

  getUserStoreOrders(userId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_USER_SHOP_ORDERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        targetuserid: userId,
      },
    })
  }

  getUserLicenses(userId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_USER_LICENSES',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        targetuserId: userId,
      },
    })
  }

  // NEWSFEEDS ===============================================================================================
  doDeleteNewsFeed(feedId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_NEWSFEED',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        feedid: feedId,
      },
    })
  }

  doUpdateNewsFeed(feedId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'UPDATE_NEWSFEED',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        feedid: feedId,
      },
    })
  }

  addNewsfeed(name: string, grouping: string, type: number, url: string, isFeatured : boolean): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'NEW_NEWSFEED',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        name, grouping, type, url, isFeatured
      },
    })
  }

  editNewsFeed(feedId: string, name: string, grouping: string, type: number, url: string, isFeatured : boolean): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_NEWSFEED',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        feedId, name, grouping, type, url, isFeatured
      },
    })
  }

  // VIDEOSTREAMS ============================================================================================
  doDeleteVideostream(feedId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_VIDEOSTREAM',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        streamid: feedId,
      },
    })
  }

  doNewVideoStream(title: string, url: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'NEW_NEWSFEED',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        name: title,
        url: url,
      },
    })
  }

  doEditVideoStreams(feedId: string, title: string, url: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_NEWSFEED',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        feedid: feedId,
        name: title,
        url: url,
      },
    })
  }

  // SCRIPTS ===============================================================================================
  getUserScripts(targetUserId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_USER_SCRIPTS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        targetUserId: targetUserId,
      },
    })
  }

  getScript(scriptId: string, typeStrategy: number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_SCRIPT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        scriptId: scriptId,
        runtimeType: typeStrategy,
      },
    })
  }

  doEditScript(
      targetUserId: string,
      scriptId: string,
      typeStrategy: number,
      isCommand: boolean,
      name: string,
      description: string,
      script: string,
      token: CancellationToken
  ): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_SCRIPT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        scriptId: scriptId,
        runtimeType: typeStrategy,
        isCommand: isCommand,
        targetUserId: targetUserId,
        name: name,
        description: description,
        compressedScript: script,
      },
    })
  }

  doAddScript(targetUserId: string, typeStrategy: number, name: string, description: string, script: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ADD_SCRIPT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        runtimeType: typeStrategy,
        targetUserId: targetUserId,
        name: name,
        description: description,
        compressedScript: script,
      },
    })
  }

  doDeleteScript(targetUserId: string, scriptId: string, typeStrategy: number, isCommand: boolean, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'REMOVE_SCRIPT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        targetUserId: targetUserId,
        scriptId: scriptId,
        runtimeType: typeStrategy,
        isCommand: isCommand,
      },
    })
  }

  // PRICEHISTORY ============================================================================================
  buildPriceHistory(market: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'BUILD_PRICE_HISTORY',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: { market },
    })
  }

  // WEBSHOP ===============================================================================================
  getWooProfile(targetuserId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_USER_WOOPROFILE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { targetuserId },
    })
  }

  getAllShopOrders(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ALL_SHOP_ORDERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }

  // To be removed
  getUserShopOrders(targetUserId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_USER_SHOP_ORDERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: { targetUserId },
    })
  }

  getAllProducts(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ALL_PRODUCTS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }

  getAllOldShopOrders(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_OLD_ORDERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }


  // Reporting ============================================================================================

  getCloudReport(config: ExchangeReportConfig, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_CLOUD_REPORTS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        ...config,
        exchanges: config.exchanges.join(','),
      },
    })
  }

  getExchangeReports(config: ExchangeReportConfig, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_EXCHANGE_REPORTS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        ...config,
        exchanges: config.exchanges.join(','),
      },
    })
  }

  getExchangeReportCharts(config: ExchangeReportConfig, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_EXCHANGE_REPORT_CHARTS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        ...config,
        exchanges: config.exchanges.join(','),
      },
    })
  }

  // Pols ============================================================================================

  configurePoll(pollId: string, title: string, isOpen: boolean, options: string[], resetVotes: boolean): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CONFIGURE_POLL',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        pollId,
        title,
        isOpen,
        resetVotes,
        options: options.join(';'),
      },
    })
  }

  // Pols ============================================================================================

  newPromotion(text: string, link: string, imageDataUrl: string, popupImageDataUrl: string, startDate : number, endDate : number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'NEW_PROMOTION',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        text,
        link,
        imageDataUrl,
        popupImageDataUrl,
        startDate,
        endDate
      },
    })
  }

  editPromotion(promotionId: number, text: string, link: string, imageDataUrl: string, popupImageDataUrl: string, startDate : number, endDate : number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_PROMOTION',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        promotionId,
        text,
        link,
        imageDataUrl,
        popupImageDataUrl,
        startDate,
        endDate
      },
    })
  }

  deletePromotion(promotionId: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_PROMOTION',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        promotionId,
      },
    })
  }


  getWhiteLabelPartners(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_WHITE_LABEL_PARTNERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { },
    })
  }

  getWhiteLabelReport(partnerIds: string[]): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_WHITE_LABEL_REPORTS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        partnerIds : partnerIds.join(","),
      },
    })
  }

  editWhiteLabelPartnerExchanges(partnerId : string, exchanges : string[]): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'UPDATE_WHITE_LABEL_EXCHANGES',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        partnerId,
        exchanges : exchanges.join(",")
      },
    })
  }

  getWhiteLabelPartnerProfile(targetUserId : string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_PROFILE',
      endPoint: 'WhiteLabelAPI.php',
      authenticator: this.authenticator,
      arguments: {
        targetUserId
      },
    })
  }

  getRuntimeLogbook(botId: string, nextPageId: number, pageLength: number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_RUNTIME_LOGBOOK',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        botId,
        nextPageId,
        pageLength,
      },
    })
  }

}
