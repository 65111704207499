import { BacktestProfile } from '@pages/WebEditor/Components/Remote/Components/Forms/WebEditorBacktestPeriodForm'
import { AppThemeName } from '@styling/themes/themes'
import { _ } from '@utils/lodash'
import { EnumHaasChartPricePlotStyle } from '@vendor/hxCharts/enums/EnumHaasChartPricePlotStyle'
import { ApiAuthenticator } from '../api/ApiAuthenticator'
import { LoginApi } from '../login/LoginApi'
import { ServiceController, ServiceControllerConstructorType } from '../ServiceController'

export type DefaultSelectionType = {
  accountId: string
  accountMarket: string
  exchange: string
  exchangeMarket: string
  interval: number
  chartStyle: EnumHaasChartPricePlotStyle
}

export type TickerBarAlignment = 'flex-start' | 'center' | 'flex-end'

export class SettingsService {
  private api: LoginApi

  favoriteAccounts: UserSettingsList<string>
  favoriteMarkets: UserSettingsList<string>
  favoriteScripts: UserSettingsList<string>
  favoriteCommands: UserSettingsList<string>
  backtestProfiles: UserSettingsList<BacktestProfile>

  language: UserSetting<string>
  theme: UserSetting<AppThemeName>
  tickerBarAlignment: UserSetting<TickerBarAlignment>
  defaultAccountId: UserSetting<string>
  defaultAccountMarket: UserSetting<string>
  defaultExchange: UserSetting<string>
  defaultExchangeMarket: UserSetting<string>
  defaultInterval: UserSetting<number>
  defaultChartStyle: UserSetting<EnumHaasChartPricePlotStyle>

  constructor(props: ServiceControllerConstructorType) {
    this.api = new LoginApi(props.authenticator, props.controller)

    this.favoriteAccounts = new UserSettingsList<string>(props.controller, props.authenticator, `FAVORITE-ACCOUNTS`)
    this.favoriteMarkets = new UserSettingsList<string>(props.controller, props.authenticator, `FAVORITE-MARKETS`)

    this.favoriteScripts = new UserSettingsList<string>(props.controller, props.authenticator, `FAVORITE-SCRIPTS`)
    this.favoriteCommands = new UserSettingsList<string>(props.controller, props.authenticator, `FAVORITE-COMMANDS`)
    this.backtestProfiles = new UserSettingsList<BacktestProfile>(props.controller, props.authenticator, `BACKTEST-PROFILES`)

    this.language = new UserSetting<string>(this.api, 'LANGUAGE', 'EN')
    this.theme = new UserSetting<AppThemeName>(this.api, 'THEME-2', 'default')
    this.tickerBarAlignment = new UserSetting<TickerBarAlignment>(this.api, 'TICKER_BAR_ALIGNMENT', 'flex-start')

    this.defaultAccountId = new UserSetting<string>(this.api, 'DEFAULT_ACCOUNT', '')
    this.defaultAccountMarket = new UserSetting<string>(this.api, 'DEFAULT_ACCOUNT_MARKET', '')

    this.defaultExchange = new UserSetting<string>(this.api, 'DEFAULT_EXCHANGE', '')
    this.defaultExchangeMarket = new UserSetting<string>(this.api, 'DEFAULT_EXCHANGE_MARKET', '')

    this.defaultInterval = new UserSetting<number>(this.api, 'DEFAULT_INTERVAL', 15)
    this.defaultChartStyle = new UserSetting<EnumHaasChartPricePlotStyle>(this.api, 'DEFAULT_CHART_STYLE', EnumHaasChartPricePlotStyle.CandleStickHLC)
  }

  async hasUserVisitedPage(page: string): Promise<boolean> {
    const setting = new UserSetting<string>(this.api, `DID_USER_VISIT_${page.toUpperCase()}`, 'false')
    await setting.fetch()

    return setting.get() === 'true'
  }

  async userVisitedPage(page: string): Promise<void> {
    const setting = new UserSetting<string>(this.api, `DID_USER_VISIT_${page.toUpperCase()}`, 'false')
    await setting.set('true')
  }

  async get(key : string) : Promise<string> {
    return this.api.getSetting(key);
  }

  async setSimTradingConfig(value : boolean) : Promise<void> {
    return this.api.setSimTradingConfig(value);
  }
}

export class UserSetting<T extends string | number> {
  private api: LoginApi
  private key: string
  private value: T

  constructor(api: LoginApi, key: string, defaultValue: T) {
    this.api = api
    this.key = key
    this.value = defaultValue
  }

  async fetch() {
    try {
      const response = await this.api.getSetting(this.key)
      if (response === undefined || response === null || response === '') return

      this.value = response
    } catch (e) {}
  }

  get(): T {
    if (!isNaN(Number(this.value))) return Number(this.value) as T

    return this.value
  }

  getNumber(): number {
    return Number(this.value)
  }

  async set(value: T) {
    // SET SETTING
    await this.api.setSetting(this.key, value?.toString() ?? '')
    this.value = value
  }
}

export class UserSettingsList<T> {
  private api: LoginApi
  private list: T[] = []

  constructor(controller: ServiceController, authenticator: ApiAuthenticator, private key: string) {
    this.api = new LoginApi(authenticator, controller)
  }

  async fetch(): Promise<T[]> {
    try {
      if (!this.list?.length) this.list = JSON.parse(await this.api.getSetting(this.key))

      return [...this.list]
    } catch (e) {
      return (this.list = [])
    }
  }

  get(): T[] {
    return this.list
  }

  has(item: T) {
    return this.list.includes(item)
  }

  async add(item: T) {
    await this.fetch()

    this.list.push(item)
    return this.api.setSetting(this.key, JSON.stringify(this.list))
  }

  async edit(editFn: (item: T) => any) {
    await this.fetch()

    _.each(this.list, editFn)
    return this.api.setSetting(this.key, JSON.stringify(this.list))
  }

  async remove(item: T) {
    await this.fetch()

    return this.filter((item1) => item !== item1)
  }

  async filter(filterFn: (item: T) => boolean) {
    await this.fetch()

    let hasMatches = true
    while (hasMatches) {
      hasMatches = false

      // eslint-disable-next-line no-loop-func
      _.each(this.list, (value, key) => {
        if (filterFn(value)) return

        this.list.splice(Number(key), 1)
        hasMatches = true
        return false
      })
    }

    await this.api.setSetting(this.key, JSON.stringify(this.list))
  }
}
