import { EnumLicenseTypes } from '@dataObjects/enums/EnumLicenseTypes'

export enum EnumProductType {
  License = 0,
  Signal = 1,
  Strategy = 2,
  LicenseExtension = 3,
  CreditOption = 4,
  RenewOption = 5,
  UpgradeOption = 6,
  CustomRow = 7
}

export enum EnumProductStatus {
  Created = 0,
  Visible = 1,
  Disabled = 2,
}

type LicenseProfile = {
  licenseName: string
  rights: EnumLicenseTypes
  enterprise: boolean
  maxBots: number
  maxDashboards: number
  maxBacktestMonths: number
  maxLabsMonths: number
  hireSignalsEnabled: boolean
  haasLabsEnabled: boolean
  resellSignalsEnabled: boolean
  marketDetailsEnabled: boolean
  localApiEnabled: boolean
  scriptedExchangesEnabled: boolean
  machinelearningEnabled: boolean
  maxSimulatedAccounts: number
  maxRealAccounts: number
  maxOpenOrders: number
}

export class HaasShopProduct {
  id: number
  sku: string
  name: string
  description: string
  image: string
  userid: string
  producttype: EnumProductType
  status: EnumProductStatus
  price: number
  salesPrice: number
  lifetime: number
  productDiscount: number
  volumediscount: number
  oneTimeProduct: boolean
  licenseProfile: LicenseProfile

  constructor(jsonData: any) {
    this.id = jsonData['Id']
    this.sku = jsonData['ReferenceId']
    this.name = jsonData['Name']
    this.description = jsonData['Description']
    this.image = jsonData['ImageBlob']
    this.userid = jsonData['UserId']
    this.producttype = jsonData['ProductType']
    this.status = jsonData['Status']
    this.price = jsonData['PriceUsd']
    this.salesPrice = jsonData['SalesPrice']
    this.lifetime = jsonData['DaysLifetime']
    this.productDiscount = jsonData['ProductDiscount']
    this.volumediscount = jsonData['VolumeDiscount']
    this.oneTimeProduct = jsonData['OneTimeProduct']
    this.licenseProfile = jsonData['LicenseProfile'] && {
      licenseName: jsonData['LicenseProfile']['LicenseName'],
      rights: jsonData['LicenseProfile']['Rights'],
      enterprise: jsonData['LicenseProfile']['Enterprise'],
      maxBots: jsonData['LicenseProfile']['MaxBots'],
      maxDashboards: jsonData['LicenseProfile']['MaxDashboards'],
      maxBacktestMonths: jsonData['LicenseProfile']['MaxBacktestMonths'],
      maxLabsMonths: jsonData['LicenseProfile']['MaxLabsMonths'],
      hireSignalsEnabled: jsonData['LicenseProfile']['HireSignalsEnabled'],
      haasLabsEnabled: jsonData['LicenseProfile']['HaasLabsEnabled'],
      resellSignalsEnabled: jsonData['LicenseProfile']['ResellSignalsEnabled'],
      marketDetailsEnabled: jsonData['LicenseProfile']['MarketDetailsEnabled'],
      localApiEnabled: jsonData['LicenseProfile']['LocalAPIEnabled'],
      scriptedExchangesEnabled: jsonData['LicenseProfile']['ScriptedExchangesEnabled'],
      machinelearningEnabled: jsonData['LicenseProfile']['MachinelearningEnabled'],
      maxSimulatedAccounts: jsonData['LicenseProfile']['MaxSimulatedAccounts'],
      maxRealAccounts: jsonData['LicenseProfile']['MaxRealAccounts'],
      maxOpenOrders: jsonData['LicenseProfile']['MaxOpenOrders'],
    }
  }
}
