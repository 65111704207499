import { HaasBlogCategory } from '@dataObjects/public/HaasBlogCategory'
import { HaasBlogComment } from '@dataObjects/public/HaasBlogComment'
import { HaasBlogReport } from '@dataObjects/public/HaasBlogReport'
import { HaasBlogTopic } from '@dataObjects/public/HaasBlogTopic'
import { CancellationToken } from '@services/api/rest/ApiRequest'
import { ScriptBlogApi } from '@services/scriptblog/ScriptBlogApi'
import _ from '@utils/lodash'
import { PaginatedResponse } from '@utils/types/PaginatedResponse'
import { ServiceController, ServiceControllerConstructorType } from '../ServiceController'

export class ScriptBlogService {
  private api: ScriptBlogApi
  private controller: ServiceController

  forumCategories : Record<number, string> = { };

  constructor(props: ServiceControllerConstructorType) {
    this.controller = props.controller
    this.api = new ScriptBlogApi(props.authenticator, this.controller)
  }

  getCategoryById(categoryId : number) : string {
    return this.controller.initData?.forumCategories[categoryId]
        ?? this.forumCategories[categoryId]
        ?? "";
  }

  getCategoryIdByName(category : string) : number {

    let categoryId = -1;
    _.each(this.controller.initData?.forumCategories, (item, key) => {
      if (item === category)
        categoryId = Number(key);
    })

    if (categoryId >= 0)
      return categoryId;

    _.each(this.controller.initData?.forumCategories, (item, key) => {
      if (item === category)
        categoryId = Number(key);
    })

    return categoryId;
  }

  async getAllCategories(): Promise<HaasBlogCategory[]> {
    const response = await this.api.getAllCategories()
    const categories = response.map((c: any) => new HaasBlogCategory(c)) as HaasBlogCategory[];

    _.each(categories, category => {
      this.forumCategories[category.categoryId] = category.category;
    })

    return categories;
  }

  async getCategory(categoryId: number): Promise<HaasBlogCategory> {
    const response = await this.api.getCategory(categoryId)
    return new HaasBlogCategory(response)
  }

  async addCategory(title: string): Promise<number> {
    const { forumCategories } = this.controller.initData
    const response = await this.api.addCategory(title)
    if (response)
      forumCategories[Number(response)] = title;

    return response
  }

  async editCategory(categoryId: number, title: string): Promise<boolean> {
    return this.api.editCategory(categoryId, title)
  }

  async deleteCategory(categoryId: number): Promise<boolean> {
    return this.api.deleteCategory(categoryId)
  }


  async getLatestTopics(pageLength: number, nextPageId: number, token: CancellationToken): Promise<PaginatedResponse<HaasBlogTopic>> {
    const response = await this.api.getLatestTopics(pageLength, nextPageId, token)
    return {
      items: response['I'].map((c: any) => new HaasBlogTopic(c)),
      nextPageId: response['NP'],
    }
  }

  async getLatestCommentedTopics(pageLength: number, nextPageId: number, token: CancellationToken): Promise<PaginatedResponse<HaasBlogTopic>> {
    const response = await this.api.getLatestCommentedTopics(pageLength, nextPageId, token)
    return {
      items: response['I'].map((c: any) => new HaasBlogTopic(c)),
      nextPageId: response['NP'],
    }
  }


  async getTopicsBySearch(searchKey: string, pageLength: number, nextPageId: number, token : CancellationToken): Promise<PaginatedResponse<HaasBlogTopic>> {
    const response = await this.api.getTopicsBySearch(searchKey, pageLength, nextPageId, token)
    return {
      items: response['I'].map((c: any) => new HaasBlogTopic(c)),
      nextPageId: response['NP'],
    }
  }

  async getTopicsByUserId(searchUserId: string, pageLength: number, nextPageId: number, token : CancellationToken): Promise<PaginatedResponse<HaasBlogTopic>> {
    const response = await this.api.getTopicsByUserId(searchUserId, pageLength, nextPageId, token)
    return {
      items: response['I'].map((c: any) => new HaasBlogTopic(c)),
      nextPageId: response['NP'],
    }
  }

  async getTopicsByCategoryId(categoryId: number, pageLength: number, nextPageId: number, token : CancellationToken): Promise<PaginatedResponse<HaasBlogTopic>> {
    const response = await this.api.getTopicsByCategoryId(categoryId, pageLength, nextPageId, token)
    return {
      items: response['I'].map((c: any) => new HaasBlogTopic(c)),
      nextPageId: response['NP'],
    }
  }



  async getTopic(topicId: number, token : CancellationToken): Promise<HaasBlogTopic> {
    const response = await this.api.getTopic(topicId, token);
    return new HaasBlogTopic(response)
  }

  async addTopic(categoryId: number, title: string, message: string): Promise<HaasBlogTopic> {
    const response = await this.api.addTopic(categoryId, title, message)
    return new HaasBlogTopic(response)
  }

  async editTopic(topicId: number, title: string, message: string): Promise<boolean> {
    return this.api.editTopic(topicId, title, message)
  }

  async moveTopic(topicId: number, categoryId: number): Promise<boolean> {
    return this.api.moveTopic(topicId, categoryId)
  }


  async deleteTopic(topicId: number): Promise<boolean> {
    return this.api.deleteTopic(topicId)
  }


  async getComments(topicId: number, currentPage: number, pageLength: number, token : CancellationToken): Promise<HaasBlogComment[]> {
    const response = await this.api.getComments(topicId, currentPage, pageLength, token)
    return response.map((c : any) => new HaasBlogComment(c))
  }

  async addComment(categoryId: number, topicId: number, message: string): Promise<HaasBlogComment> {
    const response = await this.api.addComment(categoryId, topicId, message)
    return new HaasBlogComment(response)
  }

  async editComment(commentId: number, message: string): Promise<boolean> {
    return this.api.editComment(commentId, message)
  }

  async deleteComment(commentId: number): Promise<boolean> {
    return this.api.deleteComment(commentId)
  }


  async addImage(parentId: number, imageBlob: string): Promise<number> {
    return Number(await this.api.addImage(parentId, imageBlob))
  }

  async deleteImage(imageId: number): Promise<boolean> {
    return this.api.deleteImage(imageId)
  }


  async upVoteTopic(topicId: number): Promise<boolean> {
    return this.api.upVoteTopic(topicId)
  }

  async reportPost(postId : number, reason : string) : Promise<boolean> {
    return this.api.reportPost(postId, reason)
  }

  async getReports() : Promise<HaasBlogReport[]> {
    const response = await this.api.getReports()
    return response.map((c : any) => new HaasBlogReport(c))
  }

  async handleReport(reportId : number, deletePost : boolean) : Promise<boolean> {
    return this.api.handleReport(reportId, deletePost)
  }

  async toggleUserBan(recordId : number) : Promise<boolean> {
    return this.api.toggleUserBan(recordId)
  }
}
