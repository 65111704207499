import { CloudInitData } from '@dataObjects/public/CloudInitData'
import { ScriptExecutionReport } from './ScriptExecutionReport'

export class ScriptExecutionServiceReport {
  machineId: string
  serviceId: string
  serviceName: string

  executionReports: ScriptExecutionReport[]

  constructor(jsonData: any, initData: CloudInitData) {
    this.machineId = jsonData['MID']
    this.serviceId = jsonData['SID']
    this.serviceName = jsonData['SN']

    this.executionReports = jsonData['ER'].map((c: any) => new ScriptExecutionReport(c, initData, this.machineId, this.serviceId, this.serviceName))
  }
}
