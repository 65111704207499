import { EnumHaasLabStatus } from '@dataObjects/enums/EnumHaasLabStatus'

export type UserLabExecutionUpdate = {
  labId: string
  population: number
  generation: number
  currentGeneration: number
  currentPopulation: number
  totalBacktests: number
  finishedBacktests: number
  runningBacktests: Record<string, string>
  status: EnumHaasLabStatus
  queueNumber: number
}

export function parseUserLabExecutionUpdate(jsonData: any): UserLabExecutionUpdate {
  return {
    labId: jsonData['LID'],
    status: jsonData['S'],
    queueNumber: jsonData['Q'],
    population: jsonData['P'],
    generation: jsonData['G'],
    currentGeneration: jsonData['CG'],
    currentPopulation: jsonData['CP'],
    totalBacktests: jsonData['TB'],
    finishedBacktests: jsonData['FB'],
    runningBacktests: jsonData['RB'],
  }
}
