import { LanguageEnglish } from './LanguageEnglish'

export class Language {
  static getLanguage(language: string): LanguageEnglish {
    const newLanguage = new LanguageEnglish()
    newLanguage.load(newLanguage)

    switch (language) {
      case 'EN':
        newLanguage.load(newLanguage)
        break
      case 'NL':
        newLanguage.load(newLanguage)
    }

    return newLanguage
  }
}
