export class UserDevice {
  deviceId: string
  ipAddress: string
  platformId: string
  lastSeen: number

  constructor(jsonData: any) {
    this.deviceId = jsonData['DeviceId']
    this.ipAddress = jsonData['IpAddress']
    this.platformId = jsonData['PlatformId']
    this.lastSeen = jsonData['LastSeen']
  }
}
