export class CryptoTransaction {
  guid: string
  amount: number
  btcprice: number
  usdprice: number
  timestamp: number
  isdeposit: boolean

  constructor(jsonData: any) {
    this.guid = jsonData['Guid']
    this.amount = jsonData['Amount']
    this.btcprice = jsonData['BtcPrice']
    this.usdprice = jsonData['UsdPrice']
    this.timestamp = jsonData['Timestamp']
    this.isdeposit = jsonData['IsDeposit']
  }
}
