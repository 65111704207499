import { CancellationToken } from '@services/api/rest/ApiRequest'
import ExceptionHandler from '@utils/ExceptionHandler'

export abstract class CacheObject<T> {
  protected value?: T
  protected query?: Promise<T | undefined>
  protected token = new CancellationToken()

  private validForInMilliSeconds: number
  private validUntil = 0

  protected constructor(validForInSeconds: number) {
    this.validForInMilliSeconds = validForInSeconds * 1000
  }

  async getValue(): Promise<T> {
    const isValid = !!this.value && this.validUntil > Date.now()
    if (isValid) return this.value as T

    await this.refreshValue()
    return this.value as T
  }

  protected async refreshValue() {
    try {
      if (!this.query) this.query = this.loadValue()

      try {
        const data = await this.query
        if (!data) return

        this.value = data
        this.validUntil = Date.now() + this.validForInMilliSeconds
      } finally {
        this.query = undefined
      }
    } catch (e) {
      ExceptionHandler.LogException(e)
    }
  }

  protected abstract loadValue(): Promise<T | undefined>
}
