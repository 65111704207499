import { UserLicense } from '@dataObjects/private/UserLicense'

export enum EnumLoginResult {
  Unapproved = -1,
  Success = 0,
  InvalidUsernameOrPassword = 1,
  Invalid2FACode = 2,
  UnknownDevice = 3,
  Failed = 4,
  TwoFANeeded = 5,
  Blocked = 6,
  NotAllowed = 7, // not used!
  ActivationNeeded = 8,
  Banned = 9
}

export class UserLoginResult {
  result: EnumLoginResult

  userId?: string
  username?: string
  interfaceSecret?: string
  license?: UserLicense

  isProductSeller?: boolean
  isAffiliate?: boolean

  deviceId?: string
  supportHash?: string

  constructor(jsonData: any) {
    this.result = jsonData['R']
    this.deviceId = jsonData['DID']

    if (jsonData['D']) {
      this.userId = jsonData['D']['UserId']
      this.username = jsonData['D']['Username']
      this.interfaceSecret = jsonData['D']['InterfaceSecret']
      this.license = new UserLicense(jsonData['D']['LicenseDetails'])
      this.isProductSeller = jsonData['D']['IsProductSeller']
      this.isAffiliate = jsonData['D']['IsAffiliate']
      this.supportHash = jsonData['D']['SupportHash']
    }
  }
}
