import { EnumWebEditorTabExecutionState } from '@pages/WebEditor/Components/Controller/WebEditorTab'

export type UserLabsBacktestProgressUpdate = {
  labId: string
  backtestId: string
  messageId: number
  state: EnumWebEditorTabExecutionState
  progress: number
  message: string
}

export function parseUserLabsBacktestProgressUpdate(jsonData: any): UserLabsBacktestProgressUpdate {
  return {
    labId: jsonData['LID'],
    backtestId: jsonData['BID'],
    messageId: jsonData['MID'],
    state: jsonData['S'],
    progress: jsonData['P'],
    message: jsonData['M'],
  }
}
