export class OperationExchangeStatistics {
  exchangeCode: string
  totalAccountCreated: number

  ordersExecuted: Record<string, number>
  tradesExecuted: Record<string, number>

  marketVolume: Record<string, number>
  contractVolume: Record<string, number>
  currencyVolume: Record<string, number>

  constructor(jsonData: any) {
    this.exchangeCode = jsonData['EC']
    this.totalAccountCreated = jsonData['TAC']
    this.ordersExecuted = jsonData['OE']
    this.tradesExecuted = jsonData['TE']
    this.marketVolume = jsonData['MV']
    this.contractVolume = jsonData['COV']
    this.currencyVolume = jsonData['CUV']
  }
}
