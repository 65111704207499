export enum EnumHaasChartShapeType {
  Cross = 1600,
  Add = 1601,
  Circle = 1602,
  TriangleUp = 1603,
  TriangleDown = 1604,
  Square = 1605,
  Diamond = 1606,
  Text = 1607,
  Dash = 1608,
}
