import { ApiRequest, CancellationToken, EnumApiMethod } from '../api/rest/ApiRequest'
import { RestApiBase } from '../api/rest/RestApiBase'

export class SetupApi extends RestApiBase {
  private baseUrl = 'SetupAPI.php'

  setupLicenseKey(email: string, licensekey: string, token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'SETUP_LICENSEKEY',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: { email, licensekey },
    })
  }

  clearConsoleLogbook(token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'CLEAR_CONSOLE_LOGBOOK',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: {},
    })
  }

  getStoredPriceData(token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'GET_STORED_PRICEDATA',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: {},
    })
  }

  clearAllPriceHistory(token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'CLEAR_ALL_PRICE_HISTORY',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: { },
    })
  }

  clearExchangePriceHistory(exchange: string, token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'CLEAR_EXCHANGE_PRICE_HISTORY',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: { exchange },
    })
  }

  clearMarketPriceHistory(market: string, token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'CLEAR_MARKET_PRICE_HISTORY',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: { market },
    })
  }

  clearAllDashboards(token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'CLEAR_ALL_DASHBOARDS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: {},
    })
  }

  clearAllBots(token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'CLEAR_ALL_BOTS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: {},
    })
  }

  clearAllAccounts(token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'CLEAR_ALL_ACCOUNTS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: {},
    })
  }

  clearAllSettings(token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'CLEAR_ALL_SETTINGS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: {},
    })
  }

  getHistoryStatus(token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'GET_HISTORY_STATUS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: {},
    })
  }

  setHistoryDepth(market: string, monthDepth: number, token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'SET_HISTORY_DEPTH',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: { market, monthDepth },
    })
  }

  removeTrackedMarket(market: string, token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'REMOVE_TRACKED_MARKET',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: { market },
    })
  }

  exportDatabase(token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'EXPORT_DATABASE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: {},
    })
  }

  importDatabase(token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'IMPORT_DATABASE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: {},
    })
  }

  migrateDatabase(token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'MIGRATE_DATABASE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: {},
    })
  }
}
