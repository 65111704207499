import { HaasBlogImage } from '@dataObjects/public/HaasBlogImage'
import { IHaasBlogMessage } from '@dataObjects/public/HaasBlogPost'

export class HaasBlogComment implements IHaasBlogMessage{
  commentId: number


  postedAt: number
  updatedAt : number;

  username: string

  avatarColor: string
  avatarCharacter = "";

  message: string
  attachments : HaasBlogImage[] = [];

  canEdit: boolean

  constructor(jsonData : any) {
    this.commentId = jsonData['CID']


    this.postedAt = jsonData['PA']
    this.updatedAt = jsonData["UA"];

    this.username = jsonData['U']
    this.message = jsonData['M']
    this.attachments = jsonData['A'].map((c: any) => new HaasBlogImage(c))

    this.avatarColor = HaasBlogComment.stringToColour(this.username)
    if (this.username && this.username.length)
      this.avatarCharacter = this.username[0].toUpperCase();

    this.canEdit = jsonData['CE']
  }

  static stringToColour(str : string) {
    if (!str)
      return "#888"

    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }
}
