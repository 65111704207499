import { Exception } from '@services/admin/dataObjects/Exception'
import { DefaultTheme } from 'styled-components'

import { EnumHaasPositionDirection } from '../scripting/EnumHaasPositionDirection'

export enum EnumOrderDirection {
  Buy = 0,
  Sell = 1,
  GoLong = 2,
  ExitLong = 3,
  GoShort = 4,
  ExitShort = 5,
}

export const isSpotOrderDirection = (direction: EnumOrderDirection) => {
  switch (direction) {
    case EnumOrderDirection.Buy:
    case EnumOrderDirection.Sell:
      return true
    case EnumOrderDirection.GoLong:
    case EnumOrderDirection.ExitLong:
    case EnumOrderDirection.GoShort:
    case EnumOrderDirection.ExitShort:
    default:
      return false
  }
}

export const isSellOrderDirection = (direction: EnumOrderDirection) => {
  switch (direction) {
    case EnumOrderDirection.Buy:
    case EnumOrderDirection.GoLong:
    case EnumOrderDirection.ExitShort:
      return false
    case EnumOrderDirection.Sell:
    case EnumOrderDirection.GoShort:
    case EnumOrderDirection.ExitLong:
    default:
      return true
  }
}

export const orderDirectionToString = (direction: EnumOrderDirection) => {
  switch (direction) {
    case EnumOrderDirection.Buy:
      return 'Buy'
    case EnumOrderDirection.Sell:
      return 'Sell'
    case EnumOrderDirection.GoLong:
      return 'Long'
    case EnumOrderDirection.ExitLong:
      return 'Exit long'
    case EnumOrderDirection.GoShort:
      return 'Short'
    case EnumOrderDirection.ExitShort:
      return 'Exit short'
    default:
      return 'Unknown'
  }
}

export const orderDirectionToColor = (direction: EnumOrderDirection, theme: DefaultTheme) => {
  switch (direction) {
    case EnumOrderDirection.Buy:
    case EnumOrderDirection.GoLong:
      return theme.GREEN
    case EnumOrderDirection.Sell:
    case EnumOrderDirection.GoShort:
      return theme.RED
    case EnumOrderDirection.ExitLong:
    case EnumOrderDirection.ExitShort:
      return theme.YELLOW
    default:
      return ''
  }
}

export const orderDirectionToPositionDirection = (orderDirection: EnumOrderDirection) => {
  switch (orderDirection) {
    case EnumOrderDirection.Buy:
    case EnumOrderDirection.GoLong:
    case EnumOrderDirection.ExitLong:
      return EnumHaasPositionDirection.Long
    case EnumOrderDirection.Sell:
    case EnumOrderDirection.GoShort:
    case EnumOrderDirection.ExitShort:
      return EnumHaasPositionDirection.Short
    default:
      throw new Exception('')
  }
}
