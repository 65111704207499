import { CacheObject } from '@dataObjects/CacheObject'
import { AdministratorApi } from '../AdministratorApi'

export class ServiceListCache extends CacheObject<Record<string, string>> {
  private api: AdministratorApi

  constructor(api: AdministratorApi) {
    super(10)

    this.api = api
  }

  protected async loadValue() {
    return this.api.getServices(this.token)
  }
}
