class Unix {
  static now() {
    return Math.floor(Date.now() / 1000)
  }

  static round(unix?: number, interval?: number) {
    if (!unix) unix = this.now()
    if (!interval) interval = 1
    interval = interval * 60

    unix = unix - (unix % interval)
    return unix
  }

  static roundUp(unix?: number, interval?: number) {
    if (!unix) unix = this.now()
    if (!interval) interval = 1
    interval = interval * 60

    unix = this.round(unix, interval)
    return unix + interval
  }

  static fromDate(date: Date) {
    return Math.floor(date.getTime() / 1000)
  }
}

export default Unix
