import {WooOrder} from "@dataObjects/private/webshop/WooOrder";
import {WooLicense} from "@dataObjects/private/webshop/WooLicense";
import {WooSubscription} from "@dataObjects/private/webshop/WooSubscription";
import {HaasShopProduct} from "@dataObjects/private/webshop/HaasShopProduct";

export class WooUserProfile {
  orders: WooOrder[]
  licenses: WooLicense[]
  subscriptions: WooSubscription[]
  oldLicenses: WooLicense[]
  constructor(jsonData: any) {
    this.orders = jsonData['Orders'].map((data: any) => new WooOrder(data))
    this.licenses = jsonData['Licenses'].map((data: any) => new WooLicense(data))
    this.subscriptions = jsonData['Subscriptions'].map((data: any) => new WooSubscription(data))
    this.oldLicenses = jsonData['OldLicenses'].map((data: any) => new WooLicense(data))
  }
}
