export type MarketingPromotionType = {
  promotionId: number
  text: string
  imageDataUrl: string
  popupImageDataUrl: string
  link: string
  startDate: number
  endDate: number
}

export const parseMarketingPromotionType = (jsonData: any): MarketingPromotionType => {
  return {
    promotionId: jsonData['PID'],
    text: jsonData['T'],
    imageDataUrl: jsonData['I'],
    popupImageDataUrl: jsonData['PI'],
    link: jsonData['L'],
    startDate: jsonData['SD'],
    endDate: jsonData['ED'],
  }
}
