import ExceptionHandler from '@utils/ExceptionHandler'
import { _ } from '@utils/lodash'
import { Query, RejectType, ResolveType } from '@utils/QueryPromise'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'
import * as HttpStatus from 'http-status-codes'

import { ServiceController } from '../../ServiceController'
import { ApiAuthenticator } from '../ApiAuthenticator'
import { ApiResponse, ApiResponseType } from './ApiResponse'

export enum EnumApiMethod {
  GET = 'GET',
  POST = 'POST',
}

export class CancellationToken {
  isCancelled = false
  private callback?: () => void

  cancel() {
    this.isCancelled = true
    if (this.callback) this.callback()
  }

  onCancel(callback: () => void) {
    this.callback = callback
  }

  throwIfCancelled() {
    if (!this.isCancelled) return

    throw new Error('cancelled')
  }
}

export class ApiRequestOptions {
  controller!: ServiceController
  method!: EnumApiMethod
  channel!: string
  endPoint!: string
  authenticator?: ApiAuthenticator
  arguments?: Record<string, any> = {}
  simulate?: boolean
  data?: string
  timeout?: number
  token?: CancellationToken
  canBeEmpty?: boolean

  rejectForTest?: boolean

  // Authentication
  key?: any
  userId?: any
}

export class ApiRequest<T> extends Query<T> {
  baseUrl: string;

  channelString!: string
  options: ApiRequestOptions
  isCancelRequested = false

  request!: Promise<AxiosResponse<ApiResponseType>>
  cancelToken!: CancelTokenSource

  guid = _.createGuid()
  startTime!: number
  endTime!: number

  constructor(options: ApiRequestOptions) {
    super()

    this.baseUrl = String(process.env.REACT_APP_REST_URL)
    // this.baseUrl = "http://127.0.0.1:1234";
    // this.baseUrl = "http://127.0.0.1:8090";
    // this.baseUrl = "https://production.hcdn.web.haasapi.com";
    // this.baseUrl = "http://web20.production.hcdn.nyc3.do.haasonline.tech";
    // this.baseUrl = "https://staging.hcdn.web.haasapi.com";

    if (process.env.REACT_APP_ENVIRONMENT === 'APP') {
      this.baseUrl = String(window.settings?.REACT_APP_REST_URL);
    }



    const convertedOptions = (Object as any).assign(new ApiRequestOptions(), options)
    const { token, ...rest } = convertedOptions

    if (token)
      token.onCancel(() => {
        this.isCancelRequested = true
        this.cancelToken.cancel()
      })

    this.options = rest
    this.start((resolve, reject) => this.execute(resolve, reject))
  }

  private execute(resolve: ResolveType<T>, reject: RejectType) {
    this.startTime = Date.now()

    this.cancelToken = axios.CancelToken.source()
    this.channelString = 'channel=' + this.options.channel

    this.prepareRequest()

    let url = `${this.baseUrl}/${this.options.endPoint}?${this.channelString}`

    const config = {
      // baseURL: this.baseUrl,
      timeout: 30000,
      timeoutErrorMessage: 'Timeout',
      responseType: 'text',
      cancelToken: this.cancelToken.token,
    } as AxiosRequestConfig

    config.timeout = 0

    switch (this.options.method) {
      case EnumApiMethod.GET:
        let queryString = this.createQueryString()
        if (queryString) queryString = '&' + queryString

        url += queryString
        this.request = axios.get<any, AxiosResponse<ApiResponseType>>(url, config)

        break
      case EnumApiMethod.POST:
        this.request = axios.post<string, AxiosResponse<ApiResponseType>>(url, this.createQueryString(), config)
        break
    }

    this.request.then((result) => this.onApiSuccess(result, resolve, reject)).catch((reason) => this.onApiError(reason, reject))
  }

  private onApiSuccess(response: AxiosResponse<ApiResponseType>, resolve: ResolveType<T>, reject: RejectType) {
    this.endTime = Date.now()

    if (this.isCancelRequested) return reject('cancel')

    // Check error code
    if (response.status !== HttpStatus.UNAUTHORIZED) {
    }

    if (response.status !== HttpStatus.OK) {
      const rejectMessage = ExceptionHandler.LogException(response)
      return reject(rejectMessage)
    }

    // Parse response
    const apiResponse = new ApiResponse(this.options, response?.data)
    if (!apiResponse.isSuccess) {
      if (apiResponse.error === 'Invalid access credentials') {
        this.options.controller.logout()
        return
      }
      const rejectMessage = ExceptionHandler.LogException(apiResponse)
      return reject(rejectMessage)
    }

    resolve(apiResponse.data)
  }

  private onApiError(error: AxiosError<ApiResponseType>, reject: RejectType) {
    this.endTime = Date.now()
    if (this.isCancelRequested) return reject('cancel')

    const rejectMessage = ExceptionHandler.LogException(error)
    return reject(rejectMessage)
  }

  private createQueryString() {
    if (_.size(this.options.arguments) === 0) return ''

    return _.join(this.options.arguments, '&', (value, key) => key + '=' + encodeURIComponent(_.normalize(value)))
  }

  private prepareRequest() {
    this.options.arguments = _.map(this.options.arguments, (container, value, property) => {
      // Property: Always lower case.
      // Value: Normalize for numbers
      container[String(property).toLowerCase()] = _.normalize(value)
    })

    // Add auth data
    if (this.options.authenticator) this.options.authenticator.execute(this.options.arguments)
  }

  dispose() {
    this.isCancelRequested = true

    if (this.cancelToken) this.cancelToken.cancel()
  }
}
