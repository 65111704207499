import { UserDevice } from '@dataObjects/private/UserDevice'
import { EnumLoginResult, UserLoginResult } from '@dataObjects/private/UserLoginResult'
import { HaasBlogTopic } from '@dataObjects/public/HaasBlogTopic'
import { UserSupportKey } from '@dataObjects/public/UserSupportKey'
import { _ } from '@utils/lodash'
import { Query } from '@utils/QueryPromise'
import { LogRecord } from '../admin/dataObjects/LogRecord'
import { ApiAuthenticator } from '../api/ApiAuthenticator'
import { ApiRequest } from '../api/rest/ApiRequest'
import { ServiceController, ServiceControllerConstructorType } from '../ServiceController'
import { LoginApi } from './LoginApi'
import Unix from "@utils/Unix";
import {UserProfile} from "@services/admin/dataObjects/UserProfile";
import {UserLicense} from "@dataObjects/private/UserLicense";

export type CaptchaRegister = {
  gt: string
  challenge: string
  offline: boolean
  new_captcha: boolean
}


type ResultMessage<T> = {
  value?: T
  error: string
  isSuccess: boolean
}

const toResultMessage = <T>(jsonData: any): ResultMessage<T> => {
  const { V: value, E: error, S: isSuccess } = jsonData
  return {
    value,
    error,
    isSuccess,
  }
}

type ActivateAccountType = {
  twoFaSecret: string
  twoFaImage: string
}

export class LoginService {
  private api: LoginApi
  private controller: ServiceController
  private authenticator: ApiAuthenticator
  private captchaId = _.createGuid()
  private lastLicenseUpdate = 0;

  constructor(props: ServiceControllerConstructorType) {
    this.controller = props.controller
    this.api = new LoginApi(props.authenticator, this.controller)
    this.authenticator = props.authenticator

    setTimeout(() => this.refreshLicense(), 15000)
  }

  async validateTurnslide(token: string): Promise<boolean> {
    return this.api.validateTurnslide(token)
  }

  async register(email: string, password: string, referral? : string): Promise<boolean> {
    const response = await this.api.register(email, password, referral ?? "")
    return response === 'OK'
  }

  async registerRequestActivationEmail(email: string): Promise<ResultMessage<boolean>> {
    const response = await this.api.requestActivationEmail(email)
    return toResultMessage(response)
  }

  async registerActivateAccount(username: string, activationCode: string): Promise<ResultMessage<ActivateAccountType>> {
    const response = await this.api.activateAccount(username, activationCode)
    const { V: value, E: error, S: isSuccess } = response
    const { TwoFaSecret, TwoFaImage } = value ?? { }

    return {
      value: {
        twoFaSecret: String(TwoFaSecret),
        twoFaImage: String(TwoFaImage),
      },
      error,
      isSuccess,
    }
  }

  registerConfirmTwo(username: string, pinCode: number): ApiRequest<boolean> {
    return this.api.confirmTwo(username, pinCode)
  }

  async loginWithCredentials(email: string, password: string, storeSession: boolean): Promise<UserLoginResult> {
    this.authenticator.reset()
    this.authenticator.initialize(email, storeSession)

    const response = await this.api.loginWithCredentials(email, password)
    const result = new UserLoginResult(response)

    return result
  }

  async loginWithOneTimeCode(onetimeCode: string): Promise<UserLoginResult> {
    const { email } = this.authenticator.session
    const response = await this.api.loginWithOneTimeCode(email, onetimeCode)

    const loginResult = new UserLoginResult(response)
    if (loginResult.result !== EnumLoginResult.Success) return loginResult

    this.authenticator.updateAfterAuth(loginResult)
    this.lastLicenseUpdate = Unix.now();
    return loginResult
  }

  emailOneTimeCode(): ApiRequest<boolean> {
    const { email } = this.authenticator.session
    return this.api.emailOneTimeCode(email)
  }

  async refreshLicense() {
    try {
      if (this.lastLicenseUpdate > 0 && this.lastLicenseUpdate + 60 < Unix.now()) {

        // Set timestamp even if call fails. If we don't, UI get spammed with errors
        this.lastLicenseUpdate = Unix.now();

        const response = await this.api.refreshLicense();
        if (response == null)
          return;

        this.authenticator.session.licenseSpecs = new UserLicense(response);
      }
    } finally {
      setTimeout(() => this.refreshLicense(), 60)
    }

  }

  async loginSupport(email: string, password: string, supportCode: string): Promise<EnumLoginResult> {
    this.authenticator.reset()

    const response = await this.api.supportLogin(email, password, supportCode)
    return response.Error
  }

  async logout(): Promise<any> {
    await this.api.logout()
    this.authenticator.reset()
    this.lastLicenseUpdate = 0;
    return true
  }

  async checkToken(): Promise<boolean> {
    const response = await this.api.checkToken()
    if (response)
      this.lastLicenseUpdate = Unix.now();

    return response;
  }

  async changePassword(password: string, newPassword: string, pincode: number): Promise<any> {
    return this.api.changePassword(password, newPassword, pincode)
  }

  async changeTwoFA(): Promise<any> {
    return this.api.changeTwoFA()
  }

  async generateUsername(): Promise<string> {
    return this.api.generateUsername()
  }

  async changeUsername(username: string): Promise<any> {
    return this.api.changeUsername(username)
  }

  async changeDisplayName(displayname: string): Promise<any> {
    return this.api.changeDisplayName(displayname)
  }

  async lostPassword(email: string, pincode: string): Promise<boolean> {
    return this.api.lostPassword(email, pincode)
  }

  isDeviceApproved(deviceId: string): Query<boolean> {
    return this.api.isDeviceApproved(deviceId)
  }

  async getAllowedDevices(): Promise<UserDevice> {
    const response = await this.api.getAllowedDevices()
    return response.map((c: any) => new UserDevice(c))
  }

  async removeDevice(id: string): Promise<boolean> {
    return await this.api.removeDevice(id)
  }

  approveDevice(deviceId: string, activationCode: string): ApiRequest<boolean> {
    return this.api.approveDevice(deviceId, activationCode)
  }

  async getConsoleLogbook(): Promise<LogRecord[]> {
    const response = await this.api.getConsoleLogbook()
    return response.map((c: any) => new LogRecord(c))
  }

  async getInstrumentalData(): Promise<LogRecord[]> {
    // Return is wrong, its dic<str,list<double>>
    const response = await this.api.getInstrumentalData()
    return response.map((c: any) => new LogRecord(c))
  }

  async changeDebugMode(enable: boolean): Promise<boolean> {
    return await this.api.changeDebugMode(enable)
  }

  async getChangelog(): Promise<HaasBlogTopic[]> {
    const response = await this.api.getChangelog()
    return response.map((c: any) => new HaasBlogTopic(c))
  }

  async approveChangelog(): Promise<boolean> {
    return await this.api.approveChangelog()
  }

  async submitSupportTicket(subject: string, message: string): Promise<UserSupportKey> {
    const response = await this.api.submitSupportTicket(subject, message)
    return new UserSupportKey(response)
  }

  async lockUser(): Promise<boolean> {
    return this.api.lockUser()
  }

  async onlinePing(): Promise<boolean> {
    const response = await this.api.onlinePing()
    return String(response) === 'PONG'
  }

  ping() {
    this.api.ping()
  }
}











