import { _ } from '@utils/lodash'
import { EnumAccountType } from '../enums/EnumAccountType'
import { CloudAccount } from './CloudAccount'
import { CloudExchange } from './CloudExchange'
import { CloudMarket } from './CloudMarket'
import { CloudPriceSource } from './CloudPriceSource'
import { CloudTradeMarket } from './CloudTradeMarket'

export class CloudInitData {
  timestamp: number
  version: string
  debugMode: boolean
  lowPowerMode: boolean
  safeMode: boolean
  operationSystem: string
  processor: string
  logicalCores: number
  totalMemory: number

  priceSources: CloudPriceSource[]
  exchanges: CloudExchange[]
  accounts: CloudAccount[]

  markets: Record<string, CloudTradeMarket[]> = {}

  scriptData: {
    categories: Record<number, string>
    categoriesShortHand: Record<number, string>
    categoriesSets: Record<number, number[]>
  }

  forumCategories : Record<number, string>;

  constructor(jsonObject: any) {
    this.timestamp = jsonObject['T']
    this.version = jsonObject['V']
    this.debugMode = jsonObject['DE']
    this.lowPowerMode = jsonObject['LP']
    this.safeMode = jsonObject['SM']
    this.totalMemory = jsonObject['MEM']

    this.operationSystem = jsonObject['OS']
    this.processor = jsonObject['CPU']
    this.logicalCores = jsonObject['LC']

    this.priceSources = jsonObject['P'].map((value: any) => new CloudPriceSource(value))
    this.priceSources = _.orderBy(this.priceSources, (c) => c.exchangeName)

    this.exchanges = jsonObject['E'].map((value: any) => new CloudExchange(value, this))

    this.markets = _.map(jsonObject['M'], (container, marketJsonObject: any[], key) => {
      const markets = marketJsonObject.map((value) => new CloudTradeMarket(value, this))
      container[key] = this.sortMarkets(markets)

      const priceSource = this.priceSources.find((c) => c.exchangeCode === key)
      if (priceSource) priceSource.markets = markets
    })

    this.accounts = []
    _.each(this.priceSources, (priceSource) => {
      if (this.exchanges.every((c) => c.exchangeCode !== priceSource.exchangeCode)) return

      if (priceSource.isSpot) this.accounts.push(new CloudAccount(priceSource, EnumAccountType.Spot))

      if (priceSource.isMargin) this.accounts.push(new CloudAccount(priceSource, EnumAccountType.Margin))

      if (priceSource.isLeverage) this.accounts.push(new CloudAccount(priceSource, EnumAccountType.Leverage))
    })

    this.scriptData = {
      categories: jsonObject['C']['C'],
      categoriesShortHand: jsonObject['C']['CSH'],
      categoriesSets: jsonObject['C']['CS'],
    }

    this.forumCategories = jsonObject['FC'];

    this.getMarkets = this.getMarkets.bind(this)
    this.getMarketList = this.getMarketList.bind(this)
    this.getTradeMarket = this.getTradeMarket.bind(this)
    this.getUniqueMarkets = this.getUniqueMarkets.bind(this)
  }

  getCoins(exchangeCode?: string) {
    const markets = this.getMarketList(exchangeCode)
    const coins = _.selectMany(markets, (value) => [value.primary, value.secondary])

    return _.distinctBy(coins, (c) => c)
  }

  getNormalizedTradableCoins(exchangeCode?: string) {
    const markets = this.getMarketList(exchangeCode)
    const coins = _.selectMany(markets, (value) => [value.normalizedPrimary, value.normalizedSecondary, value.normalizedMarginCurrency])

    return _.distinctBy(coins, (c) => c)
  }

  getMarkets(name: string) {
    if (!this.markets[name]) return []

    return this.markets[name]
  }

  getMarketList(exchangeCode?: string): CloudTradeMarket[] {
    if (!exchangeCode) return _.selectMany(this.markets, (item) => item)

    return this.markets[exchangeCode] || []
  }

  getTradeMarket(market: CloudMarket | string | undefined): CloudTradeMarket | undefined {
    if (!market) return undefined

    if (typeof market === 'string') market = new CloudMarket(market, this)

    const cm = market as CloudMarket

    const exchange = this.priceSources.find((value) => value.exchangeName === cm.exchangeCode || value.exchangeName === cm.exchangeName)
    if (!exchange) return undefined

    return exchange.markets.find((value: CloudTradeMarket) => value.primary === cm.primary && value.secondary === cm.secondary && value.contractName === cm.contractName)
  }

  getTradeMarketByShortTag(exchangeName: string, tag: string): CloudTradeMarket | undefined {
    const exchange = this.priceSources.find((value) => value.exchangeName === exchangeName || value.exchangeName === exchangeName)
    if (!exchange) return undefined

    return exchange.markets.find((value: CloudTradeMarket) => value.shortTag === tag)
  }

  getUniqueMarkets() {
    const uniqueMarkets: string[] = []
    _.each(this.markets, (value) => {
      _.each(value, (market: CloudTradeMarket) => {
        const shortName = `${market.normalizedPrimary}/${market.normalizedSecondary}`
        if (uniqueMarkets.indexOf(shortName) < 0) uniqueMarkets.push(shortName)
      })
    })

    return uniqueMarkets.sort()
  }

  private sortMarkets(markets: CloudTradeMarket[]) {
    const mainCurrencies = ['BTC', 'BCH', 'ETH', 'XRP', 'LTC']

    return markets.sort((a, b) => {
      const isMainA = mainCurrencies.indexOf(a.normalizedPrimary)
      const isMainB = mainCurrencies.indexOf(b.normalizedPrimary)

      if (isMainA >= 0 && isMainB < 0) return -1
      if (isMainA < 0 && isMainB >= 0) return 1

      if (isMainA >= 0 && isMainB >= 0) {
        if (isMainA !== isMainB) return isMainA - isMainB
      }

      if (a.primary.toLowerCase() > b.primary.toLowerCase()) return 1
      if (a.primary.toLowerCase() < b.primary.toLowerCase()) return -1

      if (a.secondary.toLowerCase() > b.secondary.toLowerCase()) return 1
      if (a.secondary.toLowerCase() < b.secondary.toLowerCase()) return -1

      if (a.isPerpetual && !b.isPerpetual) return 1
      if (!a.isPerpetual && b.isPerpetual) return -1

      if (a.settlementDate > b.settlementDate) return 1
      if (a.settlementDate < b.settlementDate) return -1

      if (a.contractName.toLowerCase() > b.contractName.toLowerCase()) return 1
      if (a.contractName.toLowerCase() < b.contractName.toLowerCase()) return -1

      return 0
    })
  }
}
