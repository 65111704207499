import { HaasShopProduct } from '@dataObjects/private/webshop/HaasShopProduct'

export enum EnumWebShopOrderStatus {
  Created = 1,
  WaitingPayment = 2,
  Completed = 3,
  Cancelled = 4,
  Refunded = 5,
  Credited = 6,
}

export class HaasShopOrder {
  id: number
  userId: string
  products: HaasShopProduct[]
  status: EnumWebShopOrderStatus
  affiliateId: string
  created: number
  finished: number
  coupon: string
  discount: number
  paymentId: number
  affiliatePaymentId: number
  subTotal: number
  totalPrice: number
  totalDiscount: number
  discountType: string
  isRefundable: boolean
  isCreditable: boolean
  creditsUsed: number
  creditValue: number

  constructor(jsonData: any) {
    this.id = jsonData['OrderNo']
    this.userId = jsonData['UserId']
    this.products = jsonData['Products'].map((data: any) => new HaasShopProduct(data))
    this.status = jsonData['Status']
    this.affiliateId = jsonData['AffiliateId']
    this.created = jsonData['Created']
    this.finished = jsonData['Finished']
    this.coupon = jsonData['Coupon']
    this.discount = jsonData['Discount']
    this.paymentId = jsonData['PaymentId']
    this.affiliatePaymentId = jsonData['AffiliatePaymentId']
    this.subTotal = jsonData['SubTotal']
    this.totalPrice = jsonData['TotalPrice']
    this.totalDiscount = jsonData['TotalDiscount']
    this.discountType = jsonData['DiscountType']
    this.isRefundable = jsonData['IsRefundable']
    this.isCreditable = jsonData['IsCreditable']
    this.creditsUsed = jsonData['CreditsUsed']
    this.creditValue = jsonData['CreditValue']
  }
}
