import { UserLicense } from '@dataObjects/private/UserLicense'
import { EnumIncomingPaymentType } from '@dataObjects/private/webshop/HaasShopIncomingPayment'
import { HaasShopOrder } from '@dataObjects/private/webshop/HaasShopOrder'
import { EnumOutgoingPaymentType, HaasShopOutgoingPayment } from '@dataObjects/private/webshop/HaasShopOutgoingPayment'
import { HaasShopPaymentInstructions } from '@dataObjects/private/webshop/HaasShopPaymentInstructions'
import { HaasShopProduct } from '@dataObjects/private/webshop/HaasShopProduct'
import { HaasShopUserDetails } from '@dataObjects/private/webshop/HaasShopUserDetails'
import { UserLicenseRegistration } from '@dataObjects/public/UserLicenseRegistration'
import { ServiceControllerConstructorType } from '../ServiceController'
import { WebshopApi } from './WebshopApi'
import {WooUserProfile} from "@dataObjects/private/webshop/WooUserProfile";
import {CancellationToken} from "@services/api/rest/ApiRequest";

export class WebshopService {
  private api: WebshopApi


  async getMyData(token: CancellationToken): Promise<WooUserProfile> {
    const response = await this.api.getMyData(token)
    return new WooUserProfile(response)
  }

  constructor(props: ServiceControllerConstructorType) {
    this.api = new WebshopApi(props.authenticator, props.controller)
  }

  //async mayPromoteTrial(): Promise<boolean> {
    //r//eturn this.api.mayPromoteTrial()
  //}

  //async mayPromoteDeveloper(): Promise<boolean> {
    //return this.api.mayPromoteDeveloper()
  //}

  //async getSupportedLicenses(): Promise<UserLicense[]> {
    //const response = await this.api.getSupportedLicenses()
    //return response.map((data: any) => new UserLicense(data))
  //}

  async getAllStoreLicenses(): Promise<HaasShopProduct[]> {
    const response = await this.api.getAllStoreLicenses()
    return response.map((data: any) => new HaasShopProduct(data))
  }

  async getAllStoreSignals(): Promise<HaasShopProduct[]> {
    const response = await this.api.getAllStoreSignals()
    return response.map((data: any) => new HaasShopProduct(data))
  }

  async getAllStoreStrategies(): Promise<HaasShopProduct[]> {
    const response = await this.api.getAllStoreStrategies()
    return response.map((data: any) => new HaasShopProduct(data))
  }

  async getStoreProductDetails(productId: number): Promise<HaasShopProduct> {
    const response = await this.api.getStoreProductDetails(productId)
    return new HaasShopProduct(response)
  }

  // The product the user is selling on the marketplace.
  //async getMyProducts(): Promise<HaasShopProduct[]> {
    //const response = await this.api.getMyProducts()
    //return response.map((data: any) => new HaasShopProduct(data))
  //}

  // Editing products sold by the user
  //async editProduct(productId: number, title: string, descr: string, price: number, daysLifetime: number, productDiscount: number, volumeDiscount: number): Promise<boolean> {
    //return await this.api.editProduct(productId, title, descr, price, daysLifetime, productDiscount, volumeDiscount)
 // }

  //async editProductVisibility(productId: number): Promise<boolean> {
   // return await this.api.editProductVisability(productId)
  //}

//  async getUserExtensionDeals(): Promise<HaasShopProduct[]> {
  //  const response = await this.api.getUserExtensionDeals()
  //  return response.map((data: any) => new HaasShopProduct(data))
 // }

 // async getUserRenewDeals(): Promise<HaasShopProduct[]> {
   // const response = await this.api.getUserRenewDeals()
   // return response.map((data: any) => new HaasShopProduct(data))
  //}

  //async getUserUpgradeDeals(): Promise<HaasShopProduct[]> {
   // const response = await this.api.getUserUpgradeDeals()
   // return response.map((data: any) => new HaasShopProduct(data));
  //}

  // =================================================================================================================

  async getMyValidOrders(): Promise<HaasShopOrder[]> {
   const response = await this.api.getUserOrders()
    return response.map((data: any) => new HaasShopOrder(data))
  }

  async getUserLicenses(): Promise<UserLicenseRegistration[]> {
    const response = await this.api.getUserLicenses()
    return response.map((data: any) => new UserLicenseRegistration(data))
  }

  // =================================================================================================================

  async getLatestOrderNo(): Promise<number> {
    return this.api.getLatestOrderNo()
  }

  async createOrder(): Promise<number> {
    return this.api.createNewOrder()
  }

  async getOrder(orderId: number): Promise<HaasShopOrder> {
    const response = await this.api.getShopOrder(orderId)
    return new HaasShopOrder(response)
  }

  async addProductToOrder(orderId: number, productId: number): Promise<boolean> {
    return this.api.addProductToOrder(orderId, productId)
  }

  async addProductToOrderBySku(orderId: number, sku: string): Promise<boolean> {
    return this.api.addProductBySkuToOrder(orderId, sku)
  }

  async removeProductFromOrder(orderId: number, productId: number): Promise<boolean> {
    return this.api.removeProductFromOrder(orderId, productId)
  }

  async applyOrderCoupon(orderId: number, coupon: string): Promise<boolean> {
    return this.api.setOrderCoupon(orderId, coupon)
  }

  async removeOrderCoupon(orderId: number): Promise<boolean> {
    return this.api.removeOrderCoupon(orderId)
  }

  async cancelOrder(orderId: number): Promise<boolean> {
    return this.api.cancelShopOrder(orderId)
  }

  async payOrder(orderId: number, paymentMethod?: EnumIncomingPaymentType, reference1?: string, reference2?: string, reference3?: string): Promise<HaasShopPaymentInstructions> {
    const response = await this.api.finishShopOrder(orderId, paymentMethod, reference1, reference2, reference3)
    return new HaasShopPaymentInstructions(response)
  }

  // =================================================================================================================

  //async getBillingDetails(): Promise<HaasShopUserDetails> {
    //const response = await this.api.getUserDetails()
    //return new HaasShopUserDetails(response)
  //}

  //async setBillingDetails(fullname: string, adres: string, postalcode: string, place: string, country: string, countryCode: string): Promise<boolean> {
   // return this.api.setupUserDetails(fullname, adres, postalcode, place, country, countryCode)
  //}

  // =================================================================================================================

  async registerAsAffiliate(affiliateName: string): Promise<boolean> {
    return this.api.registerAsAffiliate(affiliateName)
  }

  async editAffiliate(affiliateName: string): Promise<boolean> {
    return this.api.editAffiliate(affiliateName)
  }

  async getAffiliateOrders(): Promise<HaasShopOrder[]> {
    const response = await this.api.getAffiliateOrders()
    return response.map((data: any) => new HaasShopOrder(data))
  }

  async getAffiliateCouponOrders(): Promise<HaasShopOrder[]> {
    const response = await this.api.getAffiliateCouponOrders()
    return response.map((data: any) => new HaasShopOrder(data))
  }

  async getAffiliatePayments(): Promise<HaasShopOutgoingPayment[]> {
    const response = await this.api.getAffiliatePayments()
    return response.map((data: any) => new HaasShopOutgoingPayment(data))
  }

  async getRequestAffiliatePayment(amount: number, paymentMethod: EnumOutgoingPaymentType, reference1: string, reference2: string, reference3: string): Promise<boolean> {
    return await this.api.getRequestAffiliatePayment(amount, paymentMethod, reference1, reference2, reference3)
  }

  // =================================================================================================================

  async setReferal(referalId: string): Promise<boolean> {
    return this.api.setReferal(referalId)
  }
}
