import UserSocketClient from '@services/api/socket/user/UserSocketClient'
import { _ } from '@utils/lodash'

class UserSocketConvertChannel {
  private socket: UserSocketClient
  private priceSource: string
  private fromCurrency: string
  private toCurrency: string

  private callback?: (conversionPrice: number) => void

  subscriptionId = _.createGuid()

  constructor(socket: UserSocketClient, priceSource: string, fromCurrency: string, toCurrency: string) {
    this.socket = socket
    this.priceSource = priceSource
    this.fromCurrency = fromCurrency
    this.toCurrency = toCurrency
  }

  subscribe(callback: (conversionPrice: number) => void) {
    this.callback = callback

    this.socket.subscribe(this.subscriptionId, 'convert', {
      priceSource: this.priceSource,
      fromCurrency: this.fromCurrency,
      toCurrency: this.toCurrency,
    })
  }

  onMessage(conversionPrice: number) {
    if (!this.callback) return

    this.callback(conversionPrice)
  }
}

export default UserSocketConvertChannel
