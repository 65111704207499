import { HaasExchangeServiceAccountReport } from './HaasExchangeServiceAccountReport'

export class HaasExchangeServiceReport {
  machineId: string
  serviceId: string
  serviceName: string

  executionReports: HaasExchangeServiceAccountReport[]

  constructor(jsonData: any) {
    this.machineId = jsonData['MID']
    this.serviceId = jsonData['SID']
    this.serviceName = jsonData['SN']

    this.executionReports = jsonData['ER'].map((c: any) => new HaasExchangeServiceAccountReport(c, this.machineId, this.serviceId, this.serviceName))
  }
}
