import { NotificationService } from '@services/notifications/NotificationService'
import { NotificationManager as Manager } from 'react-notifications'

import playSound from '../../sounds'

export class NotificationManager {
  private static notificationService: NotificationService

  static initialize(notificationService: NotificationService): void {
    NotificationManager.notificationService = notificationService
  }

  static info(message: string, title?: string, timeOut?: number, onClick?: () => void, priority?: boolean): void {
    playSound(this.notificationService.infoSound.get())
    Manager.info(message, title, timeOut, onClick, priority)
  }

  static success(message: string, title?: string, timeOut?: number, onClick?: () => void, priority?: boolean): void {
    playSound(this.notificationService.successSound.get())
    Manager.success(message, title, timeOut, onClick, priority)
  }

  static warning(message: string, title?: string, timeOut?: number, onClick?: () => void, priority?: boolean): void {
    playSound(this.notificationService.warningSound.get())
    Manager.warning(message, title, timeOut, onClick, priority)
  }

  static error(message: string, title?: string, timeOut?: number, onClick?: () => void, priority?: boolean): void {
    playSound(this.notificationService.errorSound.get())
    Manager.error(message, title, timeOut, onClick, priority)
  }

  static conditional(isSuccess: boolean, successMessage: string, errorMessage: string, title?: string, timeOut?: number, onClick?: () => void, priority?: boolean): void {
    if (isSuccess) this.success(successMessage, title, timeOut, onClick, priority)
    else this.error(errorMessage, title, timeOut, onClick, priority)
  }
}
