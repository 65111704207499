import { EnumUserTelegramBotStatus } from './EnumUserTelegramBotStatus'
import { UserNotificationProfile } from './UserNotificationProfile'

export class UserTelegramRecord {
  token: string
  status: EnumUserTelegramBotStatus
  profile: UserNotificationProfile

  constructor(jsonData: any) {
    this.token = jsonData['T']
    this.status = jsonData['S']
    this.profile = new UserNotificationProfile(jsonData['P'])
  }
}
