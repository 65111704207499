type Callback<T> = (data: T) => void

export class EventHandler<TData> {
  private subscriptions: Callback<TData>[] = []

  constructor() {
    this.fire = this.fire.bind(this)
    this.subscribe = this.subscribe.bind(this)
    this.unsubscribe = this.unsubscribe.bind(this)
    this.hasSubscribers = this.hasSubscribers.bind(this)
  }

  fire(data: TData) {
    this.subscriptions.map((c) => c(data))
  }

  subscribe(callback: Callback<TData>) {
    this.subscriptions.push(callback)
  }

  unsubscribe(callback: Callback<TData>) {
    this.subscriptions = this.subscriptions.filter((c) => c !== callback)
  }

  hasSubscribers() {
    return !!this.subscriptions.length
  }
}
