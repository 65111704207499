import { UserAccount } from '../account/UserAccount'
import { UserPortfolioHistoryItem } from './UserPortfolioHistoryItem'

export class UserPortfolioBalance {
  timestamp: number
  currency: string

  account: UserAccount

  price: number
  amount: number

  value: number
  valueInBTC: number
  valueInUSD: number

  daily: UserPortfolioHistoryItem
  weekly: UserPortfolioHistoryItem
  monthly: UserPortfolioHistoryItem

  share = 0

  constructor(account: UserAccount, jsonData: any, currentValue = 0) {
    this.account = account

    this.timestamp = jsonData['T']
    this.currency = jsonData['C']

    this.price = jsonData['P']
    this.amount = jsonData['A']

    this.value = jsonData['V']
    this.valueInBTC = jsonData['VB']
    this.valueInUSD = jsonData['VU']

    this.daily = UserPortfolioHistoryItem.fromJson(jsonData['RD'], this.value)
    this.weekly = UserPortfolioHistoryItem.fromJson(jsonData['RW'], this.value)
    this.monthly = UserPortfolioHistoryItem.fromJson(jsonData['RM'], this.value)

    if (currentValue !== 0) this.share = (this.value / currentValue) * 100
  }

  toPieData(isCurrency: boolean) {
    return {
      title: isCurrency ? this.currency : this.account.name,
      value: this.value,
      color: '',
    }
  }
}
