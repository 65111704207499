import { EnumPortfolioChartStyle } from '@dataObjects/enums/EnumPortfolioChartStyle'
import { UserBalance, UserBalanceValue } from '@dataObjects/private/account/UserBalance'
import { UserPortfolio } from '@dataObjects/private/portfolio/UserPortfolio'
import UserTradeFrequencyPortfolio from '@dataObjects/private/portfolio/UserTradeFrequencyPortfolio'
import UserTradeVolumePortfolio from '@dataObjects/private/portfolio/UserTradeVolume'
import { _ } from '@utils/lodash'
import { Query } from '@utils/QueryPromise'
import { HaasChartContainer } from '@vendor/hxCharts/types/HaasChartContainer'
import { ApiRequest, CancellationToken } from '../api/rest/ApiRequest'
import { ServiceController, ServiceControllerConstructorType } from '../ServiceController'
import { PortfolioApi } from './PortfolioApi'

type ConvertResultType = {
  rate: number
  value: number
}

export class PortfolioService {
  private api: PortfolioApi
  private controller: ServiceController

  private balanceCache: Record<string, UserBalance[]> = {}

  constructor(props: ServiceControllerConstructorType) {
    this.controller = props.controller
    this.api = new PortfolioApi(props.authenticator, this.controller)
  }

  async getBalance(accountId: string, currency: string, aggregateCurrencies: boolean, token: CancellationToken): Promise<UserBalanceValue[]> {
    const response = await this.api.getBalance(accountId, currency, aggregateCurrencies, token)

    const balance = response.map((c: any) => new UserBalance(c))
    const balanceValues = response.map((c: any) => new UserBalanceValue(c))

    this.balanceCache[accountId] = balance

    return balanceValues
  }

  convertAmount(priceSource: string, fromCurrency: string, toCurrency: string, amount: number): ApiRequest<number> {
    return this.api.convertAmount(priceSource, fromCurrency, toCurrency, amount)
  }

  async convertAmounts(priceSource: string, items: Record<string, number>, toCurrency: string): Promise<Record<string, ConvertResultType>> {
    const response = await this.api.convertAmounts(priceSource, items, toCurrency)
    return _.map(response, (container, item: any, key) => {
      const { R: rate, A: value } = item
      container[key] = { rate, value }
    })
  }

  async getPortfolio(accountIds: string[], coins: string[], currency: string, token: CancellationToken): Promise<UserPortfolio> {
    const response = await this.api.getUserPortfolio(accountIds, coins, currency, 0, token)
    return new UserPortfolio(this.controller.accountService, response)
  }

  getPortfolioChart(
    accountIds: string[],
    coins: string[],
    currency: string,
    interval: number,
    style: EnumPortfolioChartStyle,
    token: CancellationToken
  ): Query<HaasChartContainer> {
    return this.api.getUserPortfolioChart(accountIds, coins, currency, interval, style, token)
  }

  async getTradeFrequency(accountIds: string[], marketSearchTags: string[], timestamp: number, token: CancellationToken): Promise<UserTradeFrequencyPortfolio> {
    const response = await this.api.getUserTradeFrequency(accountIds, marketSearchTags, timestamp, token)
    return new UserTradeFrequencyPortfolio(this.controller, response)
  }

  getTradeFrequencyChart(accountIds: string[], marketSearchTags: string[], timestamp: number, interval: number, token: CancellationToken): Query<HaasChartContainer> {
    return this.api.getUserTradeFrequencyChart(accountIds, marketSearchTags, timestamp, interval, token)
  }

  async getTradeVolume(accountIds: string[], marketSearchTags: string[], timestamp: number, token: CancellationToken): Promise<UserTradeVolumePortfolio> {
    const response = await this.api.getUserTradeVolume(accountIds, marketSearchTags, timestamp, token)
    return new UserTradeVolumePortfolio(this.controller, response)
  }

  getTradeVolumeChart(accountIds: string[], marketSearchTags: string[], timestamp: number, interval: number, token: CancellationToken): Query<HaasChartContainer> {
    return this.api.getUserTradeVolumeChart(accountIds, marketSearchTags, timestamp, interval, token)
  }
}
