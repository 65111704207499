import { AccountService } from '@services/account/AccountService'
import { _ } from '@utils/lodash'
import { CloudMarket } from '../../public/CloudMarket'
import { UserCurrencyPortfolio } from './UserCurrencyPortfolio'
import { UserPortfolioAccount } from './UserPortfolioAccount'
import { UserPortfolioHistoryItem } from './UserPortfolioHistoryItem'

export class UserPortfolio {
  timestamp: number

  value: number
  valueInBTC: number
  valueInUSD: number

  accountBalances: UserPortfolioAccount[]
  currencyBalances: UserCurrencyPortfolio[]

  rollingDaily: UserPortfolioHistoryItem
  rollingWeekly: UserPortfolioHistoryItem
  rollingMonthly: UserPortfolioHistoryItem

  constructor(accountService: AccountService, jsonData: any) {
    this.timestamp = jsonData['T']

    this.value = jsonData['V']
    this.valueInBTC = jsonData['VB']
    this.valueInUSD = jsonData['VU']

    this.accountBalances = jsonData['I'].map((c: any) => new UserPortfolioAccount(accountService, c, this.value))

    const currencyItems = _.selectMany(this.accountBalances, (c) => c.items)
    const groupedOnCoin = _.groupBy(currencyItems, (c) => CloudMarket.getNormalizeCurrency(c.currency))

    this.currencyBalances = _.select(groupedOnCoin, (items, key) => new UserCurrencyPortfolio(this.timestamp, String(key), items, this.value))

    this.rollingDaily = UserPortfolioHistoryItem.fromItems(
      this.accountBalances.map((c) => c.rollingDaily),
      this.value
    )
    this.rollingWeekly = UserPortfolioHistoryItem.fromItems(
      this.accountBalances.map((c) => c.rollingWeekly),
      this.value
    )
    this.rollingMonthly = UserPortfolioHistoryItem.fromItems(
      this.accountBalances.map((c) => c.rollingMonthly),
      this.value
    )
  }
}
