import { HaasScriptSettingsForm } from '@components/HaasScriptSettings/HaasScriptSettingsForm'
import { EnumHaasRuntimeType } from '@dataObjects/enums/EnumHaasRuntimeType'
import { EnumHaasChartPricePlotStyle } from '@vendor/hxCharts/enums/EnumHaasChartPricePlotStyle'
import { ApiRequest, CancellationToken, EnumApiMethod } from '../api/rest/ApiRequest'
import { RestApiBase } from '../api/rest/RestApiBase'

export class BotApi extends RestApiBase {
  private baseUrl = 'BotAPI.php'

  getBot(botId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BOT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        botId: botId,
      },
    })
  }

  getBots(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BOTS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getBotChart(botId: string, interval: number, style: EnumHaasChartPricePlotStyle, showVolume: boolean, saveSettings: boolean, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_CHART',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        botId,
        interval,
        style,
        showVolume,
        saveSettings,
      },
    })
  }

  getProfitChart(botId: string, interval: number, saveSettings: boolean, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_PROFIT_CHART',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        botId,
        interval,
        saveSettings,
      },
    })
  }

  activateBot(botId: string, cleanReports: boolean): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ACTIVATE_BOT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        botId,
        cleanReports,
      },
    })
  }

  pauseBot(botId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'PAUSE_BOT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { botId },
    })
  }

  resumeBot(botId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'RESUME_BOT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { botId },
    })
  }

  deactivateBot(botId: string, cancelOrders: boolean): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DEACTIVATE_BOT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      timeout: 100000,
      arguments: {
        botId,
        cancelOrders,
      },
    })
  }

  deactivateAllBots(accountId: string, market: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DEACTIVATE_ALL_BOTS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      timeout: 100000,
      arguments: {
        accountId,
        market,
      },
    })
  }

  addBot(
      accountId: string,
      market: string,
      leverage: number,
      botName: string,
      scriptId: string,
      scriptType: EnumHaasRuntimeType,
      interval: number,
      chartStyle: EnumHaasChartPricePlotStyle
  ): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ADD_BOT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        botName,
        scriptId,
        scriptType,
        accountId,
        market,
        leverage,
        interval,
        chartStyle,
      },
    })
  }

  addBotFromBacktest(backtestId: string, botName: string, accountId: string, market: string, leverage: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ADD_BOT_FROM_BACKTEST',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        backtestId,
        botName,
        accountId,
        market,
        leverage,
      },
    })
  }

  addBotFromLabs(labId: string, backtestId: string, botName: string, accountId: string, market: string, leverage: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ADD_BOT_FROM_LABS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        labId,
        backtestId,
        botName,
        accountId,
        market,
        leverage,
      },
    })
  }

  editBot(botId: string, scriptId: string, settings: HaasScriptSettingsForm): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_SETTINGS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        botId: botId,
        scriptId: scriptId,
        settings: JSON.stringify(settings),
      },
    })
  }

  editScript(botId: string, scriptId: string, scriptType: EnumHaasRuntimeType): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_SCRIPT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        botId,
        scriptId,
        scriptType,
      },
    })
  }

  editNotes(botId: string, notes: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CHANGE_BOT_NOTES',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        botId,
        notes,
      },
    })
  }

  executeButton(botId: string, buttonId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'BUTTON_EXECUTION',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        botId,
        buttonId,
      },
    })
  }

  renameBot(botId: string, botName: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'RENAME_BOT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        botId,
        botName
      },
    })
  }

  favoriteBot(botId: string, isFavorite: boolean): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'FAVORITE_BOT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        botId,
        isFavorite,
      },
    })
  }

  resetBot(botId: string, resetReports: boolean, resetPositions: boolean, resetLogs: boolean, resetChart: boolean): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'RESET_BOT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        botId,
        config: JSON.stringify(
            {
              ResetReports: resetReports,
              ResetPositions: resetPositions,
              ResetLogs: resetLogs,
              ResetChart: resetChart,
            }
        ),
      },
    })
  }

  cloneBot(botId: string, botName: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CLONE_BOT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        botId,
        botName,
      },
    })
  }

  deleteBot(botId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_BOT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        botId: botId,
      },
    })
  }

  getRuntime(botId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_RUNTIME',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        botId: botId,
      },
    })
  }

  getRuntimeReport(botId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_RUNTIME_REPORT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        botId: botId,
      },
    })
  }

  getRuntimeCustomReport(botId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_RUNTIME_CUSTOM_REPORT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        botId: botId,
      },
    })
  }

  getRuntimeOpenOrders(botId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_RUNTIME_OPEN_ORDERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        botId: botId,
      },
    })
  }

  getRuntimeOpenPositions(botId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_RUNTIME_OPEN_POSITIONS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        botId: botId,
      },
    })
  }

  getRuntimeClosedPositions(botId: string, nextPageId: number, pageLength: number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_RUNTIME_CLOSED_POSITIONS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        botId,
        nextPageId,
        pageLength,
      },
    })
  }

  getRuntimeLogbook(botId: string, nextPageId: number, pageLength: number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_RUNTIME_LOGBOOK',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        botId,
        nextPageId,
        pageLength,
      },
    })
  }

  getOpenOrders(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_OPEN_ORDERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }

  getOpenPositions(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_OPEN_POSITIONS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }

  getBotOrdersCsv(botId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ORDERS_CSV',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: { botId },
    })
  }

  getBotPositionsCsv(botId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_POSITIONS_CSV',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: { botId },
    })
  }

  cancelOrder(botId: string, orderId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CANCEL_ORDER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        botId,
        orderId,
      },
    })
  }

  cancelAllOrders(botId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CANCEL_ALL_ORDERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        botId,
      },
    })
  }

  getBotTemplates(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BOT_TEMPLATES',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { },
    })
  }
  getBotTemplate(botId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_TEMPLATE_BOT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        botId,
      },
    })
  }
  cloneBotTemplate(botId: string, botName : string, accountId : string, market : string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CLONE_TEMPLATE_BOT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        botId,
        botName,
        accountId,
        market
      },
    })
  }
  getReport(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_REPORT',
      endPoint: "WhiteLabelAPI",
      authenticator: this.authenticator,
      arguments: { },
    })
  }
}
