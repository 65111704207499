import { UserAccount } from '@dataObjects/private/account/UserAccount'
import { CloudMarket } from '@dataObjects/public/CloudMarket'
import { CloudTradeMarket } from '@dataObjects/public/CloudTradeMarket'
import { Query } from '@utils/QueryPromise'
import { CancellationToken } from '../api/rest/ApiRequest'
import { ServiceController, ServiceControllerConstructorType } from '../ServiceController'
import { TradingApi } from './TradingApi'

export class TradingService {
  private api: TradingApi
  private controller: ServiceController

  constructor(props: ServiceControllerConstructorType) {
    this.controller = props.controller
    this.api = new TradingApi(props.authenticator, props.controller)
  }

  async placeOrder(accountGuid: string, requestOrder: object): Promise<boolean> {
    await this.api.placeOrder(requestOrder)
    return true
  }

  async cancelOrder(accountGuid: string, orderId: string): Promise<boolean> {
    return this.api.cancelOrder(accountGuid, orderId)
  }

  getMargin(account: UserAccount, market: CloudMarket, leverage: number, price: number, amount: number, token: CancellationToken): Query<any> {
    return this.api.getMargin(account, market, leverage, price, amount, token)
  }

  getMaxAmount(
    account: UserAccount,
    market: CloudMarket | CloudTradeMarket,
    leverage: number,
    price: number,
    isBuy: boolean,
    percentage: number,
    token: CancellationToken
  ): Query<any> {
    return this.api.getMaxAmount(account, market, leverage, price, isBuy, percentage, token)
  }

  async closeAllPositions(accountId: string, market: string): Promise<boolean> {
    return this.api.closeAllPositions(accountId, market)
  }

  async cancelAllOpenOrders(accountId: string, market: string): Promise<boolean> {
    return this.api.cancelAllOpenOrders(accountId, market)
  }
}
