import { UserOrder } from '@dataObjects/private/account/UserOrder'
import { UserOrderContainer } from '@dataObjects/private/account/UserOrderContainer'
import { UserTrade } from '@dataObjects/private/account/UserTrade'
import { EventHandler } from '@utils/eventHandlers/EventHandler'
import { KeyedEventHandler } from '@utils/eventHandlers/KeyedEventHandler'
import { _ } from '@utils/lodash'
import { PaginatedResponse } from '@utils/types/PaginatedResponse'
import { AccountApi } from '../account/AccountApi'
import { CancellationToken } from '../api/rest/ApiRequest'
import { ServiceController, ServiceControllerConstructorType } from '../ServiceController'

export class OrderService {
  private api: AccountApi
  private controller: ServiceController

  private orderCache: UserOrder[] = []
  orderUpdate = new KeyedEventHandler<UserOrder>()
  orderUpdates = new EventHandler<UserOrder[]>()

  tradeUpdate = new EventHandler<UserTrade>()

  constructor(props: ServiceControllerConstructorType) {
    this.controller = props.controller
    this.api = new AccountApi(props.authenticator, this.controller)
  }

  onOrderOpened(jsonData: any) {
    const order = new UserOrder(this.controller, jsonData['AID'], jsonData['O'])

    this.orderCache = [...this.orderCache.filter((c) => c.orderId !== order.orderId), order]

    if (this.orderUpdates.hasSubscribers()) this.orderUpdates.fire([...this.orderCache])
  }

  onTradeUpdate(jsonData: any) {
    const trade = new UserTrade(this.controller, jsonData)
    this.tradeUpdate.fire(trade)
  }

  onOrderUpdate(jsonData: any) {
    const orderId = jsonData['OID']
    const order = this.orderCache.find((c) => c.orderId === orderId)
    if (!order) return

    // order = {...order};
    order.type = jsonData['T']
    order.orderPrice = jsonData['OP']
    order.triggerPrice = jsonData['TP']
    order.orderAmount = jsonData['OA']
    order.tradeAmount = jsonData['TA']

    this.orderCache = [...this.orderCache.filter((c) => c.orderId !== orderId), order]

    if (this.orderUpdate.hasSubscribers(orderId)) this.orderUpdate.fire(orderId, order)
    if (this.orderUpdates.hasSubscribers()) this.orderUpdates.fire([...this.orderCache])
  }

  onOrderClosed(orderId: string) {
    this.orderCache = this.orderCache.filter((c) => c.orderId !== orderId)

    if (this.orderUpdates.hasSubscribers()) this.orderUpdates.fire([...this.orderCache])
  }

  onAccountDeleted(accountId: string) {
    this.orderCache = this.orderCache.filter((c) => c.accountId !== accountId)

    if (this.orderUpdates.hasSubscribers()) this.orderUpdates.fire([...this.orderCache])
  }

  async fetchOpenOrders() {
    if (this.controller.authenticator.isWhiteLabel())
      return;

    const response = await this.api.getAllOpenOrders()
    const orderContainers = response.map((c: any) => new UserOrderContainer(this.controller, c))
    this.orderCache = _.selectMany(orderContainers, (value: UserOrderContainer) => value.orders)
  }

  async getOpenOrders(accountGuid: string): Promise<UserOrderContainer> {
    const response = await this.api.getOpenOrders(accountGuid)
    const container = new UserOrderContainer(this.controller, response)
    this.orderCache = [...this.orderCache.filter((c) => c.accountId !== accountGuid), ...container.orders]

    return container
  }

  getAllOpenOrders(accountId?: string): UserOrder[] {
    if (!accountId) return [...this.orderCache]

    return [...this.orderCache.filter((c) => c.accountId === accountId)]
  }

  async getTradeHistory(accountIds: string[], marketSearchTags: string[], nextPageId: number, pageLength: number, token: CancellationToken): Promise<PaginatedResponse<UserTrade>> {
    const response = await this.api.getTradeHistory(accountIds, marketSearchTags, nextPageId, pageLength, token)

    return {
      items: response['I'].map((c: any) => new UserTrade(this.controller, c)),
      nextPageId: response['NP'],
    }
  }
}
