import { HaasScriptLogColors } from '@components/HaasScriptLog/Component/HaasScriptLogMessage'
import { HaasScriptSelectModelView } from '@components/HaasScriptSelectModel/HaasScriptSelectModel'
import { HaasStateStoreProps } from '@utils/HaasStateStore'
import { EditorLayoutTypes } from '../Views/WebEditorDynamicView'
import { EnumWebEditorTabExecutionState, WebEditorTabState } from './Controller/WebEditorTab'
import { HaasScriptThemeType } from './Editors/LuaEditor/Components/providers/HaasScriptTheme.themes'
import WebLuaEditor from './Editors/LuaEditor/WebLuaEditor'
import WebVisualEditor from './Editors/VisualEditor/WebVisualEditor'
import { WebEditorRefsType } from './WebEditor.refs'

export const MinimumWidth = 150
export const MinimumHeight = 150

export type WebEditorPageState = WebEditorState &
  HaasStateStoreProps<WebEditorState> & {
    saveScriptIfNeeded: () => Promise<boolean>
  }

export type WebEditorMemoryState = HaasScriptLogColors & {
  recentScripts: string[]
  selectScriptTab?: HaasScriptSelectModelView

  layoutStyle?: EditorLayoutTypes
  layoutRotate: number
  isScriptSectionCollapsed?: boolean
  isPinnedBacktestRemoteOpen?: boolean

  themeId: string
  fontFamily: string
  fontSize: number
}

export type WebEditorState = WebEditorMemoryState &
  WebEditorRefsType<WebLuaEditor | WebVisualEditor> & {
    scriptId?: string
    scriptIds: string[]

    scriptState?: WebEditorTabState

    isMenuCollapsed?: boolean
    isDebugMatrixOpen?: boolean
    isBacktestRemoteOpen?: boolean
    isSaveAsRequired: boolean
    canShowPinnedRemote?: boolean

    isTouchDevice?: boolean
    hideMobileWarning?: boolean

    customTheme: HaasScriptThemeType
  }

export type WebEditorBacktestProgressUpdate = {
  backtestId: string
  messageId: number
  state: EnumWebEditorTabExecutionState
  progress: number
  message: string
}

export function parseWebEditorBacktestUpdate(jsonData: any) {
  return {
    messageId: jsonData['MID'],
    backtestId: jsonData['BID'],
    state: jsonData['S'],
    progress: jsonData['P'],
    message: jsonData['M'],
  }
}
