import { UserBalanceContainer } from '@dataObjects/private/account/UserBalanceContainer'
import { Query } from '@utils/QueryPromise'
import { AccountApi } from '../account/AccountApi'
import { CancellationToken } from '../api/rest/ApiRequest'
import { ServiceController, ServiceControllerConstructorType } from '../ServiceController'

export class BalanceService {
  private api: AccountApi
  private controller: ServiceController

  constructor(props: ServiceControllerConstructorType) {
    this.api = new AccountApi(props.authenticator, props.controller)
    this.controller = props.controller
  }

  async getBalance(accountId: string, token: CancellationToken): Promise<UserBalanceContainer> {
    const response = await this.api.getBalance(accountId, token)
    return new UserBalanceContainer(response)
  }

  async getAllBalances(token: CancellationToken): Promise<UserBalanceContainer> {
    const response = await this.api.getAllBalances(token)
    return response.map((c: any) => new UserBalanceContainer(c))
  }

  depositFunds(accountId: string, walletId: string, currency: string, amount: number): Query<boolean> {
    return this.api.depositFunds(accountId, walletId, currency, amount)
  }

  withdrawalFunds(accountId: string, walletId: string, currency: string, amount: number): Query<boolean> {
    return this.api.withdrawalFunds(accountId, walletId, currency, amount)
  }
}
