import { ApiRequest, EnumApiMethod } from '../api/rest/ApiRequest'
import { RestApiBase } from '../api/rest/RestApiBase'

export class LoginApi extends RestApiBase {
  private baseUrl = 'UserAPI.php'

  onlinePing(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'PING',
      endPoint: this.baseUrl,
      arguments: {},
    })
  }

  ping(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'PING_AUTH',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  register(email: string, password: string, referral : string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'REGISTER',
      endPoint: 'RegisterAPI.php',
      arguments: {
        email,
        password,
        referral,
      },
    })
  }

  requestActivationEmail(email: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'REQUEST_ACTIVATION_EMAIL',
      endPoint: 'RegisterAPI.php',
      arguments: { email },
    })
  }

  activateAccount(username: string, activationCode: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ACTIVATE_USER',
      endPoint: 'RegisterAPI.php',
      arguments: { username, activationCode },
    })
  }

  confirmTwo(username: string, pinCode: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CONFIRM_TWO_FA',
      endPoint: 'RegisterAPI.php',
      arguments: { username, pinCode },
    })
  }

  login(username: string, password: string, pincode: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'LOGIN',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        username,
        password,
        pincode: pincode,
      },
    })
  }

  loginWithCredentials(email: string, password: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'LOGIN_WITH_CREDENTIALS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        email,
        password,
      },
    })
  }

  loginWithOneTimeCode(email: string, onetimeCode: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'LOGIN_WITH_ONE_TIME_CODE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        email,
        pincode: onetimeCode,
      },
    })
  }

  refreshLicense(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'REFRESH_LICENSE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { },
      canBeEmpty : true
    })
  }

  emailOneTimeCode(email: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'SEND_ONE_TIME_CODE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        email
      },
    })
  }

  registerCaptcha(captchaId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'REGISTER_CAPTCHA',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        captchaId,
      },
    })
  }

  validateCaptcha(challenge: string, validate: string, secondaryCode: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'VALIDATE_CAPTCHA',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        challenge,
        validate,
        secondaryCode,
      },
    })
  }

  validateTurnslide(token : string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'VALIDATE_TURNSLIDE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { token },
    })
  }

  supportLogin(email: string, password: string, supportCode: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'SUPPORT_LOGIN',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        email,
        password,
        supportCode: supportCode,
      },
    })
  }

  logout(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'LOGOUT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  checkToken(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CHECK_TOKEN',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  lostPassword(email: string, pincode: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'LOST_PASSWORD',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        email,
        pincode,
      },
    })
  }

  changePassword(password: string, newpassword: string, pincode: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CHANGE_PASSWORD',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        password,
        newpassword,
        pincode: pincode,
      },
    })
  }

  changeTwoFA(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CHANGE_2FA',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
      },
    })
  }
  generateUsername(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GENERATE_USERNAME',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }
  changeUsername(newusername: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CHANGE_USERNAME',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        newusername,
      },
    })
  }

  changeDisplayName(displayname: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CHANGE_DISPLAYNAME',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        displayname: displayname,
      },
    })
  }

  isDeviceApproved(deviceId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'IS_DEVICE_APPROVED',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { deviceId },
    })
  }
  getAllowedDevices(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ALLOWED_DEVICES',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  removeDevice(id: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'REMOVE_ALLOWED_DEVICE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        id: id,
      },
    })
  }

  approveDevice(deviceId: string, activationCode: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CONFIRM_DEVICE',
      endPoint: 'RegisterAPI.php',
      authenticator: this.authenticator,
      canBeEmpty: true,
      arguments: { deviceId, activationCode },
    })
  }

  setSimTradingConfig(fillWhenTouched : boolean): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'SET_SIM_TRADING_CONFIG',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        fillWhenTouched,
      },
    })
  }

  getSetting(key: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_USER_SETTING',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        key,
      },
    })
  }

  setSetting(key: string, value: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'SET_USER_SETTING',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        key,
        value,
      },
    })
  }

  getConsoleLogbook(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_CONSOLE_LOG',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getInstrumentalData(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_INSTRUMENTAL_DATA',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  changeDebugMode(enabled: boolean): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DEBUG_MODE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { enabled },
    })
  }

  getChangelog(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'USER_CHANGELOG',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  approveChangelog(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'APROVE_CHANGELOG',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  lockUser(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'LOCK_USER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
    })
  }

  submitSupportTicket(subject: string, message: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'SUBMIT_SUPPORT_TICKET',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        subject,
        message,
      },
    })
  }
}
