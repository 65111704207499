import { _ } from '@utils/lodash'
import { UserBalance, UserBalanceValue } from './UserBalance'
import { UserWalletCoinInfo } from './UserWalletContainer'

export class UserBalanceContainer {
  accountId!: string
  items!: UserBalance[]

  constructor(jsonData?: any) {
    this.accountId = jsonData['AID']
    this.items = jsonData['I'].map((c: any) => new UserBalance(c))
  }

  getTotalAmount(currency: string) {
    const items = this.items.find((c) => c.currency === currency)
    return items?.total || 0
  }

  getAvailableAmountOnCurrency(currency: string) {
    const items = this.items.find((c) => c.currency === currency)
    return items?.available || 0
  }

  getAvailableAmountOnWalletId(walletTag: string) {
    const items = this.items.find((c) => c.walletTag === walletTag)
    return items?.available || 0
  }

  getCoins(): UserWalletCoinInfo[] {
    return _.select(this.items, (item) => {
      return {
        coin: item.currency,
        amount: item.available,
      }
    }) as UserWalletCoinInfo[]
  }

  clone() {
    const wallet = new UserBalanceContainer()
    wallet.accountId = this.accountId
    wallet.items = this.items.map((c) => {
      return { ...c }
    })

    return wallet
  }
}

export class UserBalanceValueContainer extends UserBalanceContainer {
  items!: UserBalanceValue[]

  constructor(jsonData?: any) {
    super(jsonData)

    this.items = jsonData['I'].map((c: any) => new UserBalanceValue(c))
  }
}
