import zindex, { Components } from '@utils/zindex'
import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  .notification-container {
    top: 60px;
    z-index: ${zindex(Components.Notifications)};
  }

  .notification {
    opacity: 1;
    border-radius: 0;
    margin-top: 1px;
    //box-shadow: 0 4px 12px -2px rgba(0,0,0,0.65) !important;
    box-shadow: none;
    backdrop-filter: blur(2px);
  }

  .notification .title {
    font-size: 1.2em;
  }

  .notification:hover,
  .notification:focus {
    filter: brightness(1.1);
  }

  .notification-enter {
  }

  .notification-enter.notification-enter-active {
  }

  .notification-leave {
  }

  .notification-leave.notification-leave-active {
  }

  .notification:before {
  }

  .notification-info {
    background-color: #2f96b4ee;
  }

  .notification-info:before {
  }

  .notification-success {
    background-color: #51a351ee;
  }

  .notification-success:before {
  }

  .notification-warning {
    background-color: #f89406ee;
  }

  .notification-warning:before {
  }

  .notification-error {
    background-color: #bd362fee;
  }

  .notification-error:before {
  }
`
