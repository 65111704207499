import { _ } from '@utils/lodash'
import { CloudInitData } from './CloudInitData'

export type CloudMarketType = {
  exchangeCode: string
  primary: string
  secondary: string
  contractName: string
}

export class CloudMarket implements CloudMarketType {
  exchangeCode = ''
  exchangeName = ''
  primary = ''
  secondary = ''
  contractName = ''

  shortName = ''

  get displayName() {
    return this.exchangeName + ' ' + this.shortName
  }

  get tag() {
    return [this.exchangeCode, this.primary, this.secondary, this.contractName].join('_')
  }

  get shortTag() {
    if (!this.contractName) return `${this.primary}-${this.secondary}`

    return `${this.primary}-${this.secondary}-${this.contractName}`
  }

  get searchTag() {
    return `${CloudMarket.getNormalizeCurrency(this.primary)}-${CloudMarket.getNormalizeCurrency(this.secondary)}`
  }

  get walletTag() {
    return `${this.primary}-${this.secondary}`
  }

  constructor(data: string | object, initData: CloudInitData) {
    if (typeof data === 'string') this.parseTag(data)
    else this.parseJson(data)

    const priceSource = initData.priceSources.find((c) => c.exchangeCode === this.exchangeCode)
    this.exchangeName = priceSource?.exchangeName || this.exchangeCode
    this.shortName = this.primary + '/' + this.secondary + (this.contractName ? ' (' + this.contractName + ')' : '')
  }

  equals(market: CloudMarket) {
    return this.tag === market.tag
  }

  private parseTag(marketTag: string) {
    const pieces = marketTag.split('_')
    if (pieces.length < 2) return

    const startIndex = pieces.length === 4 ? 1 : 0

    this.exchangeCode = startIndex ? pieces[0] : ''

    this.primary = pieces[startIndex]
    this.secondary = pieces[startIndex + 1]
    this.contractName = pieces[startIndex + 2] || ''
  }

  private parseJson(jsonObject: any) {
    this.exchangeCode = jsonObject['PS']
    this.primary = jsonObject['P']
    this.secondary = jsonObject['S']
    this.contractName = jsonObject['C']
  }

  static FromTag(marketTag: string): CloudMarketType {
    const pieces = marketTag.split('_')

    return {
      exchangeCode: pieces[0],
      primary: pieces[1],
      secondary: pieces[2],
      contractName: pieces[3],
    }
  }

  static getNormalizeCurrency(currency: string) {
    switch (currency) {
      case 'XBT':
        return 'BTC'
      case 'XDG':
        return 'DOGE'
      default:
        return currency
    }
  }

  static getNormalizeContract(contract: string) {
    return contract
  }

  sort(b: CloudMarket) {
    if (this.exchangeName !== b.exchangeName) return _.stringSort(this.exchangeName, b.exchangeName)

    if (this.primary !== b.primary) return _.stringSort(this.exchangeName, b.exchangeName)

    if (this.secondary !== b.secondary) return _.stringSort(this.exchangeName, b.exchangeName)

    return _.stringSort(this.exchangeName, b.exchangeName)
  }
}
