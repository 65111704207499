import { ServiceController } from '@services/ServiceController'
import { _ } from '@utils/lodash'
import { EnumAccountStatus } from '../../enums/EnumAccountStatus'
import { EnumAccountType } from '../../enums/EnumAccountType'
import { EnumWalletStyle } from '../../enums/EnumWalletStyle'
import { CloudExchange } from '../../public/CloudExchange'
import { CloudPriceSource } from '../../public/CloudPriceSource'
import { CloudTradeMarket } from '../../public/CloudTradeMarket'

export class UserAccount {
  accountId: string
  name: string

  originalName: string
  sortName: string

  exchangeCode: string

  status: EnumAccountStatus
  isLive: boolean
  isSimulated: boolean
  typeAsShortString = ''

  isPublic: boolean

  driverType: EnumAccountType
  driverTypeAsString!: string

  isSpot = false
  isMargin = false
  isLeverage = false

  version : number;

  private services: ServiceController

  get priceSource(): CloudPriceSource {
    return this.services.initData.priceSources.find((c) => c.exchangeCode === this.exchangeCode) as CloudPriceSource
  }

  get exchange(): CloudExchange {
    return this.services.initData.exchanges.find((c) => c.exchangeCode === this.exchangeCode) as CloudExchange
  }
  get exchangeName(): string {
    return this.priceSource.exchangeName
  }

  get markets(): CloudTradeMarket[] {
    const markets = this.priceSource.markets
    if (this.driverType !== EnumAccountType.Margin) return [...markets]

    return [...markets.filter((c) => c.isMargin)]
  }

  get walletMode(): EnumWalletStyle {
    return this.exchange.walletMode
  }

  constructor(services: ServiceController, jsonData: any) {
    this.services = services

    this.accountId = jsonData['AID']

    this.name = jsonData['N']
    this.originalName = this.name
    this.sortName = this.name

    this.exchangeCode = jsonData['EC']

    this.status = jsonData['S']
    this.version = jsonData['V'];

    this.isSimulated = jsonData['IS']
    this.isLive = !this.isSimulated

    if (this.isLive) this.typeAsShortString = 'Live'
    if (this.isSimulated) this.typeAsShortString = 'Sim'

    this.isPublic = jsonData['PA']

    this.driverType = jsonData['ET']
    switch (this.driverType) {
      case EnumAccountType.Spot:
        this.driverTypeAsString = 'Spot'
        this.isSpot = true
        break
      case EnumAccountType.Margin:
        this.driverTypeAsString = 'Margin'
        this.isMargin = true
        break
      case EnumAccountType.Leverage:
        this.driverTypeAsString = 'Leverage'
        this.isLeverage = true
        break
    }

    this.checkNames()
  }

  checkNames() {
    this.sortName = `a-${this.originalName}`
    if (this.isSimulated) {
      this.name = `[Sim] ${this.originalName}`
      this.sortName = `zz-${this.originalName}`
    } else {
      this.name = this.originalName
      this.sortName = this.originalName
    }
  }

  getMarket(marketTag?: string): CloudTradeMarket {
    if (!marketTag) return this.markets[0]

    const market = this.markets.find((c) => c.tag === marketTag)
    if (market) return market

    return this.markets[0]
  }

  getTradableCoins() {
    if (this.isLeverage) return this.markets.map((c) => c.marginCurrency)

    return _.selectMany(this.markets, (c) => [c.primary, c.secondary])
  }

  getNormalizedTradableCoins() {
    if (this.isLeverage) return this.markets.map((c) => c.normalizedMarginCurrency)

    return _.selectMany(this.markets, (c) => [c.normalizedPrimary, c.normalizedSecondary])
  }

  sort(value: UserAccount) {
    return _.stringSort(this.sortName, value.sortName)
  }

  isSupported(isSpotSupported?: boolean, isMarginSupported?: boolean, isLeverageSupported?: boolean) {
    if ([isSpotSupported, isMarginSupported, isSpotSupported].indexOf(undefined) > -1) return true

    if (!isSpotSupported && !isMarginSupported && !isLeverageSupported) return true

    if (this.isSpot && !isSpotSupported) return false

    if (this.isMargin && !isMarginSupported) return false

    if (this.isLeverage && !isLeverageSupported) return false

    return true
  }

  equals(account: UserAccount) {
    return this.accountId === account?.accountId
  }
}
