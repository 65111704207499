import { UserAccount } from '@dataObjects/private/account/UserAccount'
import { CloudMarket } from '@dataObjects/public/CloudMarket'
import { CloudTradeMarket } from '@dataObjects/public/CloudTradeMarket'
import { ApiRequest, CancellationToken, EnumApiMethod } from '../api/rest/ApiRequest'
import { RestApiBase } from '../api/rest/RestApiBase'

export class TradingApi extends RestApiBase {
  private baseUrl = 'TradingAPI.php'

  placeOrder(requestOrder: any): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'PLACE_ORDER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        order: JSON.stringify(requestOrder),
      },
    })
  }

  cancelOrder(accountId: string, orderId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CANCEL_ORDER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        accountId,
        orderId,
      },
    })
  }

  getMargin(account: UserAccount, market: CloudMarket, leverage: number, price: number, amount: number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'USED_MARGIN',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        driverName: account.exchangeCode,
        driverType: account.driverType,
        market: market.tag,
        leverage,
        price,
        amount,
      },
    })
  }

  getMaxAmount(
    account: UserAccount,
    market: CloudMarket | CloudTradeMarket,
    leverage: number,
    price: number,
    isBuy: boolean,
    percentage: number,
    token: CancellationToken
  ): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'MAX_AMOUNT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        accountId: account.accountId,
        market: market.tag,
        leverage,
        price,
        isBuy,
        amountPercentage: percentage,
        usedAmount: 0,
      },
    })
  }

  closeAllPositions(accountId: string, market: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CLOSE_ALL_OPEN_POSITIONS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        accountId,
        market,
      },
    })
  }

  cancelAllOpenOrders(accountId: string, market: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CANCEL_ALL_OPEN_ORDERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        accountId,
        market,
      },
    })
  }
}
