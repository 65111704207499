export class RuntimeAsyncPositionUpdate {
  positionGuid = ''
  positionId = ''

  currentPrice = 0
  realizedProfits = 0
  unrealizedProfits = 0
  roi = 0

  constructor(jsonData: any) {
    this.positionGuid = jsonData['PG']
    this.positionId = jsonData['PID']
    this.currentPrice = jsonData['CP']
    this.realizedProfits = jsonData['RP']
    this.unrealizedProfits = jsonData['UP']
    this.roi = jsonData['ROI']
  }
}
