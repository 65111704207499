import { DashboardWidgetType } from '@pages/Dashboard/Widgets/Components/DashboardWidget.types'
import { ApiRequest, CancellationToken, EnumApiMethod } from '../api/rest/ApiRequest'
import { RestApiBase } from '../api/rest/RestApiBase'

export class DashboardApi extends RestApiBase {
  private baseUrl = 'DashboardAPI.php'

  getDashboards(token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_DASHBOARDS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: {},
    })
  }

  addDashboard(name: string, index: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ADD_DASHBOARD',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        name,
        index,
      },
    })
  }

  editDashboard(dashboardId: string, name: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_DASHBOARD',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { dashboardId, name },
    })
  }

  editDashboardSorting(dashboardIds: string[]): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_DASHBOARD_SORTING',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        dashboardIds: JSON.stringify(dashboardIds),
      },
    })
  }

  cloneDashboard(dashboardId: string, name: string, index: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CLONE_DASHBOARD',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { dashboardId, name, index },
    })
  }

  clearDashboard(dashboardId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CLEAR_DASHBOARD',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { dashboardId },
    })
  }

  deleteDashboard(dashboardId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_DASHBOARD',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { dashboardId },
    })
  }

  getWidgets(dashboardId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_WIDGETS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: { dashboardId },
    })
  }

  addWidget(dashboardId: string, type: DashboardWidgetType, x: number, y: number, z: number, width: number, height: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ADD_WIDGET',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        dashboardId,
        type,
        x,
        y,
        z,
        width,
        height,
      },
    })
  }

  cloneWidget(dashboardId: string, widgetId: string, x: number, y: number, z: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CLONE_WIDGET',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        dashboardId,
        widgetId,
        x,
        y,
        z,
      },
    })
  }

  moveWidget(dashboardId: string, widgetId: string, x: number, y: number, z: number, width: number, height: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'MOVE_WIDGET',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        dashboardId,
        widgetId,
        x,
        y,
        z,
        width,
        height,
      },
    })
  }

  setupWidget(dashboardId: string, widgetId: string, settings: any): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'SETUP_WIDGET',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        dashboardId,
        widgetId,
        specs: JSON.stringify(settings),
      },
    })
  }

  deleteWidget(dashboardId: string, widgetId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_WIDGET',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { dashboardId, widgetId },
    })
  }
}
