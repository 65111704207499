import { _ } from '@utils/lodash'
import { CloudInitData } from './CloudInitData'
import { CloudMarket } from './CloudMarket'

export class CloudTradeMarket extends CloudMarket {
  marginCurrency: string

  get normalizedPrimary() {
    return CloudMarket.getNormalizeCurrency(this.primary)
  }
  get normalizedSecondary() {
    return CloudMarket.getNormalizeCurrency(this.secondary)
  }
  get normalizedContractName() {
    return CloudMarket.getNormalizeCurrency(this.contractName)
  }
  get normalizedMarginCurrency() {
    return CloudMarket.getNormalizeCurrency(this.marginCurrency)
  }

  exchangeSymbol: string

  amountLabel: string
  profitLabel: string

  priceDecimals: number
  amountDecimals: number
  priceStep: number
  amountStep: number

  amountDecimalType: number
  priceDecimalType: number

  makerFee: number
  takerFee: number

  minimumTradeAmount: number
  minimumTradeVolume: number

  contractValue = 0
  contractValueCurrency = ''

  isMargin = false
  isPerpetual = false

  settlementDate = 0

  minLeverage = 0
  maxLeverage = 0

  constructor(jsonObject: any, initData: CloudInitData) {
    super(jsonObject, initData)

    this.marginCurrency = this.primary
    this.amountLabel = this.primary
    this.profitLabel = this.secondary

    this.contractValueCurrency = this.secondary

    if (jsonObject['CD']) {
      this.marginCurrency = jsonObject['CD']['MC']
      this.amountLabel = jsonObject['CD']['AL']
      this.profitLabel = jsonObject['CD']['PL']

      this.contractValue = jsonObject['CD']['CV']
      this.contractValueCurrency = jsonObject['CD']['CVC']

      this.settlementDate = jsonObject['CD']['SD']
      this.isPerpetual = this.settlementDate > 0

      this.minLeverage = jsonObject['CD']['LL']
      this.maxLeverage = jsonObject['CD']['HL']
      this.shortName = jsonObject['CD']['DN'] || this.shortName
    }

    this.exchangeSymbol = jsonObject['ES'];

    this.priceStep = jsonObject['PSP']
    this.amountStep = jsonObject['ASP']
    this.priceDecimals = jsonObject['PD']
    this.amountDecimals = jsonObject['AD']

    this.priceDecimalType = jsonObject['PDT']
    this.amountDecimalType = jsonObject['ADT']

    this.makerFee = jsonObject['MF']
    this.takerFee = jsonObject['TF']

    this.minimumTradeAmount = jsonObject['MTA']
    this.minimumTradeVolume = jsonObject['MTV']

    this.isMargin = jsonObject['IM']
  }

  parsePrice(price: number) {
    return this.parseNumbersToDecimalCount(price)
  }

  parseAmount(amount: number) {
    return this.parseNumbersToDecimalCount(amount)
  }

  isAmountEnough(price: number, amount: number) {
    if (amount <= 0) return 'Amount can not be equal to or below 0.'

    if (amount < this.minimumTradeAmount) return `Amount is below minimum of ${this.minimumTradeAmount} ${this.amountLabel}`

    if (price * amount < this.minimumTradeVolume) return `Amount is below minimum of ${this.minimumTradeVolume} ${this.primary}`

    return true
  }

  calculateMinimumAmount(price: number) {
    let amount = this.minimumTradeAmount
    if (this.minimumTradeVolume > 0) amount = _.round(Math.max(amount, this.minimumTradeVolume / price))

    return amount
  }

  private parseNumbersToDecimalCount(amount: number) {
    const amountNumber = Number(amount)
    if (isNaN(amountNumber)) return amount

    const amountString = amountNumber.toString()
    if (amountString.indexOf('.') < 0) return amount

    const pieces = amountString.split('.')
    if (pieces[1].length < this.amountDecimals) return amount

    pieces[1] = pieces[1].slice(0, this.amountDecimals)
    return Number(pieces.join('.'))
  }

  getLeverages() {
    if (this.minLeverage === this.maxLeverage) return [this.minLeverage]

    const leverage = [0.0, 1.0, 2.0, 3.0, 5.0, 10.0, 15.0, 20, 25.0, 30, 35.0, 40, 45, 50.0, 60, 70, 80, 90, 100.0, 110.0, 120.0, 125.0]

    return leverage.filter((c) => c >= this.minLeverage && c <= this.maxLeverage)
  }

  static isFiat(currency?: string) {
    switch (currency) {
      case 'AUD':
      case 'AWG':
      case 'BUSD':
      case 'CAD':
      case 'CHF':
      case 'CUSD':
      case 'CUSDT':
      case 'EUR':
      case 'EURS':
      case 'GBP':
      case 'GUSD':
      case 'HUSD':
      case 'JPY':
      case 'LUSD':
      case 'MUSD':
      case 'OUSD':
      case 'SUSD':
      case 'TUSD':
      case 'USD':
      case 'USDC':
      case 'USDJ':
      case 'USDK':
      case 'USDN':
      case 'USDS':
      case 'USDT':
      case 'XCAD':
      case 'XSGD':
      case 'ZUSD':
        return true
      default:
        return false
    }
  }
}
