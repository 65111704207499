import { EnumUserNotificationChannel } from '@dataObjects/private/notifications/EnumUserNotificationChannel'
import { EnumUserNotificationsCategory } from '@dataObjects/private/notifications/EnumUserNotificationsCategory'
import { UserNotificationProfile } from '@dataObjects/private/notifications/UserNotificationProfile'
import { ApiRequest, CancellationToken, EnumApiMethod } from '../api/rest/ApiRequest'
import { RestApiBase } from '../api/rest/RestApiBase'

export class NotificationApi extends RestApiBase {
  private baseUrl = 'NotificationsAPI.php'

  getLastNotifications(pageLength: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_LATEST_NOTIFICATIONS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        pageLength,
      },
    })
  }

  getNotificationPage(category: EnumUserNotificationsCategory, nextPageId: number, pageLength: number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_NOTIFICATION_PAGE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        category,
        nextPageId,
        pageLength,
      },
    })
  }

  downloadNotifications(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DOWNLOAD_NOTIFICATIONS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getNotificationProfile(type: EnumUserNotificationChannel): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_NOTIFICATION_PROFILE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        type,
      },
    })
  }

  setNotificationProfile(type: EnumUserNotificationChannel, profile: UserNotificationProfile): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'SET_NOTIFICATION_PROFILE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        type,
        profile: JSON.stringify(UserNotificationProfile.ToApiObject(profile)),
      },
    })
  }

  removeNotificationBot(channel: EnumUserNotificationChannel): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'REMOVE_NOTIFICATION_BOT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { channel },
    })
  }

  getTelegramBot(token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'GET_TELEGRAM_BOT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: {},
    })
  }

  setupTelegramBot(token: string, profile: UserNotificationProfile): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'SETUP_TELEGRAM_BOT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        token,
        profile: JSON.stringify(UserNotificationProfile.ToApiObject(profile)),
      },
    })
  }

  getDiscordBot(token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'GET_DISCORD_BOT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: {},
    })
  }

  setupDiscordBot(serverId: string, token: string, profile: UserNotificationProfile): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'SETUP_DISCORD_BOT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        serverId,
        token,
        profile: JSON.stringify(UserNotificationProfile.ToApiObject(profile)),
      },
    })
  }

  getSlackBot(token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'GET_SLACK_BOT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      arguments: {},
    })
  }

  setupSlackBot(applicationId: string, profile: UserNotificationProfile): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'SETUP_SLACK_BOT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        applicationId,
        profile: JSON.stringify(UserNotificationProfile.ToApiObject(profile)),
      },
    })
  }
}
