import { ApiRequestOptions } from './ApiRequest'

export type ApiResponseType = {
  Success: boolean
  Error: string | null
  Data: any | null
}

export class ApiResponse {
  private options: ApiRequestOptions

  isSuccess = false
  error = ''
  data: any = null

  constructor(options: ApiRequestOptions, response?: ApiResponseType) {
    this.options = options

    if (response === null || response === undefined) return

    try {
      this.data = response.Data
      this.error = ApiResponse.getErrorMessage(this.options, this.data, response.Error)
      this.isSuccess = response.Success
    } catch (e) {
      console.log(e, 'Error parsing server response.', response)

      this.error = 'Response parsing error'
      this.isSuccess = false
    }
  }

  static failed(options: ApiRequestOptions, errorMessage: string): ApiResponse {
    const response = new ApiResponse(options)
    response.error = this.getErrorMessage(options, null, errorMessage)
    return response
  }

  log() {}

  static getErrorMessage(options: ApiRequestOptions, data: any, message: any) {
    if (data) return data
    if (message) return message
    if (!data && options.canBeEmpty) return null;
    if (!data) return 'Empty response'

    return 'Unknown Error'
  }
}
