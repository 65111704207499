import { ApiRequest, EnumApiMethod } from '../api/rest/ApiRequest'
import { RestApiBase } from '../api/rest/RestApiBase'

export class ExternalWalletApi extends RestApiBase {
  private baseUrl = 'ExternalWalletAPI.php'

  getSupportedCurrencies(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'SUPPORTED_CURRENCIES',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
    })
  }

  getWallets(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_WALLETS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
    })
  }

  getWallet(walletid: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_WALLET',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        walletid,
      },
    })
  }

  updateWallet(walletid: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'UPDATE_WALLET',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        walletid,
      },
    })
  }

  addWallet(currency: string, adres: string, name: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'REGISTER_WALLET',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        currency,
        adres,
        name,
      },
    })
  }

  editWallet(walletid: string, currency: string, adres: string, name: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_WALLET',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        walletid,
        currency,
        adres,
        name,
      },
    })
  }

  deleteWallet(walletid: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'REMOVE_WALLET',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        walletid,
      },
    })
  }
}
