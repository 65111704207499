import { ServiceController } from '../../ServiceController'
import { ApiAuthenticator } from '../ApiAuthenticator'

export class RestApiBase {
  protected authenticator: ApiAuthenticator
  protected controller: ServiceController

  constructor(authenticator: ApiAuthenticator, controller: ServiceController) {
    this.authenticator = authenticator
    this.controller = controller
  }
}
