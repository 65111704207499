import {WooLicense} from "@dataObjects/private/webshop/WooLicense";
import {WooProduct} from "@dataObjects/private/webshop/WooProduct";

export class WooOrder {
  id: number
  email: string
  timestamp: number
  lineItems: WooProduct[]
  constructor(jsonData: any) {
    this.id = jsonData['Id']
    this.email = jsonData['Email']
    this.timestamp = jsonData['Timestamp']
    this.lineItems = jsonData['LineItems'].map((data: any) => new WooProduct(data))
  }
}
