import { HaasScriptSettingsForm } from '@components/HaasScriptSettings/HaasScriptSettingsForm'
import { EnumHaasRuntimeType } from '@dataObjects/enums/EnumHaasRuntimeType'
import { HaasScriptBackup } from '@dataObjects/public/HaasScriptBackup'
import { HaasScriptPerformanceProfile } from '@dataObjects/public/HaasScriptPerformanceProfile'
import { HaasBlock } from '@dataObjects/scripting/HaasBlock'
import { HaasCommand } from '@dataObjects/scripting/HaasCommand'
import { HaasElement } from '@dataObjects/scripting/HaasElement'
import { HaasScriptCompiledResult, parseCompileResult } from '@dataObjects/scripting/HaasScriptCompiledResult'
import { HaasScriptItem } from '@dataObjects/scripting/HaasScriptItem'
import { HaasScriptRecord } from '@dataObjects/scripting/HaasScriptRecord'
import { EnumSharedScriptStatus, SharedHaasScriptType } from '@services/public/PublicService'
import { EventHandler } from '@utils/eventHandlers/EventHandler'
import { _ } from '@utils/lodash'
import { secureStorage } from '@utils/SecureStorage'
import Unix from '@utils/Unix'
import { CancellationToken } from '../api/rest/ApiRequest'
import { ServiceController, ServiceControllerConstructorType } from '../ServiceController'
import { ScriptApi } from './ScriptApi'
import {HaasScriptFolderType, parseHaasScriptFolderType} from "@dataObjects/scripting/HaasScriptFolder";

export class ScriptService {
  private api: ScriptApi
  private controller: ServiceController

  private scripts: HaasScriptItem[] = []
  private commands: HaasCommand[] = []
  private blocks: HaasBlock[] = []
  private elements: HaasElement[] = []
  private folders: HaasScriptFolderType[] = []

  scriptRename = new EventHandler<void>()
  luaCommandUpdates = new EventHandler<HaasCommand[]>()
  visualCommandUpdates = new EventHandler<HaasBlock[]>()

  constructor(props: ServiceControllerConstructorType) {
    this.controller = props.controller
    this.api = new ScriptApi(props.authenticator, this.controller)
  }

  async fetchScripts(): Promise<void> {
    if (this.controller.authenticator.isWhiteLabel())
      return;

    if (this.scripts.length) return

    const response = await this.api.getAllScriptItems()
    this.scripts = response.map((data: any) => new HaasScriptRecord(data))
  }

  async fetchCommands(): Promise<void> {
    if (this.controller.authenticator.isWhiteLabel())
      return;

    if (this.commands.length) return

    const response = await this.api.getCommands()
    this.commands = response.map((data: any) => new HaasCommand(data))
    this.luaCommandUpdates.fire(this.commands)
  }

  async fetchBlocks(): Promise<void> {
    if (this.controller.authenticator.isWhiteLabel())
      return;

    if (this.blocks.length) return

    this.blocks = (await this.api.getBlocks())?.map((c: any) => new HaasBlock(c))
  }

  async fetchElements(): Promise<void> {
    if (this.controller.authenticator.isWhiteLabel())
      return;

    if (this.elements.length) return

    const response = await this.api.getElements()
    this.elements = response.map((data: any) => new HaasElement(data))
  }

  async fetchFolders(): Promise<void> {
    if (this.folders.length) return
    const response = await this.api.getAllScriptFolders()
    this.folders = response.map((c: any) => parseHaasScriptFolderType(c))

    _.each(this.folders, folder => {
      folder.parent = this.folders.find(c => c.id === folder.parentId);
      folder.children = this.folders.filter(c => c.parentId == folder.id);
    })
  }

  getScripts(): HaasScriptItem[] {
    return this.scripts
  }

  getCommands(): HaasCommand[] {
    return [...this.commands]
  }

  getBlocks(): HaasBlock[] {
    return [...this.blocks]
  }

  getElements(): HaasElement[] {
    return this.elements
  }

  getFolders(): HaasScriptFolderType[] {
    return [...this.folders]
  }

  getScriptName(scriptId: string) {
    const script = this.scripts.find((c) => c.scriptId === scriptId)
    if (script) return script.name

    return ' - '
  }

  onLuaCommandUpdate(jsonData: any) {
    const command = new HaasCommand(jsonData)
    this.commands = this.commands.filter((c) => c.scriptId !== command.scriptId)
    if (command.isValid) this.commands.push(command)

    const script = this.scripts.find((c) => c.scriptId === command.scriptId)
    if (script) {
      script.isValid = command.isValid
      script.commandName = command.commandName
    }

    this.luaCommandUpdates.fire(this.commands)
  }

  onVisualCommandUpdate(jsonData: any) {
    const block = new HaasBlock(jsonData)
    this.blocks = this.blocks.filter((c) => c.command?.scriptId !== block.command.scriptId)
    if (block.command.isValid) this.blocks.push(block)

    const script = this.scripts.find((c) => c.scriptId === block.command.scriptId)
    if (script) {
      script.isValid = block.command.isValid
      script.commandName = block.command.commandName
    }

    this.visualCommandUpdates.fire(this.blocks)
  }

  getScriptDetails(scriptId?: string): HaasScriptItem | undefined {
    if (!scriptId) return undefined

    return this.scripts.find((c) => c.scriptId === scriptId)
  }

  async getScriptRecord(scriptId: string, token?: CancellationToken): Promise<HaasScriptRecord> {
    const response = await this.api.getScriptRecord(scriptId, token)
    return new HaasScriptRecord(response)
  }

  async getScriptSource(script: HaasScriptItem | string, token?: CancellationToken): Promise<string> {
    const scriptId = typeof script === 'string' ? script : script.scriptId
    const response = await this.api.getScriptRecord(scriptId, token)
    const record = new HaasScriptRecord(response)
    return record.sourceCode
  }

  async addScript(name: string, description: string, script: string, type: EnumHaasRuntimeType): Promise<HaasScriptItem | undefined> {
    const response = await this.api.addScript(name, description, script, type)
    if (!response) return undefined

    const details = new HaasScriptRecord(response)
    this.scripts.push(details)
    this.scripts = _.orderBy(this.scripts, (c) => c.name.toLowerCase())

    return details
  }

  async editScript(scriptId: string, scriptName: string, scriptDescription: string, sourceCode: string): Promise<HaasScriptCompiledResult> {
    const response = await this.api.editScript(scriptId, scriptName, scriptDescription, sourceCode)
    const compileResult = parseCompileResult(response)

    const script = this.getScriptDetails(scriptId)
    if (script && compileResult.dependencies) {
      script.isValid = compileResult.isValid
      script.dependencies = compileResult.dependencies
    }

    this.scriptRename.fire()
    return compileResult
  }

  async editScriptSpecifications(scriptId: string, scriptName: string, scriptDescription: string): Promise<boolean> {
    const script = this.getScriptDetails(scriptId)
    if (!script || !script.canEdit) return false

    const response = await this.api.editScriptSpecifications(scriptId, scriptName, scriptDescription)
    if (!response) return false

    script.name = scriptName
    script.description = scriptDescription
    this.scriptRename.fire()
    return true
  }

  async editScriptSourceCode(scriptId: string, scriptCode: string, settings: HaasScriptSettingsForm): Promise<HaasScriptCompiledResult> {
    const response = await this.api.editScriptSourceCode(scriptId, scriptCode, settings)
    const compileResult = parseCompileResult(response)

    const script = this.getScriptDetails(scriptId)
    if (script) {
      if (script.isCommand && !compileResult.isCommand) {
        // Script changed from command to bot. Remove command.
        this.commands = this.commands.filter((c) => c.scriptId !== scriptId)
        this.luaCommandUpdates.fire(this.commands)

        this.blocks = this.blocks.filter((c) => c.command?.scriptId !== scriptId)
        this.visualCommandUpdates.fire(this.blocks)
      }

      script.isValid = compileResult.isValid
      script.dependencies = compileResult.dependencies
      script.isCommand = compileResult.isCommand
      script.commandName = response['C'] ? `$CC_${response['C']['CN']}` : ''
      script.updatedUnix = Unix.now()
    }

    return compileResult
  }

  async deleteScript(script: HaasScriptItem): Promise<boolean> {
    if (!script.canEdit) return false

    await this.api.deleteScript(script.scriptId)
    this.scripts = this.scripts.filter((c) => c.scriptId !== script.scriptId)

    return true
  }

  publishStrategyToStore(scriptId: string): boolean {
    if (!scriptId) return false
    return Boolean(this.api.publishStrategyToStore(scriptId))
  }

  publishStrategyToCommunity(scriptId: string): boolean {
    if (!scriptId) return false
    return Boolean(this.api.publishStrategyToCommunity(scriptId))
  }

  unpublishStrategy(scriptId: string): boolean {
    if (!scriptId) return false
    return Boolean(this.api.unpublishStrategy(scriptId))
  }

  async profileScript(scriptId: string): Promise<boolean> {
    if (!scriptId) return false
    return Boolean(this.api.profileScript(scriptId))
  }

  async getAllProfiles(market: string): Promise<HaasScriptPerformanceProfile[]> {
    const response = await this.api.getAllProfiles(market)
    return response.map((c: any) => new HaasScriptPerformanceProfile(c))
  }

  async getScriptProfile(scriptId: string, market: string): Promise<HaasScriptPerformanceProfile> {
    const response = await this.api.getScriptProfile(scriptId, market)
    return new HaasScriptPerformanceProfile(response)
  }

  async createNewBackup(scriptId: string): Promise<boolean> {
    if (!scriptId) return false
    return Boolean(this.api.createNewBackup(scriptId))
  }

  async getBackupVersions(scriptId: string): Promise<HaasScriptBackup[]> {
    if (!scriptId) return []
    const response = await this.api.getBackupVersions(scriptId)
    return response.map((c: any) => new HaasScriptBackup(c))
  }

  async getBackupVersion(scriptId: string, wantedVersion: number): Promise<HaasScriptRecord> {
    const response = await this.api.openBackup(scriptId, wantedVersion)
    const record = new HaasScriptRecord(response)

    this.scripts.push(record)
    this.scripts = _.orderBy(this.scripts, (c) => c.name.toLowerCase())

    return record
  }

  async restoreBackup(scriptId: string, wantedVersion: number): Promise<HaasScriptRecord> {
    const response = await this.api.restoreBackup(scriptId, wantedVersion)
    return new HaasScriptRecord(response)
  }

  async shareScript(scriptId: string, scriptName: string, scriptDescription: string, status: EnumSharedScriptStatus, allowedUsers: string): Promise<string> {
    return this.api.shareScript(scriptId, scriptName, scriptDescription, status, allowedUsers)
  }

  saveSharedScript(shareId: string) {
    return this.api.saveSharedScript(shareId)
  }

  viewedSharedScript(shareId: string) {
    return this.api.viewedSharedScript(shareId)
  }

  forkedSharedScript(shareId: string) {
    return this.api.forkedSharedScript(shareId)
  }

  downloadedSharedScript(shareId: string) {
    return this.api.downloadedSharedScript(shareId)
  }

  async deleteSharedScript(shareId : string): Promise<boolean> {
    return this.api.deleteSharedScript(shareId)
  }



  async moveScriptToFolder(scriptId: string, folderId: number) {
    let script = this.scripts.find((c) => c.scriptId === scriptId)
    if (!script) return true

    const response = await this.api.moveScriptToFolder(scriptId, folderId)
    if (!response) return false

    this.scripts = this.scripts.filter((c) => c.scriptId !== scriptId)

    script = { ...script, folderId }
    this.scripts.push(script)
  }

  async createFolder(folderName: string, parent?: HaasScriptFolderType) {
    const response = await this.api.createFolder(folderName, parent?.id ?? -1)
    const folder = parseHaasScriptFolderType(response)

    this.folders = [...this.folders, folder]
    parent?.children.push(folder);
    folder.parent = parent;

    return folder
  }

  async editFolder(folderId: number, name : string) {
    const folder = this.folders.find((c) => c.id === folderId)
    if (!folder) return true

    const response = await this.api.editFolder(folderId, name)
    if (!response) return false

    // Remove folder from list
    this.folders = this.folders.filter((c) => c.id !== folderId)

    // Add fodler
    this.folders.push({
      ...folder,
      name
    });
    
    return true
  }

  async deleteFolder(folderId: number) {
    const folder = this.folders.find((c) => c.id === folderId)
    if (!folder) return true

    const childFolders = [...folder.children]

    for (let i = 0; i < childFolders.length; i++) {
      await this.deleteFolder(childFolders[i].id);
    }

    const response = await this.api.deleteFolder(folderId)
    if (!response) return false

    // Remove folder from parent
    if (folder.parent) folder.parent.children = folder.parent.children.filter((c) => c.id !== folderId)

    // Remove folder from list
    this.folders = this.folders.filter((c) => c.id !== folderId)
    return true
  }

}
