class ColorHelper {
  static Aqua = '#00FFFF'
  static Black = '#000000'
  static Blue = '#0000CC'
  static Cyan = '#00CCCC'
  static DarkGray = '#808080'
  static DarkGreen = '#008000'
  static Fuchsia = '#FF00FF'
  static Gold = '#CC9900'
  static Gray = '#C0C0C0'
  static Green = '#00FF00'
  static Maroon = '#800000'
  static Olive = '#808000'
  static Orange = '#FF8000'
  static Purple = '#FF1a8c'
  static Red = '#FF0000'
  static SkyBlue = '#33CCFF'
  static Teal = '#008080'
  static White = '#FFFFFF'
  static Yellow = '#FFFF00'

  static rgbaToString(r: number, g: number, b: number, a = 1) {
    return `rgba(${r}, ${g}, ${b}, ${a})`
  }

  static getRGBA(color: string) {
    const p = parseInt // Use p as a byte saving reference to parseInt

    color = color.replace(/\s/g, '') // Remove all spaces
    if (color.length === 3 || color.length === 6) color = `#${color}`

    let cache: any

    // Checks for 6 digit hex and converts string to integer
    if ((cache = /#([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})/.exec(color))) return [p(cache[1], 16), p(cache[2], 16), p(cache[3], 16), 1]
    // Checks for 3 digit hex and converts string to integer
    else if ((cache = /#([\da-fA-F])([\da-fA-F])([\da-fA-F])/.exec(color))) return [p(cache[1], 16) * 17, p(cache[2], 16) * 17, p(cache[3], 16) * 17, 1]
    // Checks for rgba and converts string to
    // integer/float using unary + operator to save bytes
    else if ((cache = /rgba\(([\d]+),([\d]+),([\d]+),([\d]+|[\d]*.[\d]+)\)/.exec(color))) return [+cache[1], +cache[2], +cache[3], +cache[4]]
    // Checks for rgb and converts string to
    // integer/float using unary + operator to save bytes
    else if ((cache = /rgb\(([\d]+),([\d]+),([\d]+)\)/.exec(color))) return [+cache[1], +cache[2], +cache[3], 1]

    return [0, 0, 0, 1]
  }

  static createRGBA(color: string, opacity?: number) {
    if (isNaN(Number(opacity))) opacity = 1

    // Already RGBA?
    if (color.indexOf('rgba') > -1) {
      return color.slice(0, color.lastIndexOf(',')) + (opacity ? ',' + opacity : '') + ')'
    }

    // Already RGB?
    if (color.indexOf('rgb') > -1) {
      return color.replace('rgb', 'rgba').replace(')', (opacity ? ',' + opacity : '') + ')')
    }

    // HEX
    const rgb = ColorHelper.hexToRgb(color)
    if (!rgb) return color

    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity ? opacity : '1'})`
  }

  static hexToRgb(hex: string) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i

    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b
    })

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    if (!result) return { r: 0, g: 0, b: 0 }

    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
  }

  static hexToRgba(hex: string, alpha: number) {
    if (alpha > 1) alpha /= 100
    const { r, g, b } = this.hexToRgb(hex)
    return this.rgbaToString(r, g, b, alpha)
  }

  static rgbToHex(r: number, g: number, b: number) {
    const red = this.valueToHex(r)
    const green = this.valueToHex(g)
    const blue = this.valueToHex(b)
    return `${red}${green}${blue}`
  }

  private static valueToHex(value: number) {
    let hex = Number(value).toString(16)
    if (hex.length < 2) {
      hex = '0' + hex
    }
    return hex.toUpperCase()
  }
}

export default ColorHelper
