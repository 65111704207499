import { _ } from '@utils/lodash'
import { EnumHaasCommandCategory } from '../enums/EnumHaasCommandCategory'
import { EnumHaasTradeSignal } from './EnumHaasTradeSignal'
import { HaasScriptInputField } from './HaasScriptInputField'

export class HaasElement {
  Guid = ''
  Name = ''
  Enabled = true

  CommandName = ''
  DisplayName = ''

  InputFields: HaasScriptInputField[] = []
  InputValues: Record<string, any> = {}

  Long = true
  Short = true
  None = true

  GoLong = true
  ExitLong = true
  GoShort = true
  ExitShort = true

  Reverse = false
  Interval = 1

  LongSignal: EnumHaasTradeSignal = EnumHaasTradeSignal.Long
  ShortSignal: EnumHaasTradeSignal = EnumHaasTradeSignal.Short
  NoneSignal: EnumHaasTradeSignal = EnumHaasTradeSignal.None

  Type!: EnumHaasCommandCategory

  constructor(jsonData: any) {
    _.each(jsonData, (value, key) => {
      // @ts-ignore
      this[key] = value
    })

    if (this.CommandName) {
      if (this.Type === EnumHaasCommandCategory.EasyIndicators) {
        this.DisplayName = this.CommandName.replace('Easy', '')
        return
      }

      const pieces = this.CommandName.match(/[A-Z][a-z]+/g)
      if (pieces) {
        for (let i = 1; i < pieces.length; i++) pieces[i] = pieces[i].toLowerCase()

        this.DisplayName = pieces.join(' ')
      }
    }
  }
}
