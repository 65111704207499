export enum EnumHaasChartLineType {
  Line = 0,
  Bars = 1,
  Band = 2,
  Circle = 3,
  Cloud = 4,
  DoubleColor = 5,
  DoubleColorFill = 6,
  Histogram = 7,
  // Number = 8,
  StackedArea = 9,
  Volume = 10,
  SignalBars = 11,
  // Char = 12,
  Shape = 14
}
