import { ServiceController } from '@services/ServiceController'
import { _ } from '@utils/lodash'
import UserTradeFrequency, { UserTradeFrequencyOnAccount, UserTradeFrequencyOnMarket } from './UserTradeFrequency'

class UserTradeFrequencyPortfolio extends UserTradeFrequency {
  perAccount: UserTradeFrequencyOnAccount[]
  perMarket: UserTradeFrequencyOnMarket[]

  constructor(services: ServiceController, jsonData: any) {
    super(jsonData)

    this.perAccount = _.select(jsonData['FPA'], (item, key) => new UserTradeFrequencyOnAccount(services.accountService, String(key), item))
    this.perMarket = _.select(jsonData['FPM'], (item, key) => new UserTradeFrequencyOnMarket(services.initData, String(key), item))
  }
}

export default UserTradeFrequencyPortfolio
