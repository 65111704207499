import { CacheObject } from '@dataObjects/CacheObject'
import { AdministratorApi } from '../AdministratorApi'
import { ServiceHealthReport } from '../dataObjects/serviceReports/ServiceHealthReport'

export class ServiceHealthReportCache extends CacheObject<ServiceHealthReport[]> {
  private api: AdministratorApi

  constructor(api: AdministratorApi) {
    super(5)

    this.api = api
  }

  protected async loadValue() {
    const response = await this.api.getAllServiceReport(this.token)
    return response
      .map((c: any) => new ServiceHealthReport(c))
      .filter((c : ServiceHealthReport) => !c.machineId.includes("WINDOWS"))
  }
}
