export class UserProfile {
  id: string
  email: string
  username: string
  rights: number
  status: boolean
  activationUnix: number


  constructor(jsonData: any) {
    this.id = jsonData['Id']
    this.email = jsonData['Email']
    this.username = jsonData['Username']
    this.rights = jsonData['Rights']
    this.status = jsonData['AccessStatus']
    this.activationUnix = jsonData['ActivationUnix']
  }
}
