import { HaasScriptPerformanceRecord } from '@dataObjects/public/HaasScriptPerformanceRecord'
import { HaasScriptScanField } from '@dataObjects/public/HaasScriptScanField'
import { NumberStatistics } from '@dataObjects/public/NumberStatistics'
import { EnumHaasScriptStatus } from '../enums/EnumHaasScriptStatus'

export class HaasScriptPerformanceProfile {
  scriptId: string

  scriptStatus: EnumHaasScriptStatus
  scriptName: string
  scriptDescription: string
  updatedUnix: number

  userId: string
  userName: string

  usedAtMarkets: string[]
  amount: NumberStatistics
  roiPerDay: NumberStatistics
  maxDrawDown: NumberStatistics
  sharpeRatio: NumberStatistics
  sortinoRatio: NumberStatistics
  winPercentage: NumberStatistics
  profitFactor: NumberStatistics
  cpcIndex: NumberStatistics
  tailRatio: NumberStatistics
  commonSenseRatio: NumberStatistics
  profitMarginRatio: NumberStatistics
  ordersPerDay: NumberStatistics

  performanceRecords: HaasScriptPerformanceRecord[]
  scanFields: HaasScriptScanField[]

  constructor(jsonData: any) {
    this.scriptId = jsonData['SID']
    this.scriptStatus = jsonData['SS']
    this.scriptName = jsonData['SN']
    this.scriptDescription = jsonData['SD']
    this.updatedUnix = jsonData['UU']

    this.userId = jsonData['UID']
    this.userName = jsonData['UN']

    if (jsonData['UM']) this.usedAtMarkets = jsonData['UM'].map((c: any) => String(c))
    else this.usedAtMarkets = []

    this.amount = new NumberStatistics(jsonData['A'])
    this.roiPerDay = new NumberStatistics(jsonData['ROIPD'])
    this.maxDrawDown = new NumberStatistics(jsonData['MDD'])
    this.sharpeRatio = new NumberStatistics(jsonData['SHR'])
    this.sortinoRatio = new NumberStatistics(jsonData['SOR'])
    this.winPercentage = new NumberStatistics(jsonData['WP'])
    this.profitFactor = new NumberStatistics(jsonData['PF'])
    this.cpcIndex = new NumberStatistics(jsonData['CPC'])
    this.tailRatio = new NumberStatistics(jsonData['TR'])
    this.commonSenseRatio = new NumberStatistics(jsonData['CSR'])
    this.profitMarginRatio = new NumberStatistics(jsonData['PMR'])
    this.ordersPerDay = new NumberStatistics(jsonData['OPD'])

    if (jsonData['PR']) this.performanceRecords = jsonData['PR'].map((c: any) => new HaasScriptPerformanceRecord(c))
    else this.performanceRecords = []

    if (jsonData['SF']) this.scanFields = jsonData['SF'].map((c: any) => new HaasScriptScanField(c))
    else this.scanFields = []
  }
}
