type Callback<TObject, TResult> = (item: TObject, key: number | string) => TResult
type Items<TObject> = TObject[] | Record<number | string, TObject> | undefined

export class _ {
  static toBool = (value: boolean | string | number | undefined | null) => {
    if (value === undefined || value === null) return false

    return value === 1 || value === true || value === 'true' || value === 'True'
  }

  static size<TObject>(items: TObject): number {
    if (items === null || items === undefined) return 0

    if (Array.isArray(items)) return (items as TObject[]).length

    return Object.keys(items).length
  }

  static isEmpty<TObject>(items: TObject): boolean {
    return this.size(items) === 0
  }

  static each<TObject, TResult>(items: Items<TObject>, callback: (item: TObject, key: string | number, index: number) => void | boolean): Items<TObject> {
    if (!items) return items

    if (Array.isArray(items)) {
      const length = items.length
      for (let i = 0; i < length; i++) callback(items[i], i, i)

      return items
    }

    const keys = Object.keys(items)
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      const value = items[key]
      const res = callback(value, key, i)

      if (res === false) break
    }

    return items
  }

  static map<TObject, TResult>(items: Items<TObject>, callback: (container: Record<string, TResult>, item: TObject, key: string | number) => void): Record<string, TResult> {
    const container: Record<string, TResult> = {}

    this.each(items, (value, key) => {
      callback(container, value, key)
    })

    return container
  }

  static select<TObject, TResult>(items: Items<TObject>, callback: (item: TObject, key: string | number, index: number) => TResult): TResult[] {
    const container: TResult[] = []

    const index = 0
    this.each(items, (value, key) => {
      container.push(callback(value, key, index))
    })

    return container
  }

  static selectMany<TObject, TResult>(items: Items<TObject>, callback: Callback<TObject, TResult[]>): TResult[] {
    const container: TResult[] = []

    this.each(items, (value, key) => {
      container.push(...callback(value, key))
    })

    return container
  }

  static filter<TObject>(items: Items<TObject>, callback: Callback<TObject, boolean>) {
    const values: any[] = []

    _.each(items, (value, key) => {
      if (callback(value, key)) values.push(value)
    })

    return values
  }

  static filterObj<TObject, TObject2>(props: TObject, rest: TObject2): Omit<TObject, keyof TObject2> {
    const other = {}

    _.each(props, (item: any, key: any) => {
      if ((rest as Object).hasOwnProperty(key)) return
      ;(other as any)[key] = item
    })

    // @ts-ignore
    return other as unknown as TObject2
  }

  static join<TObject>(items: Items<TObject>, separator: string, callback: Callback<TObject, string>): string {
    const container: any[] = []
    this.each(items, (value, key) => {
      container.push(callback(value, key))
    })

    return container.join(separator)
  }

  static min<TObject>(object: Items<TObject>, callback: Callback<TObject, number>) {
    let lowestValue = 0
    let lowestItem: any = null

    _.each(object, (value, key) => {
      const result = callback(value, key)
      if (lowestItem === null || result < lowestValue) {
        lowestItem = value
        lowestValue = result
      }
    })

    return lowestItem
  }

  static max<TObject>(object: Items<TObject>, callback: Callback<TObject, number | undefined>): TObject | undefined {
    let highestValue = 0
    let highestItem: TObject | undefined = undefined

    _.each(object, (value, key) => {
      const result = callback(value, key)
      if (result === undefined) return

      if (highestItem === undefined || result > highestValue) {
        highestItem = value
        highestValue = result
      }
    })

    return highestItem
  }

  static sum<TObject>(object: Items<TObject>, callback: Callback<TObject, number>) {
    let sum = 0

    _.each(object, (value, key) => {
      sum += callback(value, key)
    })

    return sum
  }

  static avg<TObject>(object: Items<TObject>, callback: Callback<TObject, number>) {
    const total = this.sum(object, callback)
    const length = _.size(object)
    if (!length) return 0

    return total / length
  }

  static find<TObject>(object: Items<TObject>, callback: Callback<TObject, boolean>): TObject | undefined {
    let needle: any = undefined

    _.each(object, (value, key) => {
      if (!callback(value, key)) return

      needle = value
      return false
    })

    return needle
  }

  static count<TObject>(object: Items<TObject>, callback: Callback<TObject, boolean>): number {
    let counter = 0
    _.each(object, (item, key) => {
      if (callback(item, key)) counter++
    })
    return counter
  }

  static all<TObject>(object: Items<TObject>, callback: Callback<TObject, boolean>): boolean {
    let isValid = true
    _.each(object, (item, key) => {
      isValid = callback(item, key)
      return isValid
    })
    return isValid
  }

  static any<TObject>(object: Items<TObject>, callback: Callback<TObject, boolean>): boolean {
    return _.find(object, callback) !== undefined
  }

  static first<TObject>(object: Items<TObject>, callback?: Callback<TObject, boolean>): TObject | undefined {
    return _.find(object, (item, key) => (callback ? callback(item, key) : true))
  }

  static distinctBy<TObject>(items: Items<TObject>, by?: Callback<TObject, string | number | boolean>): TObject[] {
    const seenValues: (string | number | boolean)[] = []
    const distinctValues: TObject[] = []

    this.each(items, (value, index) => {
      const key = by ? by(value, index) : (value as unknown as string)
      if (seenValues.indexOf(key) >= 0) return

      seenValues.push(key)
      distinctValues.push(value)
    })

    return distinctValues
  }

  static orderBy<TObject>(items: Items<TObject>, by: Callback<TObject, string | number | boolean>): TObject[] {
    let isNumber = true

    const sorted = this.select(items, (value, index) => {
      const key = by(value, index)
      if (isNaN(Number(key))) isNumber = false

      return { key, value }
    }).sort((a1, b) => {
      return isNumber ? _.numberSort(Number(a1.key), Number(b.key)) : _.stringSort(String(a1.key), String(b.key))
    })

    return _.select(sorted, (item) => item.value)
  }

  static orderDescBy<TObject>(items: Items<TObject>, by: Callback<TObject, string | number | boolean>): TObject[] {
    const sorted = _.orderBy(items, by)
    sorted.reverse()
    return sorted
  }

  static orderByObj<TObject>(items: Items<TObject>, by: Callback<TObject, string | number>): Record<string, TObject> {
    const sorted = this.select(items, (item, key) => {
      return {
        key: by(item, key),
        value: item,
      }
    }).sort((a1, b) => {
      return _.stringSort(String(a1.key), String(b.key))
    })

    return _.map(sorted, (container, value) => (container[value.key] = value.value))
  }

  static groupBy<TObject>(items: Items<TObject>, callback: Callback<TObject, string | number>): Record<string, TObject[]> {
    const container: Record<string, TObject[]> = {}

    this.each(items, (value, key) => {
      const group = callback(value, key)
      if (!container[group]) container[group] = []

      container[group].push(value)
    })

    return container
  }

  static numberSort(a: number, b: number): number {
    if (a > b) return 1

    if (a < b) return -1

    return 0
  }

  static stringSort(a: string, b: string): number {
    a = a?.toString().toLowerCase() || ''
    b = b?.toString().toLowerCase() || ''

    if (a > b) return 1

    if (a < b) return -1

    return 0
  }

  static createGuid() {
    return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line no-mixed-operators
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  static createShortGuid() {
    return 'Axxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line no-mixed-operators
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }
}
