import { EnumNewsFeedType } from '../enums/EnumNewsFeedType'

export class CloudVideoStreamSource {
  id: string
  name: string
  type: EnumNewsFeedType
  typeAsString: string
  url: string

  constructor(jsonData: any) {
    this.id = jsonData['Id']
    this.name = jsonData['Name']
    this.type = jsonData['Type']
    switch (this.type) {
      case EnumNewsFeedType.RSS:
        this.typeAsString = 'RSS-Feed'
        break
      case EnumNewsFeedType.Twitter:
        this.typeAsString = 'Twitter'
        break
      default:
        this.typeAsString = 'Unknown'
    }
    this.url = jsonData['URL']
  }
}
