import {EnumAccountMarketMarginMode} from '@dataObjects/enums/EnumAccountMarketMarginMode'
import {EnumAccountPositionMode} from '@dataObjects/enums/EnumAccountPositionMode'
import {EnumAccountStatus} from '@dataObjects/enums/EnumAccountStatus'
import {UserAccount} from '@dataObjects/private/account/UserAccount'
import {UserAccountMarketSettings} from '@dataObjects/private/account/UserAccountMarketSettings'
import {EventHandler} from '@utils/eventHandlers/EventHandler'
import {_} from '@utils/lodash'
import {ServiceController, ServiceControllerConstructorType} from '../ServiceController'
import {AccountApi} from './AccountApi'

export class AccountService {
  private api: AccountApi
  private controller: ServiceController

  private accounts: UserAccount[] = []

  accountUpdates = new EventHandler<UserAccount>()

  constructor(props: ServiceControllerConstructorType) {
    this.controller = props.controller

    this.api = new AccountApi(props.authenticator, this.controller)
  }

  async fetchAccounts(): Promise<void> {
    if (this.controller.authenticator.isWhiteLabel())
      return;

    const response = await this.api.getAccounts()
    this.accounts = response.map((c: any) => new UserAccount(this.controller, c)).sort((a: UserAccount, b: UserAccount) => _.stringSort(a.name, b.name))
  }

  getAccounts(): UserAccount[] {
    const sortedAccounts = [...this.accounts].sort((a, b) => a.sort(b))
    return sortedAccounts.filter((c) => !!c.exchange)
  }

  getAccount(guidOrName: string | undefined): UserAccount | undefined {
    if (!guidOrName) return
    return this.accounts.find((c) => c.accountId === guidOrName || c.name === guidOrName)
  }

  async renameAccount(guid: string, name: string): Promise<UserAccount | undefined> {
    const account = this.getAccount(guid)
    if (!account) return undefined

    await this.api.renameAccount(guid, name)
    account.originalName = name
    account.checkNames()

    this.accountUpdates.fire(account)
    return account
  }

  async editAccountKeys(accountId: string, version : number, publicKey: string, privateKey: string, extraKey: string): Promise<boolean> {
    const account = this.getAccount(accountId)
    if (!account) return false

    const response = await this.api.editAccountKeys(accountId, version, publicKey, privateKey, extraKey)
    account.status = response ? EnumAccountStatus.Connected : EnumAccountStatus.KeysError
    return response
  }

  async changeAccountVisability(accountId: string, publicVisible: boolean): Promise<boolean> {
    const account = this.api.changeAccountVisability(accountId, publicVisible)
    if (!account) return false

    return true
  }

  async deleteAccount(guid: string): Promise<UserAccount | undefined> {
    const account = this.getAccount(guid)
    if (!account) return undefined

    await this.api.deleteAccount(guid)

    this.accounts = this.accounts.filter((c) => c.accountId !== guid)

    this.controller.orderService.onAccountDeleted(guid)
    this.controller.positionService.onAccountDeleted(guid)

    return account
  }

  async createAccount(driverType: number, driverName: string, version : number, accountName: string, publicKey: string, privateKey: string, extraKey: string): Promise<UserAccount> {
    const response = await this.api.addAccount(accountName, driverName, driverType, version, publicKey, privateKey, extraKey)
    const account = new UserAccount(this.controller, response)
    this.accounts.push(account)
    this.accountUpdates.fire(account)

    return account
  }

  async testAccount(driverType: number, driverName: string, version : number, publicKey: string, privateKey: string, extraKey: string): Promise<boolean> {
    const response = await this.api.testAccount(driverName, driverType, version, publicKey, privateKey, extraKey)
    return !!response
  }

  async checkAccount(accountId : string, exchangeCode : string): Promise<boolean> {
    const account = this.accounts.find(c => c.accountId === accountId);
    if (!account)
      return false;

    const response = await this.api.checkAccount(accountId, exchangeCode);
    if (response)
      await this.fetchAccounts();

    return !!response
  }

  async createSimulatedAccount(driverType: number, driverName: string, accountName: string): Promise<UserAccount> {
    const response = await this.api.addSimulatedAccount(accountName, driverName, driverType)
    const account = new UserAccount(this.controller, response)
    this.accounts.push(account)

    return account
  }

  async getPositionMode(accountId: string, market: string): Promise<EnumAccountPositionMode> {
    const account = this.accounts.find((c) => c.accountId === accountId)
    if (!account) return EnumAccountPositionMode.OneWay

    return this.api.getPositionMode(accountId, market)
  }

  async getMarginSettings(accountId: string, market: string): Promise<UserAccountMarketSettings | undefined> {
    const account = this.accounts.find((c) => c.accountId === accountId)
    if (!account) return

    const response = await this.api.getMarginSettings(accountId, market)
    return new UserAccountMarketSettings(market, response)
  }

  async adjustMarginSettings(accountId: string, market: string, positionMode: EnumAccountPositionMode, marginMode: EnumAccountMarketMarginMode, leverage: number) {
    const account = this.accounts.find((c) => c.accountId === accountId)
    if (!account) return

    await this.api.adjustMarginSettings(accountId, market, positionMode, marginMode, leverage)
  }

  async startOAuth(accountName: string, driverType: number, driverName: string) : Promise<{ account : UserAccount, url : string}> {
    const response = await this.api.startOAuth(accountName, driverName, driverType);
    const account = new UserAccount(this.controller, response['A']);
    this.accounts.push(account)
    this.accountUpdates.fire(account)

    return {
      account,
      url : response['U']
    }
  }
  async isAuthCompleted(accountId: string) : Promise<boolean> {
    const response = await this.api.isAuthCompleted(accountId);
    if (!response) return false;

    const account = this.getAccount(accountId)
    if (!account)
      return true;

    account.status = EnumAccountStatus.Connected;
    this.accountUpdates.fire(account)
    return true;
  }

  async completeTradeStationAuth(responseState: string, responseCode: string) : Promise<boolean> {
    return this.api.completeTradeStationAuth(responseState, responseCode);
  }
}
