import { MarketSocketChannel } from '@services/api/socket/market/MarketSocketChannels'
import MarketSocketClient from '@services/api/socket/market/MarketSocketClient'
import { WebSocketSubscription } from '@services/api/socket/user/UserSocketClient'
import { _ } from '@utils/lodash'

class MarketSocketChannelCollection<T> {
  private socket: MarketSocketClient

  private channelsOnMarket: Record<string, MarketSocketChannel<any>> = {}
  private channelsOnSubscriptionId: Record<string, MarketSocketChannel<any>> = {}

  private channelConstructor: (market: string) => MarketSocketChannel<any>

  constructor(socket: MarketSocketClient, channelConstructor: (market: string) => MarketSocketChannel<any>) {
    this.socket = socket
    this.channelConstructor = channelConstructor
  }

  subscribe(market: string, callback: (data: T) => void): WebSocketSubscription {
    let channel = this.channelsOnMarket[market]
    if (channel) {
      channel.subscribe(callback)
      return new WebSocketSubscription(() => this.unsubscribe(market, callback))
    }

    // Create channel
    channel = this.channelConstructor(market)

    // Initialize channel (subscribes)
    channel.initialize()

    // Register callback
    channel.subscribe(callback)

    // Register channel
    this.channelsOnMarket[market] = channel
    this.channelsOnSubscriptionId[channel.subscriptionId] = channel

    return new WebSocketSubscription(() => this.unsubscribe(market, callback))
  }

  unsubscribe<T>(market: string, callback: (data: T) => void) {
    const channel = this.channelsOnMarket[market]
    if (!channel) return

    // Remove subscription from channel
    channel.unsubscribe(callback)

    // Stop if others are still listening
    if (channel.hasSubscribers()) return

    // Remove channel registration
    delete this.channelsOnMarket[market]
    delete this.channelsOnSubscriptionId[channel.subscriptionId]

    // Dispose channel
    channel.dispose()
  }

  onMessage(subscriberId: string, data: any) {
    const channel = this.channelsOnSubscriptionId[subscriberId]
    if (!channel) return

    channel.onMessage(data)
  }

  onReconnect() {
    _.each(this.channelsOnMarket, (c) => c.initialize())
  }
}

export default MarketSocketChannelCollection
