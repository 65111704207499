import { HaasScriptSettingsForm } from '@components/HaasScriptSettings/HaasScriptSettingsForm'
import { EnumHaasRuntimeType } from '@dataObjects/enums/EnumHaasRuntimeType'
import { HaasBlock } from '@dataObjects/scripting/HaasBlock'
import { EnumSharedScriptStatus } from '@services/public/PublicService'
import { ApiRequest, CancellationToken, EnumApiMethod } from '../api/rest/ApiRequest'
import { RestApiBase } from '../api/rest/RestApiBase'

export class ScriptApi extends RestApiBase {
  private baseUrl = 'HaasScriptAPI.php'
  private backtestApiUrl = 'BacktestAPI.php'

  getCommands(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_COMMANDS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getCommand(scriptId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_COMMAND',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        scriptId: scriptId,
      },
    })
  }

  getBlocks(): ApiRequest<HaasBlock[]> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BLOCKS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getBlock(scriptId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BLOCK',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        scriptId: scriptId,
      },
    })
  }

  getElements(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ELEMENTS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getScriptItem(scriptId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_SCRIPT_ITEM',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      canBeEmpty: true,
      token,
      arguments: { scriptId },
    })
  }

  getAllScriptItems(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ALL_SCRIPT_ITEMS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getScriptRecord(scriptId: string, token?: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_SCRIPT_RECORD',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      canBeEmpty: true,
      token,
      arguments: { scriptId },
    })
  }

  addScript(name: string, description: string, script: string, type: EnumHaasRuntimeType): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ADD_SCRIPT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        name,
        description,
        script,
        type,
      },
    })
  }

  editScript(scriptId: string, scriptName: string, scriptDescription: string, sourceCode: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_SCRIPT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        scriptId: scriptId,
        name: scriptName,
        description: scriptDescription,
        sourceCode,
      },
    })
  }

  editScriptSpecifications(scriptId: string, name: string, description: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_SCRIPT_SPECIFICATIONS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { scriptId, name, description },
    })
  }

  editScriptSourceCode(scriptId: string, sourceCode: string, settings: HaasScriptSettingsForm): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_SCRIPT_SOURCECODE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        scriptId,
        sourceCode: sourceCode,
        settings: JSON.stringify(settings),
      },
    })
  }

  deleteScript(scriptId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_SCRIPT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { scriptId },
    })
  }

  deleteSharedScript(shareId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_SHARED_SCRIPT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { shareId },
    })
  }

  archiveBacktest(backtestId: string, archiveResult: boolean): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ARCHIVE_BACKTEST',
      endPoint: this.backtestApiUrl,
      timeout: 1000 * 60 * 10,
      authenticator: this.authenticator,
      arguments: {
        backtestId,
        archiveResult,
      },
    })
  }

  editBacktestTag(backtestId: string, backtestTag: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_BACKTEST_TAG',
      endPoint: this.backtestApiUrl,
      timeout: 1000 * 60 * 10,
      authenticator: this.authenticator,
      arguments: {
        backtestId,
        backtestTag,
      },
    })
  }

  deleteBacktest(backtestId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_BACKTEST',
      endPoint: this.backtestApiUrl,
      timeout: 1000 * 60 * 10,
      authenticator: this.authenticator,
      arguments: {
        backtestId,
      },
    })
  }

  deleteUnarchivedBacktest(): ApiRequest<boolean> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_UNARCHIVED_BACKTEST',
      endPoint: this.backtestApiUrl,
      timeout: 1000 * 60 * 10,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  publishStrategyToStore(scriptId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'PUBLISH_SCRIPT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { scriptId },
    })
  }

  publishStrategyToCommunity(scriptId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'PUBLISH_COMMUNITY_SCRIPT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { scriptId },
    })
  }

  unpublishStrategy(scriptId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'UNPUBLISH_SCRIPT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { scriptId },
    })
  }

  profileScript(scriptId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'PROFILE_SCRIPT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { scriptId },
    })
  }

  getAllProfiles(market: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_PROFILES',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { market },
    })
  }

  getScriptProfile(scriptId: string, market: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_PROFILE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { scriptId, market },
    })
  }

  getProfileReferenceMarkets(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_PROFILE_REFERENCE_MARKETS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getBackupVersions(scriptId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BACKUP_VERSIONS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { scriptId },
    })
  }

  createNewBackup(scriptId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CREATE_NEW_BACKUP',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { scriptId },
    })
  }

  restoreBackup(scriptId: string, wantedVersion: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'RESTORE_BACKUP',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { scriptId, wantedVersion },
    })
  }

  openBackup(scriptId: string, wantedVersion: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BACKUP',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { scriptId, wantedVersion },
    })
  }

  shareScript(scriptId: string, scriptName: string, scriptDescription: string, status: EnumSharedScriptStatus, allowedUsers: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'SHARE_SCRIPT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { scriptId, scriptName, scriptDescription, status, allowedUsers },
    })
  }
  saveSharedScript(shareId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'SAVE_SHARED_SCRIPT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { shareId },
    })
  }
  viewedSharedScript(shareId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'SHARED_SCRIPT_VIEWED',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { shareId },
    })
  }
  forkedSharedScript(shareId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'SHARED_SCRIPT_FORKED',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { shareId },
    })
  }
  downloadedSharedScript(shareId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'SHARED_SCRIPT_DOWNLOADED',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { shareId },
    })
  }




  getAllScriptFolders(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ALL_SCRIPT_FOLDERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  createFolder(folderName: string, parentId: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CREATE_FOLDER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { folderName, parentId },
    })
  }

  editFolder(folderId: number, name : string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_FOLDER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { folderId, name },
    })
  }

  deleteFolder(folderId: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_FOLDER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { folderId },
    })
  }

  moveScriptToFolder(scriptId: string, folderId: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'MOVE_SCRIPT_TO_FOLDER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { scriptId, folderId },
    })
  }
}
