import { CloudMarket } from '@dataObjects/public/CloudMarket'
import { CloudOrderbook } from '@dataObjects/public/CloudOrderbook'
import { CloudPublicTrade } from '@dataObjects/public/CloudPublicTrade'
import { CloudTick } from '@dataObjects/public/CloudTick'
import { CloudTradeMarket } from '@dataObjects/public/CloudTradeMarket'
import MarketSocketClient from '@services/api/socket/market/MarketSocketClient'
import { _ } from '@utils/lodash'
import { Query } from '@utils/QueryPromise'
import { EnumHaasChartPricePlotStyle } from '@vendor/hxCharts/enums/EnumHaasChartPricePlotStyle'
import { HaasChartContainer } from '@vendor/hxCharts/types/HaasChartContainer'
import { CancellationToken } from '../api/rest/ApiRequest'
import { ServiceControllerConstructorType } from '../ServiceController'
import { PriceApi } from './PriceApi'

export class PriceService {
  private api: PriceApi
  private publicSocket: MarketSocketClient

  private uniqueMarkets?: CloudTradeMarket[]
  private uniqueCoins?: string[]

  constructor(props: ServiceControllerConstructorType) {
    this.api = new PriceApi(props.authenticator, props.controller)
    this.publicSocket = props.publicSocket
  }

  async getPrice(market: CloudMarket, token?: CancellationToken): Promise<number> {
    const response = await this.api.getPrice(market, token)
    return new CloudTick(response).close
  }

  async getTick(market: CloudMarket, token?: CancellationToken): Promise<CloudTick> {
    const response = await this.api.getPrice(market, token)
    return new CloudTick(response)
  }

  getChart(market: CloudMarket, interval: number, style: EnumHaasChartPricePlotStyle, token?: CancellationToken): Query<HaasChartContainer> {
    return this.api.getChart(market, interval, style, token)
  }

  getHistoryDebugChart(market: CloudMarket, token?: CancellationToken): Query<any> {
    return this.api.getHistoryDebugChart(market, token)
  }

  getVolumeDebugChart(market: CloudMarket, token?: CancellationToken): Query<any> {
    return this.api.getVolumeDebugChart(market, token)
  }

  getSpreadDebugChart(market: CloudMarket, token?: CancellationToken): Query<any> {
    return this.api.getSpreadDebugChart(market, token)
  }

  async getOrderbook(market: CloudMarket, token?: CancellationToken): Promise<CloudOrderbook> {
    const response = await this.api.getOrderbook(market, token)
    return new CloudOrderbook(response)
  }

  async getTrades(market: CloudMarket, token?: CancellationToken): Promise<CloudPublicTrade[]> {
    const response = await this.api.getTrades(market, token)

    const trades: CloudPublicTrade[] = response.map((c: any) => new CloudPublicTrade(c))
    return _.orderDescBy(trades, (c) => c.unix)
  }

  async getUniqueCoins(token?: CancellationToken): Promise<string[]> {
    if (this.uniqueCoins) return this.uniqueCoins

    this.uniqueCoins = await this.api.getUniqueCoins(token)
    return this.uniqueCoins as string[]
  }

  async getUniqueMarkets(token?: CancellationToken): Promise<CloudMarket[]> {
    if (this.uniqueMarkets) return this.uniqueMarkets

    this.uniqueMarkets = await this.api.getUniqueMarkets(token)
    return this.uniqueMarkets as CloudMarket[]
  }

  async getPriceCompareChart(
    market1: string,
    market2: string,
    market3: string,
    market4: string,
    market5: string,
    interval: number,
    token?: CancellationToken
  ): Promise<HaasChartContainer> {
    return this.api.getPriceCompareChart(market1, market2, market3, market4, market5, interval, token)
  }

  async getVolumeCompareChart(
    market1: string,
    market2: string,
    market3: string,
    market4: string,
    market5: string,
    interval: number,
    token?: CancellationToken
  ): Promise<HaasChartContainer> {
    return this.api.getVolumeCompareChart(market1, market2, market3, market4, market5, interval, token)
  }

  async getOrderbookCompareChart(
    market1: string,
    market2: string,
    market3: string,
    market4: string,
    market5: string,
    interval: number,
    token?: CancellationToken
  ): Promise<HaasChartContainer> {
    return this.api.getOrderbookCompareChart(market1, market2, market3, market4, market5, interval, token)
  }
}
