export class HaasShopPaymentInstructions {
  invoiceIdentifier: string
  redirectUrl: string
  isFinished: boolean
  paymentId: number

  constructor(jsonData: any) {
    this.invoiceIdentifier = jsonData['InvoiceIdentifier']
    this.redirectUrl = jsonData['RedirectUrl']
    this.isFinished = jsonData['IsFinished']
    this.paymentId = jsonData['PaymentId']
  }
}
