export class LicenseActivation {
  licenseKey: string
  hash: string
  reactivations: number
  version: string
  checkCounter: number
  lastSeen: number

  constructor(jsonData: any) {
    this.licenseKey = jsonData['LicenseKey']
    this.hash = jsonData['Hash']
    this.reactivations = jsonData['Reactivations']
    this.version = jsonData['Version']
    this.checkCounter = jsonData['CheckCounter']
    this.lastSeen = jsonData['LastSeen']
  }
}
