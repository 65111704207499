import { EnumHaasCommandType } from '@dataObjects/enums/EnumHaasCommandType'
import { HaasScriptInputField } from '@dataObjects/scripting/HaasScriptInputField'
import { _ } from '@utils/lodash'
import { Query } from '@utils/QueryPromise'
import { HaasChartPlotType } from '@vendor/hxCharts/types/HaasChartPlotType'
import { CancellationToken } from '../api/rest/ApiRequest'
import { ServiceControllerConstructorType } from '../ServiceController'
import { ChartingApi } from './ChartingApi'

export class IndicatorPlotSettings {
  indicatorId?: string
  type: number
  name: string
  abbreviation: string
  isOverlay: boolean
  parameters: HaasScriptInputField[]

  constructor(jsonData: any) {
    this.type = jsonData.T
    this.name = jsonData.N
    this.abbreviation = jsonData.A
    this.isOverlay = jsonData.IV
    this.parameters = jsonData.P.map((c: any) => new HaasScriptInputField(c))
    _.each(this.parameters, (item) => {
      item.key = item.name
    })
  }
}

export class ChartingService {
  private api: ChartingApi
  private indicators?: IndicatorPlotSettings[]

  constructor(props: ServiceControllerConstructorType) {
    this.api = new ChartingApi(props.authenticator, props.controller)
  }

  async getAvailableIndicators(): Promise<IndicatorPlotSettings[]> {
    if (this.indicators) return this.indicators

    const response = await this.api.getAvailableIndicators()
    this.indicators = response.map((c: any) => new IndicatorPlotSettings(c))

    return this.indicators as IndicatorPlotSettings[]
  }

  async getIndicator(type: EnumHaasCommandType): Promise<IndicatorPlotSettings | undefined> {
    const indicators = await this.getAvailableIndicators()
    return indicators.find((c) => c.type === type)
  }

  getCandlePlot(market: string, interval: number, depth: number, endUnix: number, token: CancellationToken): Query<HaasChartPlotType> {
    return this.api.getCandlePlot(market, interval, depth, endUnix, token)
  }

  getPricePlot(market: string, interval: number, depth: number, endUnix: number, token: CancellationToken): Query<HaasChartPlotType> {
    return this.api.getPricePlot(market, interval, depth, endUnix, token)
  }

  getVolumePlot(market: string, interval: number, depth: number, endUnix: number, token: CancellationToken): Query<HaasChartPlotType> {
    return this.api.getVolumePlot(market, interval, depth, endUnix, token)
  }

  getIndicatorPlot(
    type: number,
    market: string,
    interval: number,
    depth: number,
    endUnix: number,
    parameters: Record<string, any>,
    token: CancellationToken
  ): Query<HaasChartPlotType> {
    return this.api.getIndicatorPlot(type, market, interval, depth, endUnix, parameters, token)
  }
}
