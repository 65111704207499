import { _ } from '@utils/lodash'

export class MarketStat {
  days: number
  change: number
  open: number
  high: number
  low: number
  close: number
  volume: number

  constructor(days: number, jsonObject: any) {
    this.days = days

    this.change = jsonObject['C']
    this.open = jsonObject['O']
    this.high = jsonObject['H']
    this.low = jsonObject['L']
    this.close = jsonObject['CL']
    this.volume = jsonObject['V']
  }
}

export type MarketIndicatorTableType = {
  indicatorName: string
  intervals: number[]
  rows: MarketIndicatorTableRowType[]
}

export type MarketIndicatorTableRowType = {
  indicatorName: string
  settings: string
  values: MarketIndicatorTableCellType[]
}

export type MarketIndicatorTableCellType = {
  interval: number
  currentValue: number
  previousValue: number
  change: number
  isRising: boolean
}

export type MarketIndicatorTableSummaryCellType = MarketIndicatorTableCellType & {
  isSummary: boolean
  bullishSignal: number
  bearishSignal: number
  neutralSignal: number
}

export class MarketPriceInformation {
  timestamp: number
  market: string
  statistics: MarketStat[]

  constructor(jsonObject: any) {
    this.timestamp = jsonObject['RT']
    this.market = jsonObject['M']

    this.statistics = _.select(jsonObject['S'] || {}, (item: any, key) => new MarketStat(Number(key), item))
  }
}

export class MarketTechnicalInformation {
  timestamp: number
  market: string

  trendIndicators: MarketIndicatorTableType[]
  sideWaysIndicators: MarketIndicatorTableType[]

  trendTable: MarketIndicatorTableType
  sideWaysTable: MarketIndicatorTableType

  constructor(jsonObject: any) {
    this.timestamp = jsonObject['RT']
    this.market = jsonObject['M']

    this.trendIndicators = jsonObject['TI'].map((c: any) => this.parseTable(c))
    this.sideWaysIndicators = jsonObject['SI'].map((c: any) => this.parseTable(c))

    const trendingRows = this.trendIndicators.map((c) => c.rows).reduce((previousValue, currentValue) => (previousValue || []).concat(currentValue || []))

    const sideWaysRows = this.sideWaysIndicators.map((c) => c.rows).reduce((previousValue, currentValue) => (previousValue || []).concat(currentValue || []))

    this.trendTable = {
      indicatorName: 'Trending',
      intervals: trendingRows[0].values.map((c) => c.interval),
      rows: trendingRows,
    }
    this.sideWaysTable = {
      indicatorName: 'Trending',
      intervals: sideWaysRows[0].values.map((c) => c.interval),
      rows: sideWaysRows,
    }

    this.createSummaryRow(this.trendTable)
    this.createSummaryRow(this.sideWaysTable)
  }

  private createSummaryRow(table: MarketIndicatorTableType) {
    const summaryRow: MarketIndicatorTableRowType = {
      indicatorName: 'Summary',
      settings: '',
      values: [],
    }

    _.each(table.intervals, (interval) => {
      let bullishSignal = 0
      let bearishSignal = 0
      const neutralSignal = 0

      _.each(table.rows, (row) => {
        const item = row.values.find((c) => c.interval === interval)
        if (!item) return
        if (item.isRising) bullishSignal++
        else bearishSignal++
      })

      summaryRow.values.push({
        interval,
        currentValue: 0,
        previousValue: 0,
        change: 0,
        isRising: false,

        isSummary: true,
        bullishSignal,
        bearishSignal,
        neutralSignal,
      } as MarketIndicatorTableSummaryCellType)
    })

    table.rows.push(summaryRow)
  }

  private parseTable(jsonData: any): MarketIndicatorTableType {
    const rows: MarketIndicatorTableRowType[] = jsonData['R'].map((x: any) => {
      return {
        indicatorName: jsonData['IN'],
        settings: x['S'],
        values: _.select(x['V'], (value: any, interval: any) => {
          return {
            interval: Number(interval),
            currentValue: value[0],
            previousValue: value[1],
            change: ((value[0] - value[1]) / value[1]) * 100,
            isRising: value[0] > value[1],
          }
        }),
      }
    })

    return {
      indicatorName: jsonData['IN'],
      intervals: jsonData['I'],
      rows,
    }
  }
}
