import { HistoryMarketSettings } from '@dataObjects/public/HistoryMarketSettings'
import { _ } from '@utils/lodash'
import { CancellationToken } from '../api/rest/ApiRequest'
import {ServiceController, ServiceControllerConstructorType} from '../ServiceController'
import { SetupApi } from './SetupApi'

export class SetupService {
  private api: SetupApi
  private controller: ServiceController;

  constructor(props: ServiceControllerConstructorType) {
    this.api = new SetupApi(props.authenticator, props.controller)
    this.controller = props.controller;
  }

  async setupLicenseKey(email: string, licensekey: string, token?: CancellationToken): Promise<boolean> {
    return this.api.setupLicenseKey(email, licensekey, token)
  }

  async clearConsoleLogbook(token?: CancellationToken): Promise<boolean> {
    return this.api.clearConsoleLogbook(token)
  }

  async getStoredPriceData(token?: CancellationToken): Promise<boolean> {
    return this.api.getStoredPriceData(token) // map this
  }

  async clearAllPriceHistory(token?: CancellationToken): Promise<boolean> {
    return this.api.clearAllPriceHistory(token)
  }

  async clearExchangePriceHistory(exchange: string, token?: CancellationToken): Promise<boolean> {
    return this.api.clearExchangePriceHistory(exchange, token)
  }

  async clearMarketPriceHistory(market: string, token?: CancellationToken): Promise<boolean> {
    return this.api.clearMarketPriceHistory(market, token)
  }

  async clearAllDashboards(token?: CancellationToken): Promise<boolean> {
    return this.api.clearAllDashboards(token)
  }

  async clearAllBots(token?: CancellationToken): Promise<boolean> {
    return this.api.clearAllBots(token)
  }

  async clearAllAccounts(token?: CancellationToken): Promise<boolean> {
    return this.api.clearAllAccounts(token)
  }

  async clearAllSettings(token?: CancellationToken): Promise<boolean> {
    return this.api.clearAllSettings(token)
  }

  async getHistoryStatus(token?: CancellationToken): Promise<HistoryMarketSettings[]> {
    const response = await this.api.getHistoryStatus(token)
    return _.select(response, (data) => new HistoryMarketSettings(data, this.controller.initData))
  }

  async setHistoryDepth(market: string, monthDepth: number, token?: CancellationToken): Promise<boolean> {
    return this.api.setHistoryDepth(market, monthDepth, token) // TODO: Returns string[historymarketsettings]
  }

  async removeTrackedMarket(market: string, token?: CancellationToken): Promise<boolean> {
    return this.api.removeTrackedMarket(market, token)
  }

  async exportDatabase(token?: CancellationToken): Promise<boolean> {
    return this.api.exportDatabase(token)
  }

  async importDatabase(token?: CancellationToken): Promise<boolean> {
    return this.api.importDatabase(token)
  }

  async migrateDatabase(token?: CancellationToken): Promise<boolean> {
    return this.api.migrateDatabase(token)
  }
}
