/* eslint no-use-before-define: 0 */        // --> OFF
import React, { Attributes } from 'react'
import { Excludes } from './genericTypes'
import { LZString } from './lzstring'

type Callback<TObject, TResult> = (item: TObject, key : number|string) => TResult;
type Items<TObject> = TObject[]|Record<number|string, TObject>|undefined;


export class _ {

  static wait(milliseconds : number) : Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, milliseconds)
    })
  }

  static delay<T>(milliseconds : number, callback : () => T) : Promise<T> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(callback())
      }, milliseconds)
    })
  }

  static delayAsync<T>(milliseconds : number, callback : () => Promise<T>) : Promise<T>  {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        callback()
          .then(resolve)
          .catch(reject)
      }, milliseconds)
    })
  }

  static getDirectory(...paths: (number|string|undefined)[]): string {
    const path = this.filter(paths, c => !!c).join("/")
    if (!path) return '';
    if (path[0] === "/")
      return path;

    return `/${path}`
  }

  static parseQueryParameters(search : string) : Map<string, string> {
    const map = new Map<string, string>();

    if (!search) return map;
    if (search.startsWith("?"))
      search = search.substring(1);

    search.split("&").map(c => {
      const [key, value] = c.split("=");
      if (!key || !value)
        return;

      map.set(key, value);
    });

    return map;
  }


  static isTouchDevice() {
    return !!('ontouchstart' in window || navigator.msMaxTouchPoints);
  };


  static size<TObject>(items: TObject) : number {
    if (items === null || items === undefined)
      return 0;

    if (Array.isArray(items))
      return (items as TObject[]).length;

    return Object.keys(items).length
  }

  static isEmpty<TObject>(items: TObject) : boolean {
    return this.size(items) === 0;
  }


  static each<TObject, TResult>(items: Items<TObject>, callback: (item: TObject, key : string|number, index : number) => void|boolean) : Items<TObject> {
    if (!items) return items;

    if (Array.isArray(items)) {
      const length = items.length;
      for (let i = 0; i < length; i++)
        callback(items[i], i, i);

      return items;
    }

    const keys = Object.keys(items);
    for (let i = 0; i < keys.length; i++) {

      const key = keys[i];
      const value = items[key];
      const res = callback(value, key, i);

      if (res === false)
        break;
    }

    return items;
  }

  static map<TObject, TResult>(items: Items<TObject>, callback: (container : Record<string, TResult>, item: TObject, key : string|number) => void) : Record<string, TResult> {

    const container:Record<string, TResult> = { };

    this.each(items, (value, key) => {
      callback(container, value, key);
    });

    return container;
  }

  static join<TObject>(items: Items<TObject>, separator: string, callback: Callback<TObject, string>) : string {

    const container : any[] = [ ];
    this.each(items, (value, key) => {
      container.push(callback(value, key));
    });

    return container.join(separator);
  }

  static select<TObject, TResult>(items: Items<TObject>, callback: (item: TObject, key : string|number, index : number) => TResult) : TResult[] {

    const container : TResult[] = [];

    let index = 0;
    this.each(items, (value, key) => {
      container.push(callback(value, key, index));
      index++
    });

    return container;
  }

  static selectMany<TObject, TResult>(items: Items<TObject>, callback: Callback<TObject, TResult[]>) : TResult[] {

    const container : TResult[] = [];

    this.each(items, (value, key) => {
      container.push(...callback(value, key));
    });

    return container;
  }


  static filter<TObject>(items : Items<TObject>, callback: Callback<TObject, boolean>) {

    const values:any[] = [];

    _.each(items, (value, key) => {
      if (callback(value, key))
        values.push(value);
    });

    return values;
  }

  static filterObj<TObject, TObject2>(props : TObject, rest : TObject2) : Omit<TObject, keyof TObject2> {
    const other = { };

    const keys = Object.keys(rest);
    _.each(props, (item : any, key : any) => {
      if (keys.includes(key))
        return;

      (other as any)[key] = item;
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return other as unknown as TObject2;
  }

  static filterProps<TCompProps, TProps extends TCompProps & Record<string, any>>(defaultProps : TCompProps, props : TProps) : [TCompProps, Excludes<TProps, TCompProps>] {

    const keys = Object.keys(defaultProps);
    const typeProps = _.map(props, (container, item, key) => {
      if (!keys.includes(String(key)))
        return;

      container[key] = item;
    }) as TCompProps;

    const restProps = _.map(props, (container, item, key) => {
      if (keys.includes(String(key)))
        return;

      container[key] = item;
    }) as Excludes<TProps, TCompProps>;

    return [typeProps, restProps];
  }



  static min<TObject>(object : Items<TObject>, callback: Callback<TObject, number>) {

    let lowestValue  = 0;
    let lowestItem : any = null;

    _.each(object, (value, key) => {

      const result = callback(value, key);
      if (lowestItem === null || result < lowestValue) {
        lowestItem = value;
        lowestValue = result;
      }
    });

    return lowestItem;
  }

  static max<TObject>(object : Items<TObject>, callback: Callback<TObject, number|undefined>) {

    let highestValue  = 0;
    let highestItem : any = null;

    _.each(object, (value, key) => {

      const result = callback(value, key);
      if (result === undefined)
        return;

      if (highestItem === null || result > highestValue) {
        highestItem = value;
        highestValue = result;
      }
    });

    return highestItem;
  }

  static sum<TObject>(object : Items<TObject>, callback: Callback<TObject, number>) {

    let sum = 0;

    _.each(object, (value, key) => {
      sum += callback(value, key);
    });

    return sum;
  }

  static avg<TObject>(object : Items<TObject>, callback: Callback<TObject, number>) {

    const total = this.sum(object, callback);
    const length = _.size(object);
    if (!length)
      return 0;

    return total / length;
  }


  static find<TObject>(object : Items<TObject>, callback: Callback<TObject, boolean>) : TObject|undefined {

    let needle:any = undefined;

    _.each(object, (value, key) => {
      if (!callback(value, key))
        return;

      needle = value;
      return false;
    });

    return needle;
  }

  static count<TObject>(object : Items<TObject>, callback: Callback<TObject, boolean>) : number {
    let counter = 0;
    _.each(object, (item, key) => {
      if (callback(item, key))
        counter++;
    });
    return counter;
  }

  static all<TObject>(object : Items<TObject>, callback: Callback<TObject, boolean>) : boolean {
    let isValid = true;
    _.each(object, (item, key) => {
      isValid = callback(item, key);
      return isValid;
    });
    return isValid;
  }

  static any<TObject>(object : Items<TObject>, callback: Callback<TObject, boolean>) : boolean {
    return _.find(object, callback) !== undefined;
  }

  static first<TObject>(object : Items<TObject>, callback?: Callback<TObject, boolean>) : TObject|undefined {
    return _.find(object, (item, key) => callback ? callback(item, key) : true);
  }


  static distinctBy<TObject>(items: Items<TObject>, by? : Callback<TObject, string|number|boolean>) : TObject[] {

    const seenValues:(string|number|boolean)[] = [];
    const distinctValues: TObject[] = [];

    this.each(items, (value, index) => {
      const key = by ? by(value, index) : value as unknown as string;
      if (seenValues.indexOf(key) >= 0)
        return;

      seenValues.push(key);
      distinctValues.push(value);
    });

    return distinctValues;
  }


  static orderBy<TObject>(items: Items<TObject>, by : Callback<TObject, string|number|boolean>) : TObject[] {

    let isNumber = true;

    const sorted = this.select(items, (value, index) => {
      const key = by(value, index);
      if (isNaN(Number(key)))
        isNumber = false;

      return { key, value };

    }).sort((a1, b) => {
      return isNumber
        ? _.numberSort(Number(a1.key), Number(b.key))
        : _.stringSort(String(a1.key), String(b.key))
    });

    return _.select(sorted, item => item.value);
  }

  static orderDescBy<TObject>(items: Items<TObject>, by : Callback<TObject, string|number|boolean>) : TObject[] {
    const sorted = _.orderBy(items, by);
    sorted.reverse();
    return sorted;
  }

  static orderByObj<TObject>(items: Items<TObject>, by : Callback<TObject, string|number>) : Record<string, TObject> {

    const sorted = this.select(items, (item, key) => {
      return {
        key : by(item, key),
        value : item
      }
    }).sort((a1, b) => {
      return _.stringSort(String(a1.key), String(b.key))
    });

    return _.map(sorted, (container, value) => container[value.key] = value.value);
  }

  static groupBy<TObject>(items: Items<TObject>, callback: Callback<TObject, string|number>) : Record<string, TObject[]> {

    const container : Record<string, TObject[]> = { };

    this.each(items, (value, key) => {
      const group = callback(value, key);
      if (!container[group])
        container[group] = [];

      container[group].push(value);
    });

    return container;
  }

  static clone<T>(classObject : T) : T {
    if (classObject === null || typeof classObject !== "object")
      return classObject;

    const props = Object.getOwnPropertyDescriptors(classObject);
    _.each(props, (item, key) => {
      props[key].value = _.clone(props[key].value)
    });

    return Object.create(
      Object.getPrototypeOf(classObject),
      props
    )
  }

  static copyClass<T>(classObject : T) : T {
    return Object.assign( Object.create( Object.getPrototypeOf(classObject)), classObject)
  }


  static areEqual<TObject>(item1 : TObject, item2 : TObject, ...predictions : ((item : TObject) => string|number|boolean|undefined|any)[]) : boolean {

    if (!predictions.length){

      if (item1 === null && item2 === null) return true;
      if (item1 === null || item2 === null) return false;

      if (item1 === undefined && item2 === undefined) return true;
      if (item1 === undefined || item2 === undefined) return false;

      switch (typeof item1) {
        case "undefined":
        case "number":
        case "boolean":
        case "string":
        case "function":
        case "symbol":
        case "bigint":
          return item1 === item2;

        case "object":
          if((item1 as any).equals && (item2 as any).equals) {
            return (item1 as any).equals(item2);
          }

          predictions = _.select(item1 as any, (item : any, key : any) =>
            (item : any) => item[key]);
      }
    }

    return predictions.every(prediction => {
      const value1 = item1 ? prediction(item1) : undefined;
      const value2 = item2 ? prediction(item2) : undefined;

      if (typeof value1 !== typeof value2)
        return false;

      switch (typeof value1) {

        case "object":
          return this.areEqual2(0, value1, value2);

        case "undefined":
        case "number":
        case "boolean":
        case "string":
        case "function":
        case "symbol":
        case "bigint":
        default:
          return value1 === value2;
      }
    });
  }


  static areEqual2<TObject>(counter : number, item1 : TObject, item2 : TObject, ...predictions : ((item : TObject) => string|number|boolean|undefined|any)[]) : boolean {

    if (!predictions.length){

      if (item1 === null && item2 === null) return true;
      if (item1 === null || item2 === null) return false;

      if (item1 === undefined && item2 === undefined) return true;
      if (item1 === undefined || item2 === undefined) return false;

      switch (typeof item1) {
        case "undefined":
        case "number":
        case "boolean":
        case "string":
        case "function":
        case "symbol":
        case "bigint":
          return item1 === item2;

        case "object":
          if((item1 as any).equals && (item2 as any).equals) {
            return (item1 as any).equals(item2);
          }

          predictions = _.select(item1 as any, (item : any, key : any) =>
            (item : any) => item[key]);
      }
    }

    return predictions.every(prediction => {
      const value1 = item1 ? prediction(item1) : undefined;
      const value2 = item2 ? prediction(item2) : undefined;

      if (typeof value1 !== typeof value2)
        return false;

      switch (typeof value1) {

        case "object":
          return this.areEqual2(counter++, value1, value2);

        case "undefined":
        case "number":
        case "boolean":
        case "string":
        case "function":
        case "symbol":
        case "bigint":
        default:
          return value1 === value2;
      }
    });
  }

  static areNotEqual<TObject>(item1 : TObject, item2 : TObject, ...predictions : ((item : TObject) => string|number|boolean|undefined|any)[]) : boolean {
    return !this.areEqual(item1, item2, ...predictions);
  }



  static numberSort(a: number, b: number) : number{
    if (a > b)
      return 1;

    if (a < b)
      return -1;

    return 0;
  }

  static stringSort(a: string, b: string) : number{
    a = a?.toString().toLowerCase() || "";
    b = b?.toString().toLowerCase() || "";

    if (a > b)
      return 1;

    if (a < b)
      return -1;

    return 0;
  }



  static stringSearch(needle?: string, ...hayStacks: (string|number|undefined)[]) : boolean {
    if (!needle) return true;
    if (!hayStacks.length) return true;

    return hayStacks.some(hayStack =>
      hayStack !== undefined && hayStack !== null && hayStack.toString().toLowerCase().indexOf(needle.toString().toLowerCase()) > -1);
  }

  static stringEquals(needle?: string, ...hayStacks: string[]) : boolean {
    if (!needle) return true;
    if (!hayStacks.length) return false;

    return hayStacks.some(hayStack =>
      hayStack.toLowerCase().trim() === needle.toString().toLowerCase().trim());
  }

  static stringSplitOnUpper = (value: string) : string[] => {
    if (value.length === 1)
      return [value];

    if (value === value.toUpperCase())
      return [value];

    return value.match(/([A-Z]?[^A-Z]*)/g)?.slice(0,-1) || []
  };

  static stringFormat = (text: string, replacements : Record<string, string|number>) => {
    _.each(replacements, (value, key) => {
      text = text.replace(`{${key}}`, value as string);
    });

    return text;
  };

  static toNumber = (value : boolean|string|number|undefined|null) => {
    const number = Number(value);
    if (isNaN(number))
      return 0;

    if (!isFinite(number))
      return 0;

    return number;
  };

  static toBool = (value : boolean|string|number|undefined|null) => {
    if (value === undefined || value === null)
      return false;

    return value === 1 ||
      value === true ||
      value === "true" ||
      value === "True";
  };


  static tryToBool = (value : boolean|string|number|undefined|null) => {
    switch (value) {
      case 1:
      case "1":
      case true:
      case "True":
      case "true":
        return true;

      case 0:
      case "0":
      case false:
      case "False":
      case "false":
        return false;
    }

    return undefined;
  };

  static round = (value? : number, decimals? : number) => {
    if (value === undefined)
      return 0;

    if (decimals === undefined)
      return Math.round(value);

    const multiplier = Math.pow(decimals, 10);

    return Math.round(value * multiplier) / multiplier;
  };


  static createGuid() {
    return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line no-mixed-operators
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static createShortGuid() {
    return 'Axxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line no-mixed-operators
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }


  static timeFormatter = new Intl.DateTimeFormat(undefined, {
    hour : "2-digit",
    minute : "2-digit",
    // second : "2-digit",
    hour12 : false
  });

  static dateFormatter = new Intl.DateTimeFormat(undefined, {
    year : "2-digit",
    month : "short",
    day : "2-digit",
  });

  static dateTimeFormatter = new Intl.DateTimeFormat(undefined, {
    year : "2-digit",
    month : "short",
    day : "2-digit",
    hour : "2-digit",
    minute : "2-digit",
    // second : "2-digit",
    hour12 : false
  });

  static unixToShortTimeString(unix : number, timezone? : string, hour12? : boolean, withoutSeconds? : boolean) {
    if (!unix)
      return " - ";

    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: !withoutSeconds ? "2-digit" : undefined,
      hour12: hour12 ?? false
    };

    if (timezone) (options as any).timeZone = timezone;

    let timeString = new Date(unix * 1000).toLocaleTimeString(undefined, options);
    if (timeString.startsWith("24:"))
      timeString = `00:${timeString.substring(3)}`;

    return timeString
  }


  static unixToShortDateString(unix : number, timezone? : string){
    if (!unix)
      return " - ";

    const options = {
      year : "2-digit",
      month : "short",
      day : "2-digit"
    };

    if (timezone)
      (options as any).timeZone = timezone;

    return new Date(unix * 1000).toLocaleDateString(undefined, options);
  }

  static unixToDateTimeString(unix : number, timezone? : string, hour12? : boolean) {
    if (!unix)
      return " - ";

    const options = {
      year : "2-digit",
      month : "short",
      day : "2-digit",
      hour : "2-digit",
      minute : "2-digit",
      second : "2-digit",
      hour12 : hour12 ?? false
    };

    if (timezone) (options as any).timeZone = timezone;


    const dtString = new Date(unix * 1000).toLocaleTimeString(undefined, options);
    return dtString.replace(" 24:", " 00:")
  }


  static getExchangeUrl(exchangeCode : string|undefined) : string {
    if (!exchangeCode) return "";
    return `/assets/images/exchanges/${exchangeCode.toLowerCase()}.ico`;
  }

  static getCoinUrl(coin: string|undefined) : string {
    if (!coin) return "";
    return `/assets/coins/${coin.toLowerCase()}.png`;
  }


  static normalize(value:number|string) {
    if (value === null || value === undefined)
      value = "";

    const nativeNumber = Number(value);
    if (isNaN(nativeNumber))
      return value;

    return value.toString().replace(",", ".");
  }

  static whatDecimalSeparator() {
    const n = 1.1;
    return n.toLocaleString().substring(1, 2);
  }

  static whatDecimalSeparatorNot() {
    if (this.whatDecimalSeparator() === ".")
      return ",";

    return ".";
  }

  static downloadToTextFile(text: string, extension: string, name : string){

    try {
      const fileContents = text;
      const filename = `${name}.${extension}`;
      const filetype = "text/plain";

      const a = document.createElement("a");
      a.href = "data:" + filetype +
        ";base64," + btoa(fileContents);
      a['download'] = filename;

      const e = document.createEvent("MouseEvents");

      // Use of deprecated function to satisfy TypeScript.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      e.initMouseEvent("click", true, false, document.defaultView, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
      a.remove();
      return true;
    } catch (ex) {

    }

    return false;
  }

  static downloadToCsv(text: string, name : string){

    try {
      const fileContents = text;
      const filename = `HTS-${name}-${Math.floor(Date.now() / 1000)}.csv`;
      const filetype = "text/plain";

      const a = document.createElement("a");
      a.href = "data:" + filetype +
        ";base64," + btoa(fileContents);
      a['download'] = filename;

      const e = document.createEvent("MouseEvents");

      // Use of deprecated function to satisfy TypeScript.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      e.initMouseEvent("click", true, false, document.defaultView, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);

      a.remove();
      return true;
    } catch (ex) {

    }

    return false;
  }

  static toCsv<TObject, TCsv>(name : string, object : Items<TObject>, callback: Callback<TObject, TCsv>) {

    const delimiter = "\t";

    const items = _.select(object, callback);
    if (!items.length)
      return "";



    const headers : string[] = [];
    for (const property in items[0])  {
      if ((items[0] as any).hasOwnProperty(property)) {
        headers.push(property);
      }
    }

    const rows : string[][] = [
      headers
    ];
    _.each(items, item => {
      const row : string[] = [];
      _.each(headers, header => {
        const value = (item as any)[header];
        row.push(value)
      });

      rows.push(row);
    });

    const csvString = rows
      .map(c => c.join(delimiter))
      .join("\r");

    this.downloadToCsv(csvString, name);

  }

  static copyToClipboard(text : string) {
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  static getChildren<T>(children: ParameterChildType<T>) : ResultChildType<T>[] {
    if (!children) return [];

    return React.Children.toArray(children)
      .filter(c  => typeof c === "object") as ResultChildType<T>[];
  }

  static getNestedChildren<T>(children: ParameterChildType<T>) : ResultChildType<T>[] {
    if (!children) return [];

    return React.Children.toArray(children)
      .filter(child => !!child)
      .map((child) => React.Children.toArray(child.props.children))
      .flat()
      .filter(c  => typeof c === "object") as ResultChildType<T>[];
  }

  static cloneChildren<T>(children: ParameterChildType<T>, props? : (child : React.ReactElement<T>, index: number) => Partial<T> & Attributes) : ResultChildType<T>[] {
    return _.getChildren<T>(children).map((c, index) => {
      let extraProps = {};
      if (typeof props === "function")
        extraProps = (props as (child : React.ReactElement<T>, index: number) => Partial<T> & Attributes)(c, index);
      if (typeof props === "object")
        extraProps = props;

      return React.cloneElement<T>(c, {
        ...extraProps
      })
    });
  }

  static zipString (text : string) {
    return LZString.compressToBase64(text);
  }

  static unzipString (zippedText : string) : string {
    return LZString.decompressFromBase64(zippedText) as string;
  }

  static reorder(array: any[], fromIndex :number, toIndex :number): any[] {
    const result = Array.from(array);
    const [removed] = result.splice(fromIndex, 1);
    result.splice(toIndex, 0, removed);

    return result;
  }
}

export type ParameterChildType<T> = React.ReactElement<T>[] | React.ReactElement<T> | undefined | null | boolean | string | number | any;
export type ResultChildType<T> = React.ReactElement<T>;

type ResultChildKeyedType<T> = ResultChildType<T> & {
  guid: string;
};

export default _