import { Query } from '@utils/QueryPromise'
import { ApiRequest, CancellationToken, EnumApiMethod } from '../api/rest/ApiRequest'
import { RestApiBase } from '../api/rest/RestApiBase'

export class ChartingApi extends RestApiBase {
  private baseUrl = 'ChartingAPI.php'

  getAvailableIndicators(): Query<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'GET_AVAILABLE_INDICATORS',
      endPoint: this.baseUrl,
      arguments: {},
    })
  }

  getCandlePlot(market: string, interval: number, depth: number, endUnix: number, token: CancellationToken): Query<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'GET_CANDLE_PLOT',
      endPoint: this.baseUrl,
      arguments: {
        market,
        interval,
        depth,
        endUnix,
      },
    })
  }

  getPricePlot(market: string, interval: number, depth: number, endUnix: number, token: CancellationToken): Query<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'GET_PRICE_PLOT',
      endPoint: this.baseUrl,
      arguments: {
        market,
        interval,
        depth,
        endUnix,
      },
    })
  }

  getVolumePlot(market: string, interval: number, depth: number, endUnix: number, token: CancellationToken): Query<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'GET_VOLUME_PLOT',
      endPoint: this.baseUrl,
      arguments: {
        market,
        interval,
        depth,
        endUnix,
      },
    })
  }

  getIndicatorPlot(type: number, market: string, interval: number, depth: number, endUnix: number, parameters: Record<string, any>, token: CancellationToken): Query<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'GET_INDICATORS_PLOT',
      endPoint: this.baseUrl,
      arguments: {
        type,
        market,
        interval,
        depth,
        endUnix,
        parameters: JSON.stringify(parameters),
      },
    })
  }
}
