export class HaasExchangeServiceAccountReport {
  machineId: string
  serviceId: string
  serviceName: string

  userId: string
  accountId: string
  accountName: string
  exchangeCode: string

  wallet: Record<string, number>
  orders: Record<string, number>
  positions: Record<string, number>

  constructor(jsonData: any, machineId: string, serviceId: string, serviceName: string) {
    this.machineId = machineId
    this.serviceId = serviceId
    this.serviceName = serviceName

    this.userId = jsonData['UID']
    this.accountId = jsonData['AID']
    this.accountName = jsonData['AN']
    this.exchangeCode = jsonData['EC']

    this.wallet = jsonData['W']
    this.orders = jsonData['O']
    this.positions = jsonData['P']
  }
}
