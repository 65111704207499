import { _ } from '@utils/lodash'

export class LanguageEnglish {
  generic = {
    offlineError: 'Oeps! It looks like the server is offline right now.',
    acceptTerms: 'I accept the terms and conditions',
    acceptSubscription: 'I accept the subscription agreement',
    account: 'Account',
    accounts: 'Accounts',
    accountPortfolio: 'Account portfolio',
    adres: 'Adres',
    addTicker: 'Add ticker',
    amount: 'Amount',
    amountFilled: 'Amount filled',
    add: 'Add',
    all: 'All',
    assets: 'Assets',
    back: 'Back',
    balance: 'Balance',
    bot: 'Bot',
    botChart: '{botName} - Chart',
    botLog: '{botName} - Logs',
    botNotes: '{botName} - Notes',
    botOrders: '{botName} - Orders',
    botPositions: '{botName} - Positions',
    cancel: 'Cancel',
    chart: 'Chart',
    accountType: 'Account type',
    chartStyle: 'Chart style',
    closed: 'Closed',
    create: 'Create',
    closeTimestamp: 'Close Timestamp',
    commands: 'Commands',
    contract: 'Contract',
    contracts: 'Contracts',
    change24h: '24H change',
    close: 'Close',
    coin: 'Coin',
    confirmPassword: 'Confirm password',
    connected: 'Connected',
    currency: 'Currency',
    currencies: 'Currencies',
    currentPassword: 'Current password',
    currentUsername: 'Current username',
    currentPrice: 'Current price',
    currencyPortfolio: 'Currency portfolio',
    hour: '1 Hour',
    hours: '{number} Hours',
    day: '1 Day',
    days: '{number} Days',
    defaultPriceDataStyle: 'Default price data style',
    description: 'Description',
    decimals: 'Decimals',
    hideBelow: 'Hide below',
    direction: 'Direction',
    downloadChart: 'Download chart',
    editChart: 'Edit chart',
    favoriteCommands: 'Favorite commands',
    fullScreen: 'Full screen',
    filter: 'Filter',
    applyFilter: 'Apply filter',
    download: 'Download',
    collapse: 'Collapse',
    expand: 'Expand',
    deactivated: 'Deactivated',
    downloadLogs: 'Download logs',
    duplicateName: 'Duplicate name',
    email: 'Email',
    enterPrice: 'Enter price',
    error: 'Error',
    errors: 'Errors',
    exchange: 'Exchange',
    exchanges: 'Exchanges',
    executionPrice: 'Execution price',
    exitPrice: 'Exit price',
    feeCosts: 'Fee costs',
    favorites: 'Favorites',
    grossProfit: 'Gross profit',
    interval: 'Interval',
    inputTooLong: 'Value cannot exceed {length} characters',
    lastUpdate: 'Last update',
    leverage: 'Leverage',
    loadText: 'Click to load',
    loadError: 'Something went wrong loading the data',
    logs: 'Logs',
    market: 'Market',
    markets: 'Markets',
    messages: 'Messages',
    month: '1 Month',
    months: '{number} Months',
    name: 'Name',
    next: 'Next',
    newPassword: 'New password',
    newUsername: 'New username',
    news: 'News',
    no: 'No',
    noMarketsAvailable: 'No markets available',
    noSuitableAccounts: 'No suitable accounts',
    notes: 'Notes',
    options: 'Options',
    open: 'Open',
    orders: 'Orders',
    portfolio: 'Portfolio',
    positions: 'Positions',
    filled: 'Filled',
    cancelled: 'Cancelled',
    failed: 'Failed',
    openAmount: 'Open amount',
    closedAmount: 'Closed amount',
    openPositions: 'Open positions',
    openOrders: 'Open orders',
    openTimestamp: 'Open Timestamp',
    orderbook: 'Orderbook',
    openInExternalWindow: 'Open in new window',
    orderHistory: 'Order history',
    orderType: 'Order type',
    pin: 'Pin',
    positionHistory: 'Position history',
    positionId: 'Position id',
    preparingEditor: 'Preparing editor',
    preparingScripts: 'Preparing scripts',
    profile: 'Profile',
    averagePrice: 'Avg. price',
    price: 'Price',
    pending: 'Pending',
    public: 'Public',
    profitChart: 'Profit chart',
    refreshData: 'Refresh',
    realizedProfits: 'Realized profit',
    rememberMe: 'Remember me',
    required: 'Required',
    roi: 'ROI',
    save: 'Save',
    slippage: 'Slippage',
    status: 'Status',
    script: 'Script',
    scripts: 'Scripts',
    search: 'Search',
    settings: 'Settings',
    settingsSaved: 'Settings saved',
    showingRecords: 'Showing {numberOfVisibleItems} out of {totalNumberOfItems} record(s).',
    showingXOutOfXItems: 'Showing {startIndex} to {endIndex} of {totalNumberOfItems} entries',
    socketConnected: 'Socket connected',
    socketDisconnected: 'Socket disconnected',
    scrollToLoad: 'Scroll down to load more.',
    simulated: 'Simulated',
    size: 'Size',
    sorting: 'Sorting',
    source: 'Source',
    spread: 'Spread',
    start: 'Start',
    sum: 'Sum',
    tilesView: 'Tiles view',
    tableView: 'Table view',
    time: 'Time',
    timeAgo: '{timespan} ago',
    timeChange: '{timespan} change',
    timestamp: 'Timestamp',
    trades: 'Trades',
    type: 'Type',
    twoFaPin: '2FA Pin',
    verifyTwoFaPin: 'Verify 2FA Pin',
    unpin: 'Unpin',
    unrealizedProfits: 'Unrealized profit',
    unrealizedProfitsRatio: 'P/L Ratio',
    username: 'Username',
    viewScript: 'View script source in editor',
    volume24h: '24H volume',
    yes: 'Yes',
    warnings: 'Warnings',
    week: '1 Week',
    weeks: '{number} Weeks',
    password: 'Password',
    wallet: 'Wallet',
    supportCode: 'Support code',
    clientLogin: 'Client login',
    haasbotUpdated: 'Haasbot updates (change log)',
    approve: 'Approve',
    help: 'Help',
    creator: 'Creator',
    testedMarket: 'Tested market',
    testedPeriod: 'Tested period',
    period: 'Period',
    noResults: 'No results',
    subject: 'Subject',
    classification: 'Classification',
    priority: 'Priority',
    votes: 'Votes',
    comment: 'Comment',
    comments: 'Comments',
    displayName: 'Display name',
  }

  leftMenu = {
    dashboards: 'Dashboards',
    bots: 'Bots',
    allBots: 'Bot Management',

    haasScript: 'HaasScript',
    webEditor: 'Web Editor',
    labs: 'Backtest Lab',
    manageScripts: 'Manage Scripts',
    browseScripts: 'Browse HaasScript.com',
    browseSignals: 'Browse Signals',
    backtests: 'Backtest History',
    manageSignals: 'Manage Signals',

    tools: 'Tools',
    marketView: 'Marketview',
    marketIntelligence: 'Market Intelligence',
    exchanges: 'Exchange Profiler',
    markets: 'Markets Explorer',
    news: 'News',
    publicStatistics: 'Usage Statistics',

    finance: 'Finance',
    management: 'Management',
    accounts: 'Exchange Accounts',
    externalWallets: 'External Wallets',
    portfolio: 'Portfolio Management',
    settings: 'Settings',
    notifications: 'Notifications',
    licenseInfo: 'License Information',
    help: 'Help',

    webshop: 'Webshop',
    webshopChart: 'Chart',
    webshopMyOrders: 'My orders',
    webshopAffiliation: 'Affiliation',
    webshopMyProducts: 'My products',
    webshopLicenses: 'Licenses',
    webshopSignals: 'Signals',
    webshopStrategies: 'Strategies',

    debug: 'Debug',
    troggleDebugMode: 'Toggle debug mode',
    consoleLogbook: 'Console logbook',
    backendSetup: 'Database management',
    versionInformation: 'Version Information',
    partnerOptions: 'Partner options',
    bugReports: 'Bug reports',
    featureRequests: 'Feature requests',
  }

  haasScript = {
    general: 'General',
    trade: 'Trade',
    script: 'Script',

    generalSettings: 'General settings',
    tradeSettings: 'Trade settings',
    scriptSettings: 'Script settings',

    loadingScriptPlaceholder: 'Loading script...',
    noScriptPlaceholder: 'No script selected',
    noSettingsPlaceholder: "This script hasn't got any extra settings",

    mainInterval: 'Default price data interval',
    tradeAmount: 'Trade amount',
    tradeAmountType: 'Trade amount type',
    tradeAmountTypeStatic: 'Static',
    defaultPriceDataStyle: 'Default price data style',
    orderType: 'Order type',
    orderTypeMarket: 'Market order',
    orderTypeLimit: 'Limit order',
    orderTypeMaker: 'Maker order',
    orderTypeNoTimeout: 'No timeout order',
    defaultFee: 'Default fee',
    loadMore: 'Load more',
    viewAll: 'View all',
    viewLast250: 'View last 250',
  }

  backtests = {
    createBot: 'Create bot from backtest settings',
    editTag: 'Edit custom backtest tag',
    viewResult: 'View backtest results',
    preserveResult: 'Preserve backtest results',
    discardResult: 'Discard backtest results',
    deleteResult: 'Delete backtest result',
  }

  accountsPage = {
    noAccounts: 'Click on the plus icon to add your first account!',
    currencyAdjusted: 'Adjusted the wallet of {accountName} to  {value} {coin}.',

    addModel: {
      titleLiveAccount: 'New account',
      titleSimulatedAccount: 'New simulated account',
      submitButton: 'Create',
      cancelButton: 'Cancel',

      accountName: 'Account name',
      exchange: 'Exchange',
      simulatedAccount: 'Simulated account',

      success: "Account '{accountName}' has been created on {exchange}.",
    },

    deleteModel: {
      title: 'Delete account',
      body: 'Are you sure you want to delete this account? Every account related feature such as bots, portfolio data and/or dashboard widgets will be removed. This cannot be reverted.',
      confirm: 'Yes',
      cancel: 'No',
      success: "Account '{accountName}' has been deleted.",
    },

    renameModel: {
      title: 'Rename account',
      confirm: 'Yes',
      cancel: 'No',
      success: "Account renamed to '{accountName}'.",
    },

    changeKeysModel: {
      title: 'Change API keys',
      confirm: 'Yes',
      cancel: 'No',
    },

    editWalletModel: {
      title: 'Configure simulated wallet',
      search: 'Search currency',
    },
  }

  accountPage = {
    page: {
      liveAccounts: 'Live accounts',
      simulatedAccounts: 'Simulated accounts',
      viewDetails: 'Account dashboard',
    },

    placeHolder: {
      noAccounts: 'Click on the plus icon on the right to add your first account!',
      liveAccounts: 'Click on the plus icon on the right to add your first real account!',
      simulatedAccounts: 'Click on the plus icon on the right to add your first simulated account!',
    },

    addModel: {
      titleLiveAccount: 'New account',
      titleSimulatedAccount: 'New simulated account',
      submitButton: 'Create',
      cancelButton: 'Cancel',

      accountName: 'Account name',
      exchange: 'Exchange',
      simulatedAccount: 'Simulated account',
    },

    deleteModel: {
      title: 'Are you sure?',
      body: 'Deleted accounts cannot be recovered. This will permanently delete all account data & portfolio history.',
      subBody: 'Please confirm this action by typing the name of the account in the input below:',
      confirm: 'Yes',
      cancel: 'No',
    },

    renameModel: {
      title: 'Rename account',
      confirm: 'Yes',
      cancel: 'No',
    },

    changeKeysModel: {
      title: 'Change API keys',
      confirm: 'Yes',
      cancel: 'No',
    },

    editWalletModel: {
      title: 'Configure simulated wallet',
      search: 'Search currency',
    },

    makeAccountPublic: {
      title: 'Enable public visability',
      body: 'Are you sure you want to make your account publicly visible? It will mean you get a page for it and everybody will be able to open this page and see how your account is performimg.',
      confirm: 'Yes',
      cancel: 'No',
      success: "Account '{accountName}' has been made public accessable.",
    },

    makeAccountPrivate: {
      title: 'Delete account',
      body: 'Are you sure you want to make your account private again? If you do then the public page get dropped and nobody will be able to see it anymore.',
      confirm: 'Yes',
      cancel: 'No',
      success: "Account '{accountName}' has been made private again.",
    },

    tiles: {
      currentBalance: 'Current balance',
      change24h: '24 Hours Change',
      change1w: '1 Week Change',
    },

    balanceSummary: {
      title: 'Balance Summary',
    },

    balanceDistributionChart: {
      title: 'Accounts',
    },

    currencyDistributionChart: {
      title: 'Currencies',
    },

    balanceHistoryChart: {
      balanceTitle: 'Balance history',
      currencyTitle: 'Currency history',
    },
  }

  assetsPage = {
    accounts: 'Accounts',
    currencies: 'Currencies',
    location: 'Location',
    portfolioValue: 'Portfolio value',
    accountValue: 'Account value',
    currencyValue: '{currency} value',
  }

  balanceTable = {
    currency: 'Currency',
    available: 'Available',
    locked: 'Locked',
    positionMargin: 'Position margin',
    inOrder: 'In order',
    inPosition: 'In position',
    total: 'Total',
    price: 'Price',
    amount: 'Amount',
    value: 'Estimated value',
    share: 'Share',
  }

  notificationPage = {
    notifications: '{type} notifications',
    timestamp: 'Timestamp',
    category: 'Category',
    message: 'Message',

    downloadModel: {
      title: 'Download all notification',
      body: 'Are you sure you want to download all your notifications? This can result into a big download.',
      confirm: 'Yes',
      cancel: 'No',
      downloadHasStarted: 'Download of notification logbook has started.',
    },
  }

  settingsPage = {
    generalTab: 'General',
    notificationsTab: 'Notifications',
    securityTab: 'Security',
    supportTab: 'Support',
    settingsSaved: 'Settings saved',

    change2FA: {
      title: 'Change 2FA Device',
    },
    changePassword: {
      title: 'Change Password',
    },
    changeUsername: {
      title: 'Change Username',
    },
    changeDisplayName: {
      title: 'Change display name',
    },
    changeTwoFA: {
      title: 'Render new 2FA code',
      newTwoFAEmailSend: 'Email send with new 2FA code',
    },
    notifications: {
      interfaceNotifications: 'Interface Notifications Settings',
      telegramNotifications: 'Telegram Notifications Settings',
      emailNotifications: 'Email Notifications Settings',
      title: 'Notifications Settings',
      security: 'Security messages',
      miscellaneous: 'Miscellaneous',
      executingOrders: 'Executing orders',
      cancelledOrders: 'Cancelled orders',
      completedOrders: 'Completed orders',
      placedOrders: 'Placed orders',
      failedOrders: 'Failed orders',
      botCompletedOrders: 'Bot completed orders',
    },
    devices: {
      title: 'Approved devices',
      sectionTitle: 'All approved devices',
      removeDevice: 'Remove device',
    },
    deleteDeviceModel: {
      title: 'Delete approved device',
      body: 'Are you sure you want to delete the approved device?',
      confirm: 'Yes',
      cancel: 'No',
      success: 'Device has been removed',
    },
    uiSettings: {
      title: 'User Interface Settings',
      autoLogout: 'Auto logout',
    },
    defaultSelection: {
      title: 'Default selections',
    },
    languages: {
      language: 'Language',
      english: 'English',
      dutch: 'Dutch',
    },
    themeSettings: {
      theme: 'Theme',
      default: 'Default',
      light: 'Light',
      dark: 'Dark',
    },
    telegramSetup: {
      title: 'Setup Telegram',
      connect: 'Connect',
      sendTestMessage: 'Send test message',
      disconnect: 'Disconnect',
    },
    teamSupport: {
      title: 'Remote support',
      body: 'When you are in need of support and somebody of support needs to login then give them this key:',
      refreshKey: 'Refresh key',
      expiresAt: 'Expires on',
    },
    securityDevices: {
      platform: 'Platform',
      ipadress: 'IP-Adres',
      lastSeen: 'Last seen',
      options: 'Options',
    },
    accountSafety: {
      title: 'Account safety settings',
      blockBotSettings: 'Block harmfull bot settings',
      stopLosingBots: 'Stop bots which make losses',
    },
  }

  botPage = {
    startBot: {
      title: 'Start bot',
      body: 'Are you sure you want to start this bot?',
      confirm: 'Yes',
      cancel: 'Cancel',
    },
    stopBot: {
      title: 'Stop bot',
      body: 'Are you sure you want to stop this bot?',
      confirm: 'Yes',
      cancel: 'Cancel',
    },
    pauseBot: {
      title: 'Pause bot',
      body: 'Are you sure you want to pause this bot?',
      confirm: 'Yes',
      cancel: 'Cancel',
    },
    resumeBot: {
      title: 'Resume bot',
      body: 'Are you sure you want to resume this bot?',
      confirm: 'Yes',
      cancel: 'Cancel',
    },
    renameModel: {
      title: 'Rename bot',
      label: 'Bot name',
      confirm: 'Save',
      success: 'Bot renamed to {botName}',
    },
    cloneModel: {
      title: 'Clone bot',
      label: 'Bot name',
      confirm: 'Create',
      success: 'Bot cloned and saved as {botName}',
    },
    clearModel: {
      title: 'Clean bot',
      body: 'Are you sure you want to clean this bot?',
      confirm: 'Yes',
      cancel: 'Cancel',
    },
    deleteModel: {
      title: 'Delete bot',
      body: 'Are you sure you want to delete this bot? This cannot be reverted.',
      confirm: 'Yes',
      cancel: 'Cancel',
      success: 'Bot has been deleted.',
    },

    reloadScriptModel: {
      title: 'Reload script',
      body: 'Are you sure you want to reload the script? Reloading the script will also clean the bot.',
      confirm: 'Yes',
      cancel: 'Cancel',
    },

    activeFor: 'Active for',
    idleSince: 'Idle since',
    openBotList: 'Open bot list',
    changeScript: 'Change script',
    viewScript: 'View script source in editor',

    profitReport: {
      grossProfit: 'Gross profits',
      feeCosts: 'Fee costs',
      realizedProfit: 'Realized profits',
      unrealizedProfit: 'Unrealized profits',
    },
    roiReport: {
      returnOnInvestment: 'Return on investment',
      roi24hAgo: 'ROI 24h ago',
      roi48hAgo: 'ROI 48h ago',
      hodl: 'Buy & hodl',
    },
    tradeReport: {
      trades: 'Trades',
      enterTrades: 'Enter trades',
      exitTrades: 'Exit trades',
      numberOfTrades: '{number} Trades',
      enterAndExitTrades: '{enterTrades} enter & {exitTrades} exit trades',
      lastOrder: 'Last trade',
      lastOrderDuration: '{timespan} ago',
    },
    positionReport: {
      title: 'Position report',
      closedPositions: 'Closed positions',
      winningPositions: 'Winning positions',
      loosingPositions: 'Losing positions',
      averageProfit: 'Average profit per position',
      numberOfWinningAndLoosingPositions: '{profitablePositions} winning & {loosingPositions} loosing positions',
    },
    customReport: 'Custom report',

    loadLog: 'Load log',

    chartError: 'Oeps! Unable to load bot chart',
    logError: 'Oeps! Unable to load bot log',
    loadError: 'Something went wrong while loading the bot!',
    botDoesNotExist: 'Looks like the bot you are looking for does not exist.',
  }

  marketviewPage = {
    showingXOutOfXMarkets: 'Showing {numberOfVisibleItems} out of {totalNumberOfItems} markets. Scroll to load more.',
    orderbook: 'Orderbook',
    trades: 'Trades',
  }

  labsPage = {
    bruteForceDescription:
      'The brute force algorithm will backtest every possible combination. Increasing the number of possibilities per parameter will have an exponential effect on the number of possible combination/backtests.',
    intelligentDescription:
      'The Intelligent algorithm should be utilized when the search space goes above 100k combinations (max. limit of Brute Force). Imagine you have script that has too much parameters to start tinkering with; Intelligent algorithm is your best friend.',
    randomDescription: 'The Random Search algorithm is nothing more than pure random. Every parameter set tested will be randomly picked from all possible combinations available.',
  }

  botsPage = {
    title: 'Haas Bots',
    active: 'Active',
    paused: 'Paused',
    idle: 'Idle',
    error: 'Error',
    openBot: 'Open bot',
    deleteBot: 'Delete bot',

    addBot: {
      title: 'Add bot',
    },
    startBot: {
      title: 'Start bots',
      body: 'Are you sure you want to start {numberOfBots} bots?',
      confirm: 'Yes',
      cancel: 'No',
    },
    stopBot: {
      title: 'Stop bot',
      body: 'Are you sure you want to stop {numberOfBots} bots?',
      confirm: 'Yes',
      cancel: 'No',
    },
    pauseBot: {
      title: 'Pause bots',
      body: 'Are you sure you want to pause {numberOfBots} bots?',
      confirm: 'Yes',
      cancel: 'No',
    },
    resumeBot: {
      title: 'Resume bot',
      body: 'Are you sure you want to resume {numberOfBots} bots?',
      confirm: 'Yes',
      cancel: 'No',
    },
    clearModel: {
      title: 'Clean bot',
      body: 'Are you sure you want to clean {numberOfBots} bots?',
      confirm: 'Yes',
      cancel: 'No',
    },
    deleteModel: {
      title: 'Delete {numberOfBots} bots',
      body: 'Are you sure you want to delete {numberOfBots} bots? Delete bots cannot be recovered.',
      confirm: 'Yes',
      cancel: 'No',
      success: 'Bots have been deleted.',
    },
    cloneModel: {
      title: 'Clone bot',
      label: 'Bot name',
      confirm: 'Create',
    },
    table: {
      name: 'Name',
      status: 'Status',
      account: 'Account',
      market: 'Market',
      script: 'Script',
      unrealizedProfits: 'Unrealized profits',
      realizedProfits: 'Realized profits',
      roi: 'Roi',
      scriptNote: 'Script note',
      options: 'Options',
    },
  }

  botsOrdersPage = {
    title: 'Haas Bot open orders',
  }

  botsPositionsPage = {
    title: 'Haas Bot open positions',
  }

  loginPage = {
    register: 'Register',
    login: 'Login',
    or: 'or',
    supportLogin: 'Support Login',
    forgotPassword: 'Forgot password',
    resetPassword: 'Reset password',
    twoFaLogin: 'Google 2FA Login',
  }

  loadingPage = {
    appData: 'App data',
    dashboards: 'Dashboards',
    orderAndPositions: 'Open Orders & Positions',
    bots: 'Bots',
    haasScripts: 'HaasScripts',
    footer: "Lil' rabbits are working hard to collect all the data.",
    standBy: 'Please stand by',
    welcomeBack: 'Welcome back, {username}',
  }

  selectScriptModel = {
    selectScript: 'Select script',
    searchScript: 'Search script',
  }

  editor = {
    scriptName: 'Script name',
    scriptDescription: 'Script description',
    scriptDescriptionPlaceholder: 'A short description of the script',
    commandScript: 'Command script',

    loadScript: 'Load script',
    recentScripts: 'Recent scripts',

    saveScript: 'Save script',
    saveScriptAs: 'Save script as',
    deleteScript: 'Delete script',
    debugScript: 'Debug script',
    runScript: 'Run script',
    backtestScript: 'Backtest script',

    editor: 'Editor',
    logAndChart: 'Logs & Chart',
    settings: 'Settings',

    scriptSaved: 'Script saved',

    newBotModel: {
      title: 'New bot',
      success: "Bot '{botName}' created.",
    },
    newScriptModel: {
      title: 'New script',
      success: "Script '{scriptName}' created.",
    },

    editScriptModel: {
      title: 'Edit script',
      success: 'Script saved.',
    },

    saveScriptAsModel: {
      title: 'Save script as',
      success: "Script saved as '{scriptName}'.",
    },

    deleteScriptModel: {
      title: 'Delete script',
      body: "Are you sure you want to delete '{scriptName}'? Deleted scripts cannot be recovered.",
      success: "Script '{scriptName}' deleted.",
    },

    importScriptModel: {
      title: 'Import script',
      pastHere: 'Paste the script here',
    },

    exportScriptModel: {
      title: 'Export script',
      success: 'The script has been copied to your clipboard',
    },

    importScriptFile: {
      title: 'Upload script',
    },

    exportScriptToFileModel: {
      title: 'Download script',
      success: 'The download of the script has started',
    },

    makeBackupModel: {
      title: 'Create backup',
      body: "Are you sure you want to create a backup for '{scriptName}'?",
      success: "Backup created of script '{scriptName}'.",
    },

    backupOverviewModel: {
      title: 'Backup overview',
    },

    restoreBackupModel: {
      title: 'Restore backup',
      body: 'Are you sure you want revert back to v{version}? The current script will be lost',
      success: "v{version} of '{scriptName}' restored.",
    },

    openBackupModel: {
      title: 'Open backup',
      body: 'Are you sure you want to open the backup? (this will generate a new record)',
      success: "Old version for script '{scriptName}' created.",
    },

    botRemote: {
      quick: 'Quick',
      quickBacktest: 'Quick backtest',
      custom: 'Custom',
      customBacktest: 'Custom backtest',
      editCustomBacktest: 'Edit backtest period',
      saved: 'Saved',
      savedBacktest: 'Saved backtest periods',
      saveBacktestPeriod: 'Save backtest period',
      results: 'Results',
      resultsPlaceholder: 'Start a backtest and view the results here',

      startTime: 'Start time',
      endTime: 'End time',
      backtestLength: 'Backtest length',
      start: 'Start',
      saveAsPeriod: 'Save as period',
    },

    placeholders: {
      selectScript: 'Select a script',
      runTestForChartData: 'Start a test for chart data',
      runTestForLogData: 'Start a test for log data',
      compilingScript: 'Compiling script',
      debuggingScript: 'A debug run is executing',
      runningScript: 'A quick backtest is being preformed',
      backtestingScript: 'A backtest is being executed',
    },
  }

  exchangePage = {
    searchExchange: 'Search exchange',

    noRatingComment: 'We support this exchange, but...',
    oneStarRatingComment: 'This is a good exchange to use, support is good.',
    twoStarRatingComment: 'This is the recommended exchange to use, both support and the API are very good',

    exchangeDescriptions: {
      BIFINEX:
        'Bitfinex is one of the oldest crypto currency exchanges and was founded in 2012 as a peer-to-peer Bitcoin exchange, offering digital asset trading services globally. Bitfinex initially started as a P2P margin lending platform for Bitcoin, but later added support for many more pairs. Bitfinex offers great liquidity, trading pairs, and a stable API that works great with HaasOnline Trading Server.',
      BINANCE:
        'The now legendary CEO, Changpeng Zhao, founded and launched one of the first Chinese crypto exchanges in 2017. Binance has proven itself many times over to be a trusted exchange, resource, innovator, and thought leader in the crypto fintech industry.  The Binance exchange also offers great liquidity, stable API, and discounts on fees when leveraging their BNB token.',
      BINANCEFUTURES:
        'The now legendary CEO, Changpeng Zhao, founded and launched one of the first Chinese crypto exchanges in 2017. Binance has proven itself many times over to be a trusted exchange, resource, innovator, and thought leader in the crypto fintech industry.  The Binance exchange also offers great liquidity, stable API, and discounts on fees when leveraging their BNB token.',
      BINANCEQUARTERLY:
        'The now legendary CEO, Changpeng Zhao, founded and launched one of the first Chinese crypto exchanges in 2017. Binance has proven itself many times over to be a trusted exchange, resource, innovator, and thought leader in the crypto fintech industry.  The Binance exchange also offers great liquidity, stable API, and discounts on fees when leveraging their BNB token.',
      BITMEX:
        'BitMEX is one of the most recognizable names when it comes to Bitcoin margin trading exchanges, which can be attributed to their flamboyant and controversial CEO, Aurthur Hayes. Traders can expect to use leverage of up to 100x, the platform should be used by experienced traders.',
      BITSTAMP:
        'Bitstamp was founded in 2011 and is among one of the longest running digital asset exchanges around and allow USD, EUR, BTC, LTC, ETH, XMR or BCH deposits and withdrawals. Bitstamp offers great liquidity, top cryptocurrency pairs, low fees, 24/7 support, and a stable API to pair with HaasOnline Trading Server.',
      BITTREX:
        'BBittrex was founded in 2014 by three cybersecurity engineers, Bittrex is a U.S.-based digital assets platform, providing fast trade execution, digital wallets and industry-leading security practices. When using Bittrex you can expect great liquidity, security, and stable algorithmic trading through their APIs.',
      CEXIO:
        'Established in 2013 as one of the first cloud mining providers, CEX.IO has become a multi-functional cryptocurrency exchange. CEX.IO offers multi-level account system, high liquidity order book for top currency pairs, stable API and 24/7 support that is accompanied by time-proven stability to guarantees safety of assets and data.',
      COINBASE:
        'Formerly known as GDAX Pro, Coinbase has rebranded their exchange into Coinbase Pro. The platform is well known for its trading functionality and liquidity, making the exchange a great choice for institutional as well as new traders.',
      DERIBIT:
        'The Deribit exchange launched in June 2016 after several years of development and has become one of the top low latency and stable platforms offering leveraged trading. Deribit started as a Bitcoin Futures and Options trading platform based out of Amsterdam. The exchange was created as an answer to those in search of a professional fully dedicated cryptocurrencies futures and options trading platform.',
      GEMINI:
        'Gemini was founded in 2014 by the notorious Winklevoss twins and is a licensed digital asset exchange and custodian built for individuals and institutional investors. Gemini offers their own stable coin, great liquidity, rigorous security standards, and a stable API. Gemini is a fiduciary and subject to the capital reserve and cybersecurity requirements as well as banking compliance standards defined by the NYSDFS and the New York Banking Law.',
      HITBTC:
        'Since 2013, HitBTC has been providing cryptocurrency trading services to institutional, merchant and individual traders worldwide for BTC, LTC, DOGE, QCN, NXT, FCN, BCN, and XMR and fiat currencies (EUR, USD).',
      HUOBI:
        'Huobi is a digital currency trading platform operated by HBUS Holdco, Inc., a U.S. company and strategic partner of Huobi Global, one of the world’s largest digital asset trading and management service providers. Huobi offers a wide variety of token options, giveaways, great support, and unparalleled security.',
      IONOMY:
        "Ionomy's Alchemy exchange is a new, however the Ionomy team is no stranger to maintaining a cryptocurrency exchange. They had previously run the ION exchange since 2016. Ionomy exchange also offers competitive trading fees, cryptocurrency wallets, masternode hosting, and sharenode services along with developing games and providing the tools necessary for game developers to incentivize their games with cryptocurrency.",
      KRAKEN:
        'Kraken was launched on September 2013 by Jesse Powell in San Francisco, California, USA. Kraken is one of the oldest cryptocurrency exchanges and has been continuing it’s growth by acquiring key technology companies, most recently Crypto Facilities. With Kraken you can enjoy great liquidity, security, and (lol!) stability with their API.',
      KRAKENFUTURES:
        'Kraken Futures, formerly known as Crypto Facilities, was founded in 2015 and offers individuals and institutions transparent, secure, 24/7 trading on a range of cryptocurrency derivatives. Kraken Futures was the first regulated entity to list futures on Bitcoin, Ethereum, Ripple XRP, Litecoin and Bitcoin Cash. Eligible users can gain access to Kraken Futures on six cryptocurrency pairs, providing a highly efficient way to trade and hedge cryptocurrency in any market environment.',
      KUCOIN:
        "Based in Hong Kong, KuCoin is a fast-growing exchange that offers well over 350 pairs for trading. Trade fees are reduced by holding the platform’s native KCS token. KuCoin also takes a variety of measures to ensure security, including cold storage of assets and complex encryption. KuCoin's modern and stable API makes pairing with the HaasBot trade bot a great experience.",
      OKCOINCOM:
        'Founded in 2013 in Beijing, China, OKCoin marks itself as a world leading digital asset trading platform. OKCoin provides fiat trading with all the major digital assets, including Bitcoin, Bitcoin Cash, Ethereum, Ethereum Classic, and Litecoin. OKCoin has recently expanded trading efforts to the United States and is looking to increase global user adoption.',
      OKCOINFUTURES:
        'OKEx Futures is an extension of the OKEx exchange, which was founded in 2014. While trading futures users can use up to 20x leverage and OKEx claims nearly $1.5B of rollingDaily volume of futures trading.',
      POLONIEX:
        "Poloniex was started in 2014 by Tristan D'Agosta in Delaware, USA. In early 2018 Poloniex was acquired by Circle, which has received millions of dollars in funding from notable investors like Goldman Sachs. Poloniex offers over 60 cryptocurrency pairs, leverage trading, fast & stable API, and charting tools.",
    },

    exchangeTable: {
      rating: 'Rating',
      location: 'Location',
      founded: 'Founded',
      spot: 'Spot',
      margin: 'Margin',
      leverage: 'Leverage',
    },
    marketsTable: {
      base: 'Base',
      quote: 'Quote',
      contract: 'Contract',
      contractValue: 'Contract value',
      maker: 'Maker Fee',
      taker: 'Taker Fee',
      minimumAmount: 'Min amount',
      minimumVolume: 'Min volume',
      margin: 'Margin',
      maxLeverage: 'Max leverage',
      fixed: 'Fixed',
      cross: 'Cross',
    },
  }

  backendSetupPage = {
    selectPriceSource: 'Select price-source',

    clearPriceCache: 'Clear all price cache',
    deleteAllBots: 'Delete all bots',
    deletePriceData: 'Delete price data',
    deleteConsoleLogbook: 'Clear console logbook',
    deleteAccounts: 'Delete all accounts',
    deleteDashboards: 'Delete all dashboards',
    deleteUserSettings: 'Delete all user settings',

    importDatabase: 'Import database',
    exportDatabase: 'Export database',

    descriptionPriceCache: 'This will clear all the price cache data, so it can all be rebuild again.',
    descriptionDeleteAllBots: 'This will delete all the bots which you have inside the TradeServer.',
    descriptionDeletePriceData: 'This will allow you to delete the price data inside the database, so it can be redownloaded and added.',
    descriptionDeleteConsoleLogbook: 'This will clear the logbooks.',
    descriptionDeleteAccounts: 'This will delete all the API accounts which you have inside the TradeServer.',
    descriptionDeleteDashboards: 'This will delete all the dashboards which you have inside the TradeServer.',
    descriptionDeleteUserSettings: 'This will delete all your personal user settings.',

    confirmClearPriceCacheModel: {
      title: 'Clear price cache',
      body: 'Are you sure you want to clear all price cache? (the cache will be rebuild)',
      confirm: 'Yes',
      cancel: 'No',
    },

    confirmDeleteAllBotsModel: {
      title: 'Delete bots',
      body: 'Are you absolutely sure you want to delete all your bots? (there is no way back if you do this)',
      confirm: 'Delete',
      cancel: 'No',
    },

    confirmDeletePriceDataModel: {
      title: 'Delete price data',
      body: 'Are you sure you want to delete the price data of {}? (it will be rebuild)',
      confirm: 'Delete',
      cancel: 'No',
    },

    confirmDeleteConsoleLogbookModel: {
      title: 'Clear console lobook',
      body: 'Are you sure you want to clear the console logbook? (it wil be rebuild)',
      confirm: 'Delete',
      cancel: 'No',
    },

    confirmDeleteAccountsModel: {
      title: 'Delete accounts',
      body: 'Are you absolutely sure you want to delete all your accounts? (there is no way back if you do this)',
      confirm: 'Delete',
      cancel: 'No',
    },

    confirmDeleteDashboardsModel: {
      title: 'Delete dashboards',
      body: 'Are you absolutely sure you want to delete all your dashboards? (there is no way back if you do this)',
      confirm: 'Delete',
      cancel: 'No',
    },

    confirmDeleteUserSettingsModel: {
      title: 'Delete user settings',
      body: 'Are you sure you want to delete all your saved settings? (it wil be rebuild)',
      confirm: 'Delete',
      cancel: 'No',
    },

    confirmImportDatabaseModel: {
      title: 'Import database',
      body: 'Are you sure you want to import the database again?',
      confirm: 'Yes',
      cancel: 'No',
    },

    confirmExportDatabaseModel: {
      title: 'Export database',
      body: 'Please confirm you want to export the database.',
      confirm: 'Confirmed',
      cancel: 'No',
    },

    succesfullClearedAllPriceCache: 'All price cache has been cleared.',
    succesfullDeletedAllBots: 'All bots have been removed.',
    succesfullDeletedPriceData: 'The price data has been removed.',
    succesfullDeletedConsoleLogbook: 'The console logbook has been cleaned',
    succesfullDeletedAccounts: 'All accounts have been removed.',
    succesfullDeletedDashboards: 'All dashboards have been removed.',
    succesfullDeletedUserSettings: 'All user settings have been removed.',
    succesfullImportDatabase: 'The database import seem to have went well.',
    succesfullExportDatabase: 'The database export seem to have went well.',
  }

  marketsPage = {
    searchMarket: 'Search market',
    markets: '{market} Market',
    news: '{market} News',
  }

  scriptEditor = {
    deleteModel: {
      title: 'Delete script',
      body: 'Are you sure you want to delete {scriptName}? This cannot be reverted.',
      confirm: 'Yes',
      cancel: 'No',
    },

    editModel: {
      title: 'Delete script',
      body: 'Are you sure you want to delete {scriptName}? This cannot be reverted.',
      confirm: 'Yes',
      cancel: 'No',
    },
  }

  scriptsPage = {
    tabs: {
      allScripts: 'All scripts',
      luaScripts: 'Lua Scripts',
      visualScripts: 'Visual Scripts',
      commandScripts: 'Commands',
      tradeBotScripts: 'Trade Bots Scripts',
    },

    toggleBotScripts: 'Toggle bot scripts',
    toggleCommandScripts: 'Toggle commands',
    searchLabel: 'Search script',

    table: {
      name: 'Name',
      description: 'Description',
      type: 'Script type',
      command: 'Command',
      commandName: 'Command name',
      dependencies: 'Dependencies',
      created: 'Created',
      updated: 'Updated',
      options: 'Options',
    },

    deleteModel: {
      title: 'Delete scripts',
      body: 'Are you sure you want to delete the following script(s)? Deleted scripts cannot be recovered.',
      confirm: 'Yes',
      cancel: 'No',
      success: '{numberOfScripts} script(s) deleted.',
    },

    editModel: {
      title: 'Edit script',
      scriptName: 'Script name',
      scriptDescription: 'Script description',
      success: 'Script changes saved.',
    },
  }

  signalsPage = {
    signals: 'Signals',
    intro:
      'You can register signals into your HaasScript and once you got a signal you will get a public and secret key. You can use both of them to send signals and you can use the public key to read the signal elsewhere. You can use your signals yourself, other people can not access them. To let other people use the signals you produce you got to publish your signal first. This way it becomes available within the webshop and other people will be able to see the backtest results of your signals. If others decide to hire your signals then they will get access to it based on the price you ask for it.',

    yourSignals: 'Your signals',
    publicKey: 'Public key',
    isPublished: 'Is published',
    emailTooltip: 'Email private key',

    newSignal: 'New signal',

    editModel: {
      title: 'Edit signal',
      signalName: 'Signal name',
      signalDescription: 'Signal description',
      success: 'Signal changes saved.',
    },

    emailModel: {
      title: 'Request private key',
      body: 'Are you sure you want to request the private key of this signal? An email with the details will be send to the registered email address.',
      success: 'An email has been send with the private key',
    },

    deleteModel: {
      title: 'Delete signal',
      body: 'Are you sure you want to delete {signalName}? This cannot be reverted.',
      confirm: 'Yes',
      cancel: 'No',
      success: 'Signal removed',
    },

    publishModel: {
      title: 'Publish signal',
      body: 'Are you sure you want to publish your signal {signalName} to the webshop?',
      confirm: 'Yes',
      cancel: 'No',
      success: 'Signal published, webshop product created.',
    },

    unpublishModel: {
      title: 'Unpublish signal',
      body: 'Are you sure you want to remove your signal {signalName} from the webshop?',
      confirm: 'Yes',
      cancel: 'No',
      success: 'Signal unpublished, webshop product removed.',
    },
  }

  newsFeedTable = {
    timestamp: 'Timestamp',
    source: 'Source',
    headline: 'Headline',
  }

  headerWidgets = {
    lastUpdate: 'Last update',
    walletTitle: 'Wallet',
    openPositionTitle: 'Open positions',
    openOrdersTitle: 'Open orders',
    tradeNowTitle: 'Trade now',
    hideBelow: 'Hide below value',
    estimateValue: 'Estimated value',
  }

  tickerChart = {
    deleteTicker: 'Delete ticker',
    openMarketview: 'Open on the marketview',
  }

  errorOverlay = {
    errorHeader: "I'm sorry!",
    error: 'An unexpected error occurred',
    errorButton: 'Try again',
  }

  executeOverlay = {
    executeMessage: 'Click on the button to load the data',
    executeButton: 'Load',
  }

  UserlicensePage = {
    orderId: 'OrderId',
    product: 'Product',
    validUntill: 'Valid untill',
    accounts: 'Accounts',
    dashboards: 'Dashboards',
    activeBots: 'Active bots',
    backtestMonths: 'Backtest months',
    licenseKey: 'Licensekey',
    yourLicenses: 'Your licenses',
    marketDetails: 'Market Details',
    haasLabs: 'HaasLabs',
    signalProduction: 'Signal Production',
    enterpriseFeatures: 'Enterprise Features',
    Main: {
      title: 'License comments',
      validOrders: 'Your orders',
    },
    LicenseComments: {
      liteLicense:
          'You currently have a free lite license and we sure hope you enjoy our product. However, at the moment you are limited to running just 1 bot and 1 exchange. You might want to upgrade to something more if its all working well for you.',

      enterpriseDeveloper:
          'You currently using the developer license. This license only allows you to develop, backtest and to use labs, you can not use this license for real trading goals.',

      enterpriseBeginner:
          'You currently using the version 3 Beginner license. At the moment, during the BETA period, you can use this license for the cloud as well. But keep in mind that it will stop when the BETA period ends.',

      enterpriseSimple:
          'You currently using the version 3 Simple license. At the moment, during the BETA period, you can use this license for the cloud as well. But keep in mind that it will stop when the BETA period ends.',

      enterpriseAdvanced:
          'You currently using the Enterprise license. At the moment, during the BETA period, you can use this license for the cloud as well.',

      sponsoredByExchange:
          'You currently setup with a license which is sponsored by a exchange. This license is equal to our Standard license and this license allows you to only trade at one exchange.',

      standard:
          'You currently setup with a Standard license.',

      professional:
          'You currently setup with a Professional license.',

      partner:
          'You currently setup with a Partner license which is sponsored by Haasonline.',

      administrator:
          'You currently setup with a Administrator license, which is equal to a cloud-based Professional license.',
    },
    upgradeOrRenew: 'Upgrade or renew'
  }

  externalWalletsPage = {
    formModel: {
      name: 'Wallet name',
      coinType: 'Coin type',
      address: 'Address',
    },
    newModel: {
      title: 'Add wallet',
      success: 'Your wallet had been added',
    },
    editModel: {
      title: 'Edit wallet',
      success: 'Your wallet had been updated',
    },
    deleteModel: {
      title: 'Delete external wallet',
      body: 'Are you sure you want to delete your external wallet {walletName}?',
      success: 'The wallet has been removed',
    },
    transactionsModel: {
      title: 'Transactions',
    },
  }

  profileFilter = {
    bestPreformingSignals: 'Best preforming signals',
    bestPreformingScripts: 'Best preforming scripts',
    referenceMarket: 'Reference market',
    scriptFeatures: 'Script features',
    adjustableSettings: 'Adjustable settings',
    multiMarketsUsed: 'Multiple markets used',
    oneDirectionTrading: 'One direction trading',
    freeSignalsOnly: 'Free signals only',
    freeScriptsOnly: 'Free scripts only',
    adjustableTradeAmount: 'Adjustable trade-amount',
    adjustableOrderTemplate: 'Adjustable order template',
    misc: 'Misc',
    viewProfile: 'View profile',
    createBot: 'Create bot',
    supportedMarketTypes: 'Supported market types',
    spotMarket: 'Spot market trading',
    marginMarket: 'Margin market trading',
    leverageMarket: 'Leverage market trading',

    managedTrading: 'Managed trading',
    TAUsed: 'TA used',
    remoteSignalBased: 'Remote signal based',
  }

  versionInformationPage = {
    versionDetails: 'Version details',
    runningVersion: 'Running version',
    operationSystem: 'Operation system',
    processor: 'Processor',
    logicalCores: 'Logical cores',
    systemMemory: 'System memory',

    operationalDetails: 'Operational details',
    debugModeActivated: 'Debug mode activated',
    lowPowerModeActivated: 'Low-power mode activated',
    safeModeActivated: 'Safe mode activated',

    changeLog: 'Change log',
    enableDebugMode: 'Enable debug mode',
    disableDebugMode: 'Disable debug mode',

    enableDebugModeModel: {
      title: 'Enable debug mode',
      body: 'Are you sure you like to enable debug mode?',
      success: 'Debug mode has been activated',
    },

    disableDebugModeModel: {
      title: 'Disable debug mode',
      body: 'Are you sure you like to disable debug mode?',
      success: 'Debug mode has been disabled',
    },
  }

  developmentTasksPage = {
    changeStatus: 'Change status',
    changePriority: 'Change priority',
    addDevelopmentTask: 'Add development task',
    editDevelopmentTask: 'Edit development task',
    bugReport: 'Bug report',
    featureRequest: 'Feature request',
    addComment: 'Add comment',
    voteUp: 'Vote up',
    voteProgressed: 'Your vote has been processed',
    requestedBy: 'Requested by',
    requestedOn: 'Requested on',
    deleteTaskModel: {
      title: 'Delete development task',
      body: 'Are you absolutly sure you want to delete this task?',
      success: 'Development task has been removed',
    },
    deleteCommentModel: {
      title: 'Delete comment',
      body: 'Are you sure you want to delete this comment?',
      success: 'Comment has been removed',
    },
  }

  // =================================================================
  // Do not translate below this line
  // =================================================================

  // Do not translate!
  licensePage = {
    resetModel: {
      title: 'License DRM reset',
      body: 'Are you sure you want to reset the DRM of this license?',
      confirm: 'Reset',
      cancel: 'No',
    },
  }

  // Do not translate!
  usersPage = {
    profileResetModel: {
      title: 'Profile reset',
      body: 'Are you sure you want to execute a profile reset? (it will affect the password and 2FA))',
      confirm: 'Yes',
      cancel: 'No',
    },

    passwordResetModel: {
      title: 'Password reset',
      body: 'Are you sure you want to execute a password reset?',
      confirm: 'Yes',
      cancel: 'No',
    },

    makeAdminModel: {
      title: 'Give administrator rights',
      body: 'Are you sure you want to give the user administrator rights?',
      confirm: 'Yes',
      cancel: 'No',
    },

    makePartnerModel: {
      title: 'Give partner rights',
      body: 'Are you sure you want to give the user partner rights?',
      confirm: 'Yes',
      cancel: 'No',
    },

    makeNormalModel: {
      title: 'Revoke administrator rights',
      body: 'Are you sure you want to revoke the user his/her administrator rights?',
      confirm: 'Yes',
      cancel: 'No',
    },

    banUserModel: {
      title: 'Revoke administrator rights',
      body: 'Are you sure you want to ban the user?',
      confirm: 'Yes',
      cancel: 'No',
    },

    unBanUserModel: {
      title: 'Remove ban',
      body: 'Are you sure you want to remove the ban of this user?',
      confirm: 'Yes',
      cancel: 'No',
    },

    fullResetModel: {
      title: 'Full login reset',
      body: 'Are you sure you want to reset the entire login of this user? It will mean he/she has gets a full new login (username, password and 2FA)',
      confirm: 'Yes',
      cancel: 'No',
    },

    deleteModel: {
      title: 'Delete user',
      body: 'Are you sure you want to delete this user? (its not revertable!)',
      confirm: 'Yes',
      cancel: 'No',
    },

    lockModal: {
      title: 'Lock out user account',
      body: 'Are you sure you clean the users approved devices, clear its password and its 2FA code?',
      confirm: 'Yes',
      cancel: 'No',
    },

    deleteUserCommentModal: {
      title: 'Delete user comment',
      body: 'Are you sure you like to remove this comment?',
      confirm: 'Yes',
      cancel: 'No',
    },
  }

  // Do not translate!
  debugPage = {
    cleanLogbookModel: {
      title: 'Clean logbook',
      body: 'Are you sure you want to clean the full logbook?',
      confirm: 'Yes',
      cancel: 'No',
    },

    cleanExceptionsModel: {
      title: 'Clean exceptions',
      body: 'Are you sure you want to clean the observed exceptions?',
      confirm: 'Yes',
      cancel: 'No',
    },

    cleanWebExceptionsModel: {
      title: 'Clean web-exceptions',
      body: 'Are you sure you want to clean the observed web exceptions?',
      confirm: 'Yes',
      cancel: 'No',
    },

    cleanFailedCommandsModel: {
      title: 'Clean failed commands',
      body: 'Are you sure you want to clean the failed commands?',
      confirm: 'Yes',
      cancel: 'No',
    },
  }

  // Do not translate!
  blogPage = {
    deleteModel: {
      title: 'Delete post',
      body: 'Are you sure you want to delete this post?',
      confirm: 'Delete',
      cancel: 'No',
    },

    newTopic: 'New post',

    editModel: {
      title: 'Edit post',
      topicTitle: 'Title',
      category: 'Category',
      author: 'Author',
      message: 'Message',
    },
  }

  // Do not translate!
  webshopProductsPage = {
    deleteModel: {
      title: 'Delete product',
      body: 'Are you sure you want to delete this product?',
      confirm: 'Yes',
      cancel: 'No',
    },

    visibilityModel: {
      title: 'Change product status',
      body: 'Are you sure you want to change the status of this product?',
      confirm: 'Yes',
      cancel: 'No',
    },
  }

  // Do not translate!
  webshopCouponsPage = {
    deleteModel: {
      title: 'Delete coupon',
      body: 'Are you sure you want to delete this coupon?',
      confirm: 'Yes',
      cancel: 'No',
    },
  }
  // Do not translate!
  webshopOrdersPage = {
    setOrderFinishedModel: {
      title: 'Force order to finished',
      body: 'Are you sure you want to change this order to finished?',
      confirm: 'Yes',
      cancel: 'No',
    },

    setZeroOrderFinishedModel: {
      title: 'Finish as zero-order',
      body: 'Are you sure you want to finish this order as zero-order?',
      confirm: 'Yes',
      cancel: 'No',
    },

    setOrderCancelledModel: {
      title: 'Change order to cancelled',
      body: 'Are you sure you want to change this order to cancelled?',
      confirm: 'Yes',
      cancel: 'No',
    },

    setOrderCreditedModel: {
      title: 'Credit order',
      body: 'Are you sure you want to credit the order?',
      confirm: 'Yes',
      cancel: 'No',
    },

    setOrderRefundedModel: {
      title: 'Change order to refunded',
      body: 'Are you sure you want to change this order to refunded?',
      confirm: 'Yes',
      cancel: 'No',
    },
  }

  // Do not translate!
  newfeedsPage = {
    deleteModel: {
      title: 'Delete newsfeed',
      body: 'Are you sure you want to delete the newsfeed?',
      confirm: 'Delete',
      cancel: 'No',
    },

    newTopic: 'New newsfeed',

    editModel: {
      title: 'Edit newsfeed',
      grouping: 'Group',
      name: 'Name',
      typeFeed: 'Type of feed',
      url: 'URL',
    },
  }

  // Do not translate!
  strategiesPage = {
    deleteModel: {
      title: 'Delete strategy',
      body: 'Are you sure you want to delete the strategy?',
      confirm: 'Delete',
      cancel: 'No',
    },

    newTopic: 'New strategy',

    editModel: {
      title: 'Edit strategy',
      name: 'Name',
      description: 'Description',
      script: 'Script',
      defaultStrategy: 'Default script (or unchecked Community)',
      typeStrategy: 'Type of strategy',
    },
  }

  // Do not translate!
  videoStreamsPage = {
    deleteModel: {
      title: 'Delete video-stream',
      body: 'Are you sure you want to delete the video-stream?',
      confirm: 'Delete',
      cancel: 'No',
    },

    newStream: 'New video-stream',

    editModel: {
      title: 'Edit video-stream',
      name: 'Name',
      tag: 'Tag',
    },
  }

  // Do not translate!
  servicesPage = {
    stopModel: {
      title: 'Stop service',
      body: 'Are you sure you want to stop the service?',
      confirm: 'Stop',
      cancel: 'No',
    },

    startModel: {
      title: 'Start service',
      serverName: 'Server',
      serviceName: 'Service',
    },

    restartModel: {
      title: 'Restart service',
    },
  }

  load(language: any) {
    const overwriteData = (currentBase: any, loadingBase: any) => {
      _.each(currentBase, (value, key) => {
        if (!loadingBase[key]) return

        if (typeof value === 'string') {
          //currentBase[key] = "XXXXXXX";
          currentBase[key] = loadingBase[key]
          return
        }

        overwriteData(value, loadingBase[key])
      })
    }

    overwriteData(this, language)
  }
}
