import { EnumHaasLabAlgorithm } from '../enums/EnumHaasLabAlgorithm'
import { EnumHaasLabStatus } from '../enums/EnumHaasLabStatus'

export type UserLabRecord = {
  labId: string
  scriptId: string
  name: string
  type: EnumHaasLabAlgorithm
  status: EnumHaasLabStatus
  isExecuting: boolean
  scheduledBacktests: number
  completedBacktests: number
  createdAt: number
  startedAt: number
  updatedAt: number
  startUnix: number
  endUnix: number
  sendEmail: boolean
  cancelReason: string
}

export function parseUserLabRecord(jsonData: any): UserLabRecord {
  return {
    labId: jsonData['LID'],
    scriptId: jsonData['SID'],
    name: jsonData['N'],
    type: jsonData['T'],
    status: jsonData['S'],
    isExecuting: jsonData['S'] === EnumHaasLabStatus.Queued || jsonData['S'] === EnumHaasLabStatus.Running,
    scheduledBacktests: jsonData['SB'],
    completedBacktests: jsonData['CB'],
    createdAt: jsonData['CA'],
    startedAt: jsonData['SA'],
    updatedAt: jsonData['UA'],
    startUnix: jsonData['SU'],
    endUnix: jsonData['EU'],
    sendEmail: jsonData['SE'],
    cancelReason : jsonData["CR"]
  }
}

export function prepareUserLabRecord(record: UserLabRecord) {
  return {
    LID: record.labId,
    SID: record.scriptId,
    T: record.type,
    S: record.status,
    SB: record.scheduledBacktests,
    CB: record.completedBacktests,
    CA: record.createdAt,
    UA: record.updatedAt,
    SU: record.startUnix,
    EU: record.endUnix,
    SE: record.sendEmail,
  }
}
