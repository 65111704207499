export class AccountReport {
  userId: string
  accountId: string
  accountName: string
  exchangeCode: string
  isSimulated: boolean

  constructor(jsonData: any) {
    this.userId = jsonData['UID']
    this.accountId = jsonData['AID']
    this.accountName = jsonData['AN']
    this.exchangeCode = jsonData['EC']
    this.isSimulated = jsonData['IS']
  }
}
