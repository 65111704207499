/* eslint-disable react/display-name */
import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppThemeName } from '@styling/themes/themes'
import React from 'react'
import { Icon } from 'react-lazy-svg'

import BinanceBlack from './svg/exchange-icons-black/binance.svg'
import BinanceWhite from './svg/exchange-icons-white/binance.svg'

import TradeStationBlack from './svg/exchange-icons-black/tradestation.svg'
import TradeStationWhite from './svg/exchange-icons-white/tradestation.svg'

import Binance from './svg/exchange-icons/binance.svg'
import Bitfinex from './svg/exchange-icons/bitfinex.svg'
import Bitget from './svg/exchange-icons/bitget.svg'
import BitMEX from './svg/exchange-icons/bitmex.svg'
import Bitpanda from './svg/exchange-icons/bitpanda-pro.svg'
import BitstampBlack from './svg/exchange-icons/bitstamp-black.svg'
import BitstampWhite from './svg/exchange-icons/bitstamp-white.svg'
import Bittrex from './svg/exchange-icons/bittrex.svg'
import Bit2Me from './svg/exchange-icons/bit2me.svg'
import Bybit from './svg/exchange-icons/bybit.svg'
import CexIO from './svg/exchange-icons/cex.svg'
import CoinbasePro from './svg/exchange-icons/coinbase-pro.svg'
import Crypto from './svg/exchange-icons/crypto.svg'
import Deribit from './svg/exchange-icons/deribit.svg'
import FTX from './svg/exchange-icons/ftx.svg'
import FTXUS from './svg/exchange-icons/ftxus.svg'
import GateIO from './svg/exchange-icons/gateio.svg'
import Gemini from './svg/exchange-icons/gemini.svg'
import Gleec from './svg/exchange-icons/gleec.svg'
import HitBTC from './svg/exchange-icons/hitbtc.svg'
import Huobi from './svg/exchange-icons/huobi.svg'
import Ionomy from './svg/exchange-icons/ionomy.svg'
import Kraken from './svg/exchange-icons/kraken.svg'
import Kucoin from './svg/exchange-icons/kucoin.svg'
import LBank from './svg/exchange-icons/lbank.svg'
import Mandala from './svg/exchange-icons/mandala.svg'
import Nash from './svg/exchange-icons/nash.svg'
import OKCoin from './svg/exchange-icons/okcoin.svg'
import OKEXBlack from './svg/exchange-icons/okex-black.svg'
import OKEXWhite from './svg/exchange-icons/okex-white.svg'
import Phemex from './svg/exchange-icons/phemex.svg'
import Poloniex from './svg/exchange-icons/poloniex.svg'
import TradeStation from './svg/exchange-icons/tradestation.svg'
import Woo from './svg/exchange-icons/woo.svg'
import WooFutures from './svg/exchange-icons/woofutures.svg'


import BinanceLogo from './svg/exchange-logos/binance.svg'
import BitfinexLogo from './svg/exchange-logos/bitfinex.svg'
import BitMEXLogo from './svg/exchange-logos/bitmex.svg'
import BitpandaLogo from './svg/exchange-logos/bitpanda-pro.svg'
import Bit2MeLogo from './svg/exchange-logos/bit2me.svg'
import BitstampLogo from './svg/exchange-logos/bitstamp.svg'
import BittrexLogo from './svg/exchange-logos/bittrex.svg'
import BybitLogo from './svg/exchange-logos/bybit.svg'
import CexIOLogo from './svg/exchange-logos/cex.svg'
import CoinbaseProLogo from './svg/exchange-logos/coinbase-pro.svg'
import DeribitLogo from './svg/exchange-logos/deribit.svg'
import FTXLogo from './svg/exchange-logos/ftx.svg'
import GateIOLogo from './svg/exchange-logos/gateio.svg'
import GeminiLogo from './svg/exchange-logos/gemini.svg'
import GleecLogo from './svg/exchange-logos/gleec.svg'
import HitBTCLogo from './svg/exchange-logos/hitbtc.svg'
import HuobiLogo from './svg/exchange-logos/huobi.svg'
import IonomyLogo from './svg/exchange-logos/ionomy.svg'
import KrakenLogo from './svg/exchange-logos/kraken.svg'
import KucoinLogo from './svg/exchange-logos/kucoin.svg'
import LBankLogo from './svg/exchange-logos/lbank.svg'
import NashLogo from './svg/exchange-logos/nash.svg'
import OKCoinLogo from './svg/exchange-logos/okcoin.svg'
import OKEXLogo from './svg/exchange-logos/okex.svg'
import PoloniexLogo from './svg/exchange-logos/poloniex.svg'
import PhemexLogo from './svg/exchange-logos/phemex.svg'
import HaasonlineIconAltLocal from './svg/haasonline/icon-alt-local.svg'
import HaasonlineIconAltStaging from './svg/haasonline/icon-alt-staging.svg'
import HaasonlineIconAlt from './svg/haasonline/icon-alt.svg'
import HaasonlineIcon from './svg/haasonline/icon.svg'
import HaasonlineLogo from './svg/haasonline/logo.svg'
import HaasScript from './svg/haasscript.svg'
import TradeNow from './svg/trade.svg'

library.add(fas, far, fal, fad, fab)

enum Styles {
  'solid' = 'fas',
  'regular' = 'far',
  'light' = 'fal',
  'duotone' = 'fad',
  'brands' = 'fab',
}

type Style = keyof typeof Styles

const getIcon = (style: Style = 'regular', icon: IconName): [IconPrefix, IconName] => {
  return [Styles[style] as IconPrefix, icon]
}

export default {

  Add: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'plus')} />,
  Admin: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'user-crown')} />,
  AngleLeft: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'chevron-left')} />,
  AngleRight: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'chevron-right')} />,
  AngleUp: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'chevron-up')} />,
  Api: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'plug')} />,
  Archive: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'archive')} />,
  ArrowUp: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'arrow-up')} />,
  ArrowLeft: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'arrow-left')} />,
  ArrowRight: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'circle-arrow-right')} />,
  At: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'at')} />,
  Backtest: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'flask')} />,
  Bold: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'bold')} />,
  Bolt: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'bolt')} />,
  BotScript: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'code')} />,
  Bug: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'bug')} />,
  Building: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'building')} />,
  Bullhorn: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'bullhorn')} />,
  Burger: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'bars')} />,
  Calendar: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'calendar')} />,
  Cancel: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'ban')} />,
  CaretDown: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'caret-down')} />,
  CaretRight: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'caret-right')} />,
  CaretUp: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'caret-up')} />,
  Chart: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'chart-line')} />,
  Check: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'check')} />,
  ChevronDoubleDown: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'chevron-double-down')} />,
  ChevronDoubleLeft: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'chevron-double-left')} />,
  ChevronDoubleRight: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'chevron-double-right')} />,
  ChevronDoubleUp: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'chevron-double-up')} />,
  ChevronDown: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'chevron-down')} />,
  ChevronLeft: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'chevron-left')} />,
  ChevronRight: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'chevron-right')} />,
  ChevronUp: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'chevron-up')} />,
  Clean: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'text-slash')} />,
  Clipboard: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'clipboard')} />,
  Clock: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'clock')} />,
  Clone: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'clone')} />,
  Close: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'times')} />,
  Code: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'code')} />,
  CollapseLeft: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'left-to-line')} />,
  CollapseRight: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'right-to-line')} />,
  CommandScript: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'terminal')} />,
  Comment: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'comment')} />,
  Copy: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'copy')} />,
  Custom: (icon: IconName, style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, icon)} />,
  Dashboard: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'th-large')} />,
  Debug: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'debug')} />,
  Delete: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'trash-alt')} />,
  Download: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'cloud-download')} />,
  Edit: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'edit')} />,
  Ellipsis: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'ellipsis-v')} />,
  Eraser: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'eraser')} />,
  ExchangeStatus: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'tachometer-slow')} />,
  Exchanges: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'exchange')} />,
  ExclamationCircle: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'exclamation-circle')} />,
  Export: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'arrow-to-top')} />,
  Eye: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'eye')} />,
  EyeSlash: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'eye-slash')} />,
  File: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'file')} />,
  Filter: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'filter')} />,
  Folder: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'folder-open')} />,
  FolderClosed: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'folder')} />,
  FolderMove: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'folder-grid')} />,
  FolderPlus: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'folder-plus')} />,
  FolderUp: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'folder-arrow-up')} />,
  Fork: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'code-branch')} />,
  Fullscreen: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'expand-alt')} />,
  Handshake: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'handshake')} />,
  Header: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'header')} />,
  Heart: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'heart')} />,
  Help: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'question-circle')} />,
  Home: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'home')} />,
  Import: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'arrow-to-bottom')} />,
  Info: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'info-circle')} />,
  InternalLink: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'link')} />,
  Italic: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'italic')} />,
  Key: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'key')} />,
  Language: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'language')} />,
  Licence: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'id-card')} />,
  Link: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'external-link')} />,
  List: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'list-ul')} />,
  Lock: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'lock')} />,
  Log: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'file-lines')} />,
  Logout: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'sign-out')} />,
  Notification: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'bell')} />,
  Offline: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'wifi-slash')} />,
  OrderedList: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'list-ol')} />,
  Orders: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'list-ol')} />,
  Pause: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'pause')} />,
  Personal: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'user-friends')} />,
  Pin: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'thumbtack')} />,
  Portfolio: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'badge-dollar')} />,
  Positions: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'list-tree')} />,
  Questions: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'comments-question-check')} />,
  Quote: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'quote-left')} />,
  Redo: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'redo')} />,
  Refresh: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'sync')} />,
  Reply: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'reply')} />,
  Report: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'file-chart-column')} />,
  Robot: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'user-robot')} />,
  Save: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'save')} />,
  Search: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'search')} />,
  Settings: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'cog')} />,
  Share: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'share-nodes')} />,
  Shield: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'shield')} />,
  Shuffle: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'shuffle')} />,
  Signal: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'signal-stream')} />,
  Silent: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'comment-alt-smile')} />,
  Star: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'star')} />,
  Start: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'play')} />,
  Stop: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'stop')} />,
  Strikethrough: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'strikethrough')} />,
  Subtract: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'minus')} />,
  TaskList: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'list-check')} />,
  Topic: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'messages')} />,
  Trophy: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'trophy')} />,
  Underline: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'underline')} />,
  Undo: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'undo')} />,
  UnorderedList: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'list')} />,
  Upload: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'cloud-upload')} />,
  Version: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'code-merge')} />,
  Wallet: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'wallet')} />,
  Web: (style?: Style): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'globe-americas')} />,
  WebShop: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'shopping-cart')} />,
  Wifi: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'wifi')} />,
  ZoomIn: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'search-plus')} />,
  ZoomOut: (style: Style = 'solid'): JSX.Element => <FontAwesomeIcon icon={getIcon(style, 'search-minus')} />,

  Bitcoin: (): JSX.Element => <FontAwesomeIcon icon={getIcon('brands', 'bitcoin')} />,
  Discord: (): JSX.Element => <FontAwesomeIcon icon={getIcon('brands', 'discord')} />,
  Slack: (): JSX.Element => <FontAwesomeIcon icon={getIcon('brands', 'slack')} />,
  Facebook: (): JSX.Element => <FontAwesomeIcon icon={getIcon('brands', 'facebook')} />,
  Telegram: (): JSX.Element => <FontAwesomeIcon icon={getIcon('brands', 'telegram')} />,
  Twitter: (): JSX.Element => <FontAwesomeIcon icon={getIcon('brands', 'twitter')} />,

  Loader: (): JSX.Element => <FontAwesomeIcon icon={getIcon('duotone', 'spinner-third')} spin />,

  TradeNow: (): JSX.Element => <Icon url={TradeNow} />,
  HaasScript: (): JSX.Element => <Icon url={HaasScript} />,
  HaasonlineIcon: (): JSX.Element => <Icon url={HaasonlineIcon} />,
  HaasonlineIconAlt: (): JSX.Element => <Icon url={HaasonlineIconAlt} />,
  HaasonlineIconAltStaging: (): JSX.Element => <Icon url={HaasonlineIconAltStaging} />,
  HaasonlineIconAltLocal: (): JSX.Element => <Icon url={HaasonlineIconAltLocal} />,
  HaasonlineLogo: (): JSX.Element => <Icon url={HaasonlineLogo} />,

  ExchangeIcons: {
    BINANCE: (theme: AppThemeName) => <Icon url={Binance} />,
    BINANCEFUTURES: (theme: AppThemeName) => <Icon url={Binance} />,
    BINANCEQUARTERLY: (theme: AppThemeName) => <Icon url={Binance} />,
    BINANCEUS: (theme: AppThemeName) => <Icon url={Binance} />,
    BITFINEX: (theme: AppThemeName) => <Icon url={Bitfinex} />,
    BITGET: (theme: AppThemeName) => <Icon url={Bitget} />,
    BITGETFUTURES: (theme: AppThemeName) => <Icon url={Bitget} />,
    BITGETFUTURESCOIN: (theme: AppThemeName) => <Icon url={Bitget} />,
    BITGETFUTURESUSDT: (theme: AppThemeName) => <Icon url={Bitget} />,
    BITMEX: (theme: AppThemeName) => <Icon url={BitMEX} />,
    BITMEXSPOT: (theme: AppThemeName) => <Icon url={BitMEX} />,
    BITMEXDERIVATIVE: (theme: AppThemeName) => <Icon url={BitMEX} />,
    BITPANDA: (theme: AppThemeName) => <Icon url={Bitpanda} />,
    BITSTAMP: (theme: AppThemeName) => <Icon url={theme === 'light' ? BitstampBlack : BitstampWhite} />,
    BITTREX: (theme: AppThemeName) => <Icon url={Bittrex} />,
    BIT2ME: (theme: AppThemeName) => <Icon url={Bit2Me} />,
    BYBIT: (theme: AppThemeName) => <Icon url={Bybit} />,
    BYBITSPOT: (theme: AppThemeName) => <Icon url={Bybit} />,
    BYBITUSDT: (theme: AppThemeName) => <Icon url={Bybit} />,
    CEXIO: (theme: AppThemeName) => <Icon url={CexIO} />,
    COINBASE: (theme: AppThemeName) => <Icon url={CoinbasePro} />,
    CRYPTO: (theme: AppThemeName) => <Icon url={Crypto} />,
    CRYPTODERIVATIVE: (theme: AppThemeName) => <Icon url={Crypto} />,
    DERIBIT: (theme: AppThemeName) => <Icon url={Deribit} />,
    FTX: (theme: AppThemeName) => <Icon url={FTX} />,
    FTXFUTURES: (theme: AppThemeName) => <Icon url={FTX} />,
    FTXUS: (theme: AppThemeName) => <Icon url={FTXUS} />,
    GATEIO: (theme: AppThemeName) => <Icon url={GateIO} />,
    GATEIOPERPETUALFUTURES: (theme: AppThemeName) => <Icon url={GateIO} />,
    GATEIODELIVERYFUTURES: (theme: AppThemeName) => <Icon url={GateIO} />,
    GEMINI: (theme: AppThemeName) => <Icon url={Gemini} />,
    GLEEC: (theme: AppThemeName) => <Icon url={Gleec} />,
    HITBTC: (theme: AppThemeName) => <Icon url={HitBTC} />,
    HUOBI: (theme: AppThemeName) => <Icon url={Huobi} />,
    HUOBIFUTURES: (theme: AppThemeName) => <Icon url={Huobi} />,
    HUOBISWAPUSDT: (theme: AppThemeName) => <Icon url={Huobi} />,
    HUOBISWAPCOIN: (theme: AppThemeName) => <Icon url={Huobi} />,
    IONOMY: (theme: AppThemeName) => <Icon url={Ionomy} />,
    KRAKEN: (theme: AppThemeName) => <Icon url={Kraken} />,
    KRAKENFUTURES: (theme: AppThemeName) => <Icon url={Kraken} />,
    KUCOIN: (theme: AppThemeName) => <Icon url={Kucoin} />,
    KUCOINFUTURES: (theme: AppThemeName) => <Icon url={Kucoin} />,
    LBANK: (theme: AppThemeName) => <Icon url={LBank} />,
    LBANKFUTURES: (theme: AppThemeName) => <Icon url={LBank} />,
    MANDALA: (theme: AppThemeName) => <Icon url={Mandala} />,
    NASH: (theme: AppThemeName) => <Icon url={Nash} />,
    OKCOINCOM: (theme: AppThemeName) => <Icon url={OKCoin} />,
    OKCOINFUTURES: (theme: AppThemeName) => <Icon url={theme === 'light' ? OKEXBlack : OKEXWhite} />,
    OKEX: (theme: AppThemeName) => <Icon url={theme === 'light' ? OKEXBlack : OKEXWhite} />,
    OKEXSWAP: (theme: AppThemeName) => <Icon url={theme === 'light' ? OKEXBlack : OKEXWhite} />,
    POLONIEX: (theme: AppThemeName) => <Icon url={Poloniex} />,
    PHEMEX: (theme: AppThemeName) => <Icon url={Phemex} />,
    PHEMEXCONTRACTS: (theme: AppThemeName) => <Icon url={Phemex} />,
    POLONIEXFUTURES: (theme: AppThemeName) => <Icon url={Poloniex} />,
    TRADESTATION: (theme: AppThemeName) => <Icon url={TradeStation} />,
    WOOX: (theme: AppThemeName) => <Icon url={Woo} />,
	WOOXFUTURES: (theme: AppThemeName) => <Icon url={WooFutures} />,
  } as Record<string, (theme: AppThemeName) => JSX.Element>,

  ExchangeIconsWhite: {
    BINANCE: (theme: AppThemeName) => <Icon url={BinanceWhite} />,
    BINANCEFUTURES: (theme: AppThemeName) => <Icon url={BinanceWhite} />,
    BINANCEQUARTERLY: (theme: AppThemeName) => <Icon url={BinanceWhite} />,
    BINANCEUS: (theme: AppThemeName) => <Icon url={BinanceWhite} />,
    TRADESTATION: (theme: AppThemeName) => <Icon url={TradeStationWhite} />,
  } as Record<string, (theme: AppThemeName) => JSX.Element>,

  ExchangeIconsBlack: {
    BINANCE: (theme: AppThemeName) => <Icon url={BinanceBlack} />,
    BINANCEFUTURES: (theme: AppThemeName) => <Icon url={BinanceBlack} />,
    BINANCEQUARTERLY: (theme: AppThemeName) => <Icon url={BinanceBlack} />,
    BINANCEUS: (theme: AppThemeName) => <Icon url={BinanceBlack} />,
    TRADESTATION: (theme: AppThemeName) => <Icon url={TradeStationBlack} />,
  } as Record<string, (theme: AppThemeName) => JSX.Element>,


  ExchangeLogos: {
    BINANCE: <Icon url={BinanceLogo} />,
    BINANCEFUTURES: <Icon url={BinanceLogo} />,
    BINANCEQUARTERLY: <Icon url={BinanceLogo} />,
    BINANCEUS: <Icon url={BinanceLogo} />,
    BITFINEX: <Icon url={BitfinexLogo} />,
    BITMEX: <Icon url={BitMEXLogo} />,
    BITMEXSPOT: <Icon url={BitMEXLogo} />,
    BITMEXDERIVATIVES: <Icon url={BitMEXLogo} />,
    BITPANDA: <Icon url={BitpandaLogo} />,
    BITSTAMP: <Icon url={BitstampLogo} />,
    BIT2ME: <Icon url={Bit2MeLogo} />,
    BITTREX: <Icon url={BittrexLogo} />,
    BYBIT: <Icon url={BybitLogo} />,
    BYBITSPOT: <Icon url={BybitLogo} />,
    BYBITUSDT: <Icon url={BybitLogo} />,
    CEXIO: <Icon url={CexIOLogo} />,
    COINBASE: <Icon url={CoinbaseProLogo} />,
    DERIBIT: <Icon url={DeribitLogo} />,
    FTX: <Icon url={FTXLogo} />,
    FTXFUTURES: <Icon url={FTXLogo} />,
    FTXUS: <Icon url={FTXLogo} />,
    GATEIO: <Icon url={GateIOLogo} />,
    GATEIOPERPETUALFUTURES: <Icon url={GateIOLogo} />,
    GATEIODELIVERYFUTURES: <Icon url={GateIOLogo} />,
    GEMINI: <Icon url={GeminiLogo} />,
    GLEEC: <Icon url={GleecLogo} />,
    HITBTC: <Icon url={HitBTCLogo} />,
    HUOBI: <Icon url={HuobiLogo} />,
    HUOBIFUTURES: <Icon url={HuobiLogo} />,
    HUOBISWAPUSDT: <Icon url={HuobiLogo} />,
    HUOBISWAPCOIN: <Icon url={HuobiLogo} />,
    IONOMY: <Icon url={IonomyLogo} />,
    KRAKEN: <Icon url={KrakenLogo} />,
    KRAKENFUTURES: <Icon url={KrakenLogo} />,
    KUCOIN: <Icon url={KucoinLogo} />,
    KUCOINFUTURES: <Icon url={KucoinLogo} />,
    LBANK: <Icon url={LBankLogo} />,
    LBANKFUTURES: <Icon url={LBankLogo} />,
    NASH: <Icon url={NashLogo} />,
    OKCOINCOM: <Icon url={OKCoinLogo} />,
    OKCOINFUTURES: <Icon url={OKEXLogo} />,
    OKEX: <Icon url={OKEXLogo} />,
    OKEXSWAP: <Icon url={OKEXLogo} />,
    POLONIEX: <Icon url={PoloniexLogo} />,
    POLONIEXFUTURES: <Icon url={PoloniexLogo} />,
    PHEMEX: <Icon url={PhemexLogo} />,
    PHEMEXCONTRACTS: <Icon url={PhemexLogo} />,
  },
}
