import { ServiceController } from '@services/ServiceController'
import { _ } from '@utils/lodash'
import { EnumHaasChartPricePlotStyle } from '@vendor/hxCharts/enums/EnumHaasChartPricePlotStyle'
import { HaasChartContainer } from '@vendor/hxCharts/types/HaasChartContainer'
import { UserAccount } from '../private/account/UserAccount'
import { CloudTradeMarket } from '../public/CloudTradeMarket'
import { HaasScriptCompiledResult } from '../scripting/HaasScriptCompiledResult'
import { HaasScriptInputField } from '../scripting/HaasScriptInputField'
import { orderTemplateToString } from '../utils'
import RuntimeCustomReport from './reports/RuntimeCustomReport'
import { RuntimeReport } from './reports/RuntimeReport'
import { RuntimeFailedOrder } from './RuntimeFailedOrder'
import { RuntimeOrder } from './RuntimeOrder'
import { RuntimePosition } from './RuntimePosition'
import {EnumAccountPositionMode} from "@dataObjects/enums/EnumAccountPositionMode";
import {EnumAccountMarketMarginMode} from "@dataObjects/enums/EnumAccountMarketMarginMode";

export type RuntimeBackLogMessage = {
  messageId: number
  message: string
}

export type RuntimeFileDefinition = {
  name : string;
  headers : string[];
}

export type Runtime = HaasScriptCompiledResult & {
  botId: string
  botName: string

  scriptId: string
  scriptName: string

  updateCounter: number
  lastUpdate: number

  activated: boolean
  paused: boolean
  activatedSince: number
  deactivatedSince: number

  account?: UserAccount
  accountId: string

  market?: CloudTradeMarket
  marketTag: string

  leverage: number
  positionMode: EnumAccountPositionMode
  marginMode : EnumAccountMarketMarginMode

  tradeAmount: number

  isScriptOk: boolean
  accountError: boolean
  tradeAmountError: boolean
  scriptTradeAmountError: boolean

  logId: string
  logCount: number

  defaultInterval: number
  defaultChartType: EnumHaasChartPricePlotStyle

  isMultiMarket: boolean
  isManagedTrading: boolean

  reports: RuntimeReport[]
  customReport: RuntimeCustomReport[]

  openOrders: RuntimeOrder[]
  failedOrders: RuntimeOrder[]

  openPositions: RuntimePosition[]
  finishedPositions: RuntimePosition[]

  files: RuntimeFileDefinition[]

  chart: HaasChartContainer
}

export const parseRuntime = (services: ServiceController, jsonData: any, isBacktest?: boolean): Runtime => {
  let account = services.accountService.getAccount(jsonData['AccountId'])
  if (isBacktest) account = services.backtestService.accounts.find((c) => c.accountId === jsonData['AccountId'])

  return {
    isValid: true,
    isCommand: true,
    luaCompileError: [],
    visualCompileError: [],
    dependencies: [],
    updateCounter: jsonData['UpdateCounter'],

    botId: jsonData['BotId'],
    botName: jsonData['BotName'],

    scriptId: jsonData['ScriptId'],
    scriptName: jsonData['ScriptName'],

    activated: jsonData['Activated'],
    paused: jsonData['Paused'],
    activatedSince: jsonData['ActivatedSince'],
    deactivatedSince: jsonData['DeactivatedSince'],
    lastUpdate: jsonData['UpdateTimestamp'],

    account: account,
    accountId: jsonData['AccountId'],

    market: services.initData.getTradeMarket(jsonData['PriceMarket']),
    marketTag: jsonData['PriceMarket'],

    leverage: jsonData['Leverage'],
    positionMode: jsonData['PositionMode'],
    marginMode : jsonData['MarginMode'],

    tradeAmount: jsonData['TradeAmount'],
    hideTradeAmountSettings: jsonData['HideTradeAmountSettings'],

    orderTemplate: jsonData['OrderTemplate'],
    orderTemplateAsString: orderTemplateToString(jsonData['OrderTemplate']),
    hideOrderSettings: jsonData['HideOrderSettings'],

    isScriptOk: jsonData['IsScriptOk'],
    accountError: jsonData['AccountError'],
    tradeAmountError: jsonData['TradeAmountError'],
    scriptTradeAmountError: jsonData['ScriptTradeAmountError'],

    logId: jsonData['LogId'],
    logCount: jsonData['LogCount'],
    executionLog: jsonData['ExecutionLog'],

    defaultInterval: jsonData['DefaultInterval'],
    defaultChartType: jsonData['DefaultChartType'],

    isMultiMarket: jsonData['IsMultiMarket'] || _.size(jsonData['Reports']) > 1,
    isManagedTrading: jsonData['IsManagedTrading'],

    isSpotSupported: jsonData['IsSpotSupported'],
    isMarginSupported: jsonData['IsMarginSupported'],
    isLeverageSupported: jsonData['IsLeverageSupported'],

    inputFields: _.map(jsonData['InputFields'], (container, value, key) => (container[key] = new HaasScriptInputField(value))),

    reports: _.select(jsonData['Reports'], (value) => new RuntimeReport(services, value)),
    customReport: _.select(jsonData['CustomReport'], (value, title) => new RuntimeCustomReport(title, value)),

    openOrders: jsonData['OpenOrders'].map((c: any) => new RuntimeOrder(services, c, true)),
    failedOrders: jsonData['FailedOrders'].map((c: any) => new RuntimeFailedOrder(services, c).toRuntimeOrder()),

    openPositions: [
      new RuntimePosition(services, jsonData['ManagedLongPosition'], jsonData['BotId']),
      new RuntimePosition(services, jsonData['ManagedShortPosition'], jsonData['BotId']),
      ...jsonData['UnmanagedPositions'].map((c: any) => new RuntimePosition(services, c, jsonData['BotId'])),
    ].filter((c) => c.size > 0),

    finishedPositions: jsonData['FinishedPositions'].map((c: any) => new RuntimePosition(services, c, jsonData['BotId'])),

    chart: jsonData['Chart'],
    files: _.select(jsonData['Files'] ?? {}, (item : any) => ({
      name : item['Name'],
      headers : item['Headers']
    })),
  }
}

export const getRuntimePositionOrders = (runtime?: Runtime) => {
  if (!runtime) return []

  const positions = [...runtime.openPositions, ...runtime.finishedPositions]

  const orders = positions.map((c) => c.enterOrders.concat(c.exitOrders)).filter((c) => c.length > 0)

  if (!orders.length) return []

  return orders.reduce((previousValue, currentValue) => (previousValue || []).concat(currentValue || []))
}

export const getRuntimeAllOrder = (runtime?: Runtime) => {
  if (!runtime) return []

  return [...runtime?.openOrders, ...runtime?.failedOrders, ...getRuntimePositionOrders(runtime)]
}
