import { _ } from '@utils/lodash'
import { EnumUserNotificationsCategory } from './EnumUserNotificationsCategory'
import { EnumUserNotificationsSeverity } from './EnumUserNotificationsSeverity'

export class UserNotification {
  notificationId: string
  timestamp: number
  category: EnumUserNotificationsCategory
  severity: EnumUserNotificationsSeverity
  title: string
  message: string

  constructor(jsonData: any) {
    this.notificationId = _.createShortGuid()
    this.timestamp = jsonData['U']
    this.category = jsonData['C']
    this.severity = jsonData['S']

    this.title = jsonData['T']
    this.message = jsonData['M']
  }
}
