import { HaasBlogComment } from '@dataObjects/public/HaasBlogComment'
import { HaasBlogImage } from '@dataObjects/public/HaasBlogImage'
import { IHaasBlogMessage } from '@dataObjects/public/HaasBlogPost'

export class HaasBlogTopic implements IHaasBlogMessage {
  categoryId: number
  topicId: number

  postedAt: number
  updatedAt : number;

  username: string
  avatarColor: string
  avatarCharacter = "X";

  title: string
  message: string
  attachments: HaasBlogImage[] = [];

  upVotes: number
  comments: number

  canEdit: boolean
  canUpvote: boolean

  isLocked : boolean;

  constructor(jsonData: any) {
    this.categoryId = jsonData['CID']
    this.topicId = jsonData['TID']

    this.postedAt = jsonData['PA']
    this.updatedAt = jsonData["UA"];
    this.username = jsonData['U']

    this.avatarColor = HaasBlogComment.stringToColour(this.username)
    if (this.username && this.username.length)
      this.avatarCharacter = this.username[0].toUpperCase();

    this.title = jsonData['T']
    this.message = jsonData['M']
    this.attachments = (jsonData['A'] ?? []).map((c: any) => new HaasBlogImage(c))

    this.upVotes = jsonData['V']
    this.comments = jsonData['C']

    this.canEdit = process.env.REACT_APP_IS_ADMIN === "true" ? true : jsonData['CE'];
    this.canUpvote = jsonData['CU'];

    this.isLocked = jsonData["IL"];
  }
}
