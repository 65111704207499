import { HaasScriptSettingsForm } from '@components/HaasScriptSettings/HaasScriptSettingsForm'
import { HaasScriptItem } from '@dataObjects/scripting/HaasScriptItem'
import { ApiRequest, CancellationToken, EnumApiMethod } from '../api/rest/ApiRequest'
import { RestApiBase } from '../api/rest/RestApiBase'

export class BacktestApi extends RestApiBase {
  private baseUrl = 'BacktestAPI.php'

  getBacktestAccounts(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BACKTEST_ACCOUNTS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  executeDebugTest(script: HaasScriptItem, settings: HaasScriptSettingsForm, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EXECUTE_DEBUGTEST',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        scriptId: script.scriptId,
        scriptType: script.type,
        settings: JSON.stringify(settings),
      },
    })
  }

  executeQuicktest(backtestId: string, script: HaasScriptItem, settings: HaasScriptSettingsForm, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EXECUTE_QUICKTEST',
      endPoint: this.baseUrl,
      timeout: 1000 * 60 * 30,
      authenticator: this.authenticator,
      token,
      arguments: {
        backtestId,
        scriptId: script.scriptId,
        settings: JSON.stringify(settings),
      },
    })
  }

  executeBackTest(backtestId: string, scriptId: string, settings: HaasScriptSettingsForm, startUnix: number, endUnix: number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EXECUTE_BACKTEST',
      endPoint: this.baseUrl,
      timeout: 1000 * 60 * 30,
      authenticator: this.authenticator,
      token,
      arguments: {
        backtestId,
        scriptId: scriptId,
        settings: JSON.stringify(settings),
        startunix: startUnix,
        endunix: endUnix,
      },
    })
  }

  cancelBacktest(serviceId: string, backtestId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CANCEL_BACKTEST',
      endPoint: this.baseUrl,
      timeout: 1000 * 60 * 10,
      authenticator: this.authenticator,
      arguments: {
        serviceId,
        backtestId,
      },
    })
  }

  isScriptExecuting(scriptId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'IS_SCRIPT_EXECUTION',
      endPoint: this.baseUrl,
      timeout: 1000 * 60 * 10,
      authenticator: this.authenticator,
      token,
      arguments: {
        scriptId,
      },
    })
  }

  getExecutingScripts(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EXECUTION_BACKTESTS',
      endPoint: this.baseUrl,
      timeout: 1000 * 60 * 10,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }

  getExecutionUpdate(serviceId: string, backtestId: string, isBacktest: boolean, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_EXECUTION_UPDATE',
      endPoint: this.baseUrl,
      timeout: 1000 * 60 * 10,
      authenticator: this.authenticator,
      token,
      arguments: {
        serviceId,
        backtestId,
        isBacktest,
      },
    })
  }

  getBacktestRuntime(backtestId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BACKTEST_RUNTIME',
      endPoint: this.baseUrl,
      timeout: 1000 * 60 * 10,
      authenticator: this.authenticator,
      token,
      arguments: {
        backtestId,
      },
    })
  }

  getBacktestInformation(backtestId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BACKTEST_INFO',
      endPoint: this.baseUrl,
      timeout: 1000 * 60 * 10,
      authenticator: this.authenticator,
      token,
      arguments: {
        backtestId,
      },
    })
  }

  getBacktestPositions(backtestId: string, nextPageId: number, pageLength: number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BACKTEST_POSITIONS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        backtestId,
        nextPageId,
        pageLength,
      },
    })
  }

  getBacktestLogs(backtestId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BACKTEST_LOGS',
      endPoint: this.baseUrl,
      timeout: 1000 * 60 * 10,
      authenticator: this.authenticator,
      token,
      arguments: {
        backtestId,
      },
    })
  }

  getBacktestChart(backtestId: string, interval: number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BACKTEST_CHART',
      endPoint: this.baseUrl,
      timeout: 1000 * 60 * 10,
      authenticator: this.authenticator,
      token,
      arguments: {
        backtestId,
        interval,
      },
    })
  }

  getBacktestChartPartition(backtestId: string, interval: number, partition : number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BACKTEST_CHART_PARTITION',
      endPoint: this.baseUrl,
      timeout: 1000 * 60 * 10,
      authenticator: this.authenticator,
      token,
      arguments: {
        backtestId,
        interval,
        partition
      },
    })
  }

  getBacktestChartPlot(backtestId: string, interval: number, prevPlotId : number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BACKTEST_CHART_PLOT',
      endPoint: this.baseUrl,
      timeout: 1000 * 60 * 10,
      authenticator: this.authenticator,
      token,
      arguments: {
        backtestId,
        interval,
        prevPlotId
      },
    })
  }

  getBacktestProfitChart(backtestId: string, interval: number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BACKTEST_PROFIT_CHART',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        backtestId,
        interval,
      },
    })
  }

  getAllBacktests(token: CancellationToken): ApiRequest<any[]> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ALL_BACKTESTS',
      endPoint: this.baseUrl,
      timeout: 1000 * 60 * 10,
      authenticator: this.authenticator,
      token,
      arguments: {},
    })
  }

  getBacktestsHistory(nextPageId: number, pageLength: number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BACKTEST_HISTORY',
      endPoint: this.baseUrl,
      timeout: 1000 * 60 * 10,
      authenticator: this.authenticator,
      token,
      arguments: {
        nextPageId,
        pageLength,
      },
    })
  }

  archiveBacktest(backtestId: string, archiveResult: boolean): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ARCHIVE_BACKTEST',
      endPoint: this.baseUrl,
      timeout: 1000 * 60 * 10,
      authenticator: this.authenticator,
      arguments: {
        backtestId,
        archiveResult,
      },
    })
  }

  editBacktestTag(backtestId: string, backtestTag: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_BACKTEST_TAG',
      endPoint: this.baseUrl,
      timeout: 1000 * 60 * 10,
      authenticator: this.authenticator,
      arguments: {
        backtestId,
        backtestTag,
      },
    })
  }

  getOrdersCsv(backtestId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ORDERS_CSV',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: { backtestId },
    })
  }

  getPositionsCsv(backtestId: string, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_POSITIONS_CSV',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: { backtestId },
    })
  }

  getFileCsv(backtestId: string, name : string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_FILE_CSV',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { backtestId, name },
    })
  }

  getFileJson(backtestId: string, name : string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_FILE_JSON',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { backtestId, name },
    })
  }


  deleteBacktest(backtestId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_BACKTEST',
      endPoint: this.baseUrl,
      timeout: 1000 * 60 * 10,
      authenticator: this.authenticator,
      arguments: {
        backtestId,
      },
    })
  }

  deleteUnarchivedBacktest(): ApiRequest<boolean> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_UNARCHIVED_BACKTEST',
      endPoint: this.baseUrl,
      timeout: 1000 * 60 * 10,
      authenticator: this.authenticator,
      arguments: {},
    })
  }
}
