import React from 'react'

export interface ISounds {
  none: void
  info: HTMLAudioElement
  success: HTMLAudioElement
  warning: HTMLAudioElement
  error: HTMLAudioElement
}

export type TSounds = keyof ISounds

export const sounds: ISounds = {
  none: void null,
  info: new Audio('/assets/sounds/info.mp3'),
  success: new Audio('/assets/sounds/success.mp3'),
  warning: new Audio('/assets/sounds/warning.mp3'),
  error: new Audio('/assets/sounds/error.mp3'),
}

/**
 * A custom hook to allow the user to play back a sound.
 * @param value {TSounds} - The selected value of the sound.
 */
export const usePlaySound = (value: TSounds) => {
  const [currentSound, setCurrentSound] = React.useState<string | null>(null)
  const [playing, setPlaying] = React.useState(false)
  const [duration, setDuration] = React.useState(0)
  const soundElement = React.useRef<HTMLAudioElement | null>(null)

  const doPlay = () => {
    if (soundElement.current) {
      soundElement.current.currentTime = 0
      soundElement.current.play()
    }
  }

  const doStop = () => {
    if (soundElement.current) {
      soundElement.current.pause()
      setCurrentSound(null)
    }
  }

  const playFunc = () => {
    setPlaying(true)
  }

  const endedFunc = () => {
    setPlaying(false)
  }

  React.useEffect(() => {
    if (value !== 'none') {
      soundElement.current = sounds[value].cloneNode(true) as HTMLAudioElement
      setCurrentSound(value)
      setDuration(sounds[value].duration)
    }
  }, [value])

  React.useEffect(() => {
    if (soundElement.current) {
      soundElement.current?.addEventListener('play', playFunc)
      soundElement.current?.addEventListener('ended', endedFunc)
    }
    return () => {
      soundElement.current?.removeEventListener('play', playFunc)
      soundElement.current?.removeEventListener('ended', endedFunc)
    }
  }, [])

  return {
    set: (type: TSounds) => {
      if (type !== 'none') {
        soundElement.current = sounds[type].cloneNode(true) as HTMLAudioElement
        setCurrentSound(type)
        setDuration(sounds[type].duration)
      } else {
        soundElement.current = null
        setCurrentSound(null)
        setDuration(0)
      }
    },
    play: () => doPlay(),
    stop: () => doStop(),
    duration,
    currentSound,
    playing,
  }
}

export default function playSound(type: TSounds) {
  if (type !== 'none') sounds[type].play()
}
