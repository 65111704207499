import { _ } from '@utils/lodash'

export type WebSocketServiceReportCounterType = {
  machineId: string
  serviceId: string
  clientIp: string
  serviceName: string

  key: string
  noClients: number // Unique clients
  noChannels: number // Unique channels
  noExchanges: number // Unique exchanges
  noMarkets: number // Unique markets
}

export class WebSocketServiceReport {
  machineId: string
  serviceId: string
  serviceName: string

  onClients: Record<string, WebSocketServiceReportCounterType>
  onChannels: Record<string, WebSocketServiceReportCounterType>
  onExchanges: Record<string, WebSocketServiceReportCounterType>
  onMarkets: Record<string, WebSocketServiceReportCounterType>

  constructor(jsonData: any) {
    this.machineId = jsonData['MID']
    this.serviceId = jsonData['SID']
    this.serviceName = jsonData['SN']

    this.onClients = _.map(jsonData['OCL'], (container, item, key) => (container[key] = this.parseCounter(this.machineId, this.serviceId, this.serviceName, String(key), item)))
    this.onChannels = _.map(jsonData['OCH'], (container, item, key) => (container[key] = this.parseCounter(this.machineId, this.serviceId, this.serviceName, String(key), item)))
    this.onExchanges = _.map(jsonData['OE'], (container, item, key) => (container[key] = this.parseCounter(this.machineId, this.serviceId, this.serviceName, String(key), item)))
    this.onMarkets = _.map(jsonData['OM'], (container, item, key) => (container[key] = this.parseCounter(this.machineId, this.serviceId, this.serviceName, String(key), item)))
  }

  private parseCounter(machineId: string, serviceId: string, serviceName: string, key: string, jsonData: any): WebSocketServiceReportCounterType {
    return {
      machineId,
      serviceId,
      serviceName,
      key,
      clientIp: jsonData['ClientIp'],
      noClients: jsonData['CLC'],
      noChannels: jsonData['CHC'],
      noExchanges: jsonData['EC'],
      noMarkets: jsonData['MC'],
    }
  }
}
