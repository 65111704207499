import { AccountService } from '@services/account/AccountService'
import { CloudInitData } from '../../public/CloudInitData'
import { CloudMarket } from '../../public/CloudMarket'
import { UserAccount } from '../account/UserAccount'

class UserTradeFrequency {
  dailyTrades: number
  weeklyTrades: number
  monthlyTrades: number
  total: number

  constructor(jsonData: any) {
    this.dailyTrades = jsonData['DT']
    this.weeklyTrades = jsonData['WT']
    this.monthlyTrades = jsonData['MT']
    this.total = jsonData['TT']
  }
}

export class UserTradeFrequencyOnAccount extends UserTradeFrequency {
  account?: UserAccount

  constructor(service: AccountService, jsonKey: string, jsonData: any) {
    super(jsonData)

    this.account = service.getAccount(jsonKey)
  }
}

export class UserTradeFrequencyOnMarket extends UserTradeFrequency {
  market?: CloudMarket

  constructor(initData: CloudInitData, jsonKey: string, jsonData: any) {
    super(jsonData)

    this.market = new CloudMarket(jsonKey, initData)
  }
}

export default UserTradeFrequency
