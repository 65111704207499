import { UserSignals } from '@dataObjects/private/UserSignals'
import { ApiRequest, CancellationToken } from '../api/rest/ApiRequest'
import { ServiceControllerConstructorType } from '../ServiceController'
import { SignalApi } from './SignalApi'

export class SignalService {
  private api: SignalApi
  private signals: UserSignals[] = []

  constructor(props: ServiceControllerConstructorType) {
    this.api = new SignalApi(props.authenticator, props.controller)
  }

  async getSignals(token: CancellationToken): Promise<UserSignals[]> {
    const response = await this.api.getSignals(token)
    this.signals = response.map((value: any) => new UserSignals(value))
    return [...this.signals]
  }

  async addSignal(name: string, description: string): Promise<UserSignals> {
    const response = await this.api.addSignal(name, description)
    const signal = new UserSignals(response)
    this.signals.push(signal)
    return signal
  }

  async editSignal(id: string, name: string, description: string): Promise<boolean> {
    const signal = this.signals.find((c) => c.id === id) as UserSignals

    await this.api.editSignal(id, name, description)
    signal.name = name
    signal.description = description

    return true
  }

  async deleteSignal(id: string): Promise<boolean> {
    await this.api.deleteSignal(id)
    this.signals = this.signals.filter((c) => c.id !== id)
    return true
  }

  emailSignal(id: string): ApiRequest<boolean> {
    return this.api.emailSignal(id)
  }

  getSignal(id: string, token: CancellationToken): ApiRequest<number> {
    return this.api.getSignal(id, token)
  }

  publishSignal(id: string): ApiRequest<boolean> {
    return this.api.publishSignal(id)
  }

  unpublishSignal(id: string): ApiRequest<boolean> {
    return this.api.unpublishSignal(id)
  }
}
