import { EnumHaasScriptInputFieldType } from '../enums/EnumHaasScriptInputFieldType'

export class HaasScriptScanField {
  name: string
  key: string
  type: EnumHaasScriptInputFieldType
  choosenOptions: string[]
  highestValue: number
  lowestValue: number
  decimals: number

  constructor(jsonData: any) {
    this.name = jsonData['N']
    this.key = jsonData['K']
    this.choosenOptions = jsonData['OO']
    this.type = jsonData['T']
    this.highestValue = jsonData['HV']
    this.lowestValue = jsonData['LV']
    this.decimals = jsonData['D']
  }
}
