import Icons from '@assets/icons'
import { EnumExchangeAuthenticationStyle } from '@dataObjects/enums/EnumExchangeAuthenticationStyle'
import { _ } from '@utils/lodash'
import { EnumExchangeRating } from '../enums/EnumExchangeRating'
import { CloudExchangeSocial } from './CloudExchangeSocial'
import { CloudTradeMarket } from './CloudTradeMarket'

export class CloudPriceSource {
  exchangeCode: string
  exchangeName: string
  exchangeFamily: string
  exchangeColor: string
  description: string
  location: string
  foundedYear: number
  referalLink: string
  rating: EnumExchangeRating

  markets!: CloudTradeMarket[]

  isSpot: boolean
  isMargin: boolean
  isLeverage: boolean
  arbitrageSupported: boolean

  gitBookApiUrl: string
  website: string

  socials: CloudExchangeSocial[]

  apiVersions : Record<number, string>
  authMethods : EnumExchangeAuthenticationStyle[]
  keyFields: {
    privateKey: string
    extraKey: string
    publicKey: string
  }

  oauth : {
    isAvailable : boolean
    buttonBackground : string;
    isDarkColor : boolean;
  }

  constructor(jsonData: any) {
    this.exchangeCode = jsonData['N']
    this.exchangeName = jsonData['FN']
    this.exchangeFamily = jsonData['F']
    this.exchangeColor = jsonData['C']

    this.location = jsonData['L']
    this.description = jsonData['D']
    this.foundedYear = jsonData['FY']
    this.referalLink = jsonData['AL']
    this.rating = jsonData['R']

    this.isSpot = jsonData['ST']
    this.isMargin = jsonData['MT']
    this.isLeverage = jsonData['LT']
    this.arbitrageSupported = jsonData['AS']

    this.gitBookApiUrl = jsonData['GI']
    this.website = jsonData['WE'];

    this.apiVersions = jsonData['AV']
    this.keyFields = {
      publicKey: jsonData['PKL'],
      privateKey: jsonData['SKL'],
      extraKey: jsonData['AFL'],
    }

    this.oauth = {
      isAvailable : jsonData['OA']['IA'],
      buttonBackground : jsonData['OA']['BB'],
      isDarkColor : jsonData['OA']['IDT']
    }

    this.authMethods = [];
    if (this.oauth.isAvailable) this.authMethods.push(EnumExchangeAuthenticationStyle.OAuth)
    if (!!this.keyFields.publicKey) this.authMethods.push(EnumExchangeAuthenticationStyle.APIKeys)


    this.socials = [
      new CloudExchangeSocial(Icons.Web(), jsonData['WE'], 'Website'),
      new CloudExchangeSocial(Icons.At(), jsonData['EM'], 'Email'),
      new CloudExchangeSocial(Icons.Twitter(), jsonData['TW'], 'Twitter'),
      new CloudExchangeSocial(Icons.Telegram(), jsonData['TE'], 'Telegram'),
      new CloudExchangeSocial(Icons.Facebook(), jsonData['FA'], 'Facebook'),
      new CloudExchangeSocial(Icons.Discord(), jsonData['DI'], 'Discord'),
    ]

    this.getCoins = this.getCoins.bind(this)
  }

  getCoins() {
    const coins = !this.isLeverage ? _.selectMany(this.markets, (c) => [c.primary, c.secondary]) : _.select(this.markets, (c) => c.marginCurrency)

    return _.distinctBy(coins, (item) => item)
  }

  sort(value: CloudPriceSource): number {
    return _.stringSort(this.exchangeName, value.exchangeName)
  }
}
