import { ServiceController } from '@services/ServiceController'
import { _ } from '@utils/lodash'
import RuntimeCustomReport from './reports/RuntimeCustomReport'
import { RuntimeReport } from './reports/RuntimeReport'
import { RuntimeAsyncPositionUpdate } from './RuntimeAsyncPositionUpdate'
import { RuntimeFailedOrder } from './RuntimeFailedOrder'
import { RuntimeOrder } from './RuntimeOrder'
import { RuntimePosition } from './RuntimePosition'

export class RuntimeAsyncUpdate {
  botId: string
  isPaused: boolean
  isActivated: boolean
  hasCompletedOrders: number
  lastUpdated: number
  updateCounter: number

  logId: string
  logCount: number
  executionLog: string[]

  openOrders: RuntimeOrder[]
  openOrdersIds: string[]
  closedOrdersIds: string[]

  failedOrders: RuntimeOrder[]

  openPositions: RuntimePosition[]
  openPositionUpdates: RuntimeAsyncPositionUpdate[]
  finishedPositions: RuntimePosition[]

  reports?: RuntimeReport[]
  customReport?: RuntimeCustomReport[]

  constructor(services: ServiceController, jsonData: any) {
    this.botId = jsonData['BID']
    this.isActivated = jsonData['A']
    this.isPaused = jsonData['P']
    this.lastUpdated = jsonData['LU']
    this.updateCounter = jsonData['UC']
    this.hasCompletedOrders = jsonData['HCO']

    this.logId = jsonData['LID']
    this.logCount = jsonData['LC']
    this.executionLog = jsonData['L']
    this.executionLog.reverse()

    this.openOrdersIds = jsonData['OID']
    this.closedOrdersIds = jsonData['CID']

    this.openOrders = jsonData['OO'].map((c: any) => new RuntimeOrder(services, c, true))
    this.failedOrders = jsonData['FO'].map((c: any) => new RuntimeFailedOrder(services, c).toRuntimeOrder())

    this.openPositions = jsonData['OP'].map((c: any) => new RuntimePosition(services, c, this.botId))
    this.openPositionUpdates = jsonData['OPU'].map((c: any) => new RuntimeAsyncPositionUpdate(c))

    this.finishedPositions = jsonData['FP'].map((c: any) => new RuntimePosition(services, c, this.botId))

    if (jsonData['R']) this.reports = _.select(jsonData['R'], (value) => new RuntimeReport(services, value))
    if (jsonData['CR']) this.customReport = _.select(jsonData['CR'], (items, title) => new RuntimeCustomReport(title, items))
  }
}
