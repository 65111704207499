import { EnumHaasCommandParameterType } from '../enums/EnumHaasCommandParameterType'
import { EnumHaasCommandType } from '../enums/EnumHaasCommandType'
import { HaasCommand } from './HaasCommand'

export class HaasCommandParameter {
  name: string
  type: EnumHaasCommandParameterType
  isRequired: boolean
  isHidden: boolean
  isField: boolean
  allowNull: boolean
  description: string
  suggestion: EnumHaasCommandType[]
  input: boolean

  constructor(jsonData: any, input: boolean) {
    this.input = input
    this.name = jsonData['Name']
    this.type = jsonData['Type']
    this.description = jsonData['Description']
    this.isRequired = jsonData['IsRequired']
    this.isHidden = jsonData['IsHidden']
    this.isField = jsonData['IsField']
    this.allowNull = jsonData['AllowNull']
    this.suggestion = jsonData['Suggestion']
  }

  static fromOutput(command: HaasCommand): HaasCommandParameter {
    const parameter = new HaasCommandParameter({}, false)
    parameter.name = 'Result'
    parameter.type = command.outputType
    parameter.description = command.returnDescription
    parameter.isHidden = command.outputHidden
    parameter.suggestion = command.outputSuggestions

    parameter.isRequired = false
    parameter.isField = false
    parameter.allowNull = false

    return parameter
  }

  toString() {
    let text = ''
    if (this.name && this.type !== EnumHaasCommandParameterType.Void) text = '**' + this.name + '** - '

    text += EnumHaasCommandParameterType[this.type] + (this.description ? ' - ' + this.description : '')

    if (!this.isRequired && this.input) text = '[ ' + text + ' ]'

    return text
  }
}
