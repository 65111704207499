import { EnumUserDiscordBotStatus } from './EnumUserDiscordBotStatus'
import { UserNotificationProfile } from './UserNotificationProfile'

export class UserDiscordRecord {
  serverId: string
  token: string
  status: EnumUserDiscordBotStatus
  profile: UserNotificationProfile

  constructor(jsonData: any) {
    this.serverId = jsonData['SID']
    this.token = jsonData['T']
    this.status = jsonData['S']
    this.profile = new UserNotificationProfile(jsonData['P'])
  }
}
