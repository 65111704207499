type Callback<T> = (data: T) => void

export class KeyedEventHandler<TData> {
  private subscriptions: Record<string, Callback<TData>[]> = {}

  fire(key: string, data: TData) {
    if (!this.subscriptions[key]) return

    this.subscriptions[key].map((c) => c(data))
  }

  subscribe(key: string, callback: Callback<TData>) {
    if (!this.subscriptions[key]) this.subscriptions[key] = []

    this.subscriptions[key].push(callback)
  }

  unsubscribe(key: string, callback: Callback<TData>) {
    if (!this.subscriptions[key]) return

    this.subscriptions[key] = this.subscriptions[key].filter((c) => c !== callback)
  }

  changeSubscription(fromKey: string | undefined, toKey: string | undefined, callback: Callback<TData>) {
    if (fromKey === toKey) return
    if (fromKey) this.unsubscribe(fromKey, callback)
    if (toKey) this.subscribe(toKey, callback)
  }

  hasSubscribers(key: string): boolean {
    if (!this.subscriptions[key]) return false

    return !!this.subscriptions[key].length
  }
}
