import { CancellationToken } from '@services/api/rest/ApiRequest'
import { BotApi } from '@services/bots/BotApi'

import { ServiceController } from '@services/ServiceController'
import { KeyedEventHandler } from '@utils/eventHandlers/KeyedEventHandler'
import { _ } from '@utils/lodash'
import AsyncObject from '../AsyncObject'
import { parseRuntime, Runtime } from './Runtime'
import { RuntimeAsyncUpdate } from './RuntimeAsyncUpdate'
import { RuntimePosition } from './RuntimePosition'

export class RuntimeAsync extends AsyncObject<Runtime, RuntimeAsyncUpdate> {
  private api: BotApi
  private services: ServiceController

  private botId: string
  private nextPositionPageId = 0

  constructor(services: ServiceController, api: BotApi, botId: string, eventHandler: KeyedEventHandler<Runtime>) {
    super(undefined, botId, eventHandler)

    this.api = api
    this.botId = botId
    this.services = services
  }

  protected async getData(): Promise<Runtime> {
    const response = await this.api.getRuntime(this.botId, this.token)
    return parseRuntime(this.services, response)
  }

  async getNextPositionPage() {
    if (this.nextPositionPageId === -1) return

    const response = await this.services.botService.getRuntimeClosedPositions(this.botId, this.nextPositionPageId, 25, new CancellationToken())

    this.nextPositionPageId = response.nextPageId

    this.updatePartial({
      finishedPositions: [...(this.data?.finishedPositions || []), ...response.items],
    })

    if (response.items.length < 25) this.nextPositionPageId = -1
  }

  async loadAll(token?: CancellationToken) {
    while (!token || !token?.isCancelled) {
      if (this.nextPositionPageId === -1) return

      const response = await this.services.botService.getRuntimeClosedPositions(this.botId, this.nextPositionPageId, 100, new CancellationToken())

      this.nextPositionPageId = response.nextPageId

      this.updatePartial({
        finishedPositions: [...(this.data?.finishedPositions || []), ...response.items],
      })

      if (response.items.length < 25) this.nextPositionPageId = -1
    }
  }

  protected processUpdate(data: RuntimeAsyncUpdate) {
    if (this.data === undefined) return

    this.data.activated = data.isActivated
    this.data.paused = data.isPaused
    this.data.lastUpdate = data.lastUpdated
    this.data.updateCounter = data.updateCounter

    this.data.logId = data.logId
    this.data.logCount = data.logCount
    this.data.executionLog = data.executionLog

    this.processOrderUpdates(data)
    this.processPositionUpdates(data)
    this.processReportUpdates(data)
  }

  private processOrderUpdates(data: RuntimeAsyncUpdate) {
    if (this.data === undefined) return

    // Filter open orders based on openOrdersId.
    this.data.openOrders = this.data.openOrders.filter((c) => data.openOrdersIds.indexOf(c.orderId) > -1)

    // Add the new open orders to the list
    this.data.openOrders.push(...data.openOrders)
    this.data.openOrders = _.distinctBy(this.data.openOrders, (item) => item.orderId)

    // Add new failed orders to the list
    this.data.failedOrders = [...this.data.failedOrders, ...data.failedOrders]
    this.data.failedOrders = _.distinctBy(this.data.failedOrders, (c) => c.orderId)

    if (this.data.failedOrders.length > 50) this.data.failedOrders = this.data.failedOrders.slice(this.data.failedOrders.length - 50)
  }

  private processPositionUpdates(data: RuntimeAsyncUpdate) {
    if (this.data === undefined) return

    // Remove position that has finished or have a full update.
    this.data.openPositions = this.data.openPositions
      .filter((c) => data.finishedPositions.every((x) => x.positionGuid !== c.positionGuid))
      .filter((c) => data.openPositions.every((x) => x.positionGuid !== c.positionGuid))

    // Add new positions
    this.data.openPositions = [...this.data.openPositions, ...data.openPositions]

    // Add finished positions
    this.data.finishedPositions = [...this.data.finishedPositions, ...data.finishedPositions]

    // Process partial position updates
    const openPositions: RuntimePosition[] = []

    _.each(this.data.openPositions, (position) => {
      const update = data.openPositionUpdates.find((c) => c.positionGuid === position.positionGuid)
      if (!update) {
        openPositions.push(position)
        return
      }

      openPositions.push({
        ...position,
        ...update,
      })
    })

    this.data.logId = data.logId
    this.data.logCount = data.logCount
    this.data.openPositions = _.distinctBy(openPositions, (c) => c.positionGuid)
    this.data.finishedPositions = _.distinctBy(this.data.finishedPositions, (c) => c.positionGuid)
  }

  private processReportUpdates(data: RuntimeAsyncUpdate) {
    if (this.data === undefined) return

    // Overwrite full updates
    this.data.customReport = data.customReport || this.data.customReport
    this.data.reports = data.reports || this.data.reports
  }
}
