import { CloudOrderbookRecord } from './CloudOrderbookRecord'

export class CloudOrderbook {
  bids: CloudOrderbookRecord[]
  asks: CloudOrderbookRecord[]

  constructor(jsonObject: any) {
    this.bids = jsonObject['Bid'].map((data: any) => new CloudOrderbookRecord(data))
    this.asks = jsonObject['Ask'].map((data: any) => new CloudOrderbookRecord(data))
  }
}
