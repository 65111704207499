export enum EnumHaasScriptInputFieldType {
  Number = 0,
  Text = 1,
  Checkbox = 2,
  Dropdown = 3,
  PriceSource = 4,
  PriceSourceMarket = 5,
  Account = 6,
  AccountMarket = 7,
  Market = 8,
  Interval = 9,
  Header = 10,
  Table = 11,
  Button = 12,
}
