import { HaasScriptSettingsForm } from '@components/HaasScriptSettings/HaasScriptSettingsForm'
import { parseUserLabConfig, prepareUserLabConfig, UserLabConfig } from './UserLabConfig'
import {
  parseUserLabParameterConfig,
  prepareUserLabParameterConfig,
  UserLabParameterConfig,
} from './UserLabParameterConfig'
import { parseUserLabRecord, prepareUserLabRecord, UserLabRecord } from './UserLabsRecords'

export type UserLabDetails = UserLabRecord & {
  config: UserLabConfig
  settings: HaasScriptSettingsForm
  parameters: UserLabParameterConfig[]
}

export function parseUserLabDetails(jsonData: any): UserLabDetails {
  return {
    ...parseUserLabRecord(jsonData),
    config: parseUserLabConfig(jsonData['C']),
    settings: jsonData['ST'],
    parameters: jsonData['P'].map((c: any) => parseUserLabParameterConfig(c)),
  }
}

export function prepareUserLabDetails(record: UserLabDetails) {
  return {
    ...prepareUserLabRecord(record),
    C: prepareUserLabConfig(record.config),
    ST: record.settings,
    P: record.parameters.map((c) => prepareUserLabParameterConfig(c)),
  }
}
