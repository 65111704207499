import { CancellationToken } from '@services/api/rest/ApiRequest'
import { KeyedEventHandler } from '@utils/eventHandlers/KeyedEventHandler'
import ExceptionHandler from '@utils/ExceptionHandler'
import { _ } from '@utils/lodash'

type AsyncObjectUpdateType = {
  updateCounter: number
}

/*
    Fetches initial data from the REST Api
    Receives full updates from the REST Api
    Receives partial updates from the WebSocket.
 */
abstract class AsyncObject<TObject, TUpdate extends AsyncObjectUpdateType> {
  protected token: CancellationToken
  private fetch?: Promise<any>

  protected data?: TObject
  private dataId?: string

  private updates: TUpdate[] = []
  private eventHandler?: KeyedEventHandler<TObject>

  protected constructor(data?: TObject, dataId?: string, eventHandler?: KeyedEventHandler<TObject>) {
    this.data = data
    this.dataId = dataId
    this.eventHandler = eventHandler
    this.token = new CancellationToken()
  }

  protected abstract getData(): Promise<TObject>
  protected abstract processUpdate(data: TUpdate): void

  private async fetchData(): Promise<TObject | undefined> {
    if (this.data) return this.data

    if (!this.fetch) {
      this.fetch = this.getData()
        .then((c) => {
          this.data = c
          this.fetch = undefined
          this.processUpdates()
        })
        .catch((reason) => {
          ExceptionHandler.LogException(reason)
          this.fetch = undefined
        })
        .finally(() => {
          this.fetch = undefined
        })
    }

    await this.fetch

    return this.data
  }

  getSync(): TObject | undefined {
    return this.data
  }

  async get(): Promise<TObject | undefined> {
    const data = await this.fetchData()
    if (!data) return data

    return {
      ...data,
    }
  }

  async refreshData(): Promise<TObject | undefined> {
    this.data = undefined
    return this.fetchData()
  }

  update(data: TUpdate) {
    this.updates.push(data)
    this.processUpdates()
  }

  updatePartial<K extends keyof TObject>(data: Pick<TObject, K> | TObject | null) {
    if (this.data === undefined) return

    this.data = {
      ...this.data,
      ...data,
    }

    this.publishUpdate()
  }

  private processUpdates() {
    if (this.data === undefined) return

    const updateCounter = (this.data as any).updateCounter

    // Remove old updates
    const updates = this.updates.filter((c) => c.updateCounter > updateCounter)

    // Check the update counter sequence
    const updatesLength = updates.length
    for (let i = 0; i < updatesLength; i++) {
      if (updateCounter + i + 1 === updates[i].updateCounter) continue

      // We missed a update
      this.data = undefined
      this.fetchData()
      return
    }

    // We good. Process all updates
    _.each(this.updates, (update) => {
      this.processUpdate(update)
    })

    this.updates = []
    this.publishUpdate()
  }

  protected publishUpdate() {
    if (!this.eventHandler || !this.dataId || !this.data) return

    if (this.eventHandler.hasSubscribers(this.dataId)) this.eventHandler.fire(this.dataId, { ...this.data })
  }

  dispose() {
    this.token.cancel()
  }
}

export default AsyncObject
