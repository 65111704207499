import { ApiAuthenticator } from '@services/api/ApiAuthenticator'

import { ServiceController } from '@services/ServiceController'
import { ApiRequest, EnumApiMethod } from '../api/rest/ApiRequest'

export class PublicApi {
  getLatestScripts(pageSize : number): ApiRequest<any> {
    return new ApiRequest({
      controller: undefined as unknown as ServiceController,
      method: EnumApiMethod.GET,
      channel: 'GET_LATEST_SHARED_SCRIPT',
      endPoint: 'HaasScriptAPI.php',
      arguments: {
        pageSize,
      },
    })
  }

  getScript(shareId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: undefined as unknown as ServiceController,
      method: EnumApiMethod.GET,
      channel: 'GET_SHARED_SCRIPT',
      endPoint: 'HaasScriptAPI.php',
      arguments: {
        shareId,
      },
    })
  }

  getScriptWithAuth(authenticator: ApiAuthenticator, shareId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: undefined as unknown as ServiceController,
      method: EnumApiMethod.GET,
      channel: 'GET_SHARED_SCRIPT_WITH_AUTH',
      endPoint: 'HaasScriptAPI.php',
      authenticator,
      arguments: {
        shareId,
      },
    })
  }
}
