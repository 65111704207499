import { ApiRequest, EnumApiMethod } from '../api/rest/ApiRequest'
import { RestApiBase } from '../api/rest/RestApiBase'

export class NewsFeedsApi extends RestApiBase {
  private baseUrl = 'NewsFeedsAPI.php'

  getNewsPage(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_NEWS_PAGE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
    })
  }

  getMarketNews(market: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_MARKET_NEWS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        market: market,
      },
    })
  }

  searchNews(newsSources: string[], searchKeys: string[]): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'SEARCH_NEWS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        newsSources: newsSources.join('|'),
        searchKeys: searchKeys.join('|'),
      },
    })
  }

  getHeadlinesOnName(feedId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_HEADLINES_ON_NAME',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        id: feedId,
      },
    })
  }

  getHeadlinesOnGroup(group: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_HEADLINES_ON_GROUP',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        group,
      },
    })
  }

  getHeadlinesOnKey(group: string, key: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_HEADLINES_ON_GROUP',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        group,
        key,
      },
    })
  }

  getNewsFeedGroups(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'GET_NEWS_FEED_GROUPS',
      endPoint: this.baseUrl,
    })
  }

  getNewsFeedSources(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.GET,
      channel: 'GET_NEWS_FEED_SOURCES',
      endPoint: this.baseUrl,
    })
  }

  getVideoFeedSources(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_VIDEO_FEED_SOURCES',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }
}
