export class WooProduct {
  id: number
  sku: string
  name: string
  description: string
  constructor(jsonData: any) {
    this.id = jsonData['Id']
    this.sku = jsonData['ProductSku']
    this.name = jsonData['Name']
    this.description = jsonData['Description']
  }
}
