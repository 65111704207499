import { EnumLicenseTypes } from '@dataObjects/enums/EnumLicenseTypes'
import { UserPosition } from '@dataObjects/private/account/UserPosition'
import { Language } from '@languages/Language'
import { LanguageEnglish } from '@languages/LanguageEnglish'
import { ServiceController } from '@services/ServiceController'
import AppThemes, { AppThemeName } from '@styling/themes/themes'
import { DefaultProps, FlexAlign, ReactRenderType } from '@utils/genericTypes'
import { LocalStorageItem } from '@utils/LocalStorageItem'
import { timezones } from '@utils/timezones'
import UnixHelper from '@vendor/hxCharts/helpers/UnixHelper'
import React from 'react'
import { DefaultTheme } from 'styled-components'

type Props = DefaultProps

type State = {
  isLoggedIn: boolean
  isUserDataLoaded: boolean
  isSocketConnected: boolean

  isAccountInformationOpen: boolean
  isUpgradeLicenseModelOpen: boolean

  tradeExecution: {
    open: boolean
    position: UserPosition | undefined
  }

  theme: DefaultTheme
  themeId: AppThemeName

  language: LanguageEnglish
  languageId: string

  pageTitle: string
  currentTitle: string

  tickerBarAlignment: FlexAlign
  timezone: string
  hour12: boolean
}

export type AppContextType = State & {
  services: ServiceController
  openTradeExecution: (position?: UserPosition) => void
  closeTradeExecution: () => void
  checkToken: () => Promise<boolean>;
  setLogin: (isLoggedIn: boolean) => void
  setTitle: (title?: string, pageTitle?: string) => void
  changeTheme: (themeId: AppThemeName, save?: boolean) => void
  setContext: <K extends keyof State>(state: Pick<State, K> | State) => void
}

export const AppContext = React.createContext<AppContextType>({} as AppContextType)

class AppContextController extends React.PureComponent<Props, State> {
  private serviceController: ServiceController
  private animationId = 0
  private lastTimeoutCheck = 0

  private timestampTimezoneSetting = new LocalStorageItem<string>('_HTS_TZ_')
  private timestampHoursSetting = new LocalStorageItem<boolean>('_HTS_TZ_H12_')
  private themeIdSetting = new LocalStorageItem<AppThemeName>('_HTS_TID_')

  constructor(props: Props) {
    super(props)

    this.serviceController = new ServiceController({
      isSocketConnected: (isConnected) => {
        this.setState({ isSocketConnected: isConnected })
      },
      logout: () => {
        this.handleLogin(false)
      },
    })

    let timezone = this.timestampTimezoneSetting.getItem('')
    if (!timezones.find((c) => c.utc.indexOf(timezone ?? '') >= 0)) timezone = ''

    this.state = {
      isLoggedIn: false,
      isUserDataLoaded: false,
      isSocketConnected: false,

      isAccountInformationOpen: false,
      isUpgradeLicenseModelOpen: false,

      tradeExecution: {
        open: false,
        position: undefined,
      },

      theme: AppThemes[this.themeIdSetting.getItem('default')],
      themeId: this.themeIdSetting.getItem('default'),

      language: Language.getLanguage('EN'),
      languageId: 'EN',

      pageTitle: process.env.REACT_APP_ENVIRONMENT !== 'APP' ? 'HaasOnline TradeServer Cloud' : 'HaasOnline TradeServer Enterprise',
      currentTitle: process.env.REACT_APP_ENVIRONMENT !== 'APP' ? 'HaasOnline TradeServer Cloud' : 'HaasOnline TradeServer Enterprise',

      tickerBarAlignment: 'flex-start',
      timezone: timezone,
      hour12: this.timestampHoursSetting.getItem(false),
    }

    this.openTradeExecution = this.openTradeExecution.bind(this)
    this.closeTradeExecution = this.closeTradeExecution.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
    this.checkToken = this.checkToken.bind(this)
    this.handleTitle = this.handleTitle.bind(this)
    this.checkTimeout = this.checkTimeout.bind(this)
    this.changeTheme = this.changeTheme.bind(this)
  }

  componentDidMount(): void {
    this.animationId = requestAnimationFrame(this.checkTimeout)
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const { timezone, hour12 } = this.state

    if (timezone !== prevState.timezone) this.timestampTimezoneSetting.setItem(timezone)

    if (hour12 !== prevState.hour12) this.timestampHoursSetting.setItem(hour12)

    UnixHelper.setDateTimeSettings(timezone, hour12)
  }

  componentWillUnmount(): void {
    cancelAnimationFrame(this.animationId)
  }

  render(): ReactRenderType {
    const context = {
      ...this.state,
      openTradeExecution: this.openTradeExecution,
      closeTradeExecution: this.closeTradeExecution,
      services: this.serviceController,
      checkToken: this.checkToken,
      setLogin: this.handleLogin,
      setTitle: this.handleTitle,
      changeTheme: this.changeTheme,
      setContext: <K extends keyof State>(state: Pick<State, K> | State) => this.setState({ ...state }),
    }

    return <AppContext.Provider value={context}>{this.props.children}</AppContext.Provider>
  }

  private async checkToken() : Promise<boolean>  {
    const { authenticator, loginService } = this.serviceController;

    if (!authenticator.isSet())
      return false;

    try {
      const response = await loginService.checkToken();
      if (response) {

        this.handleLogin(true);
        return true;
      }
    } catch (e) {

    }

    authenticator.reset()

    return false
  }

  private handleLogin(isLoggedIn: boolean) {
    const { settingsService, publicSocket, userSocket, authenticator } = this.serviceController

    if (isLoggedIn) {
      if (process.env.REACT_APP_IS_ADMIN === 'true' && process.env.REACT_APP_ENVIRONMENT !== 'LOCAL') {
        const { licenseSpecs } = authenticator.session
        const isEligable = licenseSpecs?.rights === EnumLicenseTypes.Administrator || licenseSpecs?.rights === EnumLicenseTypes.Developer
        if (!isEligable) {
          this.handleLogin(false)
          return
        }
      }

      settingsService.favoriteAccounts.fetch().then()
      settingsService.favoriteMarkets.fetch().then()

      this.setState({ isLoggedIn })
    } else {
      authenticator.reset()

      publicSocket.stop()
      userSocket.stop()

      this.setState({ isLoggedIn, isUserDataLoaded: false })
      process.env.REACT_APP_INTERCOM_ID && window.Intercom('shutdown')
      window.location.reload()
    }
  }

  private openTradeExecution(position?: UserPosition) {
    this.setState({ tradeExecution: { open: true, position } })
  }

  private closeTradeExecution() {
    this.setState({ tradeExecution: { open: false, position: undefined } })
  }

  private handleTitle(currentTitle?: string, pageTitle?: string) {
    if (this.state.currentTitle === currentTitle) return

    if (!currentTitle) currentTitle = 'HaasOnline TradeServer Cloud'
    if (!pageTitle) pageTitle = currentTitle

    this.setState({ currentTitle, pageTitle })
  }

  private changeTheme = (themeId: AppThemeName, save = true) => {
    if (this.state.themeId === themeId) return
    save && this.themeIdSetting.setItem(themeId)
    this.setState({ themeId, theme: AppThemes[themeId] })
  }

  private checkTimeout() {
    const { isLoggedIn } = this.state
    const now = Date.now()

    if (isLoggedIn && this.lastTimeoutCheck + 30 * 1000 < now) {
      this.serviceController.loginService.ping()
      this.lastTimeoutCheck = now
    }

    requestAnimationFrame(this.checkTimeout)
  }
}

export default AppContextController
