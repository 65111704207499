import { AccountReport } from './AccountReport'

export class AccountServiceReport {
  machineId: string
  serviceId: string
  serviceName: string

  accounts: AccountReport[]

  constructor(jsonData: any) {
    this.machineId = jsonData['MID']
    this.serviceId = jsonData['SID']
    this.serviceName = jsonData['SN']

    this.accounts = jsonData['A'].map((c: any) => new AccountReport(c))
  }
}
