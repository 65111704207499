import { UserExternalWallet } from '@dataObjects/public/UserExternalWallet.'
import { ServiceController, ServiceControllerConstructorType } from '../ServiceController'
import { ExternalWalletApi } from './ExternalWalletApi'

export class ExternalWalletService {
  private api: ExternalWalletApi
  private controller: ServiceController

  constructor(props: ServiceControllerConstructorType) {
    this.controller = props.controller
    this.api = new ExternalWalletApi(props.authenticator, this.controller)
  }

  async getSupportedCurrencies(): Promise<string[]> {
    const response = await this.api.getSupportedCurrencies()
    return response.map((c: any) => c)
  }

  async getWallets(): Promise<UserExternalWallet[]> {
    const response = await this.api.getWallets()
    return response.map((c: any) => new UserExternalWallet(c))
  }

  async getWallet(walletid: string): Promise<UserExternalWallet> {
    const response = await this.api.getWallet(walletid)
    return new UserExternalWallet(response)
  }

  async updateWallet(walletid: string): Promise<UserExternalWallet> {
    const response = await this.api.updateWallet(walletid)
    return new UserExternalWallet(response)
  }

  async addWallet(currency: string, adres: string, name: string): Promise<string> {
    const response = await this.api.addWallet(currency, adres, name)
    return String(response)
  }

  async editWallet(walletid: string, currency: string, adres: string, name: string): Promise<boolean> {
    const response = await this.api.editWallet(walletid, currency, adres, name)
    return Boolean(response)
  }

  async deleteWallet(walletid: string): Promise<boolean> {
    const response = await this.api.deleteWallet(walletid)
    return Boolean(response)
  }
}
