import {
  parseUserPositionActivityReport,
  UserPositionActivityReport,
} from '@services/interface/objects/UserPositionActivityReport'
import {
  createUserStatisticMoneyType,
  parseUserStatisticMoneyType,
  UserStatisticMoneyType,
} from './UserStatisticMoneyType'

export type UserStatisticReportType = {
  startTimestamp: number
  endTimestamp: number

  orderCount: number
  tradeCount: number

  botTradeVolume: UserStatisticMoneyType
  botFeeCosts: UserStatisticMoneyType
  botProfitRealized: UserStatisticMoneyType

  userStartBalance: UserStatisticMoneyType
  userEndBalance: UserStatisticMoneyType
  userBalanceChange : UserStatisticMoneyType
  userBalancePercentageChange : UserStatisticMoneyType

  botWinningPositions: UserPositionActivityReport[]
  botLoosingPositions: UserPositionActivityReport[]

  referralRebate: number
}

export const parseUserStatisticReportType = (jsonData: any): UserStatisticReportType => {
  const changeInBTC = jsonData['UEB']['BTC'] - jsonData['USB']['BTC'];
  const changeInUSD = jsonData['UEB']['USD'] - jsonData['USB']['USD'];

  const changeInBTCPercentage = changeInBTC / jsonData['USB']['BTC'] * 100;
  const changeInUSDPercentage = changeInUSD / jsonData['USB']['USD'] * 100;

  return {
    startTimestamp: jsonData['ST'],
    endTimestamp: jsonData['ET'],

    orderCount: jsonData['OC'],
    tradeCount: jsonData['TC'],

    botTradeVolume: parseUserStatisticMoneyType(jsonData['BTV']),
    botFeeCosts: parseUserStatisticMoneyType(jsonData['BFC']),
    botProfitRealized: parseUserStatisticMoneyType(jsonData['BPR']),

    userStartBalance: parseUserStatisticMoneyType(jsonData['USB']),
    userEndBalance: parseUserStatisticMoneyType(jsonData['UEB']),

    userBalanceChange : createUserStatisticMoneyType(changeInBTC, changeInUSD),
    userBalancePercentageChange : createUserStatisticMoneyType(changeInBTCPercentage, changeInUSDPercentage),

    botWinningPositions: jsonData['WP'].map((c: any) => parseUserPositionActivityReport(c)),
    botLoosingPositions: jsonData['LB'].map((c: any) => parseUserPositionActivityReport(c)),

    referralRebate: jsonData['RR'],
  }
}
