export class UserLicenseRegistration {
  licenseKey: string
  licenseType: string
  validUntill: number
  orderId: number

  constructor(jsonData: any) {
    this.licenseKey = jsonData['LicenseKey']
    this.licenseType = jsonData['LicenseType']
    this.validUntill = jsonData['ValidUntill']
    this.orderId = jsonData['OrderId']
  }
}
