import { CloudInitData } from '@dataObjects/public/CloudInitData'
import { CloudTradeMarket } from '@dataObjects/public/CloudTradeMarket'
import { EnumServiceType } from '../../enums/EnumServiceType'

export class BotExecutionReport {
  machineId: string
  serviceId: string
  serviceName: string

  userId: string
  botId: string
  botName: string
  accountId: string
  scriptId: string
  scriptName: string
  scriptType: EnumServiceType

  market?: CloudTradeMarket
  marketTag: string
  leverage: number

  registerTimestamp: number
  activateTimestamp: number
  updateTimestamp: number
  profitable: boolean

  constructor(jsonData: any, initData: CloudInitData, machineId: string, serviceId: string, serviceName: string) {
    this.machineId = machineId
    this.serviceId = serviceId
    this.serviceName = serviceName

    this.userId = jsonData['UID']
    this.botId = jsonData['BID']
    this.botName = jsonData['BN']
    this.accountId = jsonData['AID']
    this.scriptId = jsonData['SID']
    this.scriptName = jsonData['SN']
    this.scriptType = jsonData['ST']

    this.market = initData.getTradeMarket(jsonData['M'])
    this.marketTag = jsonData['M']
    this.leverage = jsonData['L']

    this.registerTimestamp = jsonData['RT']
    this.activateTimestamp = jsonData['AT']
    this.updateTimestamp = jsonData['UT']

    this.profitable = jsonData['P']
  }
}
