import { _ } from '@utils/lodash'
import { EnumHaasCommandCategory } from '../enums/EnumHaasCommandCategory'
import { EnumHaasCommandParameterType } from '../enums/EnumHaasCommandParameterType'
import { EnumHaasCommandType } from '../enums/EnumHaasCommandType'
import { EnumHaasSyntaxType } from '../enums/EnumHaasSyntaxType'
import { HaasCommandParameter } from './HaasCommandParameter'

export class HaasCommand {
  commandUiGuid = _.createGuid()

  scriptId?: string
  isValid: boolean

  commandName: string
  command: EnumHaasCommandType
  commandType: EnumHaasSyntaxType
  category: EnumHaasCommandCategory
  description: string

  isConstant: boolean
  isPrimary: boolean
  requiresCall: boolean

  changeTypes: EnumHaasCommandType[]

  parameters: HaasCommandParameter[]

  output: HaasCommandParameter
  outputIndex: HaasCommandParameter[]

  outputType: EnumHaasCommandParameterType
  outputSuggestions: EnumHaasCommandType[]
  outputHidden: boolean
  returnDescription: string

  constructor(jsonData: any) {
    this.scriptId = jsonData['ScriptId']
    this.isValid = jsonData['IsValid'] !== undefined ? jsonData['IsValid'] : true

    this.command = jsonData['Command']
    this.commandName = jsonData['CommandName']
    this.commandType = jsonData['CommandType']

    this.category = jsonData['Category']
    this.description = jsonData['Description']

    this.returnDescription = jsonData['ReturnDescription']
    this.isConstant = jsonData['IsConstant']
    this.isPrimary = jsonData['IsPrimary']
    this.requiresCall = jsonData['RequiresCall']
    this.outputHidden = jsonData['OutputHidden']

    this.outputType = jsonData['OutputType']
    this.outputSuggestions = jsonData['OutputSuggestions']
    this.changeTypes = jsonData['ChangeTypes']

    this.parameters = jsonData['Parameters'].map((c: any) => new HaasCommandParameter(c, true))
    this.outputIndex = jsonData['OutputIndex'].map((c: any) => new HaasCommandParameter(c, false))

    this.output = HaasCommandParameter.fromOutput(this)
  }
}
