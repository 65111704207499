export class OperationScriptStatistics {
  luaScripts: number
  visualScripts: number
  elementScripts: number

  total: number

  constructor(jsonData: any) {
    this.luaScripts = jsonData['LS']
    this.visualScripts = jsonData['VS']
    this.elementScripts = jsonData['ES']

    this.total = this.luaScripts + this.visualScripts + this.elementScripts
  }
}
