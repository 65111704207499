import { UserDashboardWidget } from '@dataObjects/private/UserDashboard'
import { DashboardWidgetType } from '@pages/Dashboard/Widgets/Components/DashboardWidget.types'
import { CancellationToken } from '../api/rest/ApiRequest'
import { ServiceController, ServiceControllerConstructorType } from '../ServiceController'
import { DashboardApi } from './DashboardApi'

export type UserDashboard = {
  dashboardId: string
  name: string
  index: number
}

export class DashboardService {
  private api: DashboardApi
  private controller: ServiceController

  public constructor(props: ServiceControllerConstructorType) {
    this.controller = props.controller
    this.api = new DashboardApi(props.authenticator, this.controller)
  }

  async getDashboards(token: CancellationToken): Promise<UserDashboard[]> {
    const response = await this.api.getDashboards(token)
    return response.map((c: any) => ({
      dashboardId: c['DID'],
      name: c['N'],
      index: c['I'],
    }))
  }

  async addDashboard(name: string, index: number): Promise<UserDashboard> {
    const response = await this.api.addDashboard(name, index)
    return {
      name: name,
      dashboardId: response,
      index,
    }
  }

  async editDashboard(dashboardId: string, name: string): Promise<boolean> {
    return this.api.editDashboard(dashboardId, name)
  }

  async editDashboardSorting(dashboardIds: string[]): Promise<boolean> {
    return this.api.editDashboardSorting(dashboardIds)
  }

  async cloneDashboard(dashboardId: string, name: string, index: number): Promise<UserDashboard> {
    const response = await this.api.cloneDashboard(dashboardId, name, index)
    return {
      name: name,
      dashboardId: response,
      index,
    }
  }

  async clearDashboard(dashboardId: string): Promise<boolean> {
    return this.api.clearDashboard(dashboardId)
  }

  async deleteDashboard(dashboardId: string): Promise<boolean> {
    return this.api.deleteDashboard(dashboardId)
  }

  async getWidgets(dashboardId: string, token: CancellationToken): Promise<UserDashboardWidget<any>[]> {
    const response = await this.api.getWidgets(dashboardId, token)
    return response.map((c: any) => new UserDashboardWidget(c))
  }

  async addWidget(dashboardId: string, type: DashboardWidgetType, x: number, y: number, z: number, width: number, height: number): Promise<UserDashboardWidget<any>> {
    const response = await this.api.addWidget(dashboardId, type, x, y, z, width, height)
    return new UserDashboardWidget(response)
  }

  async cloneWidget(dashboardId: string, widgetId: string, x: number, y: number, z: number): Promise<UserDashboardWidget<any>> {
    const response = await this.api.cloneWidget(dashboardId, widgetId, x, y, z)
    return new UserDashboardWidget(response)
  }

  async moveWidgets(dashboardId: string, widgetId: string, x: number, y: number, z: number, width: number, height: number): Promise<boolean> {
    return this.api.moveWidget(dashboardId, widgetId, x, y, z, width, height)
  }

  async setupWidget(dashboardId: string, widgetId: string, settings: any): Promise<boolean> {
    return this.api.setupWidget(dashboardId, widgetId, settings)
  }

  async deleteWidget(dashboardId: string, widgetId: string): Promise<boolean> {
    return this.api.deleteWidget(dashboardId, widgetId)
  }
}
