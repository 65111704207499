import { Exception } from '@services/admin/dataObjects/Exception'
import { EnumHaasChartAxisSide } from '@vendor/hxCharts/enums/EnumHaasChartAxisSide'
import { EnumHaasChartLineType } from '@vendor/hxCharts/enums/EnumHaasChartLineType'
import { EnumHaasChartStatus } from '@vendor/hxCharts/enums/EnumHaasChartStatus'
import { _ } from '@vendor/hxCharts/helpers/lodash'
import { AxisValue, Unix } from '@vendor/hxCharts/hxCharts.types'
import { HaasChartContainer } from '@vendor/hxCharts/types/HaasChartContainer'
import { getEmptyDataLines } from '@vendor/hxCharts/types/HaasChartDataLineType'
import { getEmptyHaasChartPlotType } from '@vendor/hxCharts/types/HaasChartPlotType'

class HxChartBuilder {

  chart : HaasChartContainer;

  constructor(chartInterval : number) {
    this.chart = {
      Guid: _.createGuid(),
      Status: EnumHaasChartStatus.IsEmpty,

      Charts: {
        // 0 : getEmptyHaasChartPlotType(plotId, 0, "", 1)
      },
      Colors: {
        Font: 'Tahoma',
        Axis: '#000000',
        Grid: '#303030',
        Text: '#BBBBBB',
        Background: 'rgb(37, 37, 37)',
        PriceGhostLine: '#FFFF00',
        VolumeGhostLine: '#FFFF00',
      },
      Interval: chartInterval,
      IsLastPartition : true
    }

    this.addPlot = this.addPlot.bind(this);
    this.addLine = this.addLine.bind(this);
    this.addBars = this.addBars.bind(this);
    this.addLabel = this.addLabel.bind(this);
    this.getAxis = this.getAxis.bind(this);
  }


  addPlot(index: number, title: string, height = 0.2) {
    this.chart.Status = EnumHaasChartStatus.IsPerfect;
    const plot = getEmptyHaasChartPlotType(_.createGuid(), index, title, height)
    this.chart.Charts[index] = plot;
    return this;
  }

  addLine(plotIndex: number, lineName : string, dataPoints : Record<Unix, AxisValue>, interval : number, color : string, side = EnumHaasChartAxisSide.Right, withLabel = false) {

    const plot = this.chart.Charts[plotIndex];
    if (!plot) throw new Exception(`Plot on index ${plotIndex} not added`)

    const line = getEmptyDataLines(lineName, dataPoints, interval, color);
    line.Side = side;

    this.chart.Charts[plotIndex].DataLines[lineName] = line;

    if (side === EnumHaasChartAxisSide.Left)
      this.chart.Charts[plotIndex].LeftAxis.IsVisible = true;

    if (withLabel) {
      const timestamps = _.select(dataPoints, (_, timestamp) => Number(timestamp));
      const lastTimestamp = Math.max(...timestamps);
      const lastValue = dataPoints[lastTimestamp];

      this.addLabel(plotIndex, lineName, lastValue, color, side);
    }

    return this;
  }

  addBars(plotIndex: number, lineName : string, dataPoints : Record<Unix, AxisValue>, interval : number, color : string, side = EnumHaasChartAxisSide.Right, withLabel = false, fillBars = false) {

    this.addLine(plotIndex, lineName, dataPoints, interval, color, side, withLabel);
    const line = this.chart.Charts[plotIndex].DataLines[lineName];
    line.Type = EnumHaasChartLineType.Bars;
    line.FixedValue = 0;
    line.Color += `;`;
    if (fillBars)
      line.Color += color;

    if (_.sum(dataPoints, c => c) === 0)
      this.getAxis(plotIndex, side).AxisEnd = 1;

      return this;
  }

  addLabel(plotIndex: number, text : string, value : number, color : string, side = EnumHaasChartAxisSide.Right) {
    const plot = this.chart.Charts[plotIndex];
    if (!plot) throw new Exception(`Plot on index ${plotIndex} not added`)

    const axis = side === EnumHaasChartAxisSide.Right ? plot.RightAxis : plot.LeftAxis;
    axis.Labels.push({
      Text : text,
      Value : value,
      Color : color,
      TextColor : "#000000"
    })

  }

  private getAxis(plotIndex: number, side : EnumHaasChartAxisSide) {
    const plot = this.chart.Charts[plotIndex];
    if (!plot) throw new Exception(`Plot on index ${plotIndex} not added`)

    if (side === EnumHaasChartAxisSide.Right)
      return plot.RightAxis;

    return plot.LeftAxis;
  }

}

export default HxChartBuilder;