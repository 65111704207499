import { createGradiant } from '@styling/colors'
import { ThemeColorsType } from '@utils/types/styled'

export const darkTheme: ThemeColorsType = {
  // Company colors
  COLOR_PRIMARY: '#21baac',
  COLOR_PRIMARY_ACTIVE: '#03d2b6',

  COLOR_SECONDARY: '#607D8B',
  COLOR_SECONDARY_ACTIVE: '#5a93af',

  // Default colors
  GREEN: '#7bd37c',
  RED: '#ff4d4d',
  YELLOW: '#FFF05B',
  ORANGE: '#FF8000',

  // Text colors
  TEXT_DISABLED_COLOR: '#666',
  TEXT_INACTIVE_COLOR: '#777',
  TEXT_COLOR_DARK: '#888',
  TEXT_COLOR: '#bbb',
  TEXT_ACTIVE_COLOR: '#dedede',
  TEXT_HOVER_COLOR: '#fff',

  // Background colors
  BACKGROUND_1: 'rgb(0,0,0)', // Background
  BACKGROUND_2: 'rgb(10,10,10)', // Header/Footer
  BACKGROUND_3: 'rgb(15,15,15)', // Content
  BACKGROUND_3_5: 'rgb(20,20,20)', // Content offset
  BACKGROUND_4: 'rgb(25,25,25)', // Content headers
  BACKGROUND_5: 'rgb(30,30,30)', // Misc
  BACKGROUND_6: 'rgb(35,35,35)', // Misc
  BACKGROUND_7: 'rgb(40,40,40)', // Misc

  // Overlay
  MODEL_OVERLAY: 'rgba(0,0,0,0.4)',
  MODEL_BACKGROUND: 'rgb(10,10,10)', // equals BACKGROUND_3

  // Logo's
  LOGO_LOADING_FILL: 'white',
  LOGO_MENU_FILL: 'white',

  // Scrollbar
  SCROLLBAR: '#424242',

  // buttons
  NORMAL_START: '#21baa6',
  NORMAL_END: '#4da196',
  BUTTON_BG: createGradiant('#21baa6', '#4da196'),
  BUTTON_BG_HOVER: createGradiant('#4da196', '#4da196'),

  SECONDARY_START: '#607D8B',
  SECONDARY_END: '#4f6571',
  SECONDARY_BUTTON_BG: createGradiant('#607D8B', '#4f6571'),
  SECONDARY_BUTTON_BG_HOVER: createGradiant('#4f6571', '#4f6571'),

  SUCCESS_START: '#7bd37c',
  SUCCESS_END: '#55c657',
  SUCCESS_BUTTON_BG: createGradiant('#7bd37c', '#55c657'),
  SUCCESS_BUTTON_BG_HOVER: createGradiant('#55c657', '#55c657'),

  WARNING_START: '#FFF05B',
  WARNING_END: '#FFD43D',
  WARNING_BUTTON_BG: createGradiant('#FFF05B', '#FFD43D'),
  WARNING_BUTTON_BG_HOVER: createGradiant('#FFD43D', '#FFD43D'),

  DANGER_START: '#ff4d4d',
  DANGER_END: '#AD332D',
  DANGER_BUTTON_BG: createGradiant('#ff4d4d', '#AD332D'),
  DANGER_BUTTON_BG_HOVER: createGradiant('#AD332D', '#AD332D'),

  // Forum field colors
  COLOR_FORM_FIELD_BORDER: 'rgba(77, 77, 77, 0.50)',
  COLOR_FORM_FIELD_BORDER_FOCUS: 'rgb(45,45,45)', // Equals background_9,

  // Chart
  CHART_TEXT: '#BBB',
  CHART_AXIS: '#444',
  CHART_GRID: '#222',
  CHART_BACKGROUND: 'rgb(15,15,15)',
  CHART_GHOST_LINES: '#FFFF00',
  CHART_UP: [false, '#00FF00'],
  CHART_DOWN: [true, '#FF0000'],
}
