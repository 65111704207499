export class WooSubscription {
  id: number
  orderId: number
  productIds: number[]
  startDate: number
  daysValid: number
  constructor(jsonData: any) {
    this.id = jsonData['LicenseId']
    this.orderId = jsonData['OrderId']
    this.productIds = jsonData['ProductId']
    this.startDate = jsonData['StartDate']
    this.daysValid = jsonData['DaysValid']
  }
}
