import { EnumPortfolioChartStyle } from '@dataObjects/enums/EnumPortfolioChartStyle'
import { ApiRequest, CancellationToken, EnumApiMethod } from '../api/rest/ApiRequest'
import { RestApiBase } from '../api/rest/RestApiBase'

export class PortfolioApi extends RestApiBase {
  private baseUrl = 'PortfolioAPI.php'

  convertAmount(priceSource: string, fromCurrency: string, toCurrency: string, amount: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CONVERT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      timeout: 60 * 1000,
      arguments: { priceSource, fromCurrency, toCurrency, amount },
    })
  }

  convertAmounts(priceSource: string, items: Record<string, number>, toCurrency: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CONVERT_All',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      timeout: 60 * 1000,
      arguments: {
        priceSource,
        toCurrency,
        items: JSON.stringify(items),
      },
    })
  }

  getBalance(accountId: string, currency: string, aggregateCurrencies: boolean, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_BALANCE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      timeout: 5 * 60 * 1000,
      arguments: {
        accountId,
        currency,
        aggregateCurrencies,
      },
    })
  }

  getUserPortfolio(accountIds: string[], coins: string[], currency: string, timestamp: number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_PORTFOLIO',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      timeout: 5 * 60 * 1000,
      arguments: {
        accountIds: accountIds.join(','),
        coins: coins.join(','),
        timestamp,
        currency,
      },
    })
  }

  getUserPortfolioChart(accountIds: string[], coins: string[], currency: string, interval: number, style: EnumPortfolioChartStyle, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_PORTFOLIO_CHART',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      timeout: 60,
      arguments: {
        accountIds: accountIds.join(','),
        coins: coins.join(','),
        currency,
        interval,
        style,
      },
    })
  }

  getUserTradeFrequency(accountIds: string[], marketSearchTags: string[], timestamp: number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_TRADE_FREQUENCY',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      timeout: 60,
      arguments: {
        accountIds: accountIds.join(','),
        markets: marketSearchTags.join(','),
        timestamp,
      },
    })
  }

  getUserTradeFrequencyChart(accountIds: string[], marketSearchTags: string[], timestamp: number, interval: number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_TRADE_FREQUENCY_CHART',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      timeout: 60,
      arguments: {
        accountIds: accountIds.join(','),
        markets: marketSearchTags.join(','),
        timestamp,
        interval,
      },
    })
  }

  getUserTradeVolume(accountIds: string[], marketSearchTags: string[], timestamp: number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_TRADE_VOLUME',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      timeout: 60,
      arguments: {
        accountIds: accountIds.join(','),
        markets: marketSearchTags.join(','),
        timestamp,
      },
    })
  }

  getUserTradeVolumeChart(accountIds: string[], marketSearchTags: string[], timestamp: number, interval: number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_TRADE_VOLUME_CHART',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token: token,
      timeout: 60,
      arguments: {
        accountIds: accountIds.join(','),
        markets: marketSearchTags.join(','),
        timestamp,
        interval,
      },
    })
  }
}
