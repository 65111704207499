import { ServiceController } from '@services/ServiceController'
import { EnumHaasOrderType, orderTypeToString } from '../../enums/EnumHaasOrderType'
import { EnumOrderDirection, orderDirectionToString } from '../../enums/EnumOrderDirection'
import { CloudTradeMarket } from '../../public/CloudTradeMarket'
import { UserAccount } from './UserAccount'

export class UserTrade {
  unix: number

  tradeId: string
  orderId: string
  source: string

  account: UserAccount
  accountId: string

  market?: CloudTradeMarket

  direction: EnumOrderDirection
  directionAsString: string

  type: EnumHaasOrderType
  typeAsString: string

  tradePrice: number
  tradeAmount: number
  amountLabel?: string

  feeCosts: number
  feeCurrency: string

  constructor(services: ServiceController, jsonData: any) {
    const initData = services.initData

    this.unix = jsonData['U']

    this.tradeId = jsonData['TID']
    this.orderId = jsonData['OID']
    this.source = jsonData['SO']

    this.accountId = jsonData['AID']
    this.account = services.accountService.getAccount(this.accountId) as UserAccount

    this.market = initData.getTradeMarket(jsonData['M']) as CloudTradeMarket

    this.direction = jsonData['D']
    this.directionAsString = orderDirectionToString(this.direction)

    this.type = jsonData['T']
    this.typeAsString = orderTypeToString(this.type)

    this.tradePrice = jsonData['TP']
    this.tradeAmount = jsonData['TA']
    this.amountLabel = this.market?.amountLabel

    this.feeCosts = jsonData['FC']
    this.feeCurrency = jsonData['FL']
  }
}
