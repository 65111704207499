export class WooLicense {
  id: number
  orderId: number
  productId: number
  licenseKey: string
  startDate: number
  daysValid: number
  constructor(jsonData: any) {
    this.id = jsonData['LicenseId']
    this.orderId = jsonData['OrderId']
    this.productId = jsonData['ProductId']
    this.licenseKey = jsonData['LicenseKey']
    this.startDate = jsonData['StartDate']
    this.daysValid = jsonData['DaysValid']
  }
}
