export enum EnumPaymentStatus {
  Created = 1,
  Open = 2,
  Finished = 3,
  Revoked = 4,
  Dismissed = 5,
}

export enum EnumOutgoingPaymentType {
  TestBTCAdres = 0,
}

export class HaasShopOutgoingPayment {
  id: number
  userId: string
  created: number
  updated: number
  amount: number
  reason: string
  typePayment: EnumOutgoingPaymentType
  status: EnumPaymentStatus
  reference1: string
  reference2: string
  reference3: string

  constructor(jsonData: any) {
    this.id = jsonData['Id']
    this.userId = jsonData['UserId']
    this.created = jsonData['Created']
    this.updated = jsonData['Updated']
    this.amount = jsonData['Amount']
    this.typePayment = jsonData['TypePayment']
    this.reason = jsonData['Reason']
    this.status = jsonData['Status']
    this.typePayment = jsonData['TypePayment']
    this.reference1 = jsonData['Reference1']
    this.reference2 = jsonData['Reference2']
    this.reference3 = jsonData['Reference3']
  }
}
