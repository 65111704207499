import { CloudOrderbook } from '@dataObjects/public/CloudOrderbook'
import { CloudPublicTrade } from '@dataObjects/public/CloudPublicTrade'
import { CloudSocketTick, CloudTick } from '@dataObjects/public/CloudTick'
import { EventHandler } from '@utils/eventHandlers/EventHandler'
import { _ } from '@utils/lodash'
import MarketSocketClient from './MarketSocketClient'

export abstract class MarketSocketChannel<T> extends EventHandler<T> {
  private socket: MarketSocketClient

  subscriptionId: string
  abstract channel: string
  private market: string

  constructor(socket: MarketSocketClient, market: string) {
    super()
    this.subscriptionId = _.createGuid()
    this.socket = socket
    this.market = market
  }

  initialize() {
    this.socket.subscribe(this.subscriptionId, this.channel, {
      market: this.market,
    })
  }

  onMessage(data: any) {
    this.fire(this.parseData(data))
  }

  abstract parseData(data: any): T

  dispose() {
    this.socket.unsubscribe(this.subscriptionId)
  }
}

export class MarketSocketPriceChannel extends MarketSocketChannel<number> {
  channel = 'price'

  parseData(data: any): number {
    return Number(data) ?? 0
  }
}

export class MarketSocketLastTickChannel extends MarketSocketChannel<CloudTick> {
  channel = 'lastTick'

  parseData(data: any): CloudTick {
    return new CloudTick(data)
  }
}

export class MarketSocketMinuteTickChannel extends MarketSocketChannel<CloudTick> {
  channel = 'minuteTick'

  parseData(data: any): CloudTick {
    return new CloudTick(data)
  }
}

export class MarketSocketOrderbookChannel extends MarketSocketChannel<CloudOrderbook> {
  channel = 'orderbook'

  parseData(data: any): CloudOrderbook {
    return new CloudOrderbook(data)
  }
}

export class MarketSocketTradesChannel extends MarketSocketChannel<CloudPublicTrade[]> {
  channel = 'trades'

  parseData(data: any): CloudPublicTrade[] {
    return _.select(data, (c) => new CloudPublicTrade(c))
  }
}

export class MarketSocketTradesSummarySlowChannel extends MarketSocketChannel<CloudSocketTick> {
  channel = 'tradesSummarySlow'

  parseData(data: any): CloudSocketTick {
    return new CloudSocketTick(data)
  }
}

export class MarketSocketTradesSummaryFastChannel extends MarketSocketChannel<CloudSocketTick> {
  channel = 'tradesSummaryFast'

  parseData(data: any): CloudSocketTick {
    return new CloudSocketTick(data)
  }
}
