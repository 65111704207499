import { ServiceController } from '@services/ServiceController'
import { UserAccount } from '../private/account/UserAccount'
import { CloudTradeMarket } from '../public/CloudTradeMarket'

const humanizeDuration = require('humanize-duration')

export class HaasScriptBacktest {
  backtestId: string

  executionStart: number
  executionEnd: number
  executionLength: number
  executionLengthReadable: string

  backtestTag: string
  backtestStart: number
  backtestEnd: number
  backtestLength: number
  backtestLengthReadable: string

  scriptId: string
  scriptName: string

  account?: UserAccount
  market?: CloudTradeMarket

  profits: [number, string][]
  rois: number[]

  isArchived: boolean

  constructor(services: ServiceController, jsonData: any) {
    const { initData, backtestService } = services

    this.backtestId = jsonData['BID']
    this.backtestTag = jsonData['BT']

    this.backtestStart = jsonData['BS']
    this.backtestEnd = jsonData['BE']
    this.backtestLength = (this.backtestEnd - this.backtestStart) * 1000
    this.backtestLengthReadable = humanizeDuration(this.backtestLength, { round: true, largest: 2 })

    this.executionStart = jsonData['ES']
    this.executionEnd = jsonData['EE']
    this.executionLength = (this.executionEnd - this.executionStart) * 1000
    this.executionLengthReadable = humanizeDuration(this.executionLength, { round: true, largest: 2 })

    this.isArchived = jsonData['IA']

    this.account = backtestService.getBacktestAccount(jsonData['AT'].toString())
    this.market = initData.getTradeMarket(jsonData['ME'])

    const [scriptId, ...scriptName] = jsonData['ST'].split('_')
    this.scriptId = scriptId
    this.scriptName = scriptName.join('_')

    this.profits = []
    const profitPieces = jsonData['PT'].split('_')
    for (let i = 0; i < profitPieces.length; i = i + 2) this.profits.push([profitPieces[i], profitPieces[i + 1]])

    this.rois = jsonData['RT'].split('_').map(Number)
  }
}
