import { HaasPieData } from '@components/HaasPieChart/HaasPieChart'
import { AccountService } from '@services/account/AccountService'
import { UserAccount } from '../account/UserAccount'
import { UserPortfolioBalance } from './UserPortfolioBalance'
import { UserPortfolioHistoryItem } from './UserPortfolioHistoryItem'

export class UserPortfolioAccount {
  timestamp: number
  account: UserAccount

  value: number
  valueInBTC: number
  valueInUSD: number
  items: UserPortfolioBalance[]

  share = 0

  rollingDaily: UserPortfolioHistoryItem
  rollingWeekly: UserPortfolioHistoryItem
  rollingMonthly: UserPortfolioHistoryItem

  constructor(accountService: AccountService, jsonData: any, currentValue = 0) {
    this.timestamp = jsonData['T']
    this.account = accountService.getAccount(jsonData['A']) as UserAccount

    this.value = jsonData['V']
    this.valueInBTC = jsonData['VB']
    this.valueInUSD = jsonData['VU']

    this.items = jsonData['I'].map((c: any) => new UserPortfolioBalance(this.account, c, this.value))

    this.rollingDaily = UserPortfolioHistoryItem.fromJson(jsonData['RD'], this.value)
    this.rollingWeekly = UserPortfolioHistoryItem.fromJson(jsonData['RW'], this.value)
    this.rollingMonthly = UserPortfolioHistoryItem.fromJson(jsonData['RM'], this.value)

    if (currentValue !== 0) this.share = (this.value / currentValue) * 100
  }

  toPieData(): HaasPieData {
    return {
      name: this.account.name,
      value: this.value,
    }
  }
}
