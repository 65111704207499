import { EnumExchangeAuthenticationStyle } from '@dataObjects/enums/EnumExchangeAuthenticationStyle'
import { _ } from '@utils/lodash'
import { EnumAccountType } from '../enums/EnumAccountType'
import { CloudPriceSource } from './CloudPriceSource'

export class CloudAccount {
  type: EnumAccountType

  priceSource: CloudPriceSource
  exchangeCode: string
  exchangeName: string
  exchangeFamily: string
  exchangeColor: string
  gitBookApiUrl: string

  authMethods : EnumExchangeAuthenticationStyle[]
  keyFields: {
    publicKey: string
    privateKey: string
    extraKey: string
  }

  oauth : {
    isAvailable : boolean
    buttonBackground : string;
    isDarkColor : boolean;
  }

  constructor(priceSource: CloudPriceSource, type: EnumAccountType) {
    this.type = type

    this.priceSource = priceSource;
    this.exchangeCode = priceSource.exchangeCode
    this.exchangeName = priceSource.exchangeName
    this.exchangeColor = priceSource.exchangeColor
    if (this.type === EnumAccountType.Margin) this.exchangeName += 'Margin'

    this.exchangeFamily = priceSource.exchangeFamily
    this.gitBookApiUrl = priceSource.gitBookApiUrl

    this.authMethods = priceSource.authMethods;
    this.keyFields = priceSource.keyFields
    this.oauth = priceSource.oauth
  }

  sort(value: CloudAccount): number {
    return _.stringSort(this.exchangeName, value.exchangeName)
  }
}
