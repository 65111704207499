import { _ } from '@utils/lodash'
import { orderTemplateToString } from '../utils'
import { EnumHaasScriptOrderType } from './EnumHaasScriptOrderType'
import { HaasScriptInputField } from './HaasScriptInputField'
import { LuaScriptCompileErrorType } from './LuaScriptCompileErrorType'
import { VisualScriptCompileErrorType } from './VisualScriptCompileErrorType'

export type HaasScriptCompiledResult = {
  isValid: boolean
  isCommand: boolean
  inputFields: Record<string, HaasScriptInputField>
  executionLog: string[]
  luaCompileError: LuaScriptCompileErrorType[]
  visualCompileError: VisualScriptCompileErrorType[]
  orderTemplate: EnumHaasScriptOrderType
  orderTemplateAsString: string

  hideTradeAmountSettings: boolean
  hideOrderSettings: boolean

  isSpotSupported: boolean
  isMarginSupported: boolean
  isLeverageSupported: boolean

  dependencies: string[]
}

export const parseCompileResult = (jsonData: any): HaasScriptCompiledResult => {
  return {
    inputFields: _.map(jsonData['I'], (container, c: any) => {
      const field = new HaasScriptInputField(c)
      container[field.key] = field
    }),
    executionLog: jsonData['CL'] || [],
    luaCompileError: jsonData['LCE']
      ? jsonData['LCE'].map((json: any) => {
          return {
            fromLine: json['FromLine'],
            toLine: json['ToLine'],
            fromColumn: json['FromColumn'],
            toColumn: json['ToColumn'],
          } as LuaScriptCompileErrorType
        })
      : [],
    visualCompileError: jsonData['VCE']
      ? jsonData['VCE'].map((json: any) => {
          return {
            commandGuid: json['CommandGuid'],
            inputGuid: json['InputGuid'],
          } as VisualScriptCompileErrorType
        })
      : [],

    orderTemplate: jsonData['O'],
    orderTemplateAsString: orderTemplateToString(jsonData['O']),
    hideTradeAmountSettings: jsonData['HT'],
    hideOrderSettings: jsonData['HO'],
    isSpotSupported: jsonData['SS'],
    isMarginSupported: jsonData['MS'],
    isLeverageSupported: jsonData['LS'],
    isValid: jsonData['IV'],
    isCommand: !!jsonData['C'],

    dependencies: jsonData['CR'] ?? [],
  } as HaasScriptCompiledResult
}
