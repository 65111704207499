import {UserLicense} from '@dataObjects/private/UserLicense'
import {UserLoginResult} from '@dataObjects/private/UserLoginResult'
import {secureStorage} from '@utils/SecureStorage'
import {_} from '@utils/lodash'
import {EnumLicenseTypes} from "@dataObjects/enums/EnumLicenseTypes";

/* eslint-disable */
const CryptoJS = require('crypto-js')

type SessionDetails = {
  userId: string
  username: string
  email: string
  licenseSpecs?: UserLicense
  isProductSeller?: boolean
  isAffiliate?: boolean
  interfaceKey: string
  supportHash?: string
  isLite : () => boolean
  isFreeLite : () => boolean
  isWhiteLabel : () => boolean
}

export class ApiAuthenticator {
  static className = 'ApiAuthenticator'
  private storageKey = 'session-v1'

  session!: SessionDetails
  storeSession?: boolean

  constructor() {
    this.storeSession = secureStorage.getItem<boolean>(this.storageKey)

    if (this.loadFromSessionStorage()) return

    if (this.loadFromLocalStorage()) return

    this.reset()
  }

  private loadFromSessionStorage() {
    try {
      const sessionJson = sessionStorage.getItem(this.storageKey)
      if (!sessionJson) return false

      const session = JSON.parse(sessionJson)
      if (this.validateSession(session)) {
        this.session = JSON.parse(sessionJson)
        // this.session.licenseSpecs!.rights = EnumLicenseTypes.WhiteLabel;

        // @ts-ignore
        window?.dataLayer?.push({ user_id: CryptoJS.MD5(this.session.email).toString() })
        return true
      }
    } catch {
      return false
    }
  }

  private loadFromLocalStorage() {
    try {
      const session = secureStorage.getItem<SessionDetails>(this.storageKey)
      if (this.validateSession(session)) {
        this.session = session as SessionDetails
        // this.session.licenseSpecs!.rights = EnumLicenseTypes.WhiteLabel;

        // @ts-ignore
        window?.dataLayer?.push({ user_id: CryptoJS.MD5(this.session.email).toString() })
        return true
      }
    } catch {
      return false
    }
  }

  initialize(email: string, storeSession: boolean) {
    this.session.email = email
    this.storeSession = storeSession
    secureStorage.setItem<boolean>('session', this.storeSession)
  }

  updateAfterAuth(details: UserLoginResult) {
    this.session.userId = String(details.userId)
    this.session.username = String(details.username)
    this.session.licenseSpecs = details.license
    this.session.isProductSeller = details.isProductSeller
    this.session.isAffiliate = details.isAffiliate
    this.session.supportHash = details.supportHash

    // @ts-ignore
    const gtmData = window?.dataLayer?.find((c) => !!c['user_id'])
    if (gtmData) gtmData['user_id'] = CryptoJS.MD5(this.session.email).toString()
    // @ts-ignore
    else window?.dataLayer?.push({ user_id: CryptoJS.MD5(this.session.email).toString() })

    this.save()
  }

  isSet(): boolean {
    return this.validateSession(this.session)
  }

  execute(parameters: Record<string, any>) {
    parameters.interfacekey = this.session.interfaceKey
    if (this.session.userId) parameters.userid = this.session.userId
  }

  reset() {
    sessionStorage.removeItem(this.storageKey)
    secureStorage.removeItem(this.storageKey)

    this.session = {
      userId: '',
      username: '',
      email: '',
      supportHash: '',
      interfaceKey: _.createGuid(),
      licenseSpecs: undefined,
      isLite : () => true,
      isFreeLite : () => true,
      isWhiteLabel : () => false
    }
  }

  save() {
    if (!this.storeSession) {
      sessionStorage.setItem(this.storageKey, JSON.stringify(this.session))
    } else {
      secureStorage.setItem(this.storageKey, this.session)
    }
  }

  isTrial() {
    if (!this.session?.licenseSpecs)
      return true;

    return this.session.licenseSpecs.rights === EnumLicenseTypes.Trial;
  }

  isLite() {
    if (!this.session?.licenseSpecs)
      return true;

    return this.session.licenseSpecs.rights === EnumLicenseTypes.Trial
      || this.session.licenseSpecs.rights === EnumLicenseTypes.LitePlus;
  }

  isFreeLite() {
    if (!this.session?.licenseSpecs)
      return true;

    return this.session.licenseSpecs.rights === EnumLicenseTypes.Trial;
  }

  isWhiteLabel() {
    if (!this.session?.licenseSpecs)
      return false;

    return this.session.licenseSpecs.rights === EnumLicenseTypes.WhiteLabel;
  }

  private validateSession(session?: SessionDetails) {
    if (!session) return false

    if (!session.userId) return false
    if (!session.username) return false
    if (!session.email) return false
    if (!session.interfaceKey) return false

    return true
  }
}
