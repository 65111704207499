import { ApiRequest, CancellationToken, EnumApiMethod } from '../api/rest/ApiRequest'
import { RestApiBase } from '../api/rest/RestApiBase'

export class ScriptBlogApi extends RestApiBase {
  private baseUrl = 'HaasScriptBlogAPI.php'

  getAllCategories(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ALL_CATEGORIES',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getCategory(categoryId: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_CATEGORY',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        categoryId,
      },
    })
  }

  addCategory(title: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ADD_CATEGORY',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        title,
      },
    })
  }

  editCategory(categoryId: number, title: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_CATEGORY',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        categoryId,
        title,
      },
    })
  }

  deleteCategory(categoryId: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_CATEGORY',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        categoryId,
      },
    })
  }

  getTopicsBySearch(searchKey: string, pageLength: number, nextPageId: number, token : CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ALL_TOPICS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        searchKey,
        pageLength,
        nextPageId,
      },
    })
  }

  getTopicsByUserId(searchUserId: string, pageLength: number, nextPageId: number, token : CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_USER_TOPICS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        searchUserId,
        pageLength,
        nextPageId,
      },
    })
  }

  getTopicsByCategoryId(categoryId: number, pageLength: number, nextPageId: number, token : CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_CATEGORY_TOPICS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token : token,
      arguments: {
        categoryId,
        pageLength,
        nextPageId,
      },
    })
  }

  getLatestTopics(pageLength: number, nextPageId: number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_LATEST_TOPICS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        pageLength,
        nextPageId,
      },
    })
  }

  getLatestCommentedTopics(pageLength: number, nextPageId: number, token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_LATEST_COMMENTS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        pageLength,
        nextPageId,
      },
    })
  }

  getTopic(topicId: number, token : CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_TOPIC',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        topicId,
      },
    })
  }

  addTopic(categoryId: number, title: string, message: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ADD_TOPIC',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        categoryId,
        title,
        message,
      },
    })
  }

  editTopic(topicId: number, title: string, message: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_TOPIC',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        topicId,
        title,
        message,
      },
    })
  }

  moveTopic(topicId: number, categoryId: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'MOVE_TOPIC',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { topicId, categoryId },
    })
  }

  deleteTopic(topicId: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_TOPIC',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        topicId,
      },
    })
  }

  getComments(topicId: number, currentPage: number, pageLength: number, token : CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_COMMENTS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      token,
      arguments: {
        topicId,
        currentPage,
        pageLength,
      },
    })
  }

  addComment(categoryId: number, topicId: number, message: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ADD_COMMENT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        categoryId,
        topicId,
        message,
      },
    })
  }

  editComment(commentId: number, message: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_COMMENT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        commentId,
        message
      },
    })
  }

  deleteComment(commentId: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_COMMENT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        commentId,
      },
    })
  }

  addImage(parentId: number, imageBlob: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ADD_IMAGE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        parentId,
        imageBlob: imageBlob,
      },
    })
  }

  deleteImage(imageId: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'DELETE_IMAGE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        imageId,
      },
    })
  }

  upVoteTopic(topicId: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'UPVOTE_TOPIC',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        topicId,
      },
    })
  }

  reportPost(postId : number, reason : string): ApiRequest<any>  {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ADD_REPORT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { postId, reason },
    })
  }



  getReports(): ApiRequest<any>  {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_REPORTS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { },
    })
  }



  handleReport(reportId : number, deletePost : boolean): ApiRequest<any>  {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'HANDLE_REPORT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { reportId, deletePost },
    })
  }

  toggleUserBan(recordId : number): ApiRequest<any>  {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'TOGGLE_USER_BAN',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: { recordId },
    })
  }
}
