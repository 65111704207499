import { createGradiant } from '@styling/colors'
import { ThemeColorsType } from '@utils/types/styled'

export const lightTheme: ThemeColorsType = {
  // Company colors
  COLOR_PRIMARY: 'rgb(33,186,172)',
  COLOR_PRIMARY_ACTIVE: 'rgb(3,210,182)',

  COLOR_SECONDARY: 'rgb(96,125,139)',
  COLOR_SECONDARY_ACTIVE: 'rgb(90,147,175)',

  // Default colors
  GREEN: '#1C6B3B',
  RED: 'rgb(177,54,54)',
  YELLOW: 'rgb(204,204,0)',
  ORANGE: 'rgb(153,77,0)',

  // Text colors
  TEXT_DISABLED_COLOR: '#999',
  TEXT_INACTIVE_COLOR: '#888',
  TEXT_COLOR_DARK: '#666',
  TEXT_COLOR: '#444',
  TEXT_ACTIVE_COLOR: '#555',
  TEXT_HOVER_COLOR: '#000',

  // Background colors
  BACKGROUND_1: 'rgb(200,200,200)', // Background
  BACKGROUND_2: 'rgb(210,210,210)', // Header/Footer
  BACKGROUND_3: 'rgb(220,220,220)', // Content
  BACKGROUND_3_5: 'rgb(225,225,225)', // Content offset
  BACKGROUND_4: 'rgb(230,230,230)', // Content headers
  BACKGROUND_5: 'rgb(235,235,235)', // Misc
  BACKGROUND_6: 'rgb(240,240,240)', // Misc
  BACKGROUND_7: 'rgb(245,245,245)', // Misc

  // Overlay
  MODEL_OVERLAY: 'rgba(255, 255, 255, 0.4)',
  MODEL_BACKGROUND: 'rgb(245,245,245)', // equals BACKGROUND_3

  // Logo's
  LOGO_LOADING_FILL: '#333',
  LOGO_MENU_FILL: '#333',

  // Scrollbar
  SCROLLBAR: 'rgba(0,0,0,0.2)',

  // buttons
  NORMAL_START: 'rgb(33,186,166)',
  NORMAL_END: 'rgb(77,161,150)',
  BUTTON_BG: createGradiant('rgb(33,186,166)', 'rgb(77,161,150)'),
  BUTTON_BG_HOVER: createGradiant('rgb(77,161,150)', 'rgb(77,161,150)'),

  SECONDARY_START: 'rgb(96,125,139)',
  SECONDARY_END: 'rgb(79,101,113)',
  SECONDARY_BUTTON_BG: createGradiant('rgb(96,125,139)', 'rgb(79,101,113)'),
  SECONDARY_BUTTON_BG_HOVER: createGradiant('rgb(79,101,113)', 'rgb(79,101,113)'),

  SUCCESS_START: 'rgb(123,211,124)',
  SUCCESS_END: 'rgb(85,198,87)',
  SUCCESS_BUTTON_BG: createGradiant('rgb(123,211,124)', 'rgb(85,198,87)'),
  SUCCESS_BUTTON_BG_HOVER: createGradiant('rgb(85,198,87)', 'rgb(85,198,87)'),

  WARNING_START: 'rgb(255,240,91)',
  WARNING_END: 'rgb(255,212,61)',
  WARNING_BUTTON_BG: createGradiant('rgb(255,240,91)', 'rgb(255,212,61)'),
  WARNING_BUTTON_BG_HOVER: createGradiant('rgb(255,212,61)', 'rgb(255,212,61)'),

  DANGER_START: 'rgb(255,77,77)',
  DANGER_END: 'rgb(173,51,45)',
  DANGER_BUTTON_BG: createGradiant('rgb(255,77,77)', 'rgb(173,51,45)'),
  DANGER_BUTTON_BG_HOVER: createGradiant('rgb(173,51,45)', 'rgb(173,51,45)'),

  // Forum field colors
  COLOR_FORM_FIELD_BORDER: 'rgba(200,200,200,0.5)',
  COLOR_FORM_FIELD_BORDER_FOCUS: 'rgb(100,100,100)',

  // Chart
  CHART_TEXT: '#222',
  CHART_AXIS: '#777',
  CHART_GRID: '#BBB',
  CHART_BACKGROUND: 'rgb(220,220,220)',
  CHART_GHOST_LINES: 'rgb(33,186,172)',
  CHART_UP: [true, '#1C6B3B'],
  CHART_DOWN: [true, '#FF0000'],
}
