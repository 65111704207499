import { _ } from '@utils/lodash'
import { EnumHaasChartShapeType } from '@vendor/hxCharts/enums/EnumHaasChartShapeType'
import { EnumHaasChartAxisSide } from '../enums/EnumHaasChartAxisSide'
import { EnumHaasChartLineDecoration } from '../enums/EnumHaasChartLineDecoration'
import { EnumHaasChartLineStyle } from '../enums/EnumHaasChartLineStyle'
import { EnumHaasChartLineType } from '../enums/EnumHaasChartLineType'
import { AxisValue, Unix } from '../hxCharts.types'

export type HaasChartDataLineType = {
  Guid: string
  Name: string
  Interval: number

  Visible: boolean
  Behind: boolean
  IgnoreOnAxis: boolean
  DrawTrailingLine: boolean

  Color: string
  Width: number
  Type: EnumHaasChartLineType
  Style: EnumHaasChartLineStyle
  Decoration: EnumHaasChartLineDecoration
  Side: EnumHaasChartAxisSide
  LineShapeType: EnumHaasChartShapeType

  FixedValue: number
  DataSets: Record<Unix, AxisValue>[]
  DataPoints: Record<Unix, AxisValue>

  ConnectedLines: string[]
}

export const getEmptyDataLines = (name : string, dataPoints : Record<Unix, AxisValue>, interval : number, color : string) : HaasChartDataLineType => {
  return {
    Guid : _.createGuid(),
    Name : name,
    Interval : interval,
    Visible : true,
    Behind : false,
    IgnoreOnAxis : false,
    DrawTrailingLine : false,
    Color : color,
    Width : 1,
    Type: EnumHaasChartLineType.Line,
    Style: EnumHaasChartLineStyle.Spiked,
    Decoration: EnumHaasChartLineDecoration.Solid,
    Side: EnumHaasChartAxisSide.Right,
    LineShapeType: EnumHaasChartShapeType.Text,
    FixedValue : 0,
    DataSets : [],
    DataPoints : dataPoints,
    ConnectedLines : [],
  }
}
