import { ServiceController } from '@services/ServiceController'
import { CloudTradeMarket } from '../../public/CloudTradeMarket'
import { EnumHaasPositionDirection } from '../../scripting/EnumHaasPositionDirection'
import { leverageToString, positionDirectionToString } from '../../utils'
import { UserAccount } from './UserAccount'

export class UserPosition {
  private services: ServiceController

  positionId: string
  accountId: string
  leverage: number
  leverageAsString: string

  get account(): UserAccount {
    return this.services.accountService.getAccount(this.accountId) as UserAccount
  }

  marketTag: string
  get market(): CloudTradeMarket {
    return this.services.initData.getTradeMarket(this.marketTag) as CloudTradeMarket
  }

  price: number
  liquidationPrice: number

  amount: number

  usedMargin: number

  isLong: boolean
  isShort: boolean

  direction: EnumHaasPositionDirection = EnumHaasPositionDirection.Long
  directionAsString = ''

  profitLoss: number
  profitLossRatio: number

  constructor(services: ServiceController, accountId: string, jsonData: any) {
    this.services = services
    this.positionId = jsonData['PID']

    this.direction = jsonData['D']
    this.directionAsString = positionDirectionToString(this.direction)

    this.isLong = this.direction === EnumHaasPositionDirection.Long
    this.isShort = !this.isLong

    this.accountId = accountId
    this.marketTag = jsonData['M']

    this.leverage = jsonData['L']
    this.leverageAsString = leverageToString(this.account, this.leverage)

    this.price = jsonData['P']
    this.liquidationPrice = jsonData['LP']

    this.amount = jsonData['A']
    this.usedMargin = jsonData['UM']

    this.profitLoss = jsonData['PL']
    this.profitLossRatio = jsonData['PLR']
  }
}
