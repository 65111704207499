export type UserPollDetailsType = {
  title: string
  isOpen: boolean
  hasUserVoted: boolean
  options: Record<string, number>
}

export const parseUserPollDetailsType = (jsonData: any): UserPollDetailsType => {
  return {
    title: jsonData['T'],
    isOpen: jsonData['IO'],
    hasUserVoted: jsonData['UHV'],
    options: jsonData['O'],
  }
}
