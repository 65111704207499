import { createGradiant } from '@styling/colors'
import { ThemeColorsType } from '@utils/types/styled'

export const contrastTheme: ThemeColorsType = {
  // Company colors
  COLOR_PRIMARY: '#C0C',
  COLOR_PRIMARY_ACTIVE: '#F0F',

  COLOR_SECONDARY: '#CC0',
  COLOR_SECONDARY_ACTIVE: '#FF0',

  // Default colors
  GREEN: '#0F0',
  RED: '#F00',
  YELLOW: '#FF0',
  ORANGE: '#ff6f00',

  // Text colors
  TEXT_DISABLED_COLOR: '#00F',
  TEXT_INACTIVE_COLOR: '#0FF',
  TEXT_COLOR_DARK: '#F0F',
  TEXT_COLOR: '#FF0',
  TEXT_ACTIVE_COLOR: '#F00',
  TEXT_HOVER_COLOR: '#0FF',

  // Background colors
  BACKGROUND_1: 'rgb(0,0,0)', // Background
  BACKGROUND_2: 'rgb(15,15,15)', // Header/Footer
  BACKGROUND_3: 'rgb(30,30,30)', // Content
  BACKGROUND_3_5: 'rgb(45,45,45)', // Content offset
  BACKGROUND_4: 'rgb(60,60,60)', // Content headers
  BACKGROUND_5: 'rgb(75,75,75)', // Misc
  BACKGROUND_6: 'rgb(90,90,90)', // Misc
  BACKGROUND_7: 'rgb(105,105,105)', // Misc

  // Overlay
  MODEL_OVERLAY: 'rgba(0,0,0,0.4)',
  MODEL_BACKGROUND: 'rgb(30,30,30)', // equals BACKGROUND_3

  // Logo's
  LOGO_LOADING_FILL: 'white',
  LOGO_MENU_FILL: 'white',

  // Scrollbar
  SCROLLBAR: '#424242',

  // buttons
  NORMAL_START: '#0FF',
  NORMAL_END: '#000',
  BUTTON_BG: createGradiant('#0FF', '#000'),
  BUTTON_BG_HOVER: createGradiant('#000', '#000'),

  SECONDARY_START: '#607D8B',
  SECONDARY_END: '#4f6571',
  SECONDARY_BUTTON_BG: createGradiant('#607D8B', '#4f6571'),
  SECONDARY_BUTTON_BG_HOVER: createGradiant('#4f6571', '#4f6571'),

  SUCCESS_START: '#00ff02',
  SUCCESS_END: '#030000',
  SUCCESS_BUTTON_BG: createGradiant('#00ff02', '#030000'),
  SUCCESS_BUTTON_BG_HOVER: createGradiant('#030000', '#030000'),

  WARNING_START: '#ffe700',
  WARNING_END: '#131300',
  WARNING_BUTTON_BG: createGradiant('#ffe700', '#131300'),
  WARNING_BUTTON_BG_HOVER: createGradiant('#131300', '#131300'),

  DANGER_START: '#ff0000',
  DANGER_END: '#020000',
  DANGER_BUTTON_BG: createGradiant('#ff0000', '#020000'),
  DANGER_BUTTON_BG_HOVER: createGradiant('#020000', '#020000'),

  // Forum field colors
  COLOR_FORM_FIELD_BORDER: 'rgba(0,19,61,0.5)',
  COLOR_FORM_FIELD_BORDER_FOCUS: '#002c92', // Equals background_9,

  // Chart
  CHART_TEXT: '',
  CHART_AXIS: '',
  CHART_GRID: '',
  CHART_BACKGROUND: '',
  CHART_GHOST_LINES: '',
  CHART_UP: [false, ''],
  CHART_DOWN: [true, ''],
}
