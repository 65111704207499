class RuntimeCustomReport {
  title: string
  items: Record<string, string>

  constructor(title: any, items: any) {
    this.title = String(title)
    this.items = items
  }
}

export default RuntimeCustomReport
