import { DashboardWidgetType } from '@pages/Dashboard/Widgets/Components/DashboardWidget.types'

export class UserDashboardWidget<T extends any> {
  dashboardId: string
  widgetId: string

  type: DashboardWidgetType
  x: number
  y: number
  z: number
  width: number
  height: number

  settings: T

  constructor(jsonData: any) {
    this.dashboardId = jsonData['DashboardId']
    this.widgetId = jsonData['WidgetId']
    this.type = jsonData['Type']
    this.x = jsonData['X']
    this.y = jsonData['Y']
    this.z = jsonData['Z']
    this.width = jsonData['Width']
    this.height = jsonData['Height']

    this.settings = jsonData['Settings']
  }
}
