import {HaasScriptSettingsForm} from '@components/HaasScriptSettings/HaasScriptSettingsForm'
import {UserAccount} from '@dataObjects/private/account/UserAccount'
import {parseRuntime, Runtime} from '@dataObjects/runtime/Runtime'
import {RuntimePosition} from '@dataObjects/runtime/RuntimePosition'
import {HaasScriptBacktest} from '@dataObjects/scripting/HaasScriptBacktest'
import {HaasScriptItem} from '@dataObjects/scripting/HaasScriptItem'
import {EnumWebEditorTabExecutionState} from '@pages/WebEditor/Components/Controller/WebEditorTab'
import {
  parseWebEditorBacktestUpdate,
  WebEditorBacktestProgressUpdate,
} from '@pages/WebEditor/Components/WebEditor.types'
import {EventHandler} from '@utils/eventHandlers/EventHandler'
import {KeyedEventHandler} from '@utils/eventHandlers/KeyedEventHandler'
import {_} from '@utils/lodash'
import {Query} from '@utils/QueryPromise'
import {PaginatedResponse} from '@utils/types/PaginatedResponse'
import {HaasChartContainer} from '@vendor/hxCharts/types/HaasChartContainer'
import {ApiRequest, CancellationToken} from '../api/rest/ApiRequest'
import {ServiceController, ServiceControllerConstructorType} from '../ServiceController'
import {BacktestApi} from './BacktestApi'
import {EnumHaasChartStatus} from "@vendor/hxCharts/enums/EnumHaasChartStatus";

export type ExecutingBacktestRecord = {
  backtestId: string
  scriptId: string
  serviceId: string
  startUnix: number
  state: EnumWebEditorTabExecutionState
}

const parseExecutingBacktestRecord = (jsonData: any): ExecutingBacktestRecord => {
  return {
    backtestId: jsonData['BID'],
    serviceId: jsonData['SID'],
    scriptId: jsonData['SCID'],
    startUnix: jsonData['SU'] ?? Math.floor(Date.now() / 1000) - 360,
    state: jsonData['S'],
  }
}

export class BacktestService {
  private api: BacktestApi
  private controller: ServiceController

  accounts: UserAccount[] = []

  backtestUpdates = new KeyedEventHandler<WebEditorBacktestProgressUpdate>()
  executingBacktests = new EventHandler<string>()
  finishedBacktests = new EventHandler<string>()

  constructor(props: ServiceControllerConstructorType) {
    this.controller = props.controller
    this.api = new BacktestApi(props.authenticator, this.controller)
  }

  onBacktestUpdate(jsonData: any) {
    const data = parseWebEditorBacktestUpdate(jsonData)
    this.backtestUpdates.fire(data.backtestId, data)
    this.executingBacktests.fire(data.backtestId)

    if (data.state === EnumWebEditorTabExecutionState.Completed || data.state === EnumWebEditorTabExecutionState.Failed) this.finishedBacktests.fire(data.backtestId)
  }

  async fetchBacktestAccounts(): Promise<void> {
    if (this.controller.authenticator.isWhiteLabel())
      return;

    if (this.accounts.length) return

    const response = await this.api.getBacktestAccounts()
    if (!response) return

    this.accounts = response.map((c: any) => new UserAccount(this.controller, c))
    this.accounts = _.orderBy(this.accounts, (c) => c.sortName)

    _.each(this.accounts, (account) => {
      account.isLive = false
      account.isSimulated = true
      account.typeAsShortString = 'Defaults'
    })
  }

  getAccounts(): UserAccount[] {
    return [...this.controller.accountService.getAccounts(), ...this.getBacktestAccounts()]
  }

  getBacktestAccounts(): UserAccount[] {
    return this.accounts.filter((c) => !!c.exchange)
  }

  getBacktestAccount(accountId: string | undefined): UserAccount | undefined {
    const account = this.accounts.find((c) => c.accountId === accountId)
    if (account) return account

    return this.controller.accountService.getAccount(accountId)
  }

  async executeQuicktest(backtestId: string, script: HaasScriptItem, settings: HaasScriptSettingsForm, token: CancellationToken): Promise<string> {
    return this.api.executeQuicktest(backtestId, script, settings, token)
  }

  async executeBackTest(backtestId: string, script: HaasScriptItem, settings: HaasScriptSettingsForm, startUnix: number, endUnix: number, token: CancellationToken): Promise<string> {
    return this.api.executeBackTest(backtestId, script.scriptId, settings, startUnix, endUnix, token)
  }

  async executeDebugTest(script: HaasScriptItem, settings: HaasScriptSettingsForm, token: CancellationToken): Promise<string[] | Record<string, string>> {
    return this.api.executeDebugTest(script, settings, token)
  }

  async cancelBacktest(serviceId: string, backtestId: string): Promise<boolean> {
    await this.api.cancelBacktest(serviceId, backtestId)
    return true
  }

  async isScriptExecuting(scriptId: string, token: CancellationToken): Promise<ExecutingBacktestRecord> {
    const response = await this.api.isScriptExecuting(scriptId, token)
    return parseExecutingBacktestRecord(response)
  }

  async getExecutingScripts(token: CancellationToken): Promise<ExecutingBacktestRecord[]> {
    const response = await this.api.getExecutingScripts(token)
    return response.map((c: any) => parseExecutingBacktestRecord(c))
  }

  async getExecutionUpdate(serviceId: string, backtestId: string, isBacktest: boolean, token: CancellationToken): Promise<WebEditorBacktestProgressUpdate> {
    if (!serviceId)
      return {
        backtestId,
        state: EnumWebEditorTabExecutionState.Requesting,
        progress: 0,
        message: '',
        messageId: -1,
      }

    const response = await this.api.getExecutionUpdate(serviceId, backtestId, isBacktest, token)
    return parseWebEditorBacktestUpdate(response)
  }

  async getBacktestRuntime(backtestId: string, token: CancellationToken): Promise<Runtime> {
    const response = await this.api.getBacktestRuntime(backtestId, token)
    const runtime = parseRuntime(this.controller, response, true)
    runtime.openPositions = []
    runtime.finishedPositions = []

    return runtime
  }

  getBacktestLogs(backtestId: string, token: CancellationToken): Query<string[]> {
    return this.api.getBacktestLogs(backtestId, token)
  }

  getBacktestChart(backtestId: string, interval: number, token: CancellationToken): Query<HaasChartContainer> {
    return this.api.getBacktestChart(backtestId, interval, token)
  }

  getBacktestChartPartition(backtestId: string, interval: number, partition : number, token: CancellationToken): Query<HaasChartContainer> {
    return this.api.getBacktestChartPartition(backtestId, interval, partition, token)
  }

  async getBacktestChartByPlots(backtestId: string, interval: number, token: CancellationToken): Promise<HaasChartContainer> {
    let prevPlotId = -2147483648;
    let chart : HaasChartContainer|undefined = undefined;

    while (!token.isCancelled) {
      const response = await this.api.getBacktestChartPlot(backtestId, interval, prevPlotId, token) as HaasChartContainer;
      if (chart === undefined)
        chart = response;

      if (response.Status !== EnumHaasChartStatus.IsEmpty)
        chart.Status = response.Status;

      // Did we reach the end?
      if (_.isEmpty(response.Charts))
        break;

      // Get current plotId
      const plotId = Number(Object.keys(response.Charts)[0]);

      // Merge plot with others
      chart.Charts[plotId] = response.Charts[plotId];

      // Set prevPlotId
      prevPlotId = plotId;
    }

    return chart as HaasChartContainer;
  }

  async getBacktestInformation(backtestId: string, token: CancellationToken): Promise<HaasScriptBacktest> {
    const response = await this.api.getBacktestInformation(backtestId, token)
    return new HaasScriptBacktest(this.controller, response)
  }

  async getBacktestPositions(backtestId: string, nextPageId: number, pageLength: number, token: CancellationToken): Promise<PaginatedResponse<RuntimePosition>> {
    const response = await this.api.getBacktestPositions(backtestId, nextPageId, pageLength, token)

    return {
      items: response['I'].map((c: any) => new RuntimePosition(this.controller, c)),
      nextPageId: response['NP'],
    }
  }

  getBacktestProfitChart(backtestId: string, interval: number, token: CancellationToken): ApiRequest<HaasChartContainer> {
    return this.api.getBacktestProfitChart(backtestId, interval, token)
  }

  async getAllBacktests(token: CancellationToken): Promise<HaasScriptBacktest[]> {
    const response = await this.api.getAllBacktests(token)
    return response.map((c) => new HaasScriptBacktest(this.controller, c))
  }

  async getBacktestsHistory(nextPageId: number, pageLength: number, token: CancellationToken): Promise<PaginatedResponse<HaasScriptBacktest>> {
    const response = await this.api.getBacktestsHistory(nextPageId, pageLength, token)

    return {
      items: response['I'].map((c: any) => new HaasScriptBacktest(this.controller, c)),
      nextPageId: response['NP'],
    }
  }

  async archiveBacktest(backtestId: string, archiveResult: boolean): Promise<boolean> {
    return this.api.archiveBacktest(backtestId, archiveResult)

    // const item = this.backtestHistory?.find(c => c.backtestId === backtestId);
    // if (!item) return false;
    //
    // const response = await this.api.archiveBacktest(backtestId);
    // if (!response) return false;
    //
    // item.isArchived = true;
    // return true;
  }

  async editBacktestTag(backtestId: string, backtestTag: string): Promise<boolean> {
    // const item = this.backtestHistory?.find(c => c.backtestId === backtestId);
    // if (!item) return false;

    const response = await this.api.editBacktestTag(backtestId, backtestTag)
    if (!response) return false

    // item.backtestTag = backtestTag;
    return true
  }

  async getOrdersCsv(backtestId: string, token: CancellationToken): Promise<string> {
    return this.api.getOrdersCsv(backtestId, token)
  }

  async getPositionsCsv(backtestId: string, token: CancellationToken): Promise<string> {
    return this.api.getPositionsCsv(backtestId, token)
  }

  async getFileCsv(backtestId: string, name : string): Promise<string> {
    return this.api.getFileCsv(backtestId, name)
  }

  async getFileJson(backtestId: string, name : string): Promise<string> {
    return this.api.getFileJson(backtestId, name)
  }


  async deleteBacktest(backtestId: string): Promise<boolean> {
    // const item = this.backtestHistory?.find(c => c.backtestId === backtestId);
    // if (!item) return false;

    const response = await this.api.deleteBacktest(backtestId)
    if (!response) return false

    // this.backtestHistory = this.backtestHistory
    //     .filter(c => c.backtestId !== backtestId);

    return true
  }

  async deleteUnarchivedBacktests(): Promise<boolean> {
    const response = await this.api.deleteUnarchivedBacktest()
    if (!response) return false

    return true
  }
}
