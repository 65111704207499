import { EnumAccountMarketMarginMode } from '../enums/EnumAccountMarketMarginMode'
import { EnumAccountPositionMode } from '../enums/EnumAccountPositionMode'
import { EnumAccountType } from '../enums/EnumAccountType'
import { EnumHaasOrderType } from '../enums/EnumHaasOrderType'
import { EnumOrderTriggerPrice } from '../enums/EnumOrderTriggerPrice'
import { EnumTimeInForce } from '../enums/EnumTimeInForce'
import { EnumWalletStyle } from '../enums/EnumWalletStyle'
import {CloudInitData} from "@dataObjects/public/CloudInitData";
import {CloudPriceSource} from "@dataObjects/public/CloudPriceSource";

export class CloudExchange {
  priceSource? : CloudPriceSource;
  walletMode: EnumWalletStyle
  positionMode: EnumAccountPositionMode

  exchangeType: EnumAccountType
  exchangeCode: string

  hasIsolatedMargin: boolean
  hasCrossMargin: boolean
  hasMultiCurrencyCollateral : boolean;

  adjustablePositionMode: boolean
  adjustableCrossLeverage: boolean
  adjustableIsolatedLeverage: boolean

  positionModes: EnumAccountPositionMode[]
  marginModes: EnumAccountMarketMarginMode[]

  orderTypes: EnumHaasOrderType[]
  orderTriggerPrices: EnumOrderTriggerPrice[]
  timeInForces: EnumTimeInForce[]

  hiddenOrder: boolean
  postOrderOnly: boolean
  reduceOrderOnly: boolean

  whitelist : string[]
  whitelistSeparator : string

  constructor(jsonData: any, initData : CloudInitData) {
    this.walletMode = jsonData['WM']
    this.positionMode = jsonData['PM']

    this.exchangeType = jsonData['ET']
    this.exchangeCode = jsonData['EC']

    this.priceSource = initData.priceSources.find(c => c.exchangeCode === this.exchangeCode);

    this.hasIsolatedMargin = jsonData['IM']
    this.hasCrossMargin = jsonData['CM']
    this.hasMultiCurrencyCollateral = jsonData['HMMC'];

    this.marginModes = []
    if (this.hasCrossMargin) this.marginModes.push(EnumAccountMarketMarginMode.CrossMargin)
    if (this.hasIsolatedMargin) this.marginModes.push(EnumAccountMarketMarginMode.IsolatedMargin)

    this.adjustablePositionMode = jsonData['AP']
    this.adjustableCrossLeverage = jsonData['AC']
    this.adjustableIsolatedLeverage = jsonData['AI']

    this.positionModes = [this.positionMode]
    if (this.adjustablePositionMode) this.positionModes.push(this.positionMode === EnumAccountPositionMode.Hedge ? EnumAccountPositionMode.OneWay : EnumAccountPositionMode.Hedge)

    this.orderTypes = jsonData['OT']
    this.orderTriggerPrices = jsonData['OTP']
    if (!this.orderTriggerPrices.length) this.orderTriggerPrices.push(EnumOrderTriggerPrice.LastPrice)

    this.hiddenOrder = jsonData['OF']['HO']
    this.postOrderOnly = jsonData['OF']['PO']
    this.reduceOrderOnly = jsonData['OF']['RO']

    this.timeInForces = [EnumTimeInForce.GoodTillCancel]
    if (jsonData['OTIF']['FOK']) this.timeInForces.push(EnumTimeInForce.FillOrKill)
    if (jsonData['OTIF']['IOC']) this.timeInForces.push(EnumTimeInForce.ImmediateOrCancel)

    this.whitelist = jsonData['WL'] ?? [];
    this.whitelistSeparator = jsonData['WLS'] ?? " ";
  }
}
