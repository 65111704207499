import { BotApi } from '@services/bots/BotApi'

import { ServiceController } from '@services/ServiceController'
import { KeyedEventHandler } from '@utils/eventHandlers/KeyedEventHandler'
import AsyncObject from '../AsyncObject'
import { HaasBot, parseHaasBot } from './HaasBot'

export class HaasBotAsync extends AsyncObject<HaasBot, any> {
  private api: BotApi
  private services: ServiceController

  private botId: string

  constructor(bot: HaasBot | undefined, services: ServiceController, api: BotApi, botId: string, eventHandler: KeyedEventHandler<HaasBot>) {
    super(bot, botId, eventHandler)

    this.api = api
    this.botId = botId
    this.services = services
  }

  protected async getData(): Promise<HaasBot> {
    const response = this.api.getRuntime(this.botId, this.token)
    return parseHaasBot(this.services, response)
  }

  protected processUpdate(data: any) {
    if (this.data === undefined) return
    const {
      A: activated,
      P: paused,
      UC: updateCounter,
      RP: realizedProfits,
      UP: unrealizedProfits,
      ROI: roi,
      SE: scriptError,
      AE: accountError,
      TE: tradeAmountError,
      SN: scriptNote,
    } = data

    this.data = { ...this.data }
    this.data.account = this.services.accountService.getAccount(this.data.accountId)
    this.data.updateCounter = updateCounter
    this.data.isActivated = activated
    this.data.isPaused = paused

    this.data.realizedProfit = realizedProfits
    this.data.unrealizedProfit = unrealizedProfits
    this.data.roi = roi

    this.data.scriptError = scriptError
    this.data.accountError = accountError
    this.data.tradeAmountError = tradeAmountError

    this.data.scriptNote = scriptNote
  }
}
