import { DefaultTheme } from 'styled-components'

import { UserAccount } from './private/account/UserAccount'
import { CloudTradeMarket } from './public/CloudTradeMarket'
import { EnumRuntimeOrderStatus } from './runtime/EnumRuntimeOrderStatus'
import { EnumHaasOrderCancelReason } from './scripting/EnumHaasOrderCancelReason'
import { EnumHaasPositionDirection } from './scripting/EnumHaasPositionDirection'
import { EnumHaasScriptOrderType } from './scripting/EnumHaasScriptOrderType'

export const leverageToString = (accountMarket: UserAccount | CloudTradeMarket | undefined, leverage: number) => {
  return `${leverage}x`
}

export const orderStatusToString = (status: EnumRuntimeOrderStatus, cancelReason: EnumHaasOrderCancelReason) => {
  switch (status) {
    case EnumRuntimeOrderStatus.Open:
      return 'Open'
    case EnumRuntimeOrderStatus.Filled:
      return 'Filled'

    case EnumRuntimeOrderStatus.Cancelled:
      if (cancelReason === EnumHaasOrderCancelReason.Timeout) return 'Timeout'

      if (cancelReason === EnumHaasOrderCancelReason.ByScript) return 'Cancelled by script'

      if (cancelReason === EnumHaasOrderCancelReason.ByUser) return 'Cancelled by user'

      return 'Cancelled'
    case EnumRuntimeOrderStatus.Failed:
      return 'Failed'
    default:
      return 'Unknown'
  }
}

export const orderStatusToTextColor = (status: EnumRuntimeOrderStatus, theme: DefaultTheme) => {
  switch (status) {
    case EnumRuntimeOrderStatus.Open:
      return theme.TEXT_ACTIVE_COLOR
    case EnumRuntimeOrderStatus.Filled:
      return theme.TEXT_COLOR
    case EnumRuntimeOrderStatus.Cancelled:
      return theme.TEXT_COLOR_DARK
    case EnumRuntimeOrderStatus.Failed:
      return theme.TEXT_DISABLED_COLOR
    default:
      return ''
  }
}

export const orderTemplateToString = (direction: EnumHaasScriptOrderType) => {
  switch (direction) {
    case EnumHaasScriptOrderType.Limit:
      return 'Limit orders'
    case EnumHaasScriptOrderType.Market:
      return 'Market orders'
    case EnumHaasScriptOrderType.NoTimeout:
      return 'No timeout orders'
    case EnumHaasScriptOrderType.MakerOrCancel:
      return 'Maker or cancel orders'

    case EnumHaasScriptOrderType.Default:
    default:
      return 'Default orders'
  }
}

export const positionDirectionToString = (direction: EnumHaasPositionDirection) => {
  if (direction === EnumHaasPositionDirection.Long) return 'Long'

  return 'Short'
}

export const positionDirectionToColor = (direction: EnumHaasPositionDirection, theme: DefaultTheme) => {
  if (direction === EnumHaasPositionDirection.Long) return theme.GREEN

  return theme.RED
}
