import { ServiceController } from '@services/ServiceController'
import { UserAccount } from '../../private/account/UserAccount'
import { CloudTradeMarket } from '../../public/CloudTradeMarket'
import {
  RuntimeFeeReport,
  RuntimeOrdersReport,
  RuntimePerformanceReport,
  RuntimePositionsReport,
  RuntimeProfitReport,
} from './RuntimeReports.types'

export class RuntimeReport {
  accountId: string
  account?: UserAccount
  market?: CloudTradeMarket
  profitLabel: string
  marginLabel: string
  displayDecimals: number

  fees!: RuntimeFeeReport
  profits!: RuntimeProfitReport
  orders!: RuntimeOrdersReport
  positions!: RuntimePositionsReport
  performance!: RuntimePerformanceReport

  constructor(services: ServiceController, jsonData: any) {
    this.accountId = jsonData['AID']
    this.account = services.accountService.getAccount(jsonData['AID'])
    if (!this.account) this.account = services.backtestService.getBacktestAccount(jsonData['AID'])

    this.market = services.initData.getTradeMarket(jsonData['M'])
    this.profitLabel = jsonData['PL']
    this.marginLabel = jsonData['ML']

    this.displayDecimals = 8
    if (CloudTradeMarket.isFiat(this.profitLabel)) this.displayDecimals = 2

    this.parseOrderReport(jsonData['O'])
    this.parsePositionReport(jsonData['P'])

    this.parseFeeReport(jsonData['F'])
    this.parseProfitReport(jsonData['PR'])

    this.parsePerformanceReport(jsonData['T'])
  }

  private parseOrderReport(jsonData: any) {
    this.orders = {
      filled: jsonData.F,
      partiallyFilled: jsonData.P,
      cancelled: jsonData.C,
      failed: jsonData.R,

      averageOpenTime: jsonData.A,
      lastCompletedOrder: jsonData.L,
    }
  }
  private parsePositionReport(jsonData: any) {
    this.positions = {
      closedPositions: jsonData.C,
      winningPositions: jsonData.W,
      loosingPositions: jsonData.C - jsonData.W,
      averageProfits: jsonData.AP,
      averagePositionSize: jsonData.APS,
      averagePositionMargin: jsonData.APM,
    }
  }

  private parseFeeReport(jsonData: any) {
    this.fees = {
      feeCosts: jsonData.FC,
      feeRebates: jsonData.FR,
      totalFeeCosts: jsonData.TFC,
      feesPerCurrency: jsonData.FPC,
    }
  }
  private parseProfitReport(jsonData: any) {
    this.profits = {
      startPrice: jsonData['SP'],
      startBalance: jsonData['SB'],
      priceChange: jsonData['PC'],
      balanceChange: jsonData['BC'],
      grossProfit: jsonData['GP'],
      realizedProfits: jsonData['RP'],
      unrealizedProfits: jsonData['UP'],
      returnOnInvestment: jsonData['ROI'],
    }
  }

  private parsePerformanceReport(jsonData: any) {
    const maxDrawDown = jsonData.ddm
    const peak = jsonData.ddp

    let maxDrawDownPercentage = 0
    if (maxDrawDown !== 0 && peak !== 0) maxDrawDownPercentage = (maxDrawDown / peak) * 100

    this.performance = {
      maxDrawDown,
      maxDrawDownPercentage,
      sharpeRatio: jsonData.SHR,
      sortinoRatio: jsonData.SOR,
      winPercentage: jsonData.WP,
      winLossPercentage: jsonData.WLP,
      profitFactor: jsonData.PF,
      cpcIndex: jsonData.CPC,
      tailRatio: jsonData.TR,
      commonSenseRatio: jsonData.CSR,
      outlierWinRatio: jsonData.OWR,
      outlierLossRatio: jsonData.OLR,
      biggestWin: jsonData.BW,
      biggestLost: jsonData.BL,
    }
  }
}
