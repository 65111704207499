export type UserStatisticMoneyType = {
  valueInBTC: number
  valueInUSD: number
  getValue: (currency: string) => number
}

export const parseUserStatisticMoneyType = (jsonData: any): UserStatisticMoneyType => {
  return createUserStatisticMoneyType(jsonData['BTC'], jsonData['USD'])
}

export const createUserStatisticMoneyType = (valueInBTC : number, valueInUSD : number): UserStatisticMoneyType => {
  return {
    valueInBTC,
    valueInUSD,
    getValue: (currency: string) => {
      switch (currency) {
        case 'BTC':
          return valueInBTC
        case 'USD':
          return valueInUSD
        default:
          return valueInBTC
      }
    },
  }
}
