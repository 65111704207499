import { OperationExchangeStatistics } from './OperationExchangeStatistics'
import { OperationScriptStatistics } from './OperationScriptStatistics'

export class OperationsCounterStatistics {
  usersRegistered: number
  botsCreated: number
  dashboardCreated: number
  dashboardWidgetsCreated: number

  botScripts: OperationScriptStatistics
  commandScripts: OperationScriptStatistics
  scriptBacktests: Record<string, number>

  exchangeStatistics: OperationExchangeStatistics[]
  simulatedStatistics: OperationExchangeStatistics[]

  constructor(jsonData: any) {
    this.usersRegistered = jsonData['UR']
    this.botsCreated = jsonData['BC']
    this.dashboardCreated = jsonData['DC']
    this.dashboardWidgetsCreated = jsonData['DWC']

    this.botScripts = new OperationScriptStatistics(jsonData['BS'])
    this.commandScripts = new OperationScriptStatistics(jsonData['CS'])
    this.scriptBacktests = jsonData['SB']

    this.exchangeStatistics = jsonData['ES'].map((c: any) => new OperationExchangeStatistics(c))
    this.simulatedStatistics = jsonData['SS'].map((c: any) => new OperationExchangeStatistics(c))
  }
}
