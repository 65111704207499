import { DefaultTheme } from 'styled-components'

import { contrastTheme } from './contrast'
import { darkTheme } from './darker'
import { defaultTheme } from './default'
import { lightTheme } from './light'

export type AppThemeName = 'default' | 'dark' | 'light' | 'contrast'

const AppThemes: Record<AppThemeName, DefaultTheme> = {
  default: { ...defaultTheme },
  dark: { ...darkTheme },
  light: { ...lightTheme },
  contrast: { ...contrastTheme },
}

export default AppThemes
