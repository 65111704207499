export class UserSignals {
  id: string
  name: string
  description: string

  secretKey: string
  published: number

  constructor(jsonData: any) {
    this.id = jsonData['Id']
    this.name = jsonData['Name']
    this.description = jsonData['Description']
    this.secretKey = jsonData['SecretKey']
    this.published = jsonData['Published']
  }
}
