import { EnumIncomingPaymentType } from '@dataObjects/private/webshop/HaasShopIncomingPayment'
import { EnumOutgoingPaymentType } from '@dataObjects/private/webshop/HaasShopOutgoingPayment'
import {ApiRequest, CancellationToken, EnumApiMethod} from '../api/rest/ApiRequest'
import { RestApiBase } from '../api/rest/RestApiBase'

export class WebshopApi extends RestApiBase {
  private baseUrl = 'WebshopAPI.php'

  getMyData(token: CancellationToken): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_MY_DATA',
      endPoint: this.baseUrl,
      token,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getSupportedLicenses(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'SUPPORTED_LICENSES',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  mayPromoteTrial(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'MAY_PROMOTE_TRIAL',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  mayPromoteDeveloper(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'MAY_PROMOTE_DEVELOPER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getAllStoreLicenses(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ALL_LICENSES',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getAllStoreSignals(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ALL_SIGNALS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getAllStoreStrategies(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ALL_STRATEGIES',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getMyProducts(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_MY_PRODUCTS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getStoreProductDetails(productId: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_PRODUCT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        productId,
      },
    })
  }

  editProduct(productId: number, name: string, description: string, price: number, daysLifetime: number, productDiscount: number, volumeDiscount: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_PRODUCT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        productId,
        name,
        description,
        price,
        daysLifetime,
        productDiscount,
        volumeDiscount,
      },
    })
  }

  editProductVisability(productId: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CHANGE_PRODUCT_VISABILITY',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        productId,
      },
    })
  }

  getUserExtensionDeals(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_EXTENSION_OPTIONS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getUserUpgradeDeals(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_UPGRADE_OPTIONS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getUserRenewDeals(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_RENEW_OPTIONS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  // ==================================================================================================================

  getUserDetails(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_USER_DETAILS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  setupUserDetails(fullname: string, adres: string, postalcode: string, place: string, country: string, countryCode: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'SETUP_USER_DETAILS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        fullname,
        adres,
        postalcode,
        place,
        country,
        countryCode,
      },
    })
  }

  // ==================================================================================================================

  getUserLicenses(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_MY_LICENSES',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getUserOrders(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_MY_ORDERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getLatestOrderNo(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_LATEST_ORDER_NO',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  createNewOrder(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CREATE_NEW_ORDER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  addProductToOrder(orderId: number, productId: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ADD_PRODUCT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        orderId,
        productId,
      },
    })
  }

  addProductBySkuToOrder(orderId: number, sku: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'ADD_PRODUCT_BY_SKU',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        orderId,
        sku,
      },
    })
  }

  removeProductFromOrder(orderId: number, productId: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'REMOVE_PRODUCT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        orderId,
        productId,
      },
    })
  }

  getShopOrder(orderId: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_ORDER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        orderId,
      },
    })
  }

  finishShopOrder(orderId: number, paymentMethod?: EnumIncomingPaymentType, reference1?: string, reference2?: string, reference3?: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'FINISH_ORDER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        orderId,
        paymentMethod,
        reference1,
        reference2,
        reference3,
      },
    })
  }

  cancelShopOrder(orderId: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'CANCEL_ORDER',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        orderId,
      },
    })
  }

  setOrderCoupon(orderId: number, coupon: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'SET_COUPON',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        orderId,
        coupon,
      },
    })
  }

  removeOrderCoupon(orderId: number): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'REMOVE_COUPON',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        orderId,
      },
    })
  }

  // ==================================================================================================================

  registerAsAffiliate(affiliateName: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'REGISTER_AS_AFFILIATE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        affiliateName,
      },
    })
  }

  editAffiliate(affiliateName: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'EDIT_AFFILIATE',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        affiliateName,
      },
    })
  }

  getAffiliateOrders(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_AFFILIATE_ORDERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getAffiliateCouponOrders(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_AFFILIATE_COUPON_ORDERS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getAffiliatePayments(): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_AFFILIATE_PAYMENTS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {},
    })
  }

  getRequestAffiliatePayment(amount: number, paymentMethod: EnumOutgoingPaymentType, reference1: string, reference2: string, reference3: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'REQUEST_AFFILIATE_PAYMENT',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        amount,
        paymentMethod,
        reference1,
        reference2,
        reference3,
      },
    })
  }

  // ==================================================================================================================

  setReferal(referalId: string): ApiRequest<any> {
    return new ApiRequest({
      controller: this.controller,
      method: EnumApiMethod.POST,
      channel: 'GET_AFFILIATE_PAYMENTS',
      endPoint: this.baseUrl,
      authenticator: this.authenticator,
      arguments: {
        referalId,
      },
    })
  }
}
