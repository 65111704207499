import { EnumHaasRuntimeType } from '../enums/EnumHaasRuntimeType'
import { EnumHaasScriptStatus } from '../enums/EnumHaasScriptStatus'

export class HaasScriptItem {
  scriptId: string
  name: string
  description: string

  type: EnumHaasRuntimeType
  typeAsString: string

  status: EnumHaasScriptStatus

  commandName: string

  isValid: boolean
  isCommand: boolean
  isFolder: boolean
  canEdit: boolean

  createdUnix: number
  updatedUnix: number

  folderId: number

  dependencies: string[]

  constructor(jsonData: any) {
    this.scriptId = jsonData['SID']
    this.name = jsonData['SN']
    this.description = jsonData['SD']

    this.type = jsonData['ST']
    this.status = jsonData['SS']

    this.commandName = jsonData['CN'] ? `CC_${jsonData['CN']}` : ''
    this.isCommand = jsonData['IC']
    this.isValid = jsonData['IV']

    this.canEdit = this.status === EnumHaasScriptStatus.Private

    this.createdUnix = jsonData['CU'] ?? Date.now() / 1000
    this.updatedUnix = jsonData['UU'] ?? Date.now() / 1000

    this.isFolder = false
    this.folderId = jsonData['FID'] ?? -1

    this.dependencies = jsonData['D'] || []

    this.typeAsString = ''
    switch (this.type) {
      case EnumHaasRuntimeType.Lua:
        this.typeAsString = 'Lua'
        break
      case EnumHaasRuntimeType.Visual:
        this.typeAsString = 'Visual'
        break
      case EnumHaasRuntimeType.Element:
        this.typeAsString = 'TradeBot'
        break
    }
  }
}
