import { NotificationManager } from '@application/Notifications/NotificationManager'
import { ApiResponse, ApiResponseType } from '@services/api/rest/ApiResponse'
import { AxiosResponse } from 'axios'

class ExceptionHandler {
  static LogException(error: unknown | undefined | null | string | Error | ApiResponse | AxiosResponse<ApiResponseType>) {
    if (!error) return

    if (typeof error === 'string') return NotificationManager.error(error)

    if (error instanceof Error && error.message) return NotificationManager.error(error.message)

    if (error instanceof ApiResponse && error.error) return NotificationManager.error(error.error)
  }

  static LogPromiseCatch(e?: Error | string) {
    if (!e) {
      console.log('Empty error..')
      debugger // Keep
      return
    }

    if (typeof e === 'string') {
      NotificationManager.error(e)
      return
    }

    if (typeof e === 'object') {
      if ((e as Object).hasOwnProperty('message')) {
        NotificationManager.error(e.message)
        return
      }
    }

    debugger // Keep
  }

  static LogApiResponseStatusError(e: AxiosResponse<ApiResponseType>) {
    // Failed API request
    const status = e.status
    const statusText = e.statusText
    const haasonlineError = String(e.data.Error)
    NotificationManager.error(`${statusText} (${haasonlineError} - ${status})`)
  }

  static LogApiResponseParseError(response: ApiResponse) {
    // Successful API request
    // Response was unsuccessful (response.Result = false)

    const haasonlineError = String(response.error)
    NotificationManager.error(haasonlineError.toString())
  }
}

export default ExceptionHandler
